import MInfiniteDetector from "./MInfiniteDetector";
import PageableComponent from "@/assets/plugins/mps-view/paging/PageableComponent";

export default {
    install(Vue, options) {
        this.installComponents(Vue);
    },
    installComponents: function (Vue) {
        const components = {
            MInfiniteDetector,
            PageableComponent
        };
        for (let key in components) {
            Vue.component(key, components[key]);
        }
    }
}