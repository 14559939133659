import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

const i = (object, action) => {
    Object.keys(object).forEach(key => {
        const value = object[key];
        action(object, key, value);
        if (isNOU(value)) return;
        if (typeof value === 'object') i(value, action);
    });
};

export default i;
