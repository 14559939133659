<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";
    import {PATH} from "@/constants";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

    export default {
        name: "MFormRentalcompanyCodeDialogPicker",
        extends: MBaseFormPicker,
        data() {
            return {
            }
        },
        computed: {
            display() {
                if (!this.options) return null;
                let _t = this.options.codeValue;
                return _t;
            },

            titleComputed() {
                return this.options.codeValue || this.$translate(this.options.defaultTitle) || this.$translate("선택");
            },
        },
        watch: {
            options: {
                deep: true,
                handler: function () {
                    if (isNOU(this.options.codeValue)) { // 오직 초기화 목적!
                        this.model = this.options.codeValue;
                    }
                }
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>
