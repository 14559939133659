import Event from './event'
import InfiniteScroll from './infinite-scroll'
import Paging from './paging'

export default {
    install(Vue, options) {
        Vue.use(Event, options);
        Vue.use(InfiniteScroll, options);
        Vue.use(Paging, options);
    }
}