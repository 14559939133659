<script>

    const DateTime = require('luxon').DateTime;

    export default {
        name: "MBaseDatePicker",
        props: {
            type: {
                type: String,
                default: 'date'
            },
            label: {
                type: String,
                default: 'date'
            },
            icon: {
                type: String,
                default: undefined
            },
            autoFix: {
                type: Boolean,
                default: false
            },
            today: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            luxonize(date) {
                let luxon;
                if (this.today) {
                    luxon = DateTime.local();
                } else if (date) {
                    luxon = this.$parseDate(date);
                } else if (this.autoFix) {
                    luxon = DateTime.local();
                }
                return luxon;
            }
        },
    }
</script>

<style scoped>

</style>
