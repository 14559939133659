<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";

    export default {
        name: "MFormDateTimePicker",
        extends: MBaseFormPicker,
        data() {
            return {
                date: undefined,
                innerDate: undefined,
                innerTime: undefined,
            }
        },
        computed: {
            displayDate() {
                return this.$dateTime(this.date);
            },
        },
        watch: {
            'innerDate'(newDate) {
                this.updateModel();
            },
            'innerTime'(newTime) {
                this.updateModel();
            },
            'model'(newDatetime) {
                this.sanitize();
            },
            inEditMode(newValue) {
                if (newValue) {
                    this.sanitize();
                }
            }
        },
        created() {
            this.sanitize();
            this.updateDateAndTime();
        },
        methods: {
            updateDateAndTime() {
                const dt = this.$parseDate(this.model);
                if (!!dt) {
                    this.innerDate = dt.startOf('day').toISODate();
                    this.innerTime = dt.get('hour') + ':' + dt.get('minute');
                }
            },

            updateModel() {
                const localDateTime = this.$parseDate(this.innerDate);
                let datetime = localDateTime;

                if (!datetime) return;

                if (!!this.innerTime) {
                    const split = this.innerTime.split(':');
                    const hour = split[0];
                    const minute = split[1];
                    datetime = localDateTime.set({
                        hour: hour,
                        minute: minute,
                    });
                }

                const d = this.$parseDate(datetime);
                this.model = !!d ? d.toISO() : undefined;
            },

            sanitize() {
                const parsed = this.$parseDate(this.model);
                this.date = !!parsed ? parsed.toISO() : undefined;
            }
        }
    }
</script>

<style scoped>
</style>