<!--직접 윈도우 매니저를 소유하고 있지는 않지만, 윈도우 매니저의 하위에 있는 컴포넌트가 윈도우 매니저 기능을 이용하기 위해서 extend 또는 mixin 해야하는 컴포넌트 입니다.-->
<script>

    export default {
        name: "WindowElement",
        data() {
            return {
                registeredEventNames: []
            }
        },
        computed: {
            windowComponent() {
                let hierarchyCount = 0;
                while (true) {
                    hierarchyCount++;
                    let expression = 'this';
                    for (let i = 0; i < hierarchyCount; i++) {
                        expression += '.$parent';
                    }
                    const vue = eval(expression);
                    // 더이상 Vue 객체가 없음.
                    if (vue === undefined) return null;
                    // WindowManager 라면 반환하기.
                    const name = vue.$options.name;
                    if (vue.isWindowComponent) return vue;
                }
            },
            windowManager() {
                return this.windowComponent().windowManager;
            },
        },
        methods: {
            replace(component, params, listeners) {
                this.windowManager.push(component, params, listeners);
            },
            add(component, params, listeners) {
                this.windowManager.add(component, params, listeners);
            },
            remove() {
                this.windowManager.remove();
            },
            removeSelf() {
                this.windowManager.removeSelf();
            },
            on(eventName, callback) {
                this.windowManager.$on(eventName, callback);
                this.registeredEventNames.push(eventName);
            },
            off(eventName) {
                this.windowManager.$off(eventName);
            },
            emit(eventName, ...args) {
                this.windowManager.$emit(eventName, ...args);
            }
        },

    }
</script>