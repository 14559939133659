<script>
    const reg = /\d+/;

    export default {
        name: "TextAreaAutogrow",
        data() {
            return {
                textareaHeight: "0px",
            };
        },
        methods: {
            autoGrow(el) {
                if (!el) return;
                const lineHeight = this.$getStyle(el, "line-height");
                const matched = lineHeight.match(reg);
                if (!matched) return;
                const number = matched[0];
                const unit = lineHeight.replace(number, "");

                el.style.cssText = 'height:auto; padding:0';
                let scrollHeight = el.scrollHeight;

                // textarea 의 height 가 line-height 보다 작다면
                // line-height 를 textarea의 height 로 한다.(최소 1줄 보장)
                if (Number(number) > Number(scrollHeight)) {
                    el.style.cssText = 'height:' + number + "px";
                    this.textareaHeight = lineHeight;
                } else {
                    scrollHeight = Math.floor(scrollHeight / number) * number;
                    el.style.cssText = 'height:' + scrollHeight + 'px';
                    this.textareaHeight = 'height:' + scrollHeight + 'px';
                }
            },
        },
    }
</script>
