import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";

class PrinterAutocomplete {
    _item;
    group = "";
    selected = false;

    constructor(item, group, selected = false) {
        this._item = item || {};
        this.group = group;
        this.selected = selected;
    }

    get groupText() {
        if (!this.group) return "";
        if (this.group === "code") return translationManager.translate("코드모델");
        if (this.group === "printer") return translationManager.translate("계약 | 모니터링 프린터");
        if (this.group === "model") return translationManager.translate("프린터 모델");
        if (this.group === "selected") return translationManager.translate("선택된 항목");
        return "";
    }

    get raw() {
        return this._item;
    }

    get innerPrinterName() {
        if (this.isCodeModel) return this._item.codeValue;
        return this._item.printerModelName;
    }

    get printerName() {
        if (this.isPrinter) return this._item.printerModelName + " [" + this._item.printerSerialNo + "]";
        if (this.isModel) return this._item.printerModelName;
        if (this.isCodeModel) return this._item.codeValue;
        return this._item.printerModelName;
    }

    get key() {
        if (this.group) return this.group;
        if (this.isContractPrinter) return this._item.printerContractId;
        if (this.isMonitoringPrinter) return this._item.monitoringPrinterId;
        if (this.isModel) return this._item.printerModelId;
        if (this.isCodeModel) return this._item.codeKey;
        return this._item.printerModelName;
    }

    get isPrinter() {
        return this.isMonitoringPrinter || this.isContractPrinter;
    }

    get isMonitoringPrinter() {
        return !!this._item.monitoringPrinterId;
    }

    get isContractPrinter() {
        return !!this._item.printerContractId;
    }

    get contractStateName() {
        if (!this.isPrinter) return;
        if (this._item.contractState === "1" || this._item.contractState === "Y") return translationManager.translate("계약중");
        return "";
    }

    get isModel() {
        return !this.isPrinter && this._item.printerModelId;
    }

    get isCodeModel() {
        return !!this._item.codeId;
    }
}

export {PrinterAutocomplete}