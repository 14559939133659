export default class MultipartBuilder {

    constructor() {
        this.form = new FormData();
    }

    appendFile(name, file, filename) {
        if (!!filename) {
            this.form.append(name, file, filename);
        } else {
            this.form.append(name, file);
        }
        return this;
    }

    appendObject(name, object) {
        const json = JSON.stringify(object);
        const blob = new Blob([json], {type: 'application/json'});
        this.form.append(name, blob);
        return this;
    }

    build() {
        return this.form;
    }

}