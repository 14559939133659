const re = /(?:\.([^.]+))?$/;
const imageExtension = [
    "gif",
    "jpg",
    "jpeg",
    "png",
    "svg",
];

const pdfExtension = [
    "pdf"
];

class FileManager {
    extension(path) {
        if (!path) return false;
        return re.exec(path.toLowerCase())[1];
    }

    isImageFile(path) {
        let extension = this.extension(path);
        return this.isImageFileByExtension(extension);
    }

    isImageFileByExtension(extension) {
        if (!extension) return false;
        return imageExtension.includes(extension.toLowerCase());
    }

    isPdfFile(path) {
        let extension = this.extension(path);
        return this.isPdfFileByExtension(extension);
    }

    isPdfFileByExtension(extension) {
        if (!extension) return false;
        return pdfExtension.includes(extension.toLowerCase());
    }
}

const fileManager = new FileManager();

export default fileManager;