const NAMESPACE = "props";

/**
 * getters
 */
const GET_PROP = "GET_PROP";
const GET_PROPS = "GET_PROPS";

/**
 * actions
 */
const SAVE_PROP = "SAVE_PROP";
const SAVE_PROPS = "SAVE_PROPS";

/**
 * mutations
 */
const SET_PROP = "SET_PROP";
const SET_PROPS = "SET_PROPS";


export {
    NAMESPACE,
    // getters
    GET_PROP,
    GET_PROPS,
    // actions
    SAVE_PROP,
    SAVE_PROPS,
    // mutations
    SET_PROP,
    SET_PROPS,
}