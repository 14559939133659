import { httpAxios } from '@/assets/plugins/mps-common/network/http-common'
import userManager from '@/assets/plugins/mps-common/user/user-manager'
import { PATH } from '@/constants'

/**
 * axios 통신 모듈의 인터셉터 기능입니다.
 */
const setup = () => {
  // 로그인 url 목록
  const loginUrls = [PATH.USER_LOGIN]
  // 토큰 재발급 대기 시간 (ms)
  const delayTime = 150

  httpAxios.interceptors.request.use(
    (config) => {
      const token = userManager.accessToken

      // 로그인 url일 경우 토큰을 추가하지 않습니다.
      if (!isLoginUrl(config.url) && token) {
        // access 토큰을 헤더에 주입
        config.headers.Authorization = 'Bearer ' + token // for Spring Boot back-end
        // config.headers['x-access-token'] = token // for Node.js Express back-end
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  httpAxios.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config
      // 토큰 재발급 경로는 처리하지 않습니다.
      if (
        !originalConfig.url.includes(PATH.REFRESH_ACCESS_TOKEN) &&
        err.response
      ) {
        // 401 권한이 없을 경우
        // 로그인 허가가 되었을 경우
        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          userManager.isLoginApproved
        ) {
          // 이미 다른 요청에서 토큰 재발급 요청을 보냈을 경우
          // 잠시 대기 후 요청을 다시 보냅니다.
          if (userManager.isTokenRefreshing) {
            await sleep(delayTime)
            return httpAxios(originalConfig)
          }

          originalConfig._retry = true
          userManager.isTokenRefreshing = true
          try {
            // RefreshToken을 함께 보내 재발급 요청
            const headers = { RefreshToken: userManager.refreshToken }

            const rs = await httpAxios.post(
              PATH.BASE_URL + PATH.REFRESH_ACCESS_TOKEN,
              null,
              { headers }
            )

            // 재발급된 토큰 할당
            userManager.accessToken = rs.data.accessToken
            userManager.refreshToken = rs.data.refreshToken

            // 바로 재요청 보낼 경우 Access 토큰이 안바뀌어 있는 경우가 있어 잠시 대기 합니다.
            await sleep(delayTime)

            return httpAxios(originalConfig)
          } catch (error) {
            return Promise.reject(error)
          } finally {
            userManager.isTokenRefreshing = false
          }
        }
      }
      return Promise.reject(err)
    }
  )

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  /**
   * 로그인 Url인지 확인하는 코드
   * @param url 확인 대상 url
   * @returns {boolean} F: 로그인 Url 아님 / T: 로그인 Url
   */
  function isLoginUrl(url) {
    return loginUrls.filter((e) => url.includes(e)).length > 0
  }
}
export default setup
