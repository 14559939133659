import {DateTime} from "luxon";

const install = () => {
    DateTime.prototype.toServerFormat = function () {
        return this.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ');
    };

    DateTime.prototype.toISOMonth = function () {
        return this.toFormat('yyyy-MM');
    };
}

export default install;
