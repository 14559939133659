<template>
  <v-card>
    <div class="d-flex justify-space-between align-center px__15 pt-3">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold pl__36"
      >
        {{ '비밀번호 찾기' | translate }}
      </v-card-title>

      <v-btn
        :ripple="false"
        class="button__Close"
        text
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>

    <!-- 패스워드 찾기 폼입니다. -->
    <template v-if="!passwordFinded">
      <v-card-text>
        <v-container
          style="height: 330px; padding: 0;"
          class="overflow-y-auto overflow-x-hidden"
        >
          <v-row>
            <v-col
              cols="12"
              style="height: 75px;"
            >
              <v-text-field
                v-model="findKey.userId"
                :maxlength="MAXLENGTH.USER.LOGIN_ID"
                :label="'아이디를 입력하세요' | translate"
                required
              />
            </v-col>
            <v-col
              cols="12"
              style="height: 75px;"
            >
              <v-text-field
                v-model="findKey.userEmail"
                :maxlength="MAXLENGTH.USER.EMAIL"
                :label="'이메일을 입력하세요' | translate"
                required
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="findKey.businessNo"
                :maxlength="MAXLENGTH.COMPANY.BUSINESS_NO"
                :label="'사업자번호를 입력하세요' | translate"
                required
              />
            </v-col>
          </v-row>
          <v-row

            class="text-left"
          >
            <small class="caption  font-weight-bold">{{ '회원님의 소중한 개인 정보 보호를 위하여' | translate
                                                     }}{{ '본인 확인이 필요합니다.' | translate }}
              {{ '아래의 정보 기재를 하시면 가입자가 등록한' | translate }}{{ '이메일로 임시 비밀번호를 발송해 드립니다.' |
                translate }}</small>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="ma-0 mt-2 pa-0"
      >
        <div

          style="width: 100%;"
        >
          <v-btn
            :ripple="false"
            class="button__100per radius__None"
            text
            @click="onClickApply"
          >
            {{ "패스워드 찾기" | translate }}
          </v-btn>
        </div>
      </v-card-actions>
    </template>

    <template v-else>
      <!-- 패스워드를 찾았습니다. 관련 정보를 유저에게 전달합니다. -->
      <v-card-text>
        <v-container style="height: 247px; padding: 0;">
          <v-row>
            <span>{{ '임시 비밀번호가 해당 이메일로 전송되었습니다.' | translate }}</span>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="ma-0 mt-2 pa-0"
      >
        <div

          style="width: 100%;"
        >
          <v-btn
            :ripple="false"
            class="button__100per radius__None"
            text
            @click="onClickClose"
          >
            {{ "닫기" | translate }}
          </v-btn>
        </div>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import {PATH} from "@/constants";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "RentalUserFindPasswordModal",
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                passwordFinded: false,
                findKey: {},
            }
        },
        mounted() {
            this.initInformation();
        },
        created() {
            this.initInformation();
        },
        methods: {
            initInformation() {
                this.passwordFinded = false;
                this.findKey = {
                    userId: null,
                    userEmail: null,
                    businessNo: null,
                }
            },

            onClickClose() {
                setTimeout(() => {
                    this.initInformation();
                }, ETC.VUETIFY_MODAL.CLOSE_DELAY);
                this.close();
            },

            onClickApply() {
                this.requestFindPassword();
            },

            requestFindPassword() {
                this.$request(PATH.USER_FIND_PASSWORD)
                    .setObject(this.findKey)
                    .enqueue()
                    .then(res => {
                        this.passwordFinded = true;
                    })
            },
        },
    }
</script>

<style scoped>

</style>