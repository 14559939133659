<script>
    import {dateHelper as helper} from "../time/date-helper";

    export default {
        name: "DateFormatUtil",
        filters: {
            date: function (value) {
                return helper.formatDate(value);
            },
            time: function (value) {
                return helper.formatTime(value);
            },
            datetime: function (value) {
                return helper.formatDateTime(value);
            },
            dateMonth: function (value) {
                return helper.formatDateMonth(value);
            },
            month: function (value) {
                return helper.formatMonth(value);
            },
            year: function (value) {
                return helper.formatYear(value);
            },
        },
        directives: {
            date: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatDate(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatDate(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
            time: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatTime(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatTime(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
            dateTime: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatDateTime(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatDateTime(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
            month: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatMonth(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatMonth(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
            year: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatYear(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatYear(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
        },
        methods: {
            $dateTimeShort: function (value) {
                return helper.formatDateTimeShort(value);
            },
            $dateMonth: function (value) {
                return helper.formatDateMonth(value);
            },
            $date: function (value) {
                return helper.formatDate(value);
            },
            $time: function (value) {
                return helper.formatTime(value);
            },
            $dateTime: function (value) {
                return helper.formatDateTime(value);
            },
            $month: function (value) {
                return helper.formatMonth(value);
            },
            $year: function (value) {
                return helper.formatYear(value);
            },

        }
    }
</script>
