<template>
  <v-app>
    <my-tool-bar
      :action-mode="actionMode"
      :title="titleComputed"
      :closable="true"
      @click:close="$emit('update:dialog', false)"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          :placeholder="'주소검색' | translate"
          @close="actionMode = false"
          @search="onSearchClicked"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="actionMode = true"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <m-layout>
      <v-layout class="fill-height">
        <v-container
          ref="container"
          class="overflow-y-auto pa-0 ma-0 mx-auto"
          style="height: calc(100vh - 56px);"
        >
          <template v-if="!searchStatusComputed">
            <address-list-item-simple-card
              :items="items"
              @pick="pick => $emit('pick', pick)"
            />

            <!--무한 스크롤 감지기-->
            <m-infinite-detector
              :end-of-data="pager.endOfData"
              :loading="loading"
              @onNext="pager.next()"
            />
          </template>
          <template v-else>
            <empty-data :text="'주소를 검색하여 주십시오.' | translate" />
          </template>
        </v-container>
      </v-layout>
    </m-layout>
  </v-app>
</template>
<script>
    import Pager from "@/assets/plugins/mps-view/paging/pager";
    import {PATH} from "@/constants";
    import MLayout from "@/components/MLayout";
    import AddressListItemSimpleCard from "@/components/items/address/AddressListItemSimpleCard";
    import Address from "@/model/address";
    import EmptyData from "@/components/part/EmptyData";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";

    export default {
        name: "MFormAddressFullDialog",
        components: {ToolbarSearchAction, MyToolBar, EmptyData, AddressListItemSimpleCard, MLayout},
        props: {
            title: null,
            dialog: null,
        },
        data() {
            return {
                pager: undefined,
                items: [],
                loading: false,
                options: {searchKeyword: null},
                finalOptions: undefined,
                searchStatus: false,
                actionMode: false,
            }
        },
        computed: {
            titleComputed() {
                return this.title || this.$translate("프린터 선택");
            },

            searchStatusComputed() {
                return (!this.searchStatus || this.options.searchKeyword.trim() === "") && this.loading === false;
            },
        },
        created() {
            this.pager = new Pager();
            this.pager.onPageChangedListener = (page) => this.loadItems(true);
            // 아직 상세 검색 컴포넌트가 없습니다. mounted 에서 첫 요청을 처리합니다.
        },
        mounted() {
        },
        methods: {
            /**
             *
             */
            createSearchOptions() {
                const options = {};
                // 검색어 할당
                Object.assign(options, this.options);
                return options;
            },
            /**
             *
             */
            async loadItems(loadMore = false, loading = true) {
                if (this.loading) return;
                // 로딩중으로 표시
                this.loading = loading;
                // 검색 옵션만들기. 이전 검색 옵션과 다르다면 dirty 객체로 보고, 처음부터 다시 로드합니다.
                const searchOptions = this.createSearchOptions();
                // 값이 없는 속성은 비교 대상에서 제외합니다.
                this.$deleteUndefined(searchOptions);
                // 다음의 경우 데이터를 모두 제거하고 새로 불러옵니다.
                // 더보기가 이닌 경우 (다시 검색)
                // 검색 조건이 변경된 경우
                if (!loadMore || this.$isDirty(this.finalOptions, searchOptions, true)) {
                    this.items = [];
                    this.pager.page = 1;
                    this.$refs.container.scrollTo({left: 0, top: 0});
                }
                this.finalOptions = this.$cloneDeep(searchOptions);
                // 페이징을 위한 파라미터 얻기.
                // (페이징은 더티 체크 영역이 아닙니다. 검색을 위한 옵션, 즉 키워드 또는 조건 필터만 검색 옵션입니다.)
                Object.assign(searchOptions, this.pager.state);

                // 요청
                try {
                    const response = await this.$request(PATH.SEARCH_ADDRESS)
                        .setObject(searchOptions)
                        .enqueue();
                    const newItems = await this.pager.proceed(response, 'data.results.juso');
                    const wrapped = newItems.map(item => new Address(item));
                    this.items = this.items.concat(wrapped);
                    this.searchStatus = true;
                } catch (e) {

                }
                this.loading = false;
            },

            /**
             * 툴바 검색 버튼이 눌렸습니다.
             * @param keyword
             */
            onSearchClicked(keyword) {
                if (typeof keyword !== "string") return;
                if (keyword.trim() === "") {
                    return;
                }

                this.options.searchKeyword = keyword;

                this.loadItems();
            }
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>