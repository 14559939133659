import Snackbar from './Snackbar';

export default class SnackbarBuilder {
    constructor(eventBus) {
        this.eventBus = eventBus;
        this._message = null;
        this._color = "black";
    }

    message(message) {
        this._message = message;
        return this;
    }

    color(color) {
        this._color = color;
        return this;
    }

    build() {
        return new Snackbar(this.eventBus, {
            message: this._message,
            color: this._color,
        });
    }
}