/**
 * 현재 웹 페이지의 포커스 상태를 주기적으로 추적하며, 변경되면 리스너에게 알려줍니다.
 */
class ActiveDetector {

    _self;
    _eventBus;
    _document;
    _active;
    _interval;
    _listeners = [];

    constructor(eventBus) {
        this._self = this;
        this._document = window.document;
        this._evaluate();
        this._scheduleEvaluation();
    }

    removeActiveStateListener(onActiveStateListener) {
        if (!this._listeners.includes(onActiveStateListener)) return;
        const index = this._listeners.indexOf(onActiveStateListener);
        return this._listeners.splice(index, 1);
    }

    addActiveStateListener(onActiveStateListener) {
        if (this._listeners.includes(onActiveStateListener)) return;
        this._listeners.push(onActiveStateListener);
    }

    _evaluate() {
        this._active = this._document.hasFocus()
    }

    _scheduleEvaluation() {
        this._interval = setInterval(this._intervalHandler(this), 1000);
    }

    _intervalHandler(self) {
        return function () {
            const previousState = self._active;
            self._evaluate();
            // 이전상태와 달라졌다면 리스너에 알려줍니다.
            if (previousState !== self._active) self._notify();
        }
    }

    _notify() {
        this._listeners.forEach((listener) => {
            try {
                listener(this._active);
            } catch (e) {
                console.error(e);
            }
        });
    }
}

const documentActiveStateDetector = new ActiveDetector();

export {ActiveDetector, documentActiveStateDetector}
