/**
 * javascript 의 제한된 primitive 값을 key-value 쌍으로 저장하는 클래스입니다.
 * 타입 별 저장 가능 여부는 다음과 같습니다.
 * boolean : Yes
 * string : Yes
 * number : Yes
 * object : Yes
 * bigint : No
 * symbol : No
 * function : No
 * undefined : No
 */
import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

class Preferences {

    name;
    prefix;

    constructor(name = 'default') {
        this.prefix = name + '.';
    }

    /**
     * key 에 해당하는 데이터를 제거합니다.
     * @param key
     */
    remove(key) {
        localStorage.removeItem(this._namespacedKey(key));
    }

    /**
     * 이 저장소가 처리하는 데이터만 저장소에서 비웁니다.
     * (localStorage 의 모든 데이터를 비우지 않음.)
     */
    clear() {
        const count = localStorage.length;
        for (let i = count - 1; i >= 0; i--) {
            const key = localStorage.key(i);
            if (key == null) continue;
            if (key.startsWith(this.prefix)) localStorage.removeItem(key);
        }
    }

    /**
     * 현재 저장소가 가진 이름을 이용해서 네임스페이스 키를 만듭니다.
     * @param key
     * @returns {*}
     * @private
     */
    _namespacedKey = (key) => this.prefix + key;

    _split = (item) => {
        const separator = item.indexOf(':');
        const type = item.substr(0, separator);
        const payload = item.substr(separator + 1, item.length - separator - 1);
        return {type, payload}
    };

    /**
     * key-value 쌍으로 영속성 데이터를 저장합니다.
     * @param key
     * @param value
     */
    put(key, value) {
        if (isNOU(key)) throw new Error('key must be defined and not null.');

        let item;
        const type = typeof value;
        switch (type) {
            case "boolean":
            case "string":
            case "number":
                item = type + ':' + value;
                break;
            case "object":
                item = type + ':' + JSON.stringify(value);
                break;
            default:
                throw new Error('Could not put the value type is ' + type);
        }

        localStorage.setItem(this._namespacedKey(key), item);
    }

    /**
     * 키에 해당하는 값을 반환합니다.
     * 데이터가 null 이면 defaultValue 를 반환합니다.
     * 만약 데이터가 null 이면서 defaultValue 를 사용하지 않았다면, 기본값으로 null 이 반환됩니다.
     *
     * @param key 반환하려는 데이터를 찾기위한 키
     * @param defaultValue 반환하려는 데이터가 null 인 경우 반환할 기본값.
     * @returns {boolean | string | number | *}
     */
    get(key, defaultValue = null) {
        if (isNOU(key)) throw new Error('key must be defined and not null.');

        const namedKey = this._namespacedKey(key);
        const item = localStorage.getItem(namedKey);
        if (item == null) return defaultValue;
        const {type, payload} = this._split(item);

        let value;
        switch (type) {
            case "boolean":
                value = Boolean(payload);
                break;
            case "string":
                value = String(payload);
                break;
            case "number":
                value = Number(payload);
                break;
            case "object":
                value = JSON.parse(payload);
                break;
            default:
                value = payload;
                break;
        }
        return value || defaultValue;
    }

}

const pref = new Preferences();
export {Preferences, pref}
