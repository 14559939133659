<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required, 'required-blue': !required && requiredBlue}"
    >
      <div class="mobile-input-wrap-title">{{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        > <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <div
        class="mobile-input-wrap-input"
      >
        <span
          v-if="!!subLabel"
          class="mobile-input-wrap-input-sublabel"
        >{{ subLabel }}</span>
        <input
          :value="displayedInternalValue"
          readonly
          v-bind="{...$attrs}"
          @click="sheet = true"
        >
      </div>
    </label>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
    <v-bottom-sheet
      v-model="sheet"
      @click:outside="onClickOutside()"
    >
      <v-sheet
        v-if="sheet"
        class="px-1"
        height="570px"
      >
        <v-row justify="center">
          <v-time-picker
            v-model="internalValue"
            :locale="locale"
            full-width
            :clearable="clearable"
            @click:minute="onClickMinute"
          />
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {DateTime} from "luxon";
import MobileBaseInput from "./MobileBaseInput";
import {runInContext as localDateTime} from "lodash";

export default {
  name: "MobileTimepicker",
  extends: MobileBaseInput,
  props: {
    dateFormat: {
      type: String,
      default: 'yyyy-MM-dd',
    },

  },
  data() {
    return {
      sheet: false,
      clearable: false,
      timeSheet: false,
    }
  },
  computed: {
    displayedInternalValue() {
      if(!this.internalValue || this.internalValue == null)
        return this.dateFormat
      try {
        return this.internalValue
      } catch (e) {
        console.error(e)
        return this.dateFormat
      }
    },
    locale() {
      if (!this.$userManager) return;
      const user = this.user;
      let code = user.languageCode ? user.languageCode : 'en';
      if (user.countryCode) {
        code += '-' + user.countryCode; // e) XX -> XX-KR
      }
      return code;
    },
  },
  watch: {


  },

  methods: {
    onClick(date) {
      this.internalValue = date

      this.sheet = false
    },
    onClickOutside() {
      this.sheet = false
    },
    /**
     * Minute 이 선택될 때 Backguard 를 제거합니다.
     */
    onClickMinute() {
      this.sheet = false
    },


  }
}
</script>

<style lang="scss" scoped>
.mobile-input{
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;
  &-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title{
      display: flex;
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;
      flex: 2;
      &-btn{
        color: #0c98fe;
        margin-left: 4px;
      }
    }
    &-input{
      display: flex;
      align-items: center;
      flex: 3;
      &-sublabel{
        overflow: auto;
        color: #6e6e6e;
        margin-right: 6px;
        &:after{
          content: ':';
        }
      }
      input{
        padding: 0.25rem;
        border: 1px solid #dddddd;
        text-align: right;
        width: 100%;
        flex: 1;
        &:focus{
          outline: none;
        }
        &:disabled{
          background: #dddddd;
        }
      }
      &-unit{
        overflow: auto;
        color: #6e6e6e;
        margin-left: 6px;
      }
    }
    &.required:before{
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }
    &.required-blue:before{
      content: '*';
      color: #0000ff;
      position: absolute;
      left: -3px;
    }
  }
  &-description{
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0.25rem 0.25rem 0;
    /*&:before{
      content: 'ⓘ';
    }*/
  }
}
</style>