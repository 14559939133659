/**
 * 폼 구성요소로 제공 가능한 프로바이더 목록입니다.
 */
import vuetify from "./providers/vuetify";
import mps from "./providers/mps";

const providers = {
    VUETIFY: 'vuetify',
    MPS: 'mps',
};

const elements = {
    vuetify,
    mps,
};

export {providers, elements};
