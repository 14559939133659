<script>
    import {numberHelper as helper} from "@/assets/plugins/mps-common/formatters/number-helper";

    export default {
        name: "NumberFormatUtil",
        filters: {
            currencyUnit: function (value) {
                return helper.formatCurrency(value);
            },
            currencyName: function (value) {
                return helper.formatCurrencyName(value);
            },
            currency: function (value) {
                return helper.formatNumber(value);
            },
            number: function (value) {
                return helper.formatNumber(value);
            },
        },
        directives: {
            currencyUnit: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatCurrency(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatCurrency(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
            currencyName: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatCurrencyName(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatCurrencyName(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
            currency: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatNumber(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatNumber(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
            number: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatNumber(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatNumber(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            }
        },
        methods: {
            $currencyUnit: function (value) {
                return helper.formatCurrency(value);
            },
            $currencyName: function (value) {
                return helper.formatCurrencyName(value);
            },
            $currency: function (value) {
                return helper.formatNumber(value);
            },
            $number: function (value) {
                return helper.formatNumber(value);
            }
        }
    }
</script>
