import DateFormatUtil from "./DateFormatUtil";
import NumberFormatUtil from "./NumberFormatUtil";
import FormatUtil from "@/assets/plugins/mps-common/formatters/FormatUtil";

export default {

    install(Vue, options) {
        Vue.mixin(DateFormatUtil);
        Vue.mixin(NumberFormatUtil);
        Vue.mixin(FormatUtil);
    }

}