import router from "@/router";

/**
 * Vue Router 를 래핑한 클래스입니다.
 *
 * Router 의 행동을 제어하고 상태값을 저장하기 위한 매니저입니다.
 */
class RouterManager {
    /**
     * 마지막 액션이 push 인지, replace 인지 나타냅니다.
     */
    _isLastAction = ROUTER_ACTION.PUSH;

    get lastAction() {
        return this._isLastAction;
    }

    get lastIsPush() {
        return this._isLastAction === ROUTER_ACTION.PUSH;
    }

    get lastIsReplace() {
        return this._isLastAction === ROUTER_ACTION.REPLACE;
    }

    push(data) {
        this._isLastAction = ROUTER_ACTION.PUSH;
        router.push(data);
    }

    replace(data) {
        this._isLastAction = ROUTER_ACTION.REPLACE;
        router.replace(data);
    }

    back() {
        router.back();
    }
}

const ROUTER_ACTION = {
    PUSH: "PUSH",
    REPLACE: "REPLACE",
}

const routerManager = new RouterManager();

export {routerManager, ROUTER_ACTION}