<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <span
          v-if="inEditMode && required"
          class="color__red mr-1"
        >*</span>
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <template v-if="displayable && inEditMode">
          <div class="in-edit-mode">
            <select
              v-model="innerData"
              class="form-select"
              :disabled="disabled"
            >
              <option
                v-for="item in filteredItems"
                :key="item.codeKey"
                :value="item.codeKey"
              >
                {{ item.codeValue }}
              </option>
            </select>
          </div>
        </template>
        <template v-else>
          <div class="in-view-mode">
            <div
              class="form-textarea__fieldset"
              :style="styleObject"
            >
              <textarea
                :value="display"
                class="form-textarea"
                :rows="1"
                disabled
                :placeholder="placeholder"
                type="text"
              />
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import MFormOptionsPicker from "@/assets/plugins/mps-form/providers/MFormOptionsPicker";

    export default {
        name: "MFormMpsOptionsPicker",
        extends: MFormOptionsPicker,
        mixins: [],
        computed: {
          styleObject() {
            return {
              textAlign: this.contentAlign,
            };
          },
        },
    }
</script>
