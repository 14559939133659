<template>
  <v-bottom-sheet
    v-model="sheet"
    :transition="'bottom-sheet-transition-without-close'"
    @click:outside="onClickOutside"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="display"
        :label="label"
        :prepend-icon="icon"
        dense
        readonly
        @click="onClickFocus"
        v-on="on"
      >
        <template #append>
          <v-btn
            v-if="clearable && innerDate"
            icon
            style="margin-top: -2px; width: 30px; height: 30px;"
            @click.stop="onResetClicked"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>
    <v-sheet
      class="text-center"
      :height="noTitleComputed ? '290px' : '370px'"
    >
      <v-row justify="center">
        <v-date-picker
          ref="datepicker"
          :key="datepickerIdentifier"
          v-model="innerDate"
          :day-format="dayFormat"
          :locale="locale"
          :type="innerType"
          full-width
          :allowed-dates="allowedDatesInner"
          scrollable
          :no-title="noTitleComputed"
          @click:date="onClickDate"
          @click:year="onClickYear"
          @click:month="onClickMonth"
        />
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import {DateTime} from "luxon";
  import MBaseDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/MBaseDatePicker";
  import now from "@/assets/plugins/mps-common/time/now";

  export default {
    name: "JDatePicker",
    mixins: [MBaseDatePicker],
    props: {
      value: {
        type: [String, Date, DateTime],
        default: undefined
      },
      autoFix: {
        type: Boolean,
        default: false
      },
      today: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: undefined
      },
      label: {
        type: String,
        default: undefined,
      },
      width: {
        type: String,
        default: '100%'
      },
      clearable: {
          type: Boolean,
        default: false,
      },
      allowedDates: {
        type: Function,
        default: null,
      },
      disableFuture: {
        type: Boolean,
        default: false,
      },
      disablePast: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        fullWidth: this.width === '100%',
        sheet: false,
        innerDate: undefined,
        datepickerIdentifier: 0,
        display: null,
      }
    },
    computed: {
      locale() {
        if (!this.$userManager) return;
        const user = this.user;
        let code = user.languageCode ? user.languageCode : 'en';
        if (user.countryCode) {
          code += '-' + user.countryCode; // e) XX -> XX-KR
        }
        return code;
      },

      innerType() {
        if (this.type === 'year') return 'year';
        return this.type === 'month' ? 'month' : 'date';
      },

      noTitleComputed() {
          return this.innerType === 'year' || this.innerType === 'month';
      },
    },
    watch: {
      value(newDate, oldDate) {
        // 새로 설정된 v-model 이 사용할 수 없을 때, autoFix 가 true 라면 현재 시간으로 수정합니다.
        if (!newDate && this.autoFix) {
          this.innerDate = this.$now().toISODate();
          return;
        }
        this.updateInnerDate(newDate);
      },

      innerDate(newPickerDate, oldPickerDate) {
        this.formatDisplay();
        this.notifyModel(newPickerDate);
      },

      display() {
        this.notifyModel();
      },

      sheet() {
        // 피커가 열릴 때
        if (this.sheet) {
          if (this.innerType === 'year') {
            this.$nextTick(() => {
              let failCount = 0;
              let interval = setInterval(() => {
                try {
                  if (failCount > 5) {
                    clearInterval(interval);
                    interval = null;
                    return;
                  }
                  // 피커 타입이 year 이면 innerDate에 해당하는 년도를 찾아 중앙에 위치시킵니다.
                  let compareValue = !!this.innerDate && !!this.$parseDate(this.innerDate) ? this.$parseDate(this.innerDate).toFormat("yyyy") : now().toFormat("yyyy");
                  const yearsWrapper = this.$refs.datepicker.$el.querySelector(".v-date-picker-years");
                  if (!yearsWrapper) return;
                  const years = yearsWrapper.querySelectorAll("li");
                  if (!years || !years.length) return;
                  let target = null;
                  let i;
                  const futureYears = [];
                  const pastYears = [];
                  const nowTextYear = now().toFormat("yyyy");
                  let found = false;
                  for (i = 0; i < years.length; i++) {
                    let text = (years[i].innerText || "").trim();
                    if (!text) continue;
                    const match = text.match(/\d+/);
                    if (!match && !match.length) return;
                    text = match[0];
                    if (!found) { // 아직 미래 년도입니다.
                      if (text == compareValue) {
                        target = years[i];
                        found = true;
                      }
                      if (!found && parseInt(text) > parseInt(nowTextYear)) futureYears.push(years[i]);
                    } else { // 해당하는 연도를 찾은 후에는 과거 년도가 됩니다.
                      if (parseInt(text) < parseInt(nowTextYear)) pastYears.push(years[i]);
                    }
                  }
                  if (!target) return;
                  setTimeout(() => {
                    yearsWrapper.scrollTo({top: target.offsetTop - 115});
                  }, 100);

                  // 피커 타입이 year 이고 disableFuture 가 true 이면 미래 년도를 disabled 처리합니다.
                  if (this.disableFuture && this.innerType === 'year') {
                    for (let j = 0; j < futureYears.length; j++) {
                      const oldElement = futureYears[j];
                      oldElement.parentNode.removeChild(oldElement);
                      // const newElement = oldElement.cloneNode(true);
                      // oldElement.parentNode.replaceChild(newElement, oldElement);
                      // newElement.classList.add('disabled');
                    }
                  }

                  // 피커 타입이 year 이고 disablePast 가 true 이면 과거 년도를 disabled 처리합니다.
                  if (this.disablePast && this.innerType === 'year') {
                    for (let j = 0; j < pastYears.length; j++) {
                      const oldElement = pastYears[j];
                      oldElement.parentNode.removeChild(oldElement);
                      // const newElement = oldElement.cloneNode(true);
                      // oldElement.parentNode.replaceChild(newElement, oldElement);
                      // newElement.classList.add('disabled');
                    }
                  }

                  clearInterval(interval);
                  interval = null;
                } catch(e) { failCount++; }
              }, 50);
            });
          }
        }
      },
    },
    created() {
      this.updateInnerDate(this.value);
      // this.notifyModel();
    },
    methods: {
      formatDisplay() {
        const d = this.$parseDate(this.innerDate);
        if (!d) {
          this.display = "";
          return;
        }

        if (this.innerType === 'month') {
          this.display = this.$month(this.innerDate);
        } else if (this.innerType === 'year') {
          this.display = d.toFormat("yyyy");
        } else {
          this.display = this.innerDate;
        }
      },

      onClickYear(d) {
        this.$emit("click:year", d);

        if (this.innerType === 'year') {
          this.innerDate = this.$parseDate(d + "-01-01").startOf("day").toISODate();
          this.backGuard.pop();
          this.sheet = false;
          setTimeout(() => this.datepickerIdentifier++, 300);
        }
      },

      onClickMonth(d) {
        this.$emit("click:month", d);
        if (this.innerType === 'month') {
            this.backGuard.pop();
            this.sheet = false;
        }
      },

      updateInnerDate: function (newDate) {
        const luxon = this.luxonize(newDate);
        this.innerDate = luxon ? luxon.toISODate() : undefined;
      },

      dayFormat(date) {
        return DateTime.fromISO(date).get('day');
      },

      notifyModel(date) {
        const luxon = this.$parseDate(date || this.innerDate);
        const value = luxon ? luxon.toISO() : undefined;
        this.$emit('input', value);
        this.sheet = false;
      },

      /**
       * BottomSheet 의 바깥쪽 부분이 선택될 때
       * 설정된 Backguard를 제거합니다.
       */
      onClickOutside() {
        this.backGuard.pop();
      },

      /**
       * 데이트피커가 열릴 때 Backguard 를 설정합니다.
       */
      onClickFocus() {
        this.backGuard.push(() => {
            this.sheet = false;
        });
      },

      /**
       * Date 클릭시 설정된 Backguard를 제거합니다.
       */
      onClickDate(d) {
        this.backGuard.pop();
        this.sheet = false;
        this.$emit("click:date", d);
      },

      /**
       * 초기화버튼이 클릭되었습니다.
       */
      onResetClicked() {
          this.innerDate = null;
          this.$emit('input', null);
      },

      allowedDatesInner(val) {
        if (this.allowedDates) return this.allowedDates(val);
        if (this.disableFuture) {
          switch(this.innerType) {
            case "month":   return this.disableFutureTypeMonth(val);
            case "date":    return this.disableFutureTypeDate(val);
            default: return true;
          }
        }
        if (this.disablePast) {
          switch(this.innerType) {
            case "month":   return this.disablePastTypeMonth(val);
            case "date":    return this.disablePastTypeDate(val);
            default: return true;
          }
        }
        return true;
      },

      disableFutureTypeDate(val) {
        const d = this.$parseDate(val).startOf("day");
        const d2 = this.$now().startOf("day");
        return d.ts <= d2.ts
      },

      disableFutureTypeMonth(val) {
        const d = this.$parseDate(val).startOf("month");
        const d2 = this.$now().startOf("month");
        return d.ts <= d2.ts;
      },

      disablePastTypeDate(val) {
        const d = this.$parseDate(val).endOf("day");
        const d2 = this.$now().endOf("day");
        return d.ts >= d2.ts
      },

      disablePastTypeMonth(val) {
        const d = this.$parseDate(val).endOf("month");
        const d2 = this.$now().endOf("month");
        return d.ts >= d2.ts;
      },
    },
  }
</script>

<style scoped>
  .v-date-picker-title {
    line-height: 1.2;
  }
</style>
