<script>
    import now from "@/assets/plugins/mps-common/time/now";

    const DateTime = require('luxon').DateTime;

    export default {
        name: "MBaseDatePicker",
        props: {
            value: {
                type: [String, Date, DateTime],
                default: undefined
            },
            type: {
                type: String,
                default: 'date'
            },
            label: {
                type: String,
                default: 'date'
            },
            icon: {
                type: String,
                default: undefined
            },
            autoFix: {
                type: Boolean,
                default: false
            },
            today: {
                type: Boolean,
                default: false
            },
            clearable: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                innerDate: undefined,
                innerTime: undefined,
                model: this.value,
                date: null,
            }
        },
        computed:{
            displayDate() {
                return this.$dateTime(this.date);
            },

            locale() {
                if (!this.user) return;
                const user = this.user;
                let code = user.languageCode ? user.languageCode : 'en';
                if (user.countryCode) {
                    code += '-' + user.countryCode; // e) XX -> XX-KR
                }
                return code;
            }
        },
        watch: {
            'value'() {
                this.model = this.value;
            },

            'innerDate'(newDate) {
                this.updateModel();
            },
            'innerTime'(newTime) {
                this.updateModel();
            },
            'model'(newDatetime) {
                this.sanitize();
            },
        },
        created() {
            this.sanitize();
        },
        methods: {
            updateDateAndTime() {
                const dt = this.$parseDate(this.model);
                if (!!dt) {
                    this.innerDate = dt.startOf('day').toISODate();
                    this.innerTime = dt.get('hour') + ':' + dt.get('minute');
                }
            },

            updateModel() {
                const localDateTime = this.$parseDate(this.innerDate);
                let datetime = localDateTime;

                if (!datetime) return;

                if (!!this.innerTime) {
                    const split = this.innerTime.split(':');
                    const hour = split[0];
                    const minute = split[1];
                    datetime = localDateTime.set({
                        hour: hour,
                        minute: minute,
                    });
                }

                this.$emit("input", datetime);
            },

            sanitize() {
                const parsed = this.$parseDate(this.model);
                if (!parsed && this.autoFix) {
                    return this.$emit('input', now().toISO());
                }
                this.date = parsed.toISO();
            }
        },
    }
</script>

<style scoped>

</style>
