<template>
  <div>
    <v-autocomplete
      :id="inputId"
      :value="model"
      :items="list"
      :loading="isLoading || loading"
      :search-input.sync="search"
      flat
      dense
      :no-data-text="noDataText"
      return-object
      :item-text="itemText"
      :item-value="itemValue"
      :placeholder="placeholder"
      :append-icon="iconYn ? 'mdi-magnify' : ''"
      :clearable="clearable"
      solo
      background-color="#f0f0f0"
      :rules="rules"
      :hide-details="true"
      :item-disabled="itemDisabled"
      :value-comparator="valueComparator"
      :disabled="disabled"
      :menu-props="menuProps"
      @change="v => $emit('change', v)"
      @input="onInput"
      @keyup.enter.prevent="onKeyupEnter"
      @update:list-index="onUpdateListIndex"
      @click:append="onKeyupEnter"
      @click:clear="onClear"
      @blur="onBlur"
    >
      <template #item="{item}">
        <slot
          name="item"
          v-bind="{item}"
        >
          <v-icon
            v-if="icon && icon(item)"
            class="mr-2"
            color="#0c98fe"
          >
            {{ icon(item) }}
          </v-icon>
          <div :class="itemClass && itemClass(item)">
            {{ read ? read(item) : byString(item) }}
          </div>
        </slot>
      </template>
      <template #selection="{ item }">
        <slot
          name="selection"
          v-bind="{item}"
        >
          {{ read ? read(item) : byString(item) }}
        </slot>
      </template>
    </v-autocomplete>
    <!-- Print Error Message -->
    <div
      v-if="hasError"
      style="color: red;"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
    import VueAutocompleteFunctions from "@/assets/plugins/vue-autocomplete/VueAutocompleteFunctions";

    export default {
        name: "VueAutocomplete",
        mixins: [VueAutocompleteFunctions],
        props: {
            value: undefined,
            items: {
                type: Array,
                default: () => [],
            },
            read: {
                type: Function,
                default: undefined,
            },
            fetch: {
                type: Function,
                default: undefined,
            },
            searchInput: {
                type: String,
                default: null,
            },
            itemText: {
                type: String,
                default: "text",
            },
            itemValue: {
                type: String,
                default: "value",
            },
            placeholder: {
                type: String,
                default: "Search",
            },
            itemClass: {
                type: Function,
                default: undefined,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: Function,
                default: undefined,
            },
            rules: {
                type: Array,
                default: undefined,
            },
            hideDetails: {
                type: Boolean,
                default: false,
            },
            itemDisabled: {
                // type: [String, Array],
                type: null,
                default: "",
            },
            loading: {
                type: Boolean,
                default: false,
            },
            valueComparator: {
                type: Function,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            noDataText: {
                type: String,
                default: "No Data Available",
            },
            menuProps: {
                type: Object,
                default: undefined,
            },
            inputId: {
                type: String,
                default: undefined
            },
            iconYn: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                model: null,
                isLoading: false,
                search: this.searchInput,
                list: [],
                isInput: false, // enter 키 입력으로 아이템 선택시 search 가 되는것을 방지하기 위한 플래그입니다.
                hasError: false, // rules 유효성 검증에 실패한 경우 true
                errorMessage: "",
                focusItemTimer: null,
                accLevelKey: 'ALL',
            };
        },
        watch: {
            items() {
                this.list = this.items;
            },

            list() {
                this.$emit("update:list", this.list);
            },

            searchInput() {
                this.search = this.searchInput;
            },

            search() {
                this.$emit('update:search-input', this.search);
            },

            model(newValue) {
                if (!newValue) this.isInput = false;
                this.$emit('input', newValue);
            },

            value(newValue) {
                this.model = newValue;
            },
        },
        created() {
            this.model = this.value;
            this.list = this.items;
        },
        methods: {
            /**
             * blur 발생시 rules 를 검사해서 오류 메시지를 출력합니다.
             */
            onBlur() {
                this.validate();
            },

            validate() {
                if (!this.rules || this.rules.length === 0) return;

                for (let i = 0; i < this.rules.length; i++) {
                    const validate = this.rules[i];
                    const result = validate(this.model);
                    // result 는 성공시 true, 실패시 에러 문자열을 반환한다.
                    if (typeof result === 'boolean' && !result) {
                        // boolean 값이지만 실패인 경우 에러는 발생했지만 에러메시지는 없습니다.
                        this.hasError = true;
                        this.errorMessage = "Invalid Data";
                        return; // 함수 종료
                    }

                    if (typeof result === 'string') {
                        this.hasError = true;
                        this.errorMessage = result;
                        return; // 합수 종료
                    }
                }

                // 모든 rules 검사에 통과한 경우
                this.hasError = false;
                this.errorMessage = "";
            },

            onInput(item) {
                this.isInput = true;
                this.model = item;
                this.validate();
            },

            /**
             * 엔터키 입력시 process 메서드를 실행합니다.
             */
            onKeyupEnter() {
                if (this.isInput) {
                    this.isInput = false;
                    return;
                }
                this.$emit('enter', this.search);
                this.process();
            },

            /**
             * Clear 시
             */
            onClear() {
                this.search = null;
                this.model = null;
                this.$emit("click:clear");
            },

            /**
             * fetch 메서드를 실행합니다.
             */
            process() {
                if (!this.fetch) return;
                this.isLoading = true;
                this.fetch(this.search)
                    .then(items => {
                        this.list = items;
                        this.isLoading = false;
                        this.search = null;
                    })
                    .catch(e => {
                        console.error(e);
                        this.isLoading = false;
                    });
            },

            onUpdateListIndex(index) {
                this.$emit("focus-item", this.list[index]);
            },
        }
    }
</script>

<style scoped>
    >>> .v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
        transform: none !important;
    }

    >>> .autucomplete__list__item {
        width: 100%;
    }

    >>> .theme--light.v-input input {
        background-color: rgb(255, 255, 255) !important;
    }
</style>
