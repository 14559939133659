<script>
    export default {
        name: "VueAutocompleteFunctions",
        methods: {
            /**
             * 오브젝트에서 키에 해당하는 값을 가져옵니다. 해당하는 키가 없을 경우 null 을 반환합니다.
             *
             * @param o 객체
             * @param s 키
             * @returns {null|*} null 또는 해당하는 키의 값
             */
            byString(o, s) {
                if (!s) return null;
                s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                s = s.replace(/^\./, '');           // strip a leading dot
                let a = s.split('.');
                for (let i = 0, n = a.length; i < n; ++i) {
                    let k = a[i];
                    if (!this.isNOU(o[k]) && k in o) {
                        o = o[k];
                    } else {
                        return null;
                    }
                }
                return o;
            },
        },
    }
</script>