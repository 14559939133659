<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required}"
    >
      <div class="mobile-input-wrap-title">
        {{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        >
          <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <div
        class="mobile-input-wrap-input"
      >
        <div
          class="add_circle"
          @click="onClick()"
        >
          <v-icon color="#0c98fe">mdi-plus</v-icon>
        </div>
        <div
          v-for="(item, index) in internalValue"
          :key="index"
          style="min-width: 150px;
            margin-right: 4px;"
          class="ma-1"
        >
          <button
            class="attachment_file_button"
            @click="onClick(item)"
          >
            <div
              class="attachment_file_name"
            >
              {{ item.resultStatus === 'T' ? '작성완료' : '서명대기' }}
              <br>
              {{ item.regDatetime | datetime }}
            </div>
            <v-icon>mdi-chevron-right</v-icon>
          </button>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";

export default {
  name: "MobileSlideButton",
  extends: MobileBaseInput,
  props: {
    value: {
      type: Array,
      default: () => []
    },
    buttonType: {
      type: String,
      default: 'file'
    },
  },
  methods: {
    onClick(e) {
      this.$emit('onClick', e)
    },
  }
}
</script>

<style lang="scss" scoped>
.mobile-input {
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;

  &-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    position: relative;

    &-title {
      display: flex;
      width: 100%;
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;

      &-btn {
        color: #0c98fe;
        margin-left: 4px;
      }
    }

    &-input {
      width: 100%;
      overflow-x: scroll;
      display: flex;
      align-items: center;
      padding-bottom: 4px;

       & .add_circle {
         margin-right: 8px;
         color: #0c98fe;
         width: 40px;
         height: 40px;
         padding: 8px;
         border: 1px solid #0c98fe;
         border-radius: 50px;
         min-width: 40px;
         display: flex;
         justify-content: center;
         align-items: center;
       }
    }

    &.required:before {
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }

    &.required-blue:before {
      content: '*';
      color: #0C98FE;
      position: absolute;
      left: -3px;
    }
  }

  &-description {
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0 0.25rem;

    /*&:before {
      content: 'ⓘ';
    }*/
  }
}

.attachment_file_button {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: #f5f5f5;
  padding: 6px 8px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
</style>