import * as symbols from "@/assets/plugins/mps-common/symbols";

/**
 * 파라미터가 비었는지 여부를 검사합니다. 객체 원형이 Symbol isEmpty 함수를 구현한 경우에 해당 객체가 제공하는 식에 의하여 비었는지 여부를 반환합니다.
 * @param v
 * @returns {*}
 */
export default (v) => {
    if (!v) return true; // 값이 없으면 빈걸로 처리.
    if (v[symbols.isEmpty]) return v[symbols.isEmpty]();
    else throw new Error(`instance type of ${typeof v} is not implemented Symbol(mps.isEmpty).`);
};
