<template>
  <v-card>
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title class="d-flex justify-center flex-grow-1 font__20 font-weight-bold">
        {{ '예약 일정 선택' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>


    <v-divider />
    <v-card-text class="pa-0">
      <v-list>
        <v-list-item class="pa-0 pt-5 px-5">
          <v-list-item-content class="text-left">
            <j-date-time-picker
              v-model="editData.reservationDatetime"
              :label="'예약일' | translate"
              type="date"
              can-not-select-past
              auto-fix
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider />

    <v-card-actions class="pa-0">
      <v-row
        justify="space-between"
        class="ma-0"
      >
        <v-btn
          text
          class="button__Left"
          @click="onCancleReservation"
        >
          {{ '예약취소' | translate }}
        </v-btn>

        <v-btn
          text
          class="button__Right"
          @click="onClickApply"
        >
          {{ '예약하기' | translate }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import JDateTimePicker from "@/assets/plugins/mps-vuetify-compat/pickers/JDateTimePicker";
    import now from "@/assets/plugins/mps-common/time/now";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";

    export default {
        name: "AsDenyVuetifyModal",
        components: {JDateTimePicker},
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                editData: {},
            }
        },
        computed: {
            item() {
                return this.propsData.item;
            },
        },
        mounted() {
            this.editData = this.getDefaultState();
        },
        created() {
            this.editData = this.getDefaultState();
        },
        methods: {
            getDefaultState() {
                return {
                    reservationDatetime: this.item.raw.reservationDatetime || now().toISO(),
                }
            },

            onClickClose() {
                this.close();
            },

            /**
             * 예약하기 버튼이 눌러졌습니다.
             */
            onClickApply() {
                const serverData = {
                    reservationDatetime: this.editData.reservationDatetime,
                };

                this.callAndClose(serverData);
            },

            /**
             * 예약 취소
             */
            onCancleReservation() {
                const serverData = {
                    reservationDatetime: null,
                }

                this.callAndClose(serverData);
            },
        }
    }
</script>

<style scoped>
    >>> .v-toolbar__content, .v-toolbar__extension {
        width: 100%;
        justify-content: space-between;
    }

    >>> .v-toolbar__items {
        flex: 1;
        justify-content: center;
    }

    >>> .v-text-field--outlined fieldset {
        border: 1px solid #ddd !important;
    }

    >>> .v-text-field--outlined {
        border-radius: 0px;
    }

</style>