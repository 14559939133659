export default {
    RENTALCOMPANY: {
        ID: 10,
    },
    PRINTER: {
        INSTALL_LOCATION: 255,
        MEMO: 255,
    },
    INSTALL_LOCATION: 255,
    MEMO: 255,
    WORK: {
        WORKORDER_CONTENT: 255,
        MEMO: 255,
        AS_CONTENT: 255,
        CUSTOMER: {
            NAME: 50,
            TELNO: 50,
            MOBILENO: 45,
            EMAIL: 200,
        },
    },
    ADDRESS: {
        DETAIL: 255,
    },
    ACCOUNT: {
        NAME: 50,
        FAX: 50,
        MEMO: 255,
    },
    COMPANY: {
        BUSINESS_NO: 50,
        TELNO: 50,
    },
    USER: {
        BIRTHDAY: 50,
        NAME: 200,
        TELNO: 50,
        MOBILENO: 45,
        EMAIL: 200,
        LOGIN_ID: 20,
        PASSWORD: 20,
    },
    DEVICE: {
        SERIAL_NO: 50,
        INSTALL_LOCATION: 50,
        MEMO: 255,
    },
    COMMUNITY_USER: {
        EMAIL: 255,
        PASSWORD: 255,
        COMPANY_NAME: 50,
        TEL_NUMBER: 50,
        CHARGER_NAME: 255,
    },
}
