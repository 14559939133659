<template>
  <v-layout :align-center="true">
    <!--            닫기 버튼 -->
    <v-btn
      v-if="closable"
      icon
      @click="onCloseClicked"
    >
      <img src="@/assets/images/main/svg/button_Close.svg">
    </v-btn>
    <!--뒤로가기-->
    <v-btn
      v-else
      icon
      @click="onCloseClicked"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <!--검색필드-->
    <v-text-field
      v-if="active || alwaysActive"
      ref="field"
      v-model="keyword"
      v-focus-delay:child-input="500"
      :loading="loading"
      :placeholder="placeholder"
      class="ma-0 caption mr-3"
      dense
      outlined
      type="search"
      hide-details
      clearable
      autocomplete="off"
      @input="onChanged"
      @click:clear="onClear"
      @keydown.native.enter="onSearch"
    />
  </v-layout>
</template>

<script>
/**
 *
 */
    export default {
        name: "ToolbarSearchAction",
        components: {},
        props: {
            closable: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: 'input here'
            },
            loading: {
                type: Boolean,
                default: false
            },
            alwaysActive: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                keyword: this.value,
                onGuard: false,
                timeout: null,
                historyId: undefined,
                active: true,
            };
        },
        watch: {
            value() {
                this.keyword = this.value;
            },
        },
        unmounted() {
            this.$refs.field.$refs.input.blur();
            this.active = false;
        },
        mounted() {
            this.keyword = this.value;
            this.active = true;
            // if (this.historyManager.wasRoute(this.historyId)) this.keyword = "";
            // this.historyId = this.historyManager.uniqueId;
        },
        methods: {
            clear() {
                this.keyword = "";
            },

            onCloseClicked() {
                this.$emit('close');
            },
            // 검색을 위한 행동이 발생했습니다.
            onSearch() {
                if (this.onGuard) return;
                this.$refs.field.$refs.input.blur();
                this.$emit('search', this.keyword);
                clearTimeout(this.timeout);
            },
            // this.keyword 가 변경되었습니다.
            onChanged() {
                if (this.onGuard) return;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.$emit('input', this.keyword);
                }, 350);
            },
            // 아직 this.keyword 가 클리어되지는 않았습니다. 곧 클리어 될 예정입니다.
            onClear() {
                if (this.onGuard) return;
                // this.$emit('input', '');
                this.$emit('search', "");
            },

            select() {
                this.focus();
                const input = this.$refs.field.$el.querySelector('input[type=search]');
                input.select();
            },

            focus() {
                this.$refs.field.focus();
            },
        },
    }
</script>

<style scoped>
    >>> .v-text-field__slot input {
    }

    >>> .v-input__slot {
        box-shadow: 0px 0px 0px 0px !important;
    }
</style>