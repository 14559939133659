<template>
  <v-bottom-sheet
    v-model="sheet"
    @click:outside="onClickOutside"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="displayDate"
        :label="label"
        :prepend-icon="icon"
        dense
        readonly
        @click="onClickFocus"
        v-on="on"
      />
    </template>
    <v-sheet
      v-if="dateSheet"
      class="px-1"
      height="370px"
    >
      <v-row justify="center">
        <v-date-picker
          v-model="innerDate"
          full-width
          :clearable="clearable"
          :min="innerMinDateComputed"
          :locale="userManager.locale"
          @click:date="onClickDate"
        />
      </v-row>
    </v-sheet>
    <v-sheet
      v-else-if="timeSheet"
      height="370px"
      class="px-3"
    >
      <v-row justify="center">
        <v-time-picker
          v-model="innerTime"
          full-width
          :clearable="clearable"
          @click:minute="onClickMinute"
        />
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
    import MBaseDateTimePicker from "@/assets/plugins/mps-vuetify-compat/pickers/MBaseDateTimePicker";
    import now from "@/assets/plugins/mps-common/time/now";

    export default {
        name: "JDateTimePicker",
        mixins: [MBaseDateTimePicker],
        props: {
            width: {
                type: String,
                default: '100%'
            },
            canNotSelectPast: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                fullWidth: this.width === '100%',
                sheet: false,
                dateSheet: false,
                timeSheet: false,
                innerMinDate: null,
            }
        },
        computed: {
            innerMinDateComputed() {
                if (!this.innerMinDate) return null;
                return this.innerMinDate.toISODate();
            },
        },
        watch: {
            'sheet'() {
                this.canNotSelectPastFunc();
                this.dateSheet = true;
                this.timeSheet = false;
            },
        },
        methods: {
            canNotSelectPastFunc() {
                if (this.canNotSelectPast) this.innerMinDate = now().startOf('day');
                else this.innerMinDate = null;
            },

            onClickDate() {
                this.dateSheet = false;
                this.timeSheet = true;
            },

            onClickMinute() {
                this.backGuard.pop();
                this.sheet = false;
            },

            /**
             * BottomSheet 의 바깥쪽 부분이 선택될 때
             * 설정된 Backguard를 제거합니다.
             */
            onClickOutside() {
                this.backGuard.pop();
            },

            /**
             * 데이트피커가 열릴 때 Backguard 를 설정합니다.
             */
            onClickFocus() {
                this.backGuard.push(() => {
                    this.sheet = false;
                });
            },
        },
    }
</script>

<style scoped>
    .v-date-picker-title {
        line-height: 1.2;
    }

    >>> .v-picker__title {
        height: 87px !important;
    }

    >>> .v-time-picker-clock__ampm > .v-picker__title__btn:first-child {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #333;
    }

    >>> .v-time-picker-clock__ampm > .v-picker__title__btn.v-picker__title__btn--active {
        color: #0C98FE !important;
    }

    >>> .v-time-picker-clock__ampm > .v-picker__title__btn:nth-child(2) {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #333;
    }
</style>
