import {createNamespacedHelpers} from "vuex";
import {NAMESPACE} from "./keys";
import mutations from "./mutations"
import getters from "./getters"
import actions from "./actions"

const {mapState, mapGetters, mapActions} = createNamespacedHelpers(NAMESPACE);
export {mapState, mapGetters, mapActions};

export default {
    namespaced: true,
    state: {
        props: {}
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
};