import { render, staticRenderFns } from "./MFormVuetifyDialogDatePicker.vue?vue&type=template&id=12dc5807&scoped=true"
import script from "./MFormVuetifyDialogDatePicker.vue?vue&type=script&lang=js"
export * from "./MFormVuetifyDialogDatePicker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12dc5807",
  null
  
)

export default component.exports