<script>
    import BaseListener from "@/assets/plugins/mps-common/event/base-listener";
    import {ON_LOADING_OVERLAY_CLOSE_REQUESTED} from "@/constants/listeners";

    /**
     * loading Overlay 를 닫습니다.
     */
    export default {
        name: "OnLoadingOverlayCloseRequestListener",
        extends: BaseListener,
        created() {
            this.registerListener(ON_LOADING_OVERLAY_CLOSE_REQUESTED, this.onLoadingOverlayCloseRequested);
        },
        beforeDestroy() {
            this.unregisterListener(ON_LOADING_OVERLAY_CLOSE_REQUESTED, this.onLoadingOverlayCloseRequested);
        }
    }
</script>
