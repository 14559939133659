/**
 * 권한을 Canonical Name 배열로 관리한다.
 */
class PermissionList {
    _permissions = [];

    constructor(permissions) {
        this._permissions = permissions;
    }

    findNodeByCanonicalName(canonicalPermissionName) {
        return this._permissions.includes(canonicalPermissionName);
    }
}

class PermissionCanonicalNameManager {

    _list;

    constructor(permissions = []) {
        this.setPermissions(permissions);
    }

    clearPermissions() {
        this._list = undefined;
    }

    setPermissions(permissions) {
        this._list = new PermissionList(permissions);
    }

    hasPermission(canonicalPermissionName) {
        return this.hasPermissionByCanonicalName(canonicalPermissionName);
    }

    hasPermissionByCanonicalName(canonicalPermissionName) {
        if (!this._list) return false;
        return !!this._list.findNodeByCanonicalName(canonicalPermissionName);
    }
}


const permissionCanonicalNameManager = new PermissionCanonicalNameManager();
export {permissionCanonicalNameManager}
