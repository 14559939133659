<script>
    import MBaseFormElement from "@/assets/plugins/mps-form/providers/MBaseFormElement";

    export default {
        name: "MFormPhoneNumber",
        extends: MBaseFormElement,
        data() {
            return {
            };
        },
    }
</script>