import clone from "./clone";
import {isNOU} from "../condition";

const removeAllByKeys = (obj) => {
    if (!obj || typeof obj !== 'object') return;

    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        delete obj[keys[i]];
    }
}

/**
 * 원본 데이터를 대상에 복사하며, Vue 반응성을 추가합니다.
 * 깊은 복사를 수행합니다. (객체 참조 복사가 아닌 원시형 값을 조사해서 복사)
 *
 * @param vm 뷰 인스턴스.
 * @param src 원본.
 * @param dst 대상.
 */
export default (vm, src, dst) => {
    // 반응성 복사를 위해서는 Vue 인스턴스가 필요합니다.
    if (isNOU(vm)) throw new Error(`To clone reactively, vm(Vue instance) must be required.`);
    // 반응성 복사는 대상 객체가 반드시 존재해야 합니다.
    if (isNOU(dst)) throw new Error(`To clone reactively, the dst(Destination object) must be required and defined.`);
    // 뷰 인스턴스의 $set 함수로 복사를 수행합니다. 반응성이 추가되어 복사됩니다.
    const processor = (src, dst, key, value) => vm.$set(dst, key, value);

    removeAllByKeys(dst);
    clone(src, dst, processor);
};
