<template>
  <v-card
    class="ma-0"
    style="padding: 14px 15px !important; border-bottom: 1px solid #ddd;"
    flat
    width="100%"
    @click="onClick"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      three-line
      class="pa-0"
    >
      <v-list-item-content class="pa-0">
        <div
          class="text-left"
          style="font-weight: bold; font-size: 1.1rem;"
        >
          <span>{{ item.raw.zipNo }}</span>
        </div>
        <div
          class="text-left"
          style="white-space: pre-wrap; font-weight: bold; font-size: 1.0rem; line-height: 1.3"
        >
          <span>{{ '도로명' | translate }} : {{ item.raw.roadAddr }}</span>
        </div>
        <v-list-item-subtitle class="text-left">
          <span>{{ '지번' | translate }} : {{ item.raw.jibunAddr }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    export default {
        name: "AddressItemSimpleCard",
        components: {},
        props: {
            item: {type: Object, default: undefined},
        },
        computed: {
        },
        methods: {
          onClick() {
              this.$emit('pick', this.item.raw);
          },
        }
    }
</script>

<style scoped>
    .v-application .subtitle-2 {
        font-size: 0.875rem;
        font-weight: inherit !important;
        letter-spacing: 0.0071428571em !important;
        line-height: 1.375rem;
        font-family: "Roboto", sans-serif !important;
    }

    .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
      border-radius: 0 !important;
    }
</style>