import {isEmpty} from "@/assets/plugins/mps-common/expansions/condition";
import parseDate from "@/assets/plugins/mps-common/time/parse-date";

class UtilManager {
    sanitizeDatetime(v) {
        const d = parseDate(v);
        return !!d ? d.toISO() : null
    }

    getUniqueId() {
        return Math.random().toString(36).substr(2, 9);
    }

    trimAllSpaces(str) {
        try {
            return str.split(' ').join('');
        } catch (err) {
            console.error('PrinterModal trimAllSpaces Error! ' + err);
            return '';
        }
    }

    trim(s) {
        if (isNOU(s)) return null;
        if (typeof s !== 'string') {
            throw new Error("trim params s should be string");
        }
        return s.trim();
    }

    searchByKeyword(obj, keyword, keyArr) {
        if (!obj) throw new Error("obj must be Object");
        if (!keyword || !this.trimAllSpaces(keyword)) return true;
        if (isEmpty(keyArr)) throw new Error("keyArr must be array and not empty");
        for (let i = 0; i < keyArr.length; i++) {
            const key = keyArr[i];
            const str = this.byString(obj, key);
            if (!str || !this.trimAllSpaces(str)) continue;
            if (this.trimAllSpaces(str).toLowerCase().indexOf(this.trimAllSpaces(keyword).toLowerCase()) > -1) return true;
        }
        return false;
    }

    byString(o, s) {
        if (!s) return null;
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        let a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return null;
            }
        }
        return o;
    }

    assign(o, s, v) {
        if (!s) return null;
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        let a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in o) {
                if (i < (n - 1)) {
                    o = o[k];
                } else {
                    o[k] = v;
                    o = o[k];
                }
            } else {
                return null;
            }
        }
        return o;
    }
}

const utilManager = new UtilManager();

export default utilManager;