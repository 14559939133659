import MobileInput from "./MobileInput";
import MobileSelect from "./MobileSelect"
import MobileDatepicker from "./MobileDatepicker";
import MobileGroupHeader from './MobileGroupHeader'
import MobileButton from "./MobileButton";
import MobileSlideButton from "./MobileSlideButton";
import MobilePrinterAutoComplete from './MobilePrinterAutoComplete'
import MobileAddress from "./MobileAddress";
import MobileTimepicker from "./MobileTimepicker";

const install = (Vue, options) => {
  Vue.component('MobileInput',MobileInput)
  Vue.component('MobileSelect',MobileSelect)
  Vue.component('MobileDatepicker',MobileDatepicker)
  Vue.component('MobileGroupHeader', MobileGroupHeader)
  Vue.component('MobileButton', MobileButton)
  Vue.component('MobileSlideButton', MobileSlideButton)
  Vue.component('MobilePrinterAutoComplete', MobilePrinterAutoComplete)
  Vue.component('MobileAddress', MobileAddress)
  Vue.component('MobileTimepicker', MobileTimepicker)
};

export default {install}