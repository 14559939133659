<template>
  <div
    :style="styleObject"
    class="m--pull--to--refresh"
  >
    <slot
      :refreshing="refreshing"
      :state="state"
      :triggered="triggered"
      name="handle"
    >
      <div class="m-pull-to-refresh-default">
        {{ refreshing?'loading...' : (triggered ? 'Release to refresh' :'Pull to refresh') }}
      </div>
    </slot>
  </div>
</template>

<script>
    import {EDGE, SCROLL_STATE, ScrollDetectorMobile} from "@/assets/plugins/mps-mobile/event/scroll-detector-mobile";
    import {Bounce, TweenLite} from "gsap";

    export default {
        name: "MPullToRefresh",
        props: {
            container: {
                type: HTMLElement,
                default: undefined
            },
            height: {
                type: String,
                default: '100px'
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                SCROLL_STATE: require("@/assets/plugins/mps-mobile/event/scroll-detector-mobile").SCROLL_STATE,
                isMounted: false,
                detector: undefined,
                state: undefined,
                triggered: false,
                handleHeight: 0,
                pulled: 0,
                refreshing: false,
                tween: undefined
            }
        },
        computed: {
            parent() {
                if (!this.isMounted) return;
                return this.$el.parentElement;
            },
            styleObject() {
                return {
                    height: this.height,
                    transform: `translatey(${this.pulled - this.handleHeight}px)`,
                }
            }
        },
        watch: {
            /**
             * 현재 컴포넌트의 높이가 변경되었습니다.
             * @param newHeight
             * @param oldHeight
             */
            handleHeight(newHeight, oldHeight) {
                if (this.detector) this.detector.threshold = newHeight;
            },
            /**
             * 상호작용에 의한 '당겨서 새로고침'의 상태가 변경되었습니다.
             * @param newState
             * @param oldState
             */
            state(newState, oldState) {
                if (SCROLL_STATE.IDLE === newState) {
                    if (!(this.triggered || this.refreshing)) this.returnBackHandle();
                    this.triggered = false;
                }
            },
            /**
             * 현재 컴포넌트의 translateY 값 입니다.
             * 이 값은 this.detector 의 onPullListener 에서 변경됩니다.
             * @param newValue
             * @param oldValue
             */
            pulled(newValue, oldValue) {
                this.$emit('pulled', newValue);
            },
            /**
             */
            triggered(newValue, oldValue) {
                if (newValue) {
                    this.$emit('triggered');
                } else {
                    this.refreshing = true;
                    this.$emit('refresh');
                }
            },
            loading(newState, oldState) {
                this.refreshing = newState;
            },
            /**
             * 로딩이 종료되면 PTR 이 되돌아갑니다.
             */
            refreshing(newState, oldState) {
                if (newState) {

                } else {
                    this.returnBackHandle();
                }
            }
        },
        created() {
            console.log('created');
        },
        mounted() {
            this.isMounted = true;
            this.updateLayout();
            this.detector = new ScrollDetectorMobile(this.container || this.$el.parentElement);
            this.detector.onScrollChangedListener = this.onScrollChanged;
            this.detector.onScrollStateChangedListener = this.onScrollStateChanged;
            this.detector.onEdgeChangedListener = this.onEdgeChanged;
            this.detector.onOverScrollChangedListener = this.onOverScrollChanged;
            this.detector.on();
        },
        updated() {
            this.updateLayout();
        },
        beforeDestroy() {
            this.detector.off();
        },
        methods: {
            /**
             *
             */
            updateLayout() {
                // 현재 컴포넌트의 크기가 변경된 경우에만 갱신합니다.
                if (this.handleHeight !== this.$el.clientHeight) this.handleHeight = this.$el.clientHeight;
            },

            returnBackHandle() {
                this.tween = TweenLite.to(this, 0.5, {pulled: 0, ease: Bounce.easeOut});
            },

            /**
             * @param state
             * @param scrollTop
             * @param visibleHeight
             * @param contentHeight
             */
            onScrollChanged(state, scrollTop, visibleHeight, contentHeight) {
                if (this.loading) return;
                console.log(`MPullToRefresh/onScrollChanged: `);
            },
            /**
             * 스크롤 상태가 변경되면 호출됩니다.
             * @param newState
             * @param oldState
             */
            onScrollStateChanged(newState, oldState) {
                if (this.loading) return;
                this.state = newState;
                console.log(`MPullToRefresh/onScrollStateChanged: ${newState}`);
            },
            /**
             * @param newEdge
             * @param oldEdge
             */
            onEdgeChanged(newEdge, oldEdge) {
                if (this.loading) return;
                console.log(`MPullToRefresh/onEdgeChanged: ${newEdge}`);
            },
            /**
             *
             * @param edge 어디서 당겨졌는지 여부. EDGE.TOP or EDGE.BOTTOM
             * @param distance 당겨진 px. 양수면 TOP 에서 음수면 BOTTOM 에서 당겨졌습니다.
             */
            onOverScrollChanged(edge, distance) {
                if (this.loading || this.refreshing || this.triggered) return;
                if (this.state !== SCROLL_STATE.PULLING) return; // 풀링이 아니면 아무것도 안함.
                if (edge !== EDGE.TOP) return; // 상단 당김이 아니면 아무것도 안함.
                console.log(`MPullToRefresh/onOverScroll: ${distance}px`);
                if (this.tween) {
                    this.tween.kill();
                    this.tween = null;
                }
                // threshold 를 보정
                let dy = distance;
                if (dy > this.handleHeight) {
                    this.triggered = true;
                    dy = this.handleHeight;
                }
                // 당김 값 수정.
                this.pulled = dy;
            }
        }
    }
</script>

<style scoped>
    .m--pull--to--refresh {
        z-index: 1;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        background-color: magenta;
    }

    .m-pull-to-refresh-default {
        height: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        align-items: flex-end;
        border: magenta 1px solid;
    }
</style>