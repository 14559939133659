<template>
  <v-toolbar-items>
    <div class="align-center font__20 font-weight-bold pt-4">
      {{ text }}
    </div>
  </v-toolbar-items>
</template>

<script>
    export default {
        name: "DialogTitle",
        props: {
            text: {
                type: String,
                default: "No title"
            },
        }
    }
</script>

<style scoped>

</style>