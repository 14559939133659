<template>
  <v-menu
    v-model="activate"
    :close-on-content-click="false"
    offset-y
    transition="scroll-y-transition"
  >
    <template v-slot:activator="{ on }">
      <slot
        :displayDate="internalDate"
        :icon="icon"
        :label="label"
        :on="on"
        name="field"
      >
        <v-text-field
          v-model="internalDate"
          :label="label"
          :prepend-icon="icon"
          dense
          readonly
          v-on="on"
        />
      </slot>
    </template>
    <v-date-picker
      v-model="internalDate"
      :day-format="dayFormat"
      :locale="locale"
      :type="type"
      @input="activate = false"
    />
  </v-menu>
</template>

<script>
    import {DateTime} from "luxon";
    import MBaseDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/MBaseDatePicker";

    export default {
        name: "MDatePicker",
        mixins: [MBaseDatePicker],
        props: {
            value: {
                type: [String, Date, DateTime],
                default: undefined
            },
            autoFix: {
                type: Boolean,
                default: false
            },
            today: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: undefined
            },
            label: {
                type: String,
                default: 'date'
            },
            width: {
                type: String,
                default: '100%'
            }
        },
        data() {
            return {
                fullWidth: this.width === '100%',
                activate: false,
                internalDate: undefined,
            }
        },
        computed: {
            locale() {
                if (!this.$userManager) return;
                const user = this.user;
                let code = user.languageCode ? user.languageCode : 'en';
                if (user.countryCode) {
                    code += '-' + user.countryCode; // e) XX -> XX-KR
                }
                return code;
            }
        },
        watch: {
            value(newDate, oldDate) {
                // 새로 설정된 v-model 이 사용할 수 없을 때, autoFix 가 true 라면 현재 시간으로 수정합니다.
                if (!newDate && this.autoFix) {
                    this.internalDate = this.$now().toISODate();
                    return;
                }
                this.updateInternalDate(newDate);
            },
            internalDate(newPickerDate, oldPickerDate) {
                this.notifyModel(newPickerDate);
            }
        },
        created() {
            this.updateInternalDate(this.value);
            // this.notifyModel();
        },
        methods: {
            updateInternalDate: function (newDate) {
                const luxon = this.luxonize(newDate);
                this.internalDate = luxon ? luxon.toISODate() : undefined;
            },
            dayFormat(date) {
                return DateTime.fromISO(date).get('day');
            },
            notifyModel(date) {
                const luxon = this.$parseDate(date || this.internalDate);
                const value = luxon ? luxon.toISO() : undefined;
                this.$emit('input', value);
            }
        },
    }
</script>

<style scoped>

</style>
