<script>
    import {ON_LOADING_OVERLAY_CLOSE_REQUESTED, ON_LOADING_OVERLAY_OPEN_REQUESTED} from "@/constants/listeners";

    export default {
        name: "LoadingOverlayUser",
        mixins: [],
        data() {
            return {
            }
        },
        methods: {
            _startOverlay(options) {
                this.notifyEvent(ON_LOADING_OVERLAY_OPEN_REQUESTED, {
                    options: options,
                });
            },

            _stopOverlay() {
                this.notifyEvent(ON_LOADING_OVERLAY_CLOSE_REQUESTED);
            },

            startOverlay(options) {
                this._startOverlay(options);
            },

            stopOverlay() {
                this._stopOverlay();
            }
        },
    }
</script>