<template>
  <div style="height: 100%; overflow-y: hidden; background: white;">
    <my-tool-bar
      :action-mode="true"
      :closable="true"
      @click:close="onClickClose"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          ref="toolbarSearchAction"
          closable
          :placeholder="'주소검색!' | translate"
          @close="onClickClose"
          @search="onSearchClicked"
        />
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px); overflow-y: hidden;">
      <template v-if="searchStatusComputed">
        <!-- 인피니트 리스트 -->
        <vue-infinite-loader
          :fetch="fetch"
          :identifier="identifier"
          item-key="key"
        >
          <template #default="{ item }">
            <address-item-simple-card
              :item="item"
              @pick="onClickApply"
            />
          </template>
        </vue-infinite-loader>
      </template>
      <template v-else>
        <empty-data :text="'주소를 검색하여 주십시오.' | translate" />
      </template>
    </div>
  </div>
</template>
<script>
    import {PATH} from "@/constants";
    import Address from "@/model/address";
    import EmptyData from "@/components/part/EmptyData";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import AddressItemSimpleCard from "@/components/items/address/AddressItemSimpleCard";
    import axios from 'axios'

    export default {
        name: "MFormAddressGoogleFullModal",
        components: {
            AddressItemSimpleCard,
            ToolbarSearchAction, MyToolBar, EmptyData
        },
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                searchOptions: {searchKeyword: null},
                historyId: undefined,
                identifier: 0,
            }
        },
        computed: {
            searchStatusComputed() {
                return this.searchOptions.searchKeyword && this.searchOptions.searchKeyword.trim();
            },
        },
        mounted() {
            this.searchOptions.searchKeyword = "";
        },
        methods: {
            async fetch(page, pageSize) {
                this.searchOptions.pageNumber = page;
                this.searchOptions.pageSize = pageSize;

                // const response = await this.$request(PATH.PUBLIC.ADDRESS_KOREA)
                //     .setObject(this.searchOptions)
                //     .catch()
                //     .enqueue();
                // if (this.$isEmpty(response.data)) {
                //   return [];
                // } else {
                //   const wrapped = response.data.map(item => new Address(item));
                //   return wrapped;
                // }
              try {
                const response = await axios.create({
                  baseURL: `https://maps.googleapis.com/maps/api/place/autocomplete/json`
                    + `?input=${encodeURI(this.searchOptions.searchKeyword)}`
                    + `&key=AIzaSyAyXbhv9ug7OsxAkU2NvbssVd-ferpuKCQ`,
                  headers: {
                    'Sec-Fetch-Mode': 'no-cors', // 원하는 모드로 설정
                  }
                }).get()

                console.log('HSTEST:: ', response)
              } catch (e) {
                console.log('HSTEST:: error ', e)
              }
            },

            /**
             * 툴바 검색 버튼이 눌렸습니다.
             * @param keyword
             */
            onSearchClicked(keyword) {
                this.searchOptions.searchKeyword = keyword;
                this.$nextTick(() => {
                  this.identifier++;
                });
            },

            /**
             * 닫기 행동을 정의합니다.
             */
            onClickClose() {
                this.close();
            },

            onClickApply(data) {
                this.callAndClose(data);
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>