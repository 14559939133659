const JSON_PREFIX = '$JSON:';

const put = function (key, data) {
    if (typeof data === 'object') {
        localStorage.setItem(key, JSON_PREFIX + JSON.stringify(data));
    } else {
        localStorage.setItem(key, data);
    }
};

const get = function (key, defaultValue) {
    const data = localStorage.getItem(key);
    if (data) {
        if (data.startsWith(JSON_PREFIX)) {
            const dataPart = data.substr(JSON_PREFIX.length, data.length);
            return JSON.parse(dataPart);
        } else {
            return data;
        }
    }
    return defaultValue ? defaultValue : null;
};

const remove = function (key) {
    if (!key || key === '')
        return
    localStorage.removeItem(key)
}

export {put, get, remove};