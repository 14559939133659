export const isElement = (obj) => {
    try {
        //Using W3 DOM2 (works for FF, Opera and Chrome)
        return obj instanceof HTMLElement;
    }
    catch(e){
        //Browsers not supporting W3 DOM2 don't have HTMLElement and
        //an exception is thrown and we end up here. Testing some
        //properties that all elements have (works on IE7)
        return (typeof obj==="object") &&
            (obj.nodeType===1) && (typeof obj.style === "object") &&
            (typeof obj.ownerDocument ==="object");
    }
};

export const getEventListeners = (element, eventName) => {
   return element.getStorage().get('prototype_event_registry').get(eventName);
};

export const removeEventListeners = (element, eventName) => {
    let events = getEventListeners(element, eventName);
    if (!events || !Array.isArray(events) || events.length === 0) return;
    events.forEach(e => {
        element.removeEventListener(eventName, e);
    });
};