<template>
  <v-card>
    <div class="d-flex justify-space-between align-center px__15 pt-3">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold pl__36"
      >
        {{ '아이디 찾기' | translate }}
      </v-card-title>

      <v-btn
        :ripple="false"
        class="button__Close"
        text
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>


    <template v-if="!findedLoginId">
      <v-card-text>
        <v-container style="height: 170px; padding: 0;">
          <v-row>
            <v-col
              cols="12"
              style="height: 75px;"
            >
              <v-text-field
                v-model="findKey.businessNo"
                :label="'사업자번호를 입력하세요' | translate"
                :maxlength="MAXLENGTH.COMPANY.BUSINESS_NO"
                required
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="findKey.userMobileno"
                :label="'핸드폰번호를 입력하세요' | translate"
                :maxlength="MAXLENGTH.USER.MOBILENO"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <div>{{ findedLoginId }}</div>
          </v-row>
        </v-container>
        <div
          class="text_alignL"
        >
          <small class="caption  font-weight-bold">{{ '회원님의 소중한 개인 정보 보호를 위하여' | translate
                                                   }}
            {{ '본인 확인이 필요합니다.' | translate }}{{ '위의 정보를 입력하세요.' | translate }}</small>
        </div>
      </v-card-text>
      <v-card-actions
        class="ma-0 mt-2 pa-0"
      >
        <div
          style="width: 100%;"
        >
          <v-btn
            :ripple="false"
            class="button__100per radius__None"
            text
            @click="onClickApply"
          >
            {{ '아이디 찾기' | translate }}
          </v-btn>
        </div>
      </v-card-actions>
    </template>
    <!-- 유저 정보를 찾았다면 찾은 로그인 아이디를 사용자에게 보여줍니다. -->
    <template v-else>
      <v-card-text>
        <v-container style="height: 170px; padding: 0;">
          <v-row>
            <v-col
              cols="12"
              class="text-left"
            >
              <span style="font-size: 1rem">{{ '찾은 로그인 아이디' | translate }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="text-left"
            >
              <span style="font-size: 1.25rem;">{{ findedLoginId }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="ma-0 mt-2 pa-0"
      >
        <div
          style="width: 100%;"
        >
          <v-btn
            :ripple="false"
            class="button__100per radius__None"
            text
            @click="onClickClose"
          >
            {{ '닫기' | translate }}
          </v-btn>
        </div>
      </v-card-actions>
    </template>
  </v-card>
</template>
<script>
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import {PATH} from "@/constants";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "RentalUserFindIdModal",
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                findedLoginId: null,
                findKey: {
                },
            }
        },
        mounted() {
            this.initInformation();
        },
        created() {
            this.initInformation();
        },
        methods: {
            initInformation() {
                this.findedLoginId = null;
                this.findKey = {
                  businessNo: null,
                  userMobileno: null,
                }
            },

            onClickClose() {
                setTimeout(() => {
                  this.initInformation();
                }, ETC.VUETIFY_MODAL.CLOSE_DELAY);
                this.close();
            },

            onClickApply() {
                this.requestFineId();
            },

            requestFineId() {
                this.$request(PATH.USER_FIND_ID)
                    .setObject(this.findKey)
                    .enqueue()
                    .then(res => {
                        this.findedLoginId = res
                    });
            },
        },
    }
</script>

<style scoped>
    >>> .v-tabs-bar {
        margin-bottom: 40px !important;
    }

    .tn_idpw_search {
        display: flex;
        position: relative;
        margin-top: 10px;
    }

    .tn_idpw_search button {
        position: relative;
        flex: 1;
        font-size: 14px;
    }

    .tn_idpw_search button:first-child:after {
        content: "";
        position: absolute;
        right: 2px;
        top: 12px;
        width: 1px;
        height: 12px;
        background: #aaa;
    }


    .tn_idpw_search button:nth-child(4):after {
        content: "";
        position: absolute;
        right: -5px;
        top: 12px;
        width: 1px;
        height: 12px;
        background: #aaaaaa;
    }


    .tn_logo {
        display: block;
        width: 100%;
        height: 190px;
        background: url('/img/tn_logo.png') no-repeat center;
        background-size: 89px;
    }

    .elevation-0 {
        color: white;
    }

    .text_alignL {
        text-align: left;
    }

    .input_height {
        height: 75px;
    }

    >>> .v-dialog,
    >>> .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
        border-radius: 0 !important;
    }

    >>> .v-application--wrap {
        min-height: initial;
    }

</style>