const NAMESPACE = "dashboard";

// getters
const GET_SALES_SUM_DATA = "GET_SALES_SUM_DATA";
const GET_SALES_LIST_DATA = "GET_SALES_LIST_DATA";
const GET_ACCOUNT_SUM_DATA = "GET_ACCOUNT_SUM_DATA";
const GET_ACCOUNT_LIST_DATA = "GET_ACCOUNT_LIST_DATA";
const GET_CONTRACT_LIST_DATA = "GET_CONTRACT_LIST_DATA";
const GET_WORK_SUM_DATA = "GET_WORK_SUM_DATA";
const GET_WORK_LIST_DATA = "GET_WORK_LIST_DATA";
const GET_AUTO_SUM_DATA = "GET_AUTO_SUM_DATA";


// actions
const GET_SETTING_DATA = "GET_SETTING_DATA";
const UPDATE_SETTING = "UPDATE_SETTING";
const SET_SALES = "SET_SALES";
const SET_ACCOUNT = "SET_ACCOUNT";
const SET_CONTRACT = "SET_CONTRACT";
const SET_WORK = "SET_WORK";
const SET_AUTO = "SET_AUTO";
const UPDATE_COMMENT = "UPDATE_COMMENT"

// mutations
const INIT = 'INIT'
const SET_DATA = 'SET_DATA'

export {
    NAMESPACE,
    GET_SETTING_DATA,
    UPDATE_SETTING,
    GET_SALES_SUM_DATA,
    GET_SALES_LIST_DATA,
    GET_ACCOUNT_SUM_DATA,
    GET_ACCOUNT_LIST_DATA,
    GET_CONTRACT_LIST_DATA,
    GET_WORK_SUM_DATA,
    GET_WORK_LIST_DATA,
    GET_AUTO_SUM_DATA,
    SET_SALES,
    SET_ACCOUNT,
    SET_CONTRACT,
    SET_WORK,
    SET_AUTO,
    INIT,
    SET_DATA,
    UPDATE_COMMENT
}

