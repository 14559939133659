export const getStyle = function (el, styleProp) {
    let value, defaultView = (el.ownerDocument || document).defaultView;
    // W3C 표준 방법:
    if (defaultView && defaultView.getComputedStyle) {
        // sanitize property name to css notation
        // (hypen separated words eg. font-Size)
        styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
        return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    }
    // 인터넷 익스플로러 핵
    else if (el.currentStyle) {
        // 프로퍼티 이름을 카멜케이스로 새니타이즈
        styleProp = styleProp.replace(/\-(\w)/g, function (str, letter) {
            return letter.toUpperCase();
        });
        value = el.currentStyle[styleProp];
        // 인터넷 익스플로러의 단위를 px 로 변경
        if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
            return (function (value) {
                const oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
                el.runtimeStyle.left = el.currentStyle.left;
                el.style.left = value || 0;
                value = el.style.pixelLeft + "px";
                el.style.left = oldLeft;
                el.runtimeStyle.left = oldRsLeft;
                return value;
            })(value);
        }
        return value;
    }
}