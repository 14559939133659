import {httpAxios} from "./http-common";
import {DEBUG} from "@/constants/env";
import VueAccessor from "../app/VueAccessor";
import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";
import {ON_LOADING_OVERLAY_CLOSE_REQUESTED, ON_LOADING_OVERLAY_OPEN_REQUESTED} from "@/constants/listeners";

const METHOD = {
    GET: 'get',
    POST: 'post',
};

export default class HttpClient extends VueAccessor {

    constructor() {
        super();
        this.name = "httpClient";
    }

    /**
     * GET 요청을 수행합니다.
     *
     * @param url 호출 주소
     * @param params GET 주소 파라미터
     * @param headers 헤더
     * @returns {*}
     */
    get(url, params = {}, headers = {}) {
        return this.request(METHOD.GET, url, params, null, headers);
    }

    /**
     * POST 요청을 수행합니다.
     *
     * @param url 호출 주소
     * @param data POST 바디 데이터
     * @param headers 헤더
     * @returns {*}
     */
    post(url, data = {}, headers = {}) {
        return this.request(METHOD.POST, url, null, data, headers);
    }

    multipart(url, form = new FormData(), headers = {}) {
        // headers['Content-Type'] = 'multipart/form-data';
        return this.request(METHOD.POST, url, null, form, headers);
    }

    request(method, url, params = {}, data = {}, headers) {
        // 원격 요청을 위한 설정.
        const config = {
            method: method,
            url: url,
            headers: headers,
            params: params,
            data: data,
        };
        return this.createPromise(config);
    }

    createPromise(request, complete, catchManually = false, loading, ms) {
        // 인증 헤더 삽입.
        // axiosSetupInterceptors 에서 토큰을 주입합니다.
        // this._concatAuthorizationHeader(request.headers);
        // logging
        this.logRequest(request);
        // return promise
        return new Promise((resolve, reject) => {
            if (loading) { // 로딩 오버레이를 표시합니다.
                this.vm.notifyEvent(ON_LOADING_OVERLAY_OPEN_REQUESTED);
            }

            httpAxios(request)
                .then(response => {
                    if (loading) { // 로딩 오버레이를 닫습니다.
                        this.vm.notifyEvent(ON_LOADING_OVERLAY_CLOSE_REQUESTED);
                    }

                    setTimeout(() => {
                        this.handleResolve(response, resolve, complete)
                    }, ms);
                })
                .catch(error => {
                    if (loading) { // 로딩 오버레이를 닫습니다.
                        this.vm.notifyEvent(ON_LOADING_OVERLAY_CLOSE_REQUESTED);
                    }

                    if (catchManually && error.response.status != 401) reject(error);
                    else this.handleReject(error, reject, complete, catchManually)
                });
        });
    }

    logRequest(request) {
        if (!DEBUG) return;
        console.log(`Axios/Request -> [${request.method}] ${request.url}`);
        console.log(`Axios/Request -> headers : ${JSON.stringify(request.headers, null, 4)}`);
        console.log(`Axios/Request -> params : ${JSON.stringify(request.params, null, 4)}`);
        console.log(`Axios/Request -> body : ${JSON.stringify(request.data, null, 4)}`);
    }

    logResponse(response) {
        if (!DEBUG) return;
        const request = response.config;
        console.log(`Axios/Response -> [${request.method}] ${request.url}`);
        console.log(`Axios/Response -> headers : ${JSON.stringify(response.headers, null, 4)}`);
        console.log(`Axios/Response -> params : ${JSON.stringify(response.params, null, 4)}`);
        console.log(`Axios/Response -> body : ${JSON.stringify(response.data, null, 4)}`);
    }

    /**
     * 요청이 성공한 경우에 호출되는 콜백입니다.
     * @param response
     * @param resolve
     * @param onComplete 성공/실패 여부와 관계없이 요청 종료를 콜백하기위한 함수
     */
    handleResolve(response, resolve, onComplete) {
        if (onComplete) onComplete();
        this.logResponse(response);
        if (resolve) {
            resolve(response.data);
        } else {
            console.warn('There is no resolve function => ' + JSON.stringify(response.config, null, 4));
        }
    }

    /**
     * * 요청이 실패한 경우에 호출되는 콜백입니다.
     * @param error
     * @param reject
     * @param onComplete 성공/실패 여부와 관계없이 요청 종료를 콜백하기위한 함수
     * @param catchManually Promise catch 를 직접 처리하려는지 여부.
     */
    handleReject(error, reject, onComplete, catchManually) {
        console.error(error);
        if (onComplete) onComplete();
        let humanReadable = false;
        let message = null;
        // 일단 서버가 응답은 했습니다.
        if (error.response) {
            // 로깅
            this.logResponse(error.response);
            // 일반 유저가 해석할 수 없는 오류에서 필터 가능한 오류를 필터 처리해 봅니다.
            const code = error.response.status;
            switch (code) {
                // BAD_REQUEST
                // 클라이언트에서 보낸 데이터를 기반으로 서버에서 처리중에 오류가 발생했습니다.
                // 서버에서 파라미터가 잘 못 되었다고 응답했습니다. 단, 서버가 처리를 잘 못 했을수도 있습니다.
                case 400:
                    console.error('The remote server said BAD_REQUEST');
                    break;
                // UNAUTHORIZED - 더이상 처리를 진행하지 않습니다. 주의하세요.
                // 인증 오류 입니다. 토큰을 제거하고 로그인 화면으로 보냅니다.
                // 추후, 토큰 갱신이 가능해 지면 토큰을 갱신하도록 변경해야 합니다.
                case 401:

                    this.vm.$alert(translationManager.translate('로그인 인증 시간이 초과되어 로그아웃 됩니다.'))
                        .then(() => {
                            this.vm.$userManager.logout();
                        });
                    // ::::::::::::::::::::::::::::::::::::::::::::::::::
                    // 이 응답은 더 이상 처리될 필요가 없습니다.
                    // ::::::::::::::::::::::::::::::::::::::::::::::::::
                    return;
                // MediaType error
                case 413:
                    this.vm
                      .$alert(translationManager.translate('서버에 요청한 용량이 너무 큽니다. 첨부파일 또는 입력한 내용을 확인해 주세요'))
                    break;
                case 415:
                    break;
                case 500:
                    // 임대업체 로그인 불허일 경우
                    // server : ErrorCode.E_COMPANY_AUTH
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.code === -1035
                    ) {
                        // 로그인이 미허가 설정
                        this.vm.$userManager.isLoginApproved = false
                        this.vm
                            .$alert(translationManager.translate('로그인 권한이 없습니다.'))
                            .then(() => {
                                this.vm.$userManager.logout()
                            })
                    }
                    break
            }
            // data 가 있고 HRI(Human Readable Information 이 존재 한다면 일반 유저가 해석할 수 있는 메시지입니다.)
            const data = error.response.data;
            if (data.hri) {
                humanReadable = true;
                message = data.hri;
            }
        }
        // 응답 바디가 없다면, 어떤 요청에서 오류가 발생했는지 해석합니다.
        else if (error.request) {
            console.error(error.request);
            message = error.message;
        }
        // 응답/요청 둘 다 존재하지 않는다면 오류 자체를 해석합니다. (요청 자체를 시도하지 못함)
        else {
            console.error(error);
            message = error.message;
        }

        // 이 HTTP 작업을 요청한 요청자가 오류를 직접 처리하기를 원합니다.
        if (catchManually) {
            reject(error);
        }
        // 직접 처리합니다.
        else {
            // 수집된 데이터로 메시지를 출력합니다.
            if (humanReadable) {
                this.vm.$alert(translationManager.translate(message));
            } else {
                this.vm.$alert(translationManager.translate('네트워크 오류가 발생했습니다.'));
            }
        }
    }

    _concatAuthorizationHeader(headers = {}) {
        const accessToken = this.vm.$userManager.accessToken;
        if (accessToken) {
            headers['Authorization'] = "Bearer " + accessToken;
        } else {
            delete headers['Authorization'];
        }
        return headers;
    };

    enqueue(request) {
        return this.createPromise(request.config);
    }

}
