import {SAVE_PROP, SET_PROP, SAVE_PROPS, SET_PROPS} from "./keys";

export default {
    [SAVE_PROP]({commit}, payload) {
        commit(SET_PROP, payload);
    },

    [SAVE_PROPS]({commit}, payload) {
        commit(SET_PROPS, payload);
    },
}