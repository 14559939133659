<script>
    import Permissions from "@/constants/permissions";

    export default {
        name: "PermissionCanonicalMixin",
        data() {
            return {
                Permissions: Permissions,
            }
        }
    }
</script>