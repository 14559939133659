import {FILTER_BUTTON_TYPE} from "../filter-button-types";

import AsSearchFilterButtonWithModal
    from "@/components/app/toolbar/component/filter-button/vuetify/AsSearchFilterButtonWithModal";
import AccountSearchFilterButtonWithModal
    from "@/components/app/toolbar/component/filter-button/vuetify/AccountSearchFilterButtonWithModal";
import DeviceSearchFilterButtonWithModal
    from "@/components/app/toolbar/component/filter-button/vuetify/DeviceSearchFilterButtonWithModal";
import PrinterSearchFilterButtonWithModal
    from "@/components/app/toolbar/component/filter-button/vuetify/PrinterSearchFilterButtonWithModal";
import printerDailySearchFilterButtonWithModal
    from "@/components/app/toolbar/component/filter-button/vuetify/PrinterDailySearchFilterButtonWithModal";
import ConsultNoteSearchFilterButtonWithModal
    from "@/components/app/toolbar/component/filter-button/vuetify/ConsultNoteSearchFilterButtonWithModal.vue";

export default {
    [FILTER_BUTTON_TYPE.MAIN_AS]: AsSearchFilterButtonWithModal,
    [FILTER_BUTTON_TYPE.MAIN_ACCOUNT]: AccountSearchFilterButtonWithModal,
    [FILTER_BUTTON_TYPE.MAIN_PRINTER]: PrinterSearchFilterButtonWithModal,
    [FILTER_BUTTON_TYPE.MAIN_DEVICE]: DeviceSearchFilterButtonWithModal,
    [FILTER_BUTTON_TYPE.MAIN_PRINTER_DAILY]: printerDailySearchFilterButtonWithModal,
    [FILTER_BUTTON_TYPE.CONSULT_NOTE]: ConsultNoteSearchFilterButtonWithModal,
}