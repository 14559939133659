import VueAccessor from "../app/VueAccessor";

class MPSPermissionManager extends VueAccessor {

    async hasPermission(permission) {
        const permissionStatus = await navigator.permissions.query({name: permission});
        console.log(permissionStatus.state); // granted, denied, prompt
        permissionStatus.onchange = function () {
            console.log("Permission changed to " + this.state);
        };
        return permissionStatus.state === 'granted';
    }

    /**
     * @param request {@link MPSPermissionRequest}
     */
    async requestPermission(request) {
        const granted = await this.queryToNavigator(request.permission);
    }

    queryToNavigator(permission) {
        return new Promise(async (resolve, reject) => {
            if (await this.hasPermission(permission)) {
                resolve();
            } else {
                reject();
            }
        });
    }
}

const permissionManager = new MPSPermissionManager();//싱글톤
export {MPSPermissionManager, permissionManager}
