<script>
    import {formatHelper as helper} from "@/assets/plugins/mps-common/formatters/format-helper";
    import {format} from "@/assets/plugins/mps-common/formatters/string-formatter";

    export default {
        name: "FormatUtil",
        filters: {
            serial: function (value) {
                return helper.formatSerial(value);
            },
        },
        directives: {
            serial: {
                bind(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatSerial(binding.value);
                },
                inserted(el, binding, vnode, oldVnode) {
                },
                update(el, binding, vnode, oldVnode) {
                    el.innerHTML = helper.formatSerial(binding.value);
                },
                unbind(el, binding, vnode, oldVnode) {
                },
            },
        },
        methods: {
            $serial: function (value) {
                return helper.formatSerial(value);
            },
            $format: function (key, ...args) {
                return format(key, ...args);
            },
            $formatTranslation: function (key, ...args) {
                return this.$format(this.$translate(key), ...args);
            },
        }
    }
</script>