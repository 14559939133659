<script>
    import MBaseFormElement from "@/assets/plugins/mps-form/providers/MBaseFormElement";

    export default {
        name: "MBaseFormVueTheMask",
        extends: MBaseFormElement,
        watch: {
            model() {
                if (!this.model) return;
                this.model = this.model.toUpperCase();
            },
        },
    }
</script>