<template>
  <v-textarea
    ref="textarea"
    v-model="model"
    :clearable="clearable"
    :label="label"
    :loading="loading"
    :readonly="readonly"
    :disabled="disabled"
    :placeholder="placeholder"
    :rules="rules"
    :auto-grow="true"
    :rows="1"
  />
</template>

<style scoped>
    textarea {
        overflow: visible;
    }
</style>

<script>
    import MBaseFormTextarea from "@/assets/plugins/mps-form/providers/MBaseFormTextarea";

    export default {
        name: "MFormVuetifyTextarea",
        extends: MBaseFormTextarea,
        data() {
            return {
                mount: false,
            }
        },
        computed: {
            textarea() {
                if (!this.mount) return;
                return this.$refs.textarea.$el.getElementsByTagName('textarea')[0]
            }
        },
        mounted() {
            this.mount = true;
        },
    }
</script>
