import axiosSetupInterceptors from "./service/authentication/axiosSetupInterceptors";

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

const appSetting = {
    mounted: false, // App.vue 가 mouted 되면 true 로 변경된다.
};

Vue.prototype.$appSetting = appSetting;

///////////////////////////////////////////////////////////////////////////
// MPS
///////////////////////////////////////////////////////////////////////////
import MPS from './plugins/mps' // MPS 플러그인
import My from './plugins/my' // 현재 프로젝트 전용 플러그인
import Common from './assets/plugins/common'
Vue.use(Common);
///////////////////////////////////////////////////////////////////////////
// Plugins
///////////////////////////////////////////////////////////////////////////
import VueNumeric from 'vue-numeric'
import VueTheMask from 'vue-the-mask'
import StyleMixin from "@/StyleMixin";
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor, /* { default global options } */);

/**
 * vue-infinite-loading 설치
 */
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading);


import InfiniteLoader from '@/assets/plugins/infinite-loader';
Vue.use(InfiniteLoader);

import Lodash from "@/plugins/lodash";
Vue.use(Lodash);

import PinchZoom from 'vue-pinch-zoom';
Vue.component('pinch-zoom', PinchZoom);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// import Cleave from '@/assets/plugins/cleave'
// Vue.use(Cleave);
////////////////////////////////////////////////////////////////////////////
Vue.config.productionTip = false;

Vue.use(My);
Vue.use(MPS);

///////////////////////////////////////////////////////////////////////////
// Plugins
///////////////////////////////////////////////////////////////////////////
Vue.use(VueNumeric);
Vue.use(VueTheMask);

///////////////////////////////////////////////////////////////////////////
// 전역 CSS 클래스 파일
///////////////////////////////////////////////////////////////////////////
Vue.mixin(StyleMixin);

import VueAutoComplete from "@/assets/plugins/vue-autocomplete";
Vue.use(VueAutoComplete);

// axios 인터셉터 설정
axiosSetupInterceptors()

const vm = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
});
vm.$mount('#app');
Vue.prototype.$vm = vm;
router.vm = vm;
store.vm = vm;

export {vm, appSetting};
