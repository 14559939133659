const NOTICE_SORT = {
    /**
     * 일반
     */
    GENERAL: "001",
    /**
     * 자료실
     */
    REFERENCE: "002",
    /**
     * 업데이트
     */
    UPDATE: "003",
    /**
     * 제품정보
     */
    PRODUCT_INFORMATION: "004",
    /**
     * 업계동향
     */
    INDUSTRY_TRENDS: "005",
}

export {NOTICE_SORT}