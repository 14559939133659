<template>
  <div style="overflow: hidden; pointer-events: none">
    <!--다이알로그가 보일때는 블락킹-->
    <!--    <block-layer :block="showing" />-->
    <!-- 랜덤하게 showing이 true가 되어도 콘텐츠가 보이지 않는 버그가 있음 -->
    <!-- 그래서 v-dialog 의 값을 항상 true로 주고 그 위 요소에 v-model 값을 주어서 버그 fix -->
    <div
      v-if="showing"
      class="text-xs-center"
    >
      <v-dialog
        :value="true"
        :persistent="persistent"
        width="500"
      >
        <!--                <template v-slot:activator="{ on }">-->
        <!--                    <v-btn color="red lighten-2" dark v-on="on">Click Me</v-btn>-->
        <!--                </template>-->
        <v-card>
          <div
            class="d-flex justify-space-between align-center"
            style="padding: 8px 0"
          >
            <v-card-title
              class="headline"
              primary-title
            >
              {{ title }}
            </v-card-title>
            <v-btn
              v-if="shouldShowNegative"
              class="button__Close"
              text
              @click="onCloseClicked"
            >
              <!--{{ buttonNegativeText }}-->
              <img src="@/assets/images/main/svg/button_Close.svg">
            </v-btn>
          </div>

          <v-card-text
            class="pa-2 font__16"
            style="min-height: 70px"
          >
            <div
              v-for="(text, index) in message.split('\n')"
              :key="index"
            >
              {{ text }}
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-0">
            <v-row class="mx-0">
              <v-btn
                v-if="shouldShowNegative"
                :class="{'button__50per': shouldShowPositive, 'button__100per': !shouldShowPositive}"
                text
                :loading="loading"
                @click="onCancelClicked"
              >
                {{ buttonNegativeText }}
              </v-btn>
              <v-btn
                v-if="shouldShowPositive"
                :class="{'button__50per': shouldShowNegative, 'button__100per': !shouldShowNegative}"
                text
                :loading="loading"
                @click="onConfirmClicked"
              >
                {{ buttonPositiveText }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
    import {ACTION_HIDE_DIALOG, ACTION_SHOW_DIALOG} from "@/assets/plugins/mps-dialog/constants";

    export default {
        name: "DialogOverlay",
        // components: {BlockLayer},

        data() {
            return {
                showing: false,
                title: null,
                message: null,
                buttonNegativeText: null,
                buttonNegativeCallback: null,
                buttonPositiveText: null,
                buttonPositiveCallback: null,
                loading: false,
            }
        },

        computed: {
            /**
             *
             * @returns {Boolean}
             */
            shouldShowNegative() {
                return !(this.$isNOU(this.buttonNegativeText) || this.$isNOU(this.buttonNegativeCallback));
            },
            /**
             *
             * @returns {Boolean}
             */
            shouldShowPositive() {
                return !(this.$isNOU(this.buttonPositiveText) || this.$isNOU(this.buttonPositiveCallback));
            },
            /**
             *
             * @returns {Boolean}
             */
            persistent() {
                return this.shouldShowNegative || this.shouldShowPositive;
            }
        },

        created() {
            this.$mps_dialog_event_bus.$on(ACTION_SHOW_DIALOG, this.showDialog);
            this.$mps_dialog_event_bus.$on(ACTION_HIDE_DIALOG, this.hideDialog);
        },

        mounted() {
        },

        beforeDestroy() {
            this.$mps_dialog_event_bus.$off(ACTION_SHOW_DIALOG, this.showDialog);
            this.$mps_dialog_event_bus.$off(ACTION_HIDE_DIALOG, this.hideDialog);
        },

        methods: {
            spreadOptions: function (options) {
                this.type = options.type;
                this.title = options.title;
                this.message = options.message;
                this.buttonNegativeText = options.buttonNegativeText;
                this.buttonNegativeCallback = options.buttonNegativeCallback;
                this.buttonPositiveText = options.buttonPositiveText;
                this.buttonPositiveCallback = options.buttonPositiveCallback;
            },
            showDialog(options) {
                if (this.showing) return;
                if (this.$isNOU(options)) return;
                this.spreadOptions(options);
                this.showing = true;
                this.backGuard.push(() => {
                    this.buttonNegativeCallback();
                    this.showing = false;
                });
            },
            hideDialog() {
                this.backGuard.pop();
                this.showing = false;
            },
            onCloseClicked() {
                this.backGuard.pop();
                this.showing = false;
            },
            onCancelClicked() {
                this.backGuard.pop();
                this.buttonNegativeCallback();
                this.showing = false;
            },
            onConfirmClicked() {
                this.backGuard.pop();
                this.buttonPositiveCallback();
                this.showing = false;
            },
        }
    }
</script>

<style scoped>
    >>> .v-dialog {
        border-radius: 0 !important;
    }

    >>> .v-sheet {
        border-radius: 0 !important;
    }

    .button__100per {
        width: 100% !important;
        background: #f4f4f4 !important;
        font-size: 0.938rem !important;
        color: #000 !important;
        height: 50px !important;
    }
</style>
