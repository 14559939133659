/**
 * 파라미터들이 모두 같은 타입인지 여부를 비교합니다.
 * @param args
 * @returns {boolean}
 */
export default (...args) => {
    const length = args.length;
    if (length < 2) throw new Error(`Could not compare. An array as parameter must be longer or equal than 2.`);
    for (let i = 0; i < length - 1; i++) {
        if (typeof args[i] !== typeof args[i + 1]) return false;
    }
    return true;
};
