<template>
  <v-app>
    <my-tool-bar
      :action-mode="actionMode"
      :title="titleComputed"
      :closable="true"
      @click:close="$emit('update:dialog', false)"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          v-model="options.searchKeyword"
          :placeholder="'모델/시리얼' | translate"
          @close="actionMode = false"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="actionMode = true"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <m-layout>
      <v-layout class="fill-height">
        <v-container
          ref="container"
          class="overflow-y-hidden pa-0 ma-0 mx-auto"
          style="height: calc(100vh - 56px);"
        >
          <v-tabs
            v-model="tab"
            background-color="white"
            class="fill-height"
            center-active
            fixed-tabs
          >
            <v-tab
              v-if="whetherLoadPrinters"
              key="printer"
            >
              {{ '프린터' | translate }}
            </v-tab>
            <v-tab
              v-if="whetherLoadUserModels"
              key="userModel"
            >
              {{ '유저' | translate }}
            </v-tab>
            <v-tab
              v-if="whetherLoadPrinterModels"
              key="model"
            >
              {{ '모델' | translate }}
            </v-tab>

            <v-tabs-items
              v-model="tab"
              class="fill-height"
            >
              <v-tab-item
                key="printer"
                class="fill-height overflow-y-auto pb-10"
              >
                <template v-if="!loading">
                  <template v-if="filteredPrinterListBySearchKeyword.length > 0">
                    <printer-list-item-simple-card
                      :items="filteredPrinterListBySearchKeyword"
                      @pick="pick => $emit('pick', pick)"
                    />
                  </template>
                  <template v-else>
                    <empty-data :text="'프린터가 없습니다.' | translate" />
                  </template>
                </template>
                <template v-else>
                  <progress-loading />
                </template>
              </v-tab-item>
              <v-tab-item
                key="userModel"
                class="fill-height overflow-y-auto pb-10"
              >
                <template v-if="!loadingCodes">
                  <template v-if="filteredUserPrinterListBySearchKeyword.length > 0">
                    <code-list-item-simple-card
                      :items="filteredUserPrinterListBySearchKeyword"
                      @pick="pick => $emit('pick', { printerModelName: pick.codeValue })"
                    />
                  </template>
                  <template v-else>
                    <empty-data :text="'유저 모델이 없습니다' | translate" />
                  </template>
                </template>
                <template v-else>
                  <progress-loading />
                </template>
              </v-tab-item>
              <v-tab-item
                key="model"
                class="fill-height overflow-y-auto pb-10"
              >
                <template v-if="!loading">
                  <template v-if="filteredPrinterModelListBySearchKeyword.length > 0">
                    <model-list-item-simple-card
                      :items="filteredPrinterModelListBySearchKeyword"
                      @pick="pick => $emit('pick', pick)"
                    />
                  </template>
                  <template v-else>
                    <empty-data :text="'프린터 모델이 없습니다' | translate" />
                  </template>
                </template>
                <template v-else>
                  <progress-loading />
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-container>
      </v-layout>
    </m-layout>
  </v-app>
</template>
<script>
    import {PATH} from "@/constants";
    import MLayout from "@/components/MLayout";
    import EmptyData from "@/components/part/EmptyData";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
    import PrinterListItemSimpleCard from "@/components/items/printer/PrinterListItemSimpleCard";
    import Printer from "@/model/printer";
    import ModelListItemSimpleCard from "@/components/items/model/ModelListItemSimpleCard";
    import Code from "@/model/code";
    import CodeListItemSimpleCard from "@/components/items/code/CodeListItemSimpleCard";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";

    export default {
        name: "MFormPrinterFullDialog",
        components: {
            ToolbarSearchAction,
            MyToolBar,
            CodeListItemSimpleCard,
            ModelListItemSimpleCard,
            PrinterListItemSimpleCard, ProgressLoading, EmptyData, MLayout
        },
        props: {
            title: null,
            dialog: null,
            searchOptions: null,
            whetherLoadUserModels: null,
        },
        data() {
            return {
                tab: null,
                items: [],
                codesItems: [],
                loading: false,
                loadingCodes: false,
                options: {searchKeyword: null},
                finalOptions: undefined,
                actionMode: false,
            }
        },
        computed: {
            titleComputed() {
                return this.title || this.$translate("프린터 선택");
            },

            onlyPrinterModels() {
                return this.searchOptions.isPrinterContract == false && this.searchOptions.isMonitoringPrinter == false;
            },

            whetherLoadPrinterModels() {
                return (!isNOU(this.searchOptions.whetherLoadPrinterModels) ? this.searchOptions.whetherLoadPrinterModels :
                    (this.onlyPrinterModels || !(this.searchOptions.isPrinterContract == true || this.searchOptions.isMonitoringPrinter == true)))
            },

            whetherLoadPrinters() {
                return (!isNOU(this.searchOptions.whetherLoadPrinters) ? this.searchOptions.whetherLoadPrinters :
                    (!this.onlyPrinterModels))
            },

            printerList() {
                if (!Array.isArray(this.items)) return [];
                return this.items && this.items.filter(item => {
                    return !!item.raw.printerContractId;
                });
            },

            printerModelList() {
                if (!Array.isArray(this.items)) return [];
                return this.items && this.items.filter(item => {
                    return !item.raw.monitoringPrinterId && !item.raw.printerContractId;
                });
            },

            codesModelList() {
                return this.codesItems;
            },

            /**
             * 검색 키워드에 의해 필터링된 프린터 목록
             */
            filteredPrinterListBySearchKeyword() {
                if (!Array.isArray(this.printerList)) return [];
                return this.printerList.filter(printer => {
                    if ((isNOU(printer.raw.printerModelName) || printer.raw.printerModelName.trim() == '') // 프린터 모델명으로 검색
                        && (isNOU(printer.raw.printerSerialNo) || printer.raw.printerSerialNo.trim() == '') // 프린터 시리얼 넘버로 검색
                    ) return false;
                    if (isNOU(this.options.searchKeyword) || this.options.searchKeyword.trim() == '') return true; // 검색 키워드가 없으면 모두 출력한다.
                    return (this.trimAllSpaces(printer.raw.printerModelName.toLowerCase()).indexOf(this.trimAllSpaces(this.options.searchKeyword.toLowerCase())) > -1)
                        || (this.trimAllSpaces(printer.raw.printerSerialNo.toLowerCase()).indexOf(this.trimAllSpaces(this.options.searchKeyword.replace(/-/gi, "").toLowerCase())) > -1);
                });
            },

            /**
             * 검색 키워드에 의해 필터링된 프린터 모델 목록
             */
            filteredPrinterModelListBySearchKeyword() {
                if (!Array.isArray(this.printerModelList)) return [];
                return this.printerModelList.filter(printerModel => {
                    if (isNOU(printerModel.raw.printerModelName) || printerModel.raw.printerModelName.trim() == '') return false;
                    if (isNOU(this.options.searchKeyword) || this.options.searchKeyword.trim() == '') return true; // 검색 키워드가 없으면 모두 출력한다.
                    return this.trimAllSpaces(printerModel.raw.printerModelName.toLowerCase()).indexOf(this.trimAllSpaces(this.options.searchKeyword.toLowerCase())) > -1;
                });
            },

            filteredUserPrinterListBySearchKeyword() {
                if (!Array.isArray(this.codesModelList)) return [];
                return this.codesModelList.filter(codesPrinterModel => {
                    if (isNOU(codesPrinterModel.raw.codeValue) || codesPrinterModel.raw.codeValue.trim() == '') return false;
                    if (isNOU(this.options.searchKeyword) || this.options.searchKeyword.trim() == '') return true; // 검색 키워드가 없으면 모두 출력한다.
                    return this.trimAllSpaces(codesPrinterModel.raw.codeValue.toLowerCase()).indexOf(this.trimAllSpaces(this.options.searchKeyword.toLowerCase())) > -1;
                });
            },
        },
        watch: {
            'searchOptions.accountId'() {
                this.loadItems();
            },

            'searchOptions.accountBranchId'() {
                this.loadItems();
            },
        },
        created() {
        },
        mounted() {
            this.loadItems();
            if (this.whetherLoadUserModels) {
                this.loadCodesItems();
            }
        },
        methods: {
            trimAllSpaces(str) {
                try {
                    return str.split(' ').join('');
                } catch (err) {
                    console.error('PrinterModal trimAllSpaces Error! ' + err);
                    return '';
                }
            },

            createSearchOptions() {
                const options = {};
                // 검색어 할당
                Object.assign(options, this.options);

                if (!!this.searchOptions) {
                    Object.assign(options, this.searchOptions);
                }

                return options;
            },

            async loadItems(loading = true) {
                if (this.loading) return;
                // 로딩중으로 표시
                this.loading = loading;
                // 검색 옵션만들기. 이전 검색 옵션과 다르다면 dirty 객체로 보고, 처음부터 다시 로드합니다.
                const searchOptions = this.createSearchOptions();
                searchOptions.whetherLoadPrinterModels = this.whetherLoadPrinterModels;
                searchOptions.whetherLoadPrinters = this.whetherLoadPrinters;

                // 값이 없는 속성은 비교 대상에서 제외합니다.
                this.$deleteUndefined(searchOptions);
                this.finalOptions = this.$cloneDeep(searchOptions);

                try {
                    const response = await this.$request(PATH.PRINTER.AND_PRINTER_LSIT)
                        .setObject(searchOptions)
                            .wait(300)
                        .enqueue();
                    this.items = response.data.map(item => new Printer(item));
                } catch (e) {
                    console.error(e);
                }

                this.loading = false;
            },

            async loadCodesItems(loading = true) {
                if (this.loadingCodes) return;
                // 로딩중으로 표시
                this.loadingCodes = loading;

                try {
                    const response = await this.$request(PATH.RENTALCOMPANY_CODES.BASE)
                        .setObject({codeType: 'CPMDL'})
                        .enqueue();
                    this.codesItems = response.data.map(item => new Code(item));
                } catch (e) {
                    console.error(e);
                }

                this.loadingCodes = false;
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>