import AlertDialog from './AlertDialog';

export default class AlertDialogBuilder {

    constructor(eventBus) {
        this.eventBus = eventBus;
        this._title = null;
        this._message = null;
        this._buttonNegativeText = null;
        this._buttonNegativeCallback = null;
        this._buttonPositiveText = null;
        this._buttonPositiveCallback = null;
    }

    title(title) {
        this._title = title;
        return this;
    }

    message(message) {
        this._message = message;
        return this;
    }

    buttonNegative(text, callback) {
        this._buttonNegativeText = text;
        this._buttonNegativeCallback = callback;
        return this;
    }

    buttonPositive(text, callback) {
        this._buttonPositiveText = text;
        this._buttonPositiveCallback = callback;
        return this;
    }

    build() {
        return new AlertDialog(this.eventBus, {
            title: this._title,
            message: this._message,
            buttonNegativeText: this._buttonNegativeText,
            buttonPositiveText: this._buttonPositiveText,
            buttonNegativeCallback: () => {
                if (this._buttonNegativeCallback) this._buttonNegativeCallback();
            },
            buttonPositiveCallback: () => {
                if (this._buttonPositiveCallback) this._buttonPositiveCallback();
            },
        });
    }
}