<script>
    import BaseListener from "@/assets/plugins/mps-common/event/base-listener";
    import {ON_LOADING_OVERLAY_CLOSE_REQUESTED} from "@/constants/listeners";

    /**
     * loading Overlay 를 닫습니다.
     */
    export default {
        name: "OnFilterResetListener",
        extends: BaseListener,
        created() {
            this.registerListener(this.mpsEvents.ON_FILTER_RESET, this.onFilterReset);
        },
        beforeDestroy() {
            this.unregisterListener(this.mpsEvents.ON_FILTER_RESET, this.onFilterReset);
        }
    }
</script>
