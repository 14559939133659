import {FORM_TYPE} from '../../form-types'
import MFormMpsTextField from "@/assets/plugins/mps-form/providers/mps/MFormMpsTextField";
import MFormMpsTextarea from "@/assets/plugins/mps-form/providers/mps/MFormMpsTextarea";
import MFormMpsAccountPicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsAccountPicker";
import MFormMpsBranchPicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsBranchPicker";
import MFormMpsPrinterPicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsPrinterPicker";
import MFormMpsCodePicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsCodePicker";
import MFormMpsDateTimePicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsDateTimePicker";
import MFormMpsAddressPicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsAddressPicker";
import MFormMpsRentalcompanyCodeDialogPicker
    from "@/assets/plugins/mps-form/providers/mps/MFormMpsRentalcompanyCodeDialogPicker";
import MFormMpsStaffPicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsStaffPicker";
import MFormMpsRentalcompanyCodePicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsRentalcompanyCodePicker";
import MFormMpsOptionsPicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsOptionsPicker";
import MFormMpsDatePicker from "@/assets/plugins/mps-form/providers/mps/MFormMpsDatePicker";
import MFormMpsNumber from "@/assets/plugins/mps-form/providers/mps/MFormMpsNumber";
import MFormMpsVueTheMask from "@/assets/plugins/mps-form/providers/mps/MFormMpsVueTheMask";
import MFormMpsCheckbox from "@/assets/plugins/mps-form/providers/mps/MFormMpsCheckbox";
import MFormMpsQuillEditor from "@/assets/plugins/mps-form/providers/mps/MFormMpsQuillEditor";
import MFormMpsFileUpload from "@/assets/plugins/mps-form/providers/mps/MFormMpsFileUpload";
import MFormMpsFileInfos from "@/assets/plugins/mps-form/providers/mps/MFormMpsFileInfos";
import MFormMpsPhoneNumber from "@/assets/plugins/mps-form/providers/mps/MFormMpsPhoneNumber";
import MFormMpsAccountPickOrWriter from "./MFormMpsAccountPickOrWriter.vue";

export default {
    [FORM_TYPE.TEXT_FIELD]: MFormMpsTextField,
    [FORM_TYPE.TEXTAREA]: MFormMpsTextarea,
    [FORM_TYPE.ACCOUNT_PICKER]: MFormMpsAccountPicker,
    [FORM_TYPE.ACCOUNT_PICK_OR_WRITER]: MFormMpsAccountPickOrWriter,
    [FORM_TYPE.DATE_PICKER]: MFormMpsDatePicker,
    [FORM_TYPE.DATETIME_PICKER]: MFormMpsDateTimePicker,
    [FORM_TYPE.BRANCH_PICKER]: MFormMpsBranchPicker,
    [FORM_TYPE.ADDRESS_PICKER]: MFormMpsAddressPicker,
    [FORM_TYPE.STAFF_PICKER]: MFormMpsStaffPicker,
    [FORM_TYPE.CODE_PICKER]: MFormMpsCodePicker,
    [FORM_TYPE.RENTALCOMPANY_CODE_PICKER]: MFormMpsRentalcompanyCodePicker,
    [FORM_TYPE.RENTALCOMPANY_CODE_DIALOG_PICKER]: MFormMpsRentalcompanyCodeDialogPicker,
    [FORM_TYPE.PRINTER_PICKER]: MFormMpsPrinterPicker,
    [FORM_TYPE.OPTIONS_PICKER]: MFormMpsOptionsPicker,
    [FORM_TYPE.NUMBER]: MFormMpsNumber,
    [FORM_TYPE.VUE_THE_MASK]: MFormMpsVueTheMask,
    [FORM_TYPE.CHECKBOX]: MFormMpsCheckbox,
    [FORM_TYPE.QUILL_EDITOR]: MFormMpsQuillEditor,
    [FORM_TYPE.FILE_UPLOAD]: MFormMpsFileUpload,
    [FORM_TYPE.FILE_INFOS]: MFormMpsFileInfos,
    [FORM_TYPE.PHONE_NUMBER]: MFormMpsPhoneNumber,
}
