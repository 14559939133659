<template>
  <v-app>
    <toolbar-quill-editor
      @close="$emit('close', model)"
      @save="$emit('save', model)"
    />

    <m-layout>
      <v-layout class="fill-height">
        <v-container
          ref="container"
          class="overflow-y-auto pa-0 ma-0"
          style="height: calc(100vh - 56px);"
        >
          <quill-editor
            v-model="model"
            class="ql-modal-editor"
            style="width: 100%; height: 100%;"
          />
        </v-container>
      </v-layout>
    </m-layout>
  </v-app>
</template>

<script>
    import MLayout from "@/components/MLayout";
    import ToolbarQuillEditor from "@/components/app/toolbar/quill-editor/ToolbarQuillEditor";

    export default {
        name: "MFormQuillEditorFullDialog",
        components: {
            ToolbarQuillEditor,
            MLayout
        },
        props: {
            html: null,
        },
        data() {
            return {
                model: null,
            }
        },
        watch: {
            html() {
                this.model = this.html;
            }
        },
        created() {
            this.model = this.html;
        },
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>

<style>
  /**
      Quill-Editor 툴바 제거
     */
  .ql-modal-editor > div.ql-toolbar {
    display: none;
  }

  .ql-modal-editor > div.ql-container {
    border: none;
  }
</style>