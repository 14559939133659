<template>
  <div>
    <template v-if="inEditMode">
      <v-bottom-sheet v-model="sheet">
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="displayDate"
            outlined
            :label="label"
            readonly
            v-on="on"
          />
        </template>
        <v-sheet
          v-if="dateSheet"
          class="px-1"
          height="370px"
        >
          <v-row justify="center">
            <v-date-picker
              v-model="innerDate"
              full-width
              :clearable="clearable"
            />
          </v-row>
        </v-sheet>
        <v-sheet
          v-else-if="timeSheet"
          height="370px"
          class="px-3"
        >
          <v-row justify="center">
            <v-time-picker
              v-model="innerTime"
              full-width
              :clearable="clearable"
              @click:minute="onClickMinute"
            />
          </v-row>
        </v-sheet>
      </v-bottom-sheet>
    </template>
    <template v-else>
      <v-text-field
        :value="displayDate"
        outlined
        :label="label"
        readonly
        v-on="on"
      />
    </template>
  </div>
</template>

<script>
    import MFormDateTimePicker from "@/assets/plugins/mps-form/providers/MFormDateTimePicker";

    export default {
        name: "MFormVuetifyDialogDatePicker",
        mixins: [MFormDateTimePicker],
        data() {
            return {
                sheet: false,
                dateSheet: false,
                timeSheet: false,
            }
        },
        watch: {
            'sheet'() {
                this.dateSheet = true;
                this.timeSheet = false;
            },
            'innerDate'() {
                this.dateSheet = false;
                this.timeSheet = true;
            },
        },

        methods: {
            onClickMinute() {
                this.sheet = false;
            },
        },
    }
</script>

<style scoped>
    >>> .v-picker__title {
        height: 87.2px;
    }
</style>