<template>
  <v-btn
    v-show="showable"
    color="#2A5270"
    dark
    small
    absolute
    left
    fab
    elevation="0"
    width="50px"
    height="50px"
    class="refresh_button_open"
    :style="{bottom: noBottom ? '25px' : '65px'}"
    @click="$emit('click')"
  >
    <v-icon>mdi-refresh</v-icon>
  </v-btn>
</template>

<script>
    import ScrollDetector from "@/assets/plugins/mps-common/document/js/scroll-detector";

    export default {
        name: "RefreshButton",
        props: {
            container: undefined,
            noBottom: {
              type: Boolean,
              default: false,
            }
        },
        data() {
            return {
                scrollDetector: undefined,
                isScrollUp: false,
                isScrollDown: false,
                scrollTop: 0,
            };
        },
        computed: {
            showable() {
                return this.scrollTop === 0 || this.isScrollUp;
            },
        },
        watch: {
            container() {
                if (this.$isElement(this.container)) {
                    this.init();
                }
            },
        },
        mounted() {
            if (this.$isElement(this.container)) {
                this.init();
            }
        },
        methods: {
            init() {
                // 스크롤 상태를 반응성 데이터로 사용하기 위해 반응성 데이터에 ScrollDetector 객체를 담습니다.
                // this.scrollDetector = new ScrollDetector(this.container, (st, obj) => {
                //     this.scrollTop = st;
                //     this.isScrollUp = obj.isScrollUp;
                //     this.isScrollDown = obj.isScrollDown;
                // });
            }
        },
    }
</script>

<style scoped>
    .v-btn--absolute.v-btn--left {
        position: fixed;
        left: inherit;
        right: 10px;
        bottom: 65px;
        -webkit-backface-visibility: hidden;
    }

    .refresh_button_open {
        animation-name: refresh_open;
        animation-duration: 0.2s;
    }

    @keyframes refresh_open {
        0% {
            transform: scale(0)
        }
        100% {
            transform: scale(1)
        }
    }
</style>