const Permissions = {
    RENTAL_ADMIN: 'RENTAL-ADMIN',
    RENTAL_WORKER: 'RENTAL-WORKER',
    CUSTOMER_USER: 'CUSTOMER_USER',
    MENU: 'MENU',
    MENU_NAV: 'MENU__NAV',

    /** 홈 **/
    MENU_NAV_HOME: 'MENU__NAV__HOME',
    // 홈 > 빠른작업
    MENU_NAV_HOME_QUICK_WORK: 'MENU__NAV__HOME__QUICK-WORK',
    // 홈 > 스마트 업무
    MENU_NAV_HOME_SMART_WORK: 'MENU__NAV__HOME__SMART-WORK',
    // 홈 > 일정관리
    MENU_NAV_HOME_SCHEDULE: 'MENU__NAV__HOME__SCHEDULE',
    // 홈 > 보고서
    MENU_NAV_HOME_REPORT_SUMMARY: 'MENU__NAV__HOME__REPORT-SUMMARY',

    /** 거래처 **/
    MENU_NAV_ACCOUNT: 'MENU__NAV__ACCOUNT',
    // 거래처 관리
    MENU_NAV_ACCOUNT_ACCOUNT_LOG: 'MENU__NAV__ACCOUNT__ACCOUNT-LOG',
    MENU_NAV_ACCOUNT_ACCOUNT_LOG_ACCOUNT_LOG_EDIT:
        'MENU__NAV__ACCOUNT__ACCOUNT-LOG__ACCOUNT-LOG-EDIT',
    MENU_NAV_ACCOUNT_ACCOUNT_LOG_ACCOUNT_LOG_REGISTER:
        'MENU__NAV__ACCOUNT__ACCOUNT-LOG__ACCOUNT-LOG-REGISTER',
    // CMS 관리
    MENU_NAV_ACCOUNT_CMS: 'MENU__NAV__ACCOUNT__CMS',
    // 신규 / 해지 거래처
    MENU_NAV_ACCOUNT_NEW_CANCEL: 'MENU__NAV__ACCOUNT__NEW-CANCEL',
    // 상담 노트 관리
    MENU_NAV_ACCOUNT_COUNSELING: 'MENU__NAV__ACCOUNT__COUNSELING',

    /** 프린터 **/
    MENU_NAV_PRINTER: 'MENU__NAV__PRINTER',
    // 월별사용량 관리
    MENU_NAV_PRINTER_MONTHLY_MANAGE: 'MENU__NAV__PRINTER__MONTHLY-MANAGE',
    // 월별사용량 내역
    MENU_NAV_PRINTER_MONTHLY_LIST: 'MENU__NAV__PRINTER__MONTHLY-LIST',
    // 월별사용량 그래프
    MENU_NAV_PRINTER_MONTHLY_GRAPH: 'MENU__NAV__PRINTER__MONTHLY-GRAPH',
    // 사용량 이월 관리
    MENU_NAV_PRINTER_CARRYOVER: 'MENU__NAV__PRINTER__CARRYOVER',
    // 모니터링 현황
    MENU_NAV_PRINTER_MONITORING_LIST: 'MENU__NAV__PRINTER__MONITORING-LIST',
    // 토너 재고관리 현황
    MENU_NAV_PRINTER_TONER: 'MENU__NAV__PRINTER__TONER',
    // 품목 계약 현황
    MENU_NAV_PRINTER_ITEM_CONTRACT: 'MENU__NAV__PRINTER__ITEM-CONTRACT',
    // 장치 현황
    MENU_NAV_PRINTER_RENTAL_DEVICE: 'MENU__NAV__PRINTER__RENTAL-DEVICE',
    // 장치 명령어 이력
    MENU_NAV_PRINTER_CMD: 'MENU__NAV__PRINTER__CMD',

    /** 작업 **/
    MENU_NAV_WORK: 'MENU__NAV__WORK',
    // 작업 > 작업 현황
    MENU_NAV_WORK_EACH_WORKER: 'MENU__NAV__WORK__EACH-WORKER',
    // 작업 > 작업일지
    MENU_NAV_WORK_WORK_JOURNAL: 'MENU__NAV__WORK__WORK-JOURNAL',

    /** 임대매출 **/
    MENU_NAV_SALES: 'MENU__NAV__SALES',
    // 매출
    MENU_NAV_SALES_BILLING: 'MENU__NAV__SALES__BILLING',
    // 매출현황
    MENU_NAV_SALES_BILLING_STATICS: 'MENU__NAV__SALES__BILLING-STATICS',
    // 입출금
    MENU_NAV_SALES_DEPOSIT: 'MENU__NAV__SALES__DEPOSIT',
    // 입출금 현황
    MENU_NAV_SALES_DEPOSIT_STATICS: 'MENU__NAV__SALES__DEPOSIT-STATICS',
    // 세금계산서 발행내역
    MENU_NAV_SALES_TAX_LIST: 'MENU__NAV__SALES__TAX-LIST',
    // 세금계산서 발행현황
    MENU_NAV_SALES_TAX_STATICS: 'MENU__NAV__SALES__TAX-STATICS',
    // 거래명세표 발송내역
    MENU_NAV_SALES_TRANSACTION: 'MENU__NAV__SALES__TRANSACTION',

    /** 전표 / 집계 **/
    MENU_NAV_ERP: 'MENU__NAV__ERP',
    // 견적 / 발주
    MENU_NAV_ERP_ESTIMATE_ORDER: 'MENU__NAV__ERP__ESTIMATE-ORDER',
    // 전표
    MENU_NAV_ERP_STATEMENT: 'MENU__NAV__ERP__STATEMENT',
    // 수불
    MENU_NAV_ERP_PAYMENT: 'MENU__NAV__ERP__PAYMENT',
    // 수불 현황
    MENU_NAV_ERP_PAYMENT_STATICS: 'MENU__NAV__ERP__PAYMENT-STATICS',
    // 원장
    MENU_NAV_ERP_LEDGER: 'MENU__NAV__ERP__LEDGER',
    // 경비
    MENU_NAV_ERP_COST: 'MENU__NAV__ERP__COST',

    /** 설정 **/
    MENU_NAV_MANAGER: 'MENU__NAV__MANAGER',
    // 코드관리
    MENU_NAV_MANAGER_RENTALCOMPANY_CODE: 'MENU__NAV__MANAGER__RENTALCOMPANY-CODE',
    // 품목관리
    MENU_NAV_MANAGER_INVENTORY_ITEM: 'MENU__NAV__MANAGER__INVENTORY-ITEM',
    // 직원관리
    MENU_NAV_MANAGER_STAFF: 'MENU__NAV__MANAGER__STAFF',
    // 고객회원 관리
    MENU_NAV_MANAGER_CUSTOMER: 'MENU__NAV__MANAGER__CUSTOMER',
    // 프린터 메시지
    MENU_NAV_MANAGER_PRINTER_MESSAGE: 'MENU__NAV__MANAGER__PRINTER-MESSAGE',
    // 임대업체 계정설정
    MENU_NAV_MANAGER_COMMON_CONFIG: 'MENU__NAV__MANAGER__COMMON-CONFIG',
    // 마이그레이션
    MENU_NAV_MANAGER_MIGRATION: 'MENU__NAV__MANAGER__COMMON-CONFIG',

    /** 커뮤니티 **/
    MENU_NAV_COMMUNITY: 'MENU__NAV__COMMUNITY',
    // 공지사항
    MENU_NAV_COMMUNITY_INFO_NOTICE: 'MENU__NAV__COMMUNITY__INFO-NOTICE',
    // 모니터링 지원 모델
    MENU_NAV_COMMUNITY_SUPPORT_MODEL: 'MENU__NAV__COMMUNITY__SUPPORT-MODEL',
    // FAQ
    MENU_NAV_COMMUNITY_FAQ: 'MENU__NAV__COMMUNITY__FAQ',
    // 메뉴얼
    MENU_NAV_COMMUNITY_USER_MANUAL: 'MENU__NAV__COMMUNITY__USER-MANUAL',

    MENU_NAV_DEVICE: 'MENU__NAV__DEVICE',

    /** 노출 관리 **/
    EXPOSE: 'EXPOSE',
    EXPOSE_SUMMARY: 'EXPOSE__SUMMARY',
    EXPOSE_SUMMARY_SALES_SUMMARY: 'EXPOSE__SUMMARY__SALES-SUMMARY',

    /** 거래처 노출 **/
    EXPOSE_ACCOUNT_INFO: 'EXPOSE__ACCOUNT-INFO',
    // 거래처 등록 및 수정 권한
    EXPOSE_ACCOUNT_INFO_REGIST_EDIT: 'EXPOSE__ACCOUNT-INFO__REGIST-EDIT',
    // 거래처 매출 설정 정보
    EXPOSE_ACCOUNT_INFO_SALES_CONFIG: 'EXPOSE__ACCOUNT-INFO__SALES-CONFIG',

    /** 매출 **/
    EXPOSE_BILLING_MANAGE: 'EXPOSE__BILLING-MANAGE',
    // 매출 등록 및 수정 권한
    EXPOSE_BILLING_MANAGE_REGIST_EDIT: 'EXPOSE__BILLING-MANAGE__REGIST-EDIT',

    // 직원 상세
    EXPOSE_STAFF_MANAGE: 'EXPOSE__STAFF-MANAGE',
    // 직원 등록 및 수정
    EXPOSE_STAFF_MANAGE_REGIST_EDIT: 'EXPOSE__STAFF-MANAGE__REGIST-EDIT',

    REPORT: 'REPORT',
}

export default Permissions