import {isNOU, isObject} from "@/assets/plugins/mps-common/expansions/condition";

/**
 * 원본을 대상에 복사합니다. 객체나 배열을 만나면 재귀적으로 호출됩니다.
 * @param src 원본
 * @param dst 대상
 * @param processor 실제 복사를 처리하는 함수입니다.
 * @param acceptor 복사를 허가하는 함수입니다.
 */
const clone = (src, dst, processor, acceptor) => {
    // 원본이 반드시 객체로 존재해야 합니다.
    if (isNOU(src)) throw new Error(`To clone, the src(Source object) must be required and defined.`);
    if (!isObject(src)) throw new Error(`To clone, the src(Source object) must be a object.`);
    // 복사
    Object.keys(src).forEach(key => {
        // 처리자에게 복사 처리를 위임합니다.
        const val = src[key];
        if (typeof val === 'undefined') return;
        // null 도 object 로 취급됨. 조심.
        const isObject = val != null && typeof val === "object";
        // 객체인 경우 새 객체나 새 배열을 설정합니다. 아니라면 그냥 원본값으로 합니다.
        const value = isObject ? (Array.isArray(val) ? [] : {}) : val;
        // 허가자가 있을때, 허가자에게 복사 허가 여부를 묻습니다.
        if (acceptor && !acceptor(src, dst, key, value)) return; // 복사 거부됨.
        // 복사 허가됨. 수행.
        processor(src, dst, key, value);
        // 복사한 객체가 객체면 재귀적으로 복사합니다.
        if (isObject) clone(val, dst[key], processor, acceptor);
    });
};
export default clone;
