<template>
  <v-bottom-navigation
    id="bottom-navigation"
    v-model="nav"
    app
    color="primary"
    grow
    height="auto"
    @change="onBottomNavChanged"
  >
    <!-- 메인홈은 일부러 권한 설정을 하지 않습니다!
                    최초 로그인시 메인홈으로 이동하기 때문에 홈권한이 없다면 아예 페이지자체가 로드되지 않는 버그가 생겨버립니다!
                    메인홈에서 가려야할 부분은 컴포넌트 내부에서 하도록 합시다.
                     -->
    <div class="ios_bottom_wrap">
      <v-btn
        :value="homeNav"
      >
        <span>{{ 'Home' }}</span>
        <v-img
          :src="getImagePath(homeNav)"
          max-width="24px"
          max-height="24px"
          class="mb-1"
        />
      </v-btn>

      <v-btn
        v-if="userManager.hasPermission(Permissions.MENU_NAV_PRINTER)"
        value="MainPrinter"
      >
        <span>{{ '프린터' | translate }}</span>
        <v-img
          :src="getImagePath('MainPrinter')"
          max-height="24px"
          max-width="24px"
          class="mb-1"
        />
      </v-btn>

      <v-btn
        v-else
        disabled
      >
        <span>{{ '프린터' | translate }}</span>
        <v-icon
          max-height="24px"
          max-width="24px"
          class="mb-1"
        >
          mdi-block-helper
        </v-icon>
      </v-btn>

      <v-btn
        v-if="userManager.hasPermission(Permissions.MENU_NAV_WORK)"
        value="MainAs"
      >
        <span>A/S</span>
        <v-img
          :src="getImagePath('MainAs')"
          max-height="24px"
          max-width="24px"
          class="mb-1"
        />
      </v-btn>

      <v-btn
        v-else
        disabled
      >
        <span>A/S</span>
        <v-icon
          max-height="24px"
          max-width="24px"
          class="mb-1"
        >
          mdi-block-helper
        </v-icon>
      </v-btn>

      <v-btn
        v-if="userManager.hasPermission(Permissions.MENU_NAV_ACCOUNT)"
        value="MainAccounts"
      >
        <span>{{ '거래처' | translate }}</span>
        <v-img
          :src="getImagePath('MainAccounts')"
          max-height="24px"
          max-width="24px"
          class="mb-1"
        />
      </v-btn>

      <v-btn
        v-else
        disabled
      >
        <span>{{ '거래처' | translate }}</span>
        <v-icon
          max-height="24px"
          max-width="24px"
          class="mb-1"
        >
          mdi-block-helper
        </v-icon>
      </v-btn>

      <v-btn
        v-if="userManager.hasPermission(Permissions.MENU_NAV_DEVICE)"
        value="MainDevices"
      >
        <span>{{ '장치' | translate }}</span>
        <v-img
          :src="getImagePath('MainDevices')"
          max-height="24px"
          max-width="24px"
          class="mb-1"
        />
      </v-btn>

      <v-btn
        v-else
        disabled
      >
        <span>{{ '장치' | translate }}</span>
        <v-icon
          max-height="24px"
          max-width="24px"
          class="mb-1"
        >
          mdi-block-helper
        </v-icon>
      </v-btn>

      <v-btn
        v-show="false"
        value="invisible"
      />
    </div>
  </v-bottom-navigation>
</template>
<script>
    export default {
        name: 'MyBottomNavigation',
        props: {
            value: {type: String, default: undefined},
            routeName: {type: String, default: ''}
        },
        data() {
            return {
                nav: this.value,
            }
        },
        computed: {
            homeNav() {
                return this.user.isAdmin ? "AdminHome" : "WorkerHome";
            },
        },
        watch: {
            value(newValue, oldValue) {
                switch (newValue) {
                    case this.homeNav:
                    case 'MainPrinter':
                    case 'MainAs':
                    case 'MainAccounts':
                    case 'MainDevices':
                        this.nav = newValue;
                        break;
                    default :
                        this.nav = 'invisible';
                        break;
                }
            }
        },
        created() {
            this.nav = this.$route.name;
        },
        mounted() {
          this.nav = this.$route.name;
        },
        methods: {
            getImagePath(name) {
                switch (name) {
                    case this.homeNav:
                        if (this.nav === name) {
                            // return require('@/assets/images/main/ic_main_menu_home_active.png');
                            return require('@/assets/images/main/svg/home_active.svg');
                        } else {
                            // return require('@/assets/images/main/ic_main_menu_home.png');
                            return require('@/assets/images/main/svg/home_inactive.svg');
                        }
                    case 'MainPrinter':
                        if (this.nav === name) {
                            return require('@/assets/images/main/ic_main_menu_printer_active.png');
                            // return require('@/assets/images/main/svg/printer_active.svg');
                        } else {
                            return require('@/assets/images/main/ic_main_menu_printer.png');
                            // return require('@/assets/images/main/svg/printer_inactive.svg');
                        }
                    case 'MainAs':
                        if (this.nav === name) {
                            // return require('@/assets/images/main/ic_main_menu_as_active.png');
                            return require('@/assets/images/main/svg/wrench_active.svg');
                        } else {
                            // return require('@/assets/images/main/ic_main_menu_as.png');
                            return require('@/assets/images/main/svg/wrench_inactive.svg');
                        }
                    case 'MainAccounts':
                        if (this.nav === name) {
                            return require('@/assets/images/main/ic_main_menu_account_active.png');
                        } else {
                            return require('@/assets/images/main/ic_main_menu_account.png');
                        }
                    case 'MainDevices':
                        if (this.nav === name) {
                            return require('@/assets/images/main/ic_main_menu_device_active.png');
                        } else {
                            return require('@/assets/images/main/ic_main_menu_device.png');
                        }
                }
            },
            onBottomNavChanged(nav) {
                if (!this.nav) return;
                this.$emit('input', nav);
              localStorage.removeItem('homeActiveTab')
            },
        },
    }
</script>

<style scoped>
    .v-item-group.v-bottom-navigation .v-btn {
        max-width: inherit !important;
        min-width: inherit !important;
        width: inherit !important;
    }

    #bottom-navigation .v-btn.v-btn--disabled {
        background-color: transparent !important;
    }

</style>
