<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

    export default {
        name: "MFormAddressPicker",
        extends: MBaseFormPicker,
        data() {
            return {
                resizeSpace: "",
            };
        },
        computed: {
            titleComputed() {
                if (this.options.address) return this.options.address;
                return this.$translate('주소검색');
            },

            display() {
                let _t = (this.options.address || "");
                if (this.options.zipCode) _t += " (" + this.options.zipCode + ")";
                return (_t + this.resizeSpace);
            },
        },
        watch: {
            options: {
                deep: true,
                handler: function () {
                    if (isNOU(this.options.address)) { // 오직 초기화 목적!
                        this.model = this.options.address;
                    }
                }
            }
        },
    }
</script>

<style>
    textarea {
        resize: none;
        overflow: hidden;
    }
</style>
