import {elements, providers} from './providers'

export default class FormProvider {

    _elements;
    _listeners;

    constructor(provider = providers.VUETIFY) {
        this.provider = provider;
        this._listeners = [];
    }

    set provider(provider) {
        this._elements = elements[provider || providers.VUETIFY];
    }

    get elements() {
        return this._elements;
    }

    getElement(formType) {
        return this._elements[formType];
    }

    addOnProviderChangedListener(onProviderChangedListener) {
        if (this._listeners.includes(onProviderChangedListener)) return;
        this._listeners.push(onProviderChangedListener);
    }

    removeOnProviderChangedListener(onProviderChangedListener) {
        if (!this._listeners.includes(onProviderChangedListener)) return;
        const index = this._listeners.indexOf(onProviderChangedListener);
        if (index === -1) return;
        this._listeners.splice(index, 1);
    }

}
