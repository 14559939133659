<script>
    export default {
        name: "UserMixin",
        data() {
            return {
                user: undefined,
                userManager: undefined,
            }
        },
        created() {
            this.user = this.$userManager.user;
            this.userManager = this.$userManager;
        },
        mounted() {
            this.user = this.$userManager.user;
            this.userManager = this.$userManager;
        },
        unmounted() {
        },
    }
</script>