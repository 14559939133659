<template>
  <div>
    <div
      v-for="(n, index) in MAX_COUNT_OF_MODALS"
      :key="index"
    >
      <!-- 모달이 존재하면 -->
      <template v-if="index < stack.length">
        <v-dialog
          v-model="stack[index].modal.dialog"
          :persistent="stack[index].options.persistent"
          :scrollable="stack[index].options.scrollable"
          :hide-overlay="stack[index].options.hideOverlay"
          :transition="stack[index].options.transition"
          :fullscreen="stack[index].options.fullscreen"
          :max-width="stack[index].options.maxWidth"
        >
          <component
            :is="stack[index].modal.component"
            :id="stack[index].modal.id"
            :props-data="stack[index].propsData || {}"
          />
        </v-dialog>
      </template>
      <!-- 빈 오브젝트 -->
      <template v-else>
        <div class="empty-object" />
      </template>
    </div>
  </div>
</template>

<script>
    import ModalVuetifyRoot from "@/assets/plugins/vuetify-modal/ModalVuetifyRoot";
    import OnModalOpenRequestedListener from "@/event/OnModalOpenRequestedListener";
    import ModalVuetifyMultiModal from "@/assets/plugins/vuetify-modal/ModalVuetifyMultiModal";

    export default {
        name: "ModalVuetifyModalLayerLegacy",
        components: {},
        mixins: [ModalVuetifyRoot, OnModalOpenRequestedListener, ModalVuetifyMultiModal],
        data() {
            return {
                index: 0,
            }
        },
        computed: {},
        watch: {},
        methods: {}
    }
</script>

<style scoped>
    >>> .v-dialog {
        overflow-x: hidden;
        overflow-y: hidden;
    }
</style>