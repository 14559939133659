<script>
    /**
     * 라우트 이동시 윈도우 스크롤 상태를 제외한 다른 엘리먼트의 스크롤 상태가 저장되지 않습니다.
     * 스크롤 가능한 엘리먼트의 스크롤 상테를 자동으로 저장 및 복구하는 믹스인 컴포넌트 입니다.
     */
    export default {
        name: "ScrollStateManager",
        data() {
            return {
                scrollStates: {},
                savedScrollStateExists: false
            }
        },
        mounted() {
            this.restoreScrollState(this.$el);
        },
        unmounted() {
            this.saveScrollState(this.$el);
            console.log(this.scrollStates);
        },
        methods: {
            restoreScrollState(el) {
                if (this.savedScrollStateExists) this._restoreScrollState('root', el);
            },
            saveScrollState(el) {
                this._saveScrollState('root', el);
            },
            _isScrollableElement(el) {
                return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
            },
            _hasChildren(el) {
                return el.children && el.children.length > 0;
            },
            _makeDomTreeName(parent, el, index) {
                const indexed = index ? '[' + index + ']' : '';
                return parent + '>' + indexed + el.localName;
            },
            _restoreScrollState(parent, el, index) {
                const name = this._makeDomTreeName(parent, el, index);
                // 스크롤 가능한 엘리먼트의 스크롤 상태가 저장되어 있다면, 스크롤 상태를 복구합니다.
                if (this._isScrollableElement(el)) {
                    if (typeof this.scrollStates[name] !== 'undefined') {
                        el.scrollLeft = this.scrollStates[name]['x'];
                        el.scrollTop = this.scrollStates[name]['y'];
                    }
                }
                // 자식이 있다면 자식을 재귀호출합니다.
                if (this._hasChildren(el)) {
                    for (let childKey in el.children) {
                        if (isNaN(childKey)) continue;
                        this._restoreScrollState(name, el.children[childKey], childKey);
                    }
                }
            },
            _saveScrollState(parent, el, index) {
                const name = this._makeDomTreeName(parent, el, index);
                // 스크롤 가능한 엘리먼트의 경우 스크롤 상태를 저장합니다.
                if (this._isScrollableElement(el)) {
                    this.scrollStates[name] = {};
                    this.scrollStates[name]['x'] = el.scrollLeft;
                    this.scrollStates[name]['y'] = el.scrollTop;
                    this.savedScrollStateExists = true;
                }
                // 자식이 있다면 자식을 재귀호출합니다.
                if (this._hasChildren(el)) {
                    for (let childKey in el.children) {
                        if (isNaN(childKey)) continue;
                        this._saveScrollState(name, el.children[childKey], childKey);
                    }
                }
            }
        },
    }
</script>