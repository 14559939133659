import HttpClient from "@/assets/plugins/mps-common/network/http-client";
import RequesterBuilder from "@/assets/plugins/mps-common/network/requester-builder";

export default {
    install(Vue, options) {
        Vue.prototype.$httpClient = new HttpClient();
        Vue.prototype.$request = function (url, ...args) {
            return new RequesterBuilder().path(url, ...args);
        };
        Vue.prototype.$requestWithOverlay = function (url, ...args) {
            return new RequesterBuilder().path(url, ...args).loading();
        }
    }

}