import {INIT, SET_DATA} from "./keys";
import Dashboard from "./dashboard.js";

export default {
    [INIT](state, {rentalcompanyId, countryCode}) {
        state.dashboard = new Dashboard(rentalcompanyId, countryCode);
        return state.dashboard;
    },
    [SET_DATA](state, { key, data }) {
        state[key] = state.dashboard.setData(key, data);
        return state[key];
    }
}