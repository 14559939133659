import array from "./internal/array";
import number from "./internal/number";
import object from "./internal/object";
import string from "./internal/string";
import * as condition from "./condition"
import * as clone from "./clone";
import * as inspection from "./inspection";
import ArrayList from './array/array-list'
import {vm} from '@/main';

export default {
    install(Vue, options) {
        this.installInternals();// initialize internal compat.
        this.installFunctions(Vue);// initialize functions.

    },
    installInternals() {
        string();
        array();
        number();
        object();
    },
    installFunctions(Vue) {
        // 조건(검사) 함수
        Vue.prototype.$isNOU = (v) => condition.isNOU(v);
        Vue.prototype.$isNullOrUndef = (v) => condition.isNullOrUndef(v);
        Vue.prototype.$isEmpty = (v) => condition.isEmpty(v);
        Vue.prototype.$isNotEmpty = (v) => !condition.isEmpty(v);
        Vue.prototype.$isPrimitive = (v) => condition.isPrimitive(v);
        Vue.prototype.$isObject = (v) => condition.isObject(v);
        Vue.prototype.$isArray = (v) => condition.isArray(v);
        Vue.prototype.$isDirty = (v1, v2) => condition.isDirty(v1, v2);
        Vue.prototype.$isEqual = (v1, v2) => condition.isEqual(v1, v2);
        Vue.prototype.$isEqualTypes = (...args) => condition.isEqualTypes(args);
        Vue.prototype.$isEqualArray = (array1, array2) => condition.isEqualArray(array1, array2);
        // 클론(복사) 함수
        Vue.prototype.$clone = (src, dst, processor, acceptor) => clone.clone(src, dst, processor, acceptor);
        Vue.prototype.$cloneDeep = (src, dst) => clone.cloneDeep(src, dst);
        Vue.prototype.$cloneReactive = (src, dst) => clone.cloneReactive(vm, src, dst);
        Vue.prototype.$cloneReactiveNew = (src, dst) => clone.cloneReactiveNew(vm, src, dst);
        Vue.prototype.$cloneSelective = (src, dst) => clone.cloneSelective(src, dst);
        Vue.prototype.$map = (src, dst) => clone.cloneSelective(src, dst);
        // 객체 조사 함수
        Vue.prototype.$inspectRecursive = (object, action) => inspection.inspectRecursive(object, action);
        Vue.prototype.$deleteUndefined = (v) => inspection.deleteUndefined(v);
        // 배열 compat
        Vue.prototype.$arrayOf = (...items) => new ArrayList(items); // ArrayList 반환
    }
}
