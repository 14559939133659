const REGEX_URL_FORMAT = /\{\d\}/g;
const action = (...args) => (match) => {
    const matches = match.match(/\d/);
    if (matches.length === 1) {
        const index = matches[0];
        const value = args[index];
        return typeof value === 'undefined' ? match : value;
    } else {
        return match;
    }
};
const format = (format, ...args) => format.replace(REGEX_URL_FORMAT, action(...args));
const formStr = format;

export {format, formStr}
