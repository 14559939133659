<template>
  <v-app>
    <my-tool-bar
      :loading="loading"
      :mode.sync="mode"
      :title="title"
    />
    <m-layout>
      <v-layout>
        <v-container fill-height />
      </v-layout>
    </m-layout>
  </v-app>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import MLayout from "@/components/MLayout";

    export default {
      name: "Settings",
      components: {MLayout, MyToolBar},
      extends: WindowComponent,
        data() {
            return {
                loading: false,
                mode: undefined
            }
        },
    }
</script>

<style scoped>

</style>