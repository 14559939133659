class InfiniteItem {
    _item;
    _visible = true;

    constructor(item) {
        this._item = item;
    }

    get raw() {
        return this._item;
    }

    get visible() {
        return this._visible;
    }

    set visible(visible) {
        this._visible = visible;
    }
}

export default InfiniteItem;