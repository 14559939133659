import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);
import userManager from "@/assets/plugins/mps-common/user/user-manager";

import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";
import Permissions from "@/constants/permissions";

/**
 * 여기에 정의된 컴포넌트는 Lazy Load 하지 않습니다.
 * (권한과 상관없이 항상 로드될 가능성이 높은 컴포넌트 모음)
 */
import SubListMain from "@/views/SubListMain";
import Settings from "@/components/windows/settings/Settings";
import AdminMain from "@/views/AdminMain";
import {NATIVE_COMMAND, nativeBridge} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
import {HISTORY_ACTION, historyManager} from "@/assets/plugins/mps-common/history/history-manager";
import {ETC} from "@/constants/etc-code";

/**
 * 프린터 Lazy Load Chunk 그룹
 */
const PrinterDetail = () => import(/* webpackChunkName: "printer" */ '@/components/windows/printer/PrinterDetail');
const MainPrinter = () => import(/* webpackChunkName: "printer" */ "@/components/windows/main/MainPrinter");
const PrinterDailyUsage = () => import(/* webpackChunkName: "printer" */ "@/components/windows/printer/PrinterDailyUsage");
const PrinterTonerManagementDetail = () => import(/* webpackChunkName: "printer" */ "@/components/windows/printer/PrinterTonerManagementDetail");
const PrinterTonerManagementEditComp = () => import(/* webpackChunkName: "printer" */ "@/components/windows/printer/PrinterTonerManagementEditComp");
const PrinterTonerManagementCreate = () => import(/* webpackChunkName: "printer" */ "@/components/windows/printer/PrinterTonerManagementCreate");

/**
 * 장치 Lazy Load Chunk 그룹
 */
const DeviceDetail = () => import(/* webpackChunkName: "device" */ '@/components/windows/device/DeviceDetail');
const MainDevices = () => import(/* webpackChunkName: "device" */ "@/components/windows/main/MainDevices");
const DeviceRegister = () => import(/* webpackChunkName: "device" */ "@/components/windows/device/DeviceRegister");

/**
 * 관리자 전용 Lazy Load Chunk 그룹
 */
const AdminHome = () => import(/* webpackChunkName: "admin" */ "@/components/windows/main/AdminHome");

/**
 * 작업자 전용 Lazy Load Chunk 그룹
 */
const WorkerHome = () => import(/* webpackChunkName: "worker" */ "@/components/windows/main/WorkerHome");

/**
 * A/S Lazy Load Chunk 그룹
 */
const AsMainWorkorderSubList = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsMainWorkorderSubList");
const MainAs = () => import(/* webpackChunkName: "as" */ "@/components/windows/main/MainAs");
const AsMainReceivedSubList = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsMainReceivedSubList");
const AsMainInProgressSubList = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsMainInProgressSubList");
const AsMainDayFinishSubList = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsMainDayFinishSubList");
const AsMainWatingStaffSubList = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsMainWatingStaffSubList");
const AsMainDayCancelSubList = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsMainDayCancelSubList");
const AsMainReservedSubList = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsMainReservedSubList");
const AsDetail = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsDetail");
const AsDetailForLatest = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsDetailForLatest");
const AsRegister = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsRegister");
const AsReRegister = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsReRegister");
const AsDetailForPush = () => import(/* webpackChunkName: "as" */ "@/components/windows/as/AsDetailForPush");

/**
 * 거래처 Lazy Load Chunk 그룹
 */
const OutstandingAccountSubList = () => import(/* webpackChunkName: "account" */ "@/components/windows/account/OutstandingAccountSubList");
const AccountDetail = () => import(/* webpackChunkName: "account" */ "@/components/new-comp/account/AccountDetail");
const MainAccounts = () => import(/* webpackChunkName: "account" */ "@/components/windows/main/MainAccounts");
const ExpirationAccountSubList = () => import(/* webpackChunkName: "account" */ "@/components/windows/account/ExpirationAccountSubList");
const UnvisitedAccountSubList = () => import(/* webpackChunkName: "account" */ "@/components/windows/account/UnvisitedAccountSubList");
const AccountRegister = () => import(/* webpackChunkName: "account" */ "@/components/windows/account/AccountRegister");
const ContractRegister = () => import(/* webpackChunkName: "contractregister" */ "@/components/new-comp/contract/ContractRegister");
const CmsTempRegister = () =>  import(/* webpackChunkName: "cmstempregister" */ "@/components/new-comp/contract/CmsTempRegister");
const ElectricalContract = () => import(/* webpackChunkName: "account" */ "@/components/new-comp/contract/ElectricalContract")
const ElectricalCms = () => import(/* webpackChunkName: "account" */ "@/components/new-comp/contract/ElectricalCMS")

/**
 * 임대 계약 Lazy Load Chunk 그룹(현재는 계약서 관련 권한이 존재하지 않지만 추후에 생길 것으로 예상되어 따로 분리하였습니다.)
 */
const RentalContractDetail = () => import(/* webpackChunkName: "rentalContract" */ "@/components/windows/contract/RentalContractDetail");

/**
 * 웰컴
 */
const Welcome = () => import(/* webpackChunkName: "welcome" */ "@/views/welcome/Welcome.vue");
const WelcomeMain = () => import(/* webpackChunkName: "welcome" */ "@/views/welcome/WelcomeMain.vue");
const NewLogin = () => import(/* webpackChunkName: "welcome" */ "@/views/welcome/login/NewLogin.vue");
const Signup = () => import(/* webpackChunkName: "welcome" */ "@/views/welcome/signup/Signup.vue");
const RentalSignup = () => import(/* webpackChunkName: "welcome" */ "@/views/welcome/signup/rental/RentalSignup.vue");
const CommunitySignup = () => import(/* webpackChunkName: "welcome" */ "@/views/welcome/signup/community/CommunitySignup.vue");
const WelcomeNoticeList = () => import(/* webpackChunkName: "welcomeContent" */ "@/views/welcome/WelcomeNoticeList.vue");
const WelcomeNoticeDetail = () => import(/* webpackChunkName: "welcomeContent" */ "@/views/welcome/WelcomeNoticeDetail.vue");
const TermsOfUse = () => import(/* webpackChunkName: "welcome" */ "@/views/welcome/termsofuse/TermsOfUse.vue");
const WorkRecordDetail = () => import(/* webpackChunkName: "account" */ "@/views/home/WorkRecordDetail")
const ConsultNoteDetail = () => import(/* webpackChunkName: "account" */ "@/views/home/ConsultNoteDetail")
const ConsultNoteRegister = () => import(/* webpackChunkName: "account" */ "@/views/home/ConsultNoteRegister")
// const ConsultNote = () => import(/* webpackChunkName: "account" */ "@/views/home/ConsultNote")

const routes = [
    /**
     * 루트 분기 라우터입니다.(처음 앱이 켜질시 이 라우터로 접속합니다.)
     * 루트 분기라우터는 backGuard 사용을 위해 Root 라우터를 등록합니다.
     */
    {
        name: 'RootDivider', path: '/', meta: {
            rootDivider: true,
            mapping: [
                {to: "AdminHome", permission: Permissions.RENTAL_ADMIN},
                {to: "WorkerHome", permission: Permissions.RENTAL_WORKER},
                {to: "Welcome",}
            ]
        },
    },

    /**
     *  분기 라우터입니다. 히스토리를 남김니다.
     */
    {
        name: 'LoginDivider', path: '/main-home', meta: {
            divider: true,
            history: true,
            mapping: [
                {to: "/"}
            ]
        },
    },

    /**
     * 로그아웃 라우터입니다. 히스토리를 남깁니다.
     */
    {
        name: 'LogoutDivider', path: '/logout-divider', meta: {
            divider: true,
            history: true,
            mapping: [
                {to: "/"}
            ]
        },
    },

    {
        path: '/welcome',
        component: WelcomeMain,
        children: [
            {
                path: '/welcome', name: 'Welcome', component: Welcome, meta: {
                    title: "TNBOX",
                    requiresAuth: false,
                },
            },
            /**
             * 공지사항 목록입니다.
             */
            {
                path: '/welcome/notice/auth/list/:sortCd',
                name: 'WelcomeNoticeList',
                props: true,
                component: WelcomeNoticeList,
                meta: {
                    requiresAuth: true,
                },
            },

            /**
             * 공지사항 상세입니다.
             */
            {
                path: '/welcome/notice/auth/detail/view/:noticeId',
                name: 'WelcomeNoticeDetail',
                props: true,
                component: WelcomeNoticeDetail,
                meta: {
                    requiresAuth: true,
                },
            },
        ]
    },

    {
        path: '/welcome/terms-of-use/:which',
        name: 'TermsOfUse',
        props: true,
        component: TermsOfUse,
        meta: {
            requiresAuth: false,
            title: translationManager.translate("틴텍씨앤씨 약관 및 개인정보 보호"),
        },
    },

    ///////////////////////////////////////////////////////////////////////////
    // routes:non auth
    ///////////////////////////////////////////////////////////////////////////
    {
        path: '/welcome/login',
        name: 'NewLogin',
        meta: {
            requiresAuth: false,
            title: translationManager.translate('로그인'),
        },
        // 라우터 레벨에서 코드를 분리하기 위한 방법입니다.route level code-splitting
        // 이 방법은 분리된 코드 청크를 생성합니다.
        // 이 라우터에 방문할 때 지연 로딩됩니다.
        component: NewLogin,
    },

    ///////////////////////////////////////////////////////////////////////////
    // routes:non auth
    ///////////////////////////////////////////////////////////////////////////
    {
        path: '/welcome/step/signup',
        name: 'Signup',
        meta: {
            requiresAuth: false,
            title: translationManager.translate('회원가입'),
        },
        // 라우터 레벨에서 코드를 분리하기 위한 방법입니다.route level code-splitting
        // 이 방법은 분리된 코드 청크를 생성합니다.
        // 이 라우터에 방문할 때 지연 로딩됩니다.
        component: Signup,
    },

    ///////////////////////////////////////////////////////////////////////////
    // routes:non auth
    ///////////////////////////////////////////////////////////////////////////
    {
        path: '/welcome/step/rental/signup',
        name: 'RentalSignup',
        meta: {
            requiresAuth: false,
            title: translationManager.translate('임대업체 회원가입'),
        },
        component: RentalSignup,
    },

    ///////////////////////////////////////////////////////////////////////////
    // routes:non auth
    ///////////////////////////////////////////////////////////////////////////
    {
        path: '/welcome/step/community/signup',
        name: 'CommunitySignup',
        meta: {
            requiresAuth: false,
            title: translationManager.translate('일반회원 가입'),
        },
        component: CommunitySignup,
    },


    ///////////////////////////////////////////////////////////////////////////
    // routes:non auth (사라질 예정)
    ///////////////////////////////////////////////////////////////////////////
    // {
    //     path: '/login',
    //     name: 'Login',
    //     meta: {
    //         requiresAuth: false,
    //         title: '로그인',
    //     },
    //     component: Login,
    // },

    ///////////////////////////////////////////////////////////////////////////
    // redirection
    ///////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////
    // routes
    ///////////////////////////////////////////////////////////////////////////

    /**
     * 분기 라우터입니다.
     */
    {
        name: 'MainHome', path: '/home', meta: {
            divider: true,
            requiresAuth: true,
            mapping: [
                {to: "AdminHome", permission: Permissions.RENTAL_ADMIN},
                {to: "WorkerHome", permission: Permissions.RENTAL_WORKER},
            ]
        },
    },

    /**
     * 관리자 페이지입니다.
     */
    {
        path: '/',
        component: AdminMain,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/auth/admin/home', name: 'AdminHome', component: AdminHome, meta: {
                    title: translationManager.translate("관리자 홈"),
                    permission: Permissions.RENTAL_ADMIN,
                },
            },
            {
                path: '/auth/main/printer', name: 'MainPrinter', component: MainPrinter, meta: {
                    title: translationManager.translate("프린터"),
                    permission: Permissions.MENU_NAV_PRINTER,
                },
            },
            {
                path: '/auth/main/as', name: 'MainAs', component: MainAs, meta: {
                    title: translationManager.translate("AS"),
                    permission: Permissions.MENU_NAV_WORK,
                },
            },
            {
                path: '/auth/main/accounts', name: 'MainAccounts', component: MainAccounts, meta: {
                    title: translationManager.translate('거래처'),
                    permission: Permissions.MENU_NAV_ACCOUNT,
                },
            },
            {
                path: '/auth/main/devices', name: 'MainDevices', component: MainDevices, meta: {
                    title: translationManager.translate("장치정보"),
                    permission: Permissions.MENU_NAV_DEVICE,
                },
            },
        ]
    },

    /**
     * 서브 리스트입니다.
     */
    {
        path: '/',
        component: SubListMain,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/auth/main/dashboard/account-outstanding',
                name: 'OutstandingAccountSubList',
                component: OutstandingAccountSubList,
                meta: {
                    title: translationManager.translate("미수 거래처"),
                    permission: Permissions.MENU_NAV_ACCOUNT,
                },
            },
            {
                path: '/auth/main/dashboard/account-expiring',
                name: 'ExpirationAccountSubList',
                component: ExpirationAccountSubList,
                meta: {
                    title: translationManager.translate("만기 도래 거래처"),
                    permission: Permissions.MENU_NAV_ACCOUNT,
                },
            },
            {
                path: '/auth/main/dashboard/account-unvisited',
                name: 'UnvisitedAccountSubList',
                component: UnvisitedAccountSubList,
                meta: {
                    title: translationManager.translate("장기 미방문 거래처"),
                    permission: Permissions.MENU_NAV_ACCOUNT,
                },
            },
            {
                path: '/auth/main/dashboard/as-received',
                name: 'AsMainReceivedSubList',
                component: AsMainReceivedSubList,
                meta: {
                    title: translationManager.translate("접수된 AS 현황"),
                    permission: Permissions.MENU_NAV_WORK,
                },
            },
            {
                path: '/auth/main/dashboard/as-wating-staff',
                name: 'AsMainWatingStaffSubList',
                component: AsMainWatingStaffSubList,
                meta: {
                    title: translationManager.translate("기사대기중인 AS 현황"),
                    permission: Permissions.MENU_NAV_WORK,
                },
            },
            {
                path: '/auth/main/dashboard/as-in-progress',
                name: 'AsMainInProgressSubList',
                component: AsMainInProgressSubList,
                meta: {
                    title: translationManager.translate("진행중인 AS 현황"),
                    permission: Permissions.MENU_NAV_WORK,
                },
            },
            {
                path: '/auth/main/dashboard/as-day-finished',
                name: 'AsMainDayFinishSubList',
                component: AsMainDayFinishSubList,
                meta: {
                    title: translationManager.translate("당일완료된 AS 현황"),
                    permission: Permissions.MENU_NAV_WORK,
                },
            },
            {
                path: '/auth/main/dashboard/as-day-canceled',
                name: 'AsMainDayCancelSubList',
                component: AsMainDayCancelSubList,
                meta: {
                    title: translationManager.translate("당일취소된 AS 현황"),
                    permission: Permissions.MENU_NAV_WORK,
                },
            },
            {
                path: '/auth/main/dashboard/as-reserved',
                name: 'AsMainReservedSubList',
                component: AsMainReservedSubList,
                meta: {
                    title: translationManager.translate("예약된 AS 일정"),
                    permission: Permissions.MENU_NAV_WORK,
                },
            },
            {
                path: '/auth/main/dashboard/as-worker/:staffId',
                name: 'AsMainWorkorderSubList',
                props: true,
                component: AsMainWorkorderSubList,
                meta: {
                    title: translationManager.translate("AS"),
                    permission: Permissions.MENU_NAV_WORK,

                },
            },
        ]
    },

    /**
     * 관리자용 AS 상세 화면입니다.
     */
    {
        path: '/auth/main/as/info/detail/:workorderId', name: 'AsDetail', props: true, component: AsDetail, meta: {
            requiresAuth: true, title: translationManager.translate("AS 상세"),
        },
    },

    /**
     * AS 상세 푸쉬 화면입니다.
     */
    {
        path: '/push/as/push/info/view/detail/:workorderId',
        props: true,
        name: 'AsDetailForPush',
        component: AsDetailForPush,
        meta: {
            requiresAuth: true, title: translationManager.translate("AS 상세"),
        },
    },

    /**
     * 최근 A/S 상세 화면입니다.
     */
    {
        path: '/auth/main/as/latest/info/view/detail/:workorderId',
        name: 'AsDetailForLatest',
        props: true,
        component: AsDetailForLatest,
        meta: {
            requiresAuth: true, title: translationManager.translate("최근 AS 상세"),
        },
    },

    /**
     * AS 등록 화면입니다.
     */
    {
        path: '/auth/main/as/info/register/:staffId', name: 'AsRegister', props: true, component: AsRegister, meta: {
            slide: true,
            requiresAuth: true, title: translationManager.translate("AS 등록"),
            permission: Permissions.MENU_NAV_WORK,
        },
    },

    /**
     * 작업 재등록 화면입니다.
     */
    {
        path: '/auth/main/as/info/reregister/:workorderId',
        name: 'AsReRegister',
        props: true,
        component: AsReRegister,
        meta: {
            requiresAuth: true, title: translationManager.translate("작업 재등록"),
            permission: Permissions.MENU_NAV_WORK,
        },
    },

    /**
     * 거래처 상세 화면입니다.
     */
    {
        path: '/auth/main/account/info/detail/:accountId',
        name: 'AccountDetail',
        component: AccountDetail,
        props: true,
        meta:{
                requiresAuth: true,
                title: translationManager.translate("거래처 상세"),
                permission: Permissions.EXPOSE_ACCOUNT_INFO

            }

    },

    /**
     * 거래처 등록 화면입니다.
     */
    {
        path: '/auth/main/account/info/register',
        name: 'AccountRegister',
        component: AccountRegister,
        props: true,
        meta: {
            slide: true,
            requiresAuth: true, title: translationManager.translate("거래처 등록"),
            permission: Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT,
        },
    },

    /**
     * 임대 계약 작성 화면입니다.
     */
    {
        path: '/auth/main/account/contractregister/:accountId/:contractId',
        name: 'ContractRegister',
        props: true,
        component: ContractRegister,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("계약서 등록"),
            permission: Permissions.MENU_NAV_ACCOUNT,
        },
    },

    /**
     * cms 임시 작성 화면입니다.
     */
    {
        path: '/auth/main/account/cmstempregister/:accountId/:cmsMemberTempId',
        name: 'CmsTempRegister',
        props: true,
        component: CmsTempRegister,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("임시 CMS 등록"),
            permission: Permissions.MENU_NAV_ACCOUNT,
        },
    },

    /**
     * 전자 계약 작성 화면입니다.
     */
    {
        path: '/auth/main/account/electricalcontract/:accountId/:contractId/:electricalContractId',
        name: 'ElectricalContract',
        props: true,
        component: ElectricalContract,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("전자계약서"),
            permission: Permissions.MENU_NAV_ACCOUNT,
        },
    },

    /**
     * 전자 CMS 작성 화면입니다.
     */
    {
        path: '/auth/main/account/electricalcms/:accountId/:cmsMemberTempId',
        name: 'ElectricalCms',
        props: true,
        component: ElectricalCms,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("CMS 계약서"),
            permission: Permissions.MENU_NAV_ACCOUNT,
        },
    },

    /**
     * 작업일지 상세 화면입니다.
     */
    {
        path: '/auth/main/account/workRecordDetail/',
        name: 'WorkRecordDetail',
        props: true,
        component: WorkRecordDetail,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("작업일지 상세"),
            permission: Permissions.MENU_NAV_ACCOUNT,
        },
    },
    /**
     * 상담노트 상세 화면입니다.
     */
    {
        path: '/auth/main/account/consultNoteDetail/',
        name: 'ConsultNoteDetail',
        props: true,
        component: ConsultNoteDetail,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("상담노트 상세"),
            permission: Permissions.MENU_NAV_ACCOUNT,
        },
    },
    /**
     * 상담노트 등록 화면입니다.
     */
    {
        path: '/auth/main/account/consultNoteRegister/',
        name: 'ConsultNoteRegister',
        props: true,
        component: ConsultNoteRegister,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("상담노트 등록"),
            permission: Permissions.MENU_NAV_ACCOUNT,
        },
    },
    // /**
    //  * 상담노트  화면입니다.
    //  */
    // {
    //     path: '/auth/main/account/consultNote/',
    //     name: 'ConsultNote',
    //     props: true,
    //     component: ConsultNote,
    //     meta: {
    //         requiresAuth: true,
    //         title: translationManager.translate("상담노트"),
    //         permission: Permissions.MENU_NAV_ACCOUNT,
    //     },
    // },

    // /**
    //  * 임대 계약 상세 화면입니다.
    //  */
    // {
    //     path: '/auth/main/account/info/rental-contract/detail/:contractId',
    //     name: 'RentalContractDetail',
    //     props: true,
    //     component: RentalContractDetail,
    //     meta: {
    //         requiresAuth: true, title: translationManager.translate("계약 상세"),
    //     },
    // },



    /**
     * 작업자 페이지입니다.
     */
    {
        path: '/',
        component: AdminMain,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/auth/worker/home', name: 'WorkerHome', component: WorkerHome, meta: {
                    title: translationManager.translate("작업자 홈"),
                    permission: Permissions.RENTAL_WORKER,
                },
            },
        ],
    },

    // 프린터 상세 화면입니다.
    {
        path: '/auth/printer/info/detail/:printerContractId/:monitoringPrinterId',
        name: 'PrinterDetail',
        props: true,
        component: PrinterDetail,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("프린터 상세"),
            permission: Permissions.MENU_NAV_PRINTER,
        }
    },

    // 프린터 일별사용량  화면입니다.
    {
        path: '/auth/printer/info/daily-usage/:printerContractId/:monitoringPrinterId',
        name: 'PrinterDailyUsage',
        props: true,
        component: PrinterDailyUsage,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("프린터 일별사용량"),
            permission: Permissions.MENU_NAV_PRINTER,
        }
    },

    // 토너재고관리 상세화면
    {
        path: '/auth/printer/info/toner-refill/:refillHistory/:monitoringPrinterId/:printerContractId',
        name: 'PrinterTonerManagementDetail',
        props: true,
        component: PrinterTonerManagementDetail,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("토너재고관리"),
            permission: Permissions.MENU_NAV_PRINTER,
        }
    },
    // 토너재고관리 수정 화면
    {
        path: '/auth/printer/info/toner/edit',
        name: 'PrinterTonerManagementEditComp',
        props: true,
        component: PrinterTonerManagementEditComp,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("토너재고관리"),
            permission: Permissions.MENU_NAV_PRINTER,
        }
    },

    // 토너재고관리 등록 화면
    {
        path: '/auth/printer/info/toner/create',
        name: 'PrinterTonerManagementCreate',
        props: true,
        component: PrinterTonerManagementCreate,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("토너재고관리 등록"),
            permission: Permissions.MENU_NAV_PRINTER,
        }
    },

    // 장치 상세 화면입니다.
    {
        path: '/auth/device/info/detail/:accountId/:tnDeviceId',
        name: 'DeviceDetail',
        props: true,
        component: DeviceDetail,
        meta: {
            requiresAuth: true,
            title: translationManager.translate("장치 상세"),
            permission: Permissions.MENU_NAV_DEVICE,
        }
    },

    /**
     * 장치 등록 화면입니다.
     */
    {
        path: '/auth/device/info/register',
        name: 'DeviceRegister',
        component: DeviceRegister,
        meta: {
            slide: true,
            requiresAuth: true, title: translationManager.translate("장치 등록"),
            permission: Permissions.MENU_NAV_DEVICE,
        },
    },

    // 설정은 단일 기능입니다.
    // 설정 내부에 특정 항목에 대한 설정 화면이 자식으로 추가된다면, child 로 구현해야 합니다.
    {
        path: '/settings/main',
        name: 'Settings',
        component: Settings,
        meta: {requiresAuth: true, title: translationManager.translate("설정"), slide: true,},
    }
];


const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        console.log('to   : ' + to.name);
        console.log('from : ' + from.name);
        if (savedPosition) {
            console.log('savedPosition:' + savedPosition.x + ', ' + savedPosition.y);
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
});

const isExitHome = (name) => {
    return (name === "AdminHome" || name === "WorkerHome" || name === "Welcome")
}

router.beforeEach((to, from, next) => {
    let canNext = historyManager.guardOrSave(!isExitHome(from.name) && (to.meta.rootDivider || to.meta.divider)); // 뒤로가기시 BackGuard가 있는경우 false 를 반환합니다. BackGuard가 아닌 경우 HTML 히스토리 상태를 저장합니다.

    if (!canNext) return next(false); // backGuard 에 의해 막혔다면 라우터 훅을 종료합니다.

    // 홈에서 뒤로가기 버튼 클릭시 라우터 이동없이 앱을 종료합니다.
    if (historyManager.isBack && isExitHome(from.name)) {
        console.log("exit app");
        nativeBridge.exit(); // 앱 실행 종료
        return next(false); // 앱 종료시 라우터 이동을 하지 않습니다.
    }

    /** 루트 분기 레코드 */
    if (to.meta.rootDivider) {
        const redirectData = to.meta.mapping.find(routeData => {
            if (!!routeData.permission) { /** Admin, Worker 를 분기합니다. */
                return userManager.hasPermission(routeData.permission);
            } else { /** Community 유저 혹은 비로그인 유저를 분기합니다. */
                return true;
            }
        });

        /** 앱 실행시 메인홈에서 BackGuard 사용을 위해 루트 라우터를 등록합니다. 히스토리가 1개 이상이어야 router hook 에 감지됩니다. */
        return next({name: redirectData.to, replace: false /* 루트 분기 라우터 히스토리 등록 */});
    }

    /** 분기 레코드 */
    if (to.meta.divider) {
        const redirectData = to.meta.mapping.find(routeData => {
            if (!!routeData.permission) {
                return userManager.hasPermission(routeData.permission);
            } else {
                return true;
            }
        });

        // 해당되는 라우터로 이동
        if (to.meta.history) {
            return window.location.href = redirectData.to;
        } else {
            return next({name: redirectData.to, replace: false /* 분기 라우터의 경우 히스토리를 남깁니다. */});
        }
    }

    // 인증이 요구되는 라우트입니다.
    if (to.matched.some(record => record.meta.requiresAuth)) {
        /** 로그인 라우터인 경우 */
        //
        if(!!to.params.permission && !userManager.hasPermission(to.params.permission)){
            switch(to.params.permission) {
                case Permissions.EXPOSE_ACCOUNT_INFO:
                    return Vue.prototype.$vm.$snackbarError(ETC.PERMISSION.ACCOUNT_INFO)
                case Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT:
                    return Vue.prototype.$vm.$snackbarError(ETC.PERMISSION.ACCOUNT_REGISTER_EDIT)
                case Permissions.EXPOSE_BILLING_MANAGE:
                    return Vue.prototype.$vm.$snackbarError(ETC.PERMISSION.BILLING_INFO)
                default: return  Vue.prototype.$vm.$snackbarError(`지정되지 않은 권한 ${to.params.permission}`)
            }
        }


        // 특정 화면으로 이동시 유저가 필요한 권한을 모두 가졌는지 체크한다.
        if (!to.meta.permission || userManager.hasPermission(to.meta.permission)) {
            return next();
        }


        // 그 외는 로그인 페이지로
        return next({name: 'NewLogin'});
    }
    // 인증이 필요없는 라우트입니다. 진행합니다.
    return next();
});

window.addEventListener('popstate', () => {
    console.log("popstate");
});

export default router
