<script>
    export default {
        name: "NavItems",
        data() {
            return {
                navItems: []
            }
        },
        created() {
            // this.navItems.push({
            //     id: 0,
            //     routeName:'MainHome',
            //     title: this.$translate('홈'),
            //     icon: require('@/assets/images/main/ic_main_menu_home.png')
            // });
            // this.navItems.push({
            //     id: 1,
            //     routeName:'MainPrinter',
            //     title: this.$translate('프린터'),
            //     icon: require('@/assets/images/main/ic_main_menu_printer.png')
            // });
            // this.navItems.push({
            //     id: 2,
            //     routeName:'MainAs',
            //     title: this.$translate('AS'),
            //     icon: require('@/assets/images/main/ic_main_menu_as.png')
            // });
            // this.navItems.push({
            //     id: 3,
            //     routeName:'MainAccounts',
            //     title: this.$translate('거래처'),
            //     icon: require('@/assets/images/main/ic_main_menu_account.png')
            // });
            // this.navItems.push({
            //     id: 4,
            //     routeName:'MainDevices',
            //     title: this.$translate('장치_정보'),
            //     icon: require('@/assets/images/main/ic_main_menu_device.png')
            // });
            // this.navItems.push({
            //     id: 5,
            //     routeName:'MainSchedule',
            //     title: this.$translate('AS_일정'),
            //     icon: require('@/assets/images/main/ic_main_menu_employee.png')
            // });
            this.navItems.push({
                id: 6,
                routeName:'Settings',
                title: this.$translate('설정'),
                icon: require('@/assets/images/main/ic_main_menu_employee.png'),
                push: true,
            });
        }
    }
</script>