import Vue from "vue";
import SnackbarBuilder from "@/assets/plugins/mps-snackbar/SnackbarBuilder";
import SnackbarOverlay from "@/assets/plugins/mps-snackbar/SnackbarOverlay";

const bus = new Vue();

export default {

    install(Vue, options) {
        this.installPrototypes(Vue, options);
        const components = {SnackbarOverlay};
        Object.keys(components).forEach(key => Vue.component(key, components[key]));
    },
    installPrototypes: function (Vue, options) {
        Vue.prototype.$mps_snackbar_event_bus = bus;
        Vue.prototype.$snackbar = (message) => new Promise((resolve) => {
            const vm = new Vue();
            return new SnackbarBuilder(bus)
                .message(message)
                .color("black")
                .build()
                .show();
        });
        Vue.prototype.$snackbarError = (message) => new Promise((resolve) => {
            const vm = new Vue();
            return new SnackbarBuilder(bus)
                .message(message)
                .color("red")
                .build()
                .show();
        });
        Vue.prototype.$snackbarSuccess = (message) => new Promise((resolve) => {
            const vm = new Vue();
            return new SnackbarBuilder(bus)
                .message(message)
                .color("success")
                .build()
                .show();
        });
    }

}
