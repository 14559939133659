<script>
    import BaseListener from "@/assets/plugins/mps-common/event/base-listener";
    import {ON_LOADING_OVERLAY_OPEN_REQUESTED} from "@/constants/listeners";

    /**
     * 서버 API 요청중 전체 화면을 오버레이로 덮어 다른 작업을 하지 못하게 방지할 때 사용합니다.
     */
    export default {
        name: "OnLoadingOverlayOpenRequestListener",
        extends: BaseListener,
        created() {
            this.registerListener(ON_LOADING_OVERLAY_OPEN_REQUESTED, this.onLoadingOverlayOpenRequested);
        },
        beforeDestroy() {
            this.unregisterListener(ON_LOADING_OVERLAY_OPEN_REQUESTED, this.onLoadingOverlayOpenRequested);
        }
    }
</script>
