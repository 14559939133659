<template>
  <v-bottom-sheet v-model="sheet">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="model"
        outlined
        :label="label"
        :readonly="readonly"
        v-on="on"
      />
    </template>
    <v-sheet
      class="text-center"
      height="370px"
    >
      <v-row justify="center">
        <v-date-picker
          v-model="model"
          full-width
          :clearable="clearable"
        />
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<style scoped>

</style>

<script>
    import MFormDatePicker from "../MFormDatePicker";

    export default {
        name: "MFormVuetifyDialogDatePicker",
        mixins: [MFormDatePicker],
        data() {
            return {
              sheet: false,
            }
        },
        watch: {
            'model'() {
                this.sheet = false;
            }
        },

        methods: {
        },
    }
</script>
