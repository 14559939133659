<template>
  <v-row
    class="mps-form-wrapper"
  >
    <v-col
      cols="4"
      class="mps-form-label__wrapper"
    >
      <label class="mps-form-label">{{ label }}</label>
    </v-col>
    <v-col
      cols="8"
      class="mps-form-field__wrapper"
    >
      <template v-if="inEditMode">
        <div class="in-edit-mode">
          <div
            class="form-textarea__fieldset"
            :class="{ disabled: disabled }"
            :style="styleObject"
          >
            <textarea
              ref="textarea"
              class="form-textarea"
              :value="display"
              :disabled="disabled"
              readonly
              :rows="1"
              :placeholder="placeholder"
              type="text"
              :style="textareaHeight"
              @click="onClick"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="in-view-mode">
          <div
            class="form-textarea__fieldset"
            :style="styleObject"
          >
            <textarea
              ref="textarea"
              class="form-textarea"
              :value="display"
              :placeholder="placeholder"
              disabled
              :rows="1"
              type="text"
              :style="textareaHeight"
            />
          </div>
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
    import MFormBranchPicker from "@/assets/plugins/mps-form/providers/MFormBranchPicker";
    import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "MFormMpsBranchPicker",
        components: {},
        extends: MFormBranchPicker,
        mixins: [TextAreaAutogrow, ModalVuetifyUser],
        data() {
            return {}
        },
        computed: {
            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },
        },
        watch: {
            display() {
                this.$refs.textarea.value = this.display;
                this.autoGrow(this.$refs.textarea);
            }
        },
        mounted() {
            this.autoGrow(this.$refs.textarea);
        },
        methods: {
            onClick() {
                this.openModalVuetify(MODAL_VUETIFY_NAMES.FORM.PICKER_BRANCH, {
                        fullscreen: true,
                        transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                    }, {
                          title: this.titleComputed,
                          accountId: this.options.accountId,
                          accountBranchId: this.options.accountBranchId, },
                    (data) => {
                        this.model = data;
                    }
                );
            },
        },
    }
</script>