import {ACTION_HIDE_SNACKBAR, ACTION_SHOW_SNACKBAR} from "@/assets/plugins/mps-snackbar/constants";

export default class Snackbar {
    constructor(eventBus, options) {
        this.eventBus = eventBus;
        this.options = options;
    }

    show() {
        this.eventBus.$emit(ACTION_SHOW_SNACKBAR, this.options);
    }

    hide() {
        this.eventBus.$emit(ACTION_SHOW_SNACKBAR);
    }

}