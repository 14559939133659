import common from './functions'

export default {
    install(Vue, options) {
        // filters 오브젝트에 있는 모든 Property를 filter, directive, method 로 등록합니다.
        for (let key in common) {
            Vue.prototype['$' + key] = common[key];
        }
    }
}
