<script>
    export default {
        name: "VuetifyModalBackGuardInterface",
        mounted() {
            /** 백가드를 재설정한다. */
            this.backGuard.pop();
            this.backGuard.push(() => {
                // 뒤로가기 클릭시 이미 백가드를 guard 한 상태기 때문에 다시 push해준다.
                // ModalVuetifyModalLayerLegacy 에서 backguard 를 pop 해준다.
                this.backGuard.push(() => {});
                this.onClickClose(); // 닫기 액션
            });
        },
        methods: {
            onClickClose() {
                console.warn("onClickClose must be implmented!");
            },

            onClickApply() {
            },
        }
    }
</script>