/**
 * MForm 에서 폼 구성요소를 구분하기 위한 정의입니다.
 */
export const FORM_TYPE = {
    TEXT_FIELD: 'text_field',
    TEXTAREA: 'textarea',
    COMBOBOX: 'combobox',
    DATE_PICKER: 'date_picker',
    TIME_PICKER: 'time_picker',
    DATETIME_PICKER: 'datetime_picker',
    ADDRESS_PICKER: 'address_picker',
    ACCOUNT_PICKER: 'account_picker',
    ACCOUNT_PICK_OR_WRITER: 'account_pick_or_writer',
    BRANCH_PICKER: 'branch_picker',
    STAFF_PICKER: 'staff_picker',
    CODE_PICKER: 'code_picker',
    RENTALCOMPANY_CODE_PICKER: 'rentalcompany_code_picker',
    RENTALCOMPANY_CODE_DIALOG_PICKER: 'rentalcompany_code_dialog_picker',
    PRINTER_PICKER: 'printer_picker',
    OPTIONS_PICKER: 'options_picker',
    NUMBER: 'number',
    VUE_THE_MASK: 'vueTheMask',
    CHECKBOX: 'CHECKBOX',
    QUILL_EDITOR: 'QUILL_EDITOR',
    FILE_UPLOAD: 'FILE_UPLOAD',
    FILE_INFOS: 'FILE_INFOS',
    PHONE_NUMBER: 'PHONE_NUMBER',
};
