<script>
    import BaseListener from "@/assets/plugins/mps-common/event/base-listener";

    export default {
        name: "OnBottomSheetOpenRequestedListener",
        extends: BaseListener,
        created() {
            this.registerListener(this.mpsEvents.ON_BOTTOM_SHEET_OPEN_REQUESTED, this.onBottomSheetOpenRequested);
        },
        beforeDestroy() {
            this.unregisterListener(this.mpsEvents.ON_BOTTOM_SHEET_OPEN_REQUESTED, this.onBottomSheetOpenRequested);
        }
    }
</script>
