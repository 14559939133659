<template>
  <v-card style="height: 100%; overflow-y: auto;">
    <v-toolbar
      color="white"
      elevation="0"
      class="d-flex align-center"
    >
      <dialog-title :text="title" />

      <v-btn
        text
        class="button__Close mr-0"
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </v-toolbar>

    <v-list
      v-if="!mounting"
      subheader
      three-line
      style="height: calc(100% - 56px); overflow-y: auto; padding-bottom: 100px;"
    >
      <v-list-item>
        <v-list-item-content>
          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; font-weight: bold;"
          >
            <span>{{ '성명' | translate }}</span>
          </div>
          <div style="border: 1px solid #eeeeee">
            <input
              v-model="supplyChargeName"
              style="padding: 0.5rem; width: 100%"
            >
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; font-weight: bold;"
          >
            <span>{{ '서명' | translate }}</span>
            <v-btn
              icon
              @click="clearPad"
            >
              <v-icon>mdi-autorenew</v-icon>
            </v-btn>
          </div>
          <div class="flex justify-center">
            <div
              ref="signaturePadContainer"
              style="width: 100%; height: 100%; min-width: 200px; max-width: 600px; min-height: 200px; max-height: 200px; background-color: rgba(245, 245, 245, 1); border: solid 5px #eee;"
            >
              <VueSignaturePad
                ref="signaturePad"
                :option="padOptions"
              />
            </div>
          </div>
          <div
            style="color: red;"
            class="pt-3"
          >
            * 서명 등록 후 상단에 [저장]버튼을 클릭해 주세요.
          </div>
          <dialog-apply-button
            :text="'등록' | translate"
            @click:apply="onClickApply"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
import DialogApplyButton from "@/components/part/sub/DialogApplyButton";
import DialogTitle from "@/components/part/sub/DialogTitle";
import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
import VSelectBackguard from "@/components/part/VSelectBackguard";
import now from "@/assets/plugins/mps-common/time/now";

export default {
  name: "ElectricalContractSignModal",
  components: {DialogApplyButton, DialogTitle,},
  mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
  data() {
    return {
      padOptions: {
        minWidth: 0.5,
        maxWidth: 2.5,
        minDistance: 1,
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgba(255,0,255, 1)"
      },
      mounting: false,
      mount: false,
      activatedId: 0,
      supplyChargeName: undefined,
    }
  },
  computed: {
    title() {
      return this.$translate("서명")
    },
  },
  mounted() {
    this.activatedId++;
    this.supplyChargeName = this.propsData.supplyChargeName
    this.mounting = true;
    setTimeout(() => {
      this.mounting = false;
      this.$nextTick(() => {
        if (this.mount) this.resizePad();
        this.clearPad(); // activated 시 마다 pad 를 clear 합니다.
      });
    }, 400);
    this.mount = true;
  },
  methods: {
    resizePad() {
      this.$nextTick(() => {
        const container = this.$refs.signaturePadContainer;
        if (!container) {
          console.error("signaturePadContainer is undefined");
          return;
        }
        const canvas = this.$refs.signaturePad.$el.querySelector('canvas');
        if (!canvas) {
          console.error("canvas is undefined");
          return;
        }
        canvas.setAttribute('width', container.clientWidth);
        canvas.setAttribute('height', container.clientHeight);
      })
    },

    clearPad() {
      this.$refs.signaturePad.clearSignature();
    },

    savePad() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      return isEmpty ? null : data;
    },

    onClickClose() {
      this.close();
    },

    onClickApply() {
      const serverData = {
        signImage: this.savePad(),
        supplyChargeName: this.supplyChargeName
      };

      this.callAndClose(serverData);
    },
  }
}
</script>

<style scoped>
>>> .v-toolbar__content, .v-toolbar__extension {
  width: 100%;
  justify-content: space-between;
}

>>> .v-toolbar__items {
  flex: 1;
  justify-content: center;
}

>>> .v-text-field--outlined fieldset {
  border: 1px solid #ddd !important;
}

>>> .v-text-field--outlined {
  border-radius: 0px;
}

</style>
