<template>
  <v-row justify="center">
    <template v-if="inEditMode">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="display"
            :clearable="clearable"
            :label="label"
            :placeholder="placeholder"
            :readonly="readonly"
            :rules="rules"
            class="px-3"
            v-on="on"
          />
        </template>

        <m-form-branch-full-dialog
          :account-id="options.accountId"
          :account-branch-id="options.accountBranchId"
          :title="titleComputed"
          :dialog.sync="dialog"
          @pick="onPick"
        />
      </v-dialog>
    </template>
    <template v-else>
      <v-text-field
        :value="display"
        :clearable="clearable"
        :label="label"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="rules"
        class="px-3"
      />
    </template>
  </v-row>
</template>

<script>
    import MFormBranchFullDialog from "@/assets/plugins/mps-form/providers/components/dialog/MFormBranchFullDialog";
    import MFormBranchPicker from "@/assets/plugins/mps-form/providers/MFormBranchPicker";

    /**
     * 사용하는 옵션
     * {items, itemName(optional), itemValue(optional)}
     */
    export default {
        name: "MFormVuetifyBranchPicker",
        components: {MFormBranchFullDialog},
        extends: MFormBranchPicker,
        data() {
            return {
                dialog: false,
            }
        },
        computed: {},
        methods: {
            onPick(pick) {
                this.dialog = false;
                this.model = pick;
            },
        },
    }
</script>