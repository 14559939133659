import path from "./api";
import locale from "./dateFormats";
import timeFormats from "./time-formats";
import codes from "./codes";
import maxlength from "./maxlength";

// API 메소드 모음
export const DEFAULT_OPTION = {
    API_REQUEST_PAGE_SIZE: 15
};
export const PATH = path;
export const LOCALE = locale; // 기존 시간 포맷
export const TIME_FORMATS = timeFormats; // 새로운 시간 포맷
export const CODE = codes;
export const MAXLENGTH = maxlength;
