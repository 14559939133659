<template>
  <v-combobox
    v-if="displayable"
    v-model="model"
    :clearable="clearable"
    :disabled="disabled"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :label="label"
    :loading="loading"
    :placeholder="placeholder"
    :readonly="readonly"
    :rules="rules"
  />
  <div v-else />
</template>

<style scoped>

</style>

<script>
    import MFormCombobox from "@/assets/plugins/mps-form/providers/MFormCombobox";

    /**
     * 사용하는 옵션
     * {items, itemName(optional), itemValue(optional)}
     */
    export default {
        name: "MFormVuetifyCombobox",
        mixins: [MFormCombobox],
        computed: {
            displayable() {
                // items 가 없으면 표시 불가능
                if (!this.options.items) return false;
                return true;
            }
        }
    }
</script>
