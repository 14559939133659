export default {
    install(Vue, options) {
        Vue.directive('focus-delay', {
            bind: function (el, binding, vnode) {
                let beFocused = el;

                let ms = 0;
                if (!!binding.value && typeof binding.value === 'number' && binding.value > 0) {
                    ms = binding.value;
                }

                setTimeout(() => {
                    /* arg 가 child-input 이면 자식 요소중 input 요소를 찾아 포커싱합니다. */
                    if (binding.arg == 'child-input') {
                        beFocused = el.querySelector("input");
                    } else if (binding.arg === 'child-textarea') {
                        beFocused = el.querySelector("textarea");
                    }
                    if (!beFocused) return;
                    beFocused.focus();
                }, ms);
            }
        });
    }
}