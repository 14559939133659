<template>
  <div>
    <v-row
      class="mps-form-wrapper mps-form-checkbox"
    >
      <v-col
        :cols="labelCols"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        :cols="contentCols"
        class="mps-form-field__wrapper"
      >
        <template v-if="inEditMode">
          <div class="in-edit-mode">
            <div
              class="form-textarea__fieldset text-left"
              :class="{ disabled: disabled }"
              :style="styleObject"
            >
              <label>
                <input
                  id=""
                  v-model="innerModel"
                  type="checkbox"
                  :disabled="disabled"
                >
                {{ placeholder }}
              </label>
              <div
                v-if="options.explanation"
                class="mps-form-explanation"
              >
                {{ options.explanation }}
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="in-view-mode">
            <div
              class="form-textarea__fieldset"
              :style="styleObject"
            >
              <textarea
                ref="textarea"
                :placeholder="placeholder"
                class="form-textarea"
                :style="textareaHeight"
                :rows="1"
                :value="innerModel"
                disabled
                type="text"
              />
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";
    import MBaseFormCheckbox from "@/assets/plugins/mps-form/providers/MBaseFormCheckbox";

    export default {
        name: "MFormMpsCheckbox",
        extends: MBaseFormCheckbox,
        mixins: [TextAreaAutogrow],
        computed: {
            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },
        },
        watch: {
            model() {
                if (!!this.$refs.textarea) {
                    this.$refs.textarea.value = this.model || '';
                    this.autoGrow(this.$refs.textarea);
                }
            }
        },
        mounted() {
            if (!!this.$refs.textarea) {
                this.autoGrow(this.$refs.textarea);
            }
        },
    }
</script>

<style scoped>
    >>> .border_bottom {
        border-bottom: 1px solid #ddd !important;
    }

    >>> .border_bottom > div:last-child {
        flex-basis: 50px !important;
        flex-grow: 1 !important;
    }
</style>
