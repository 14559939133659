import RequesterBuilder from "@/assets/plugins/mps-common/network/requester-builder";
import {CODE, PATH} from "@/constants";
import userManager from "@/assets/plugins/mps-common/user/user-manager";
import now from "@/assets/plugins/mps-common/time/now";
import {cloneSelective} from "@/assets/plugins/mps-common/expansions/clone";
import parseDate from "@/assets/plugins/mps-common/time/parse-date";
import {vm} from "@/main";
import {ON_WORKORDER_STATUS_UPDATED} from "@/constants/listeners";
import VueAccessor from "@/assets/plugins/mps-common/app/VueAccessor";

export default class Work extends VueAccessor {

  _work;

  constructor(work) {
    super();
    this._work = work;
  }

  get raw() {
    return this._work;
  }

  get isReservationIsOver() {
    if (!this.reservationDatetimeObj) return false;
    if (this._work.workorderState === CODE.AS.COMPLETED || this._work.workorderState === CODE.AS.COMPLETED) return false;
    return now().ts > this.reservationDatetimeObj.ts;
  }

  get doneDatetimeObj() {
    return parseDate(this._work.doneDate) || {};
  }

  get reservationDatetimeObj() {
    return parseDate(this._work.reservationDatetime) || {};
  }

  get isWorkorderStateCompleted() {
    return this._work.workorderState === CODE.AS.COMPLETED;
  }

  get isWorkorderStateCanceled() {
    return this._work.workorderState === CODE.AS.CANCELED;
  }

  get isWorkorderStateReceived() {
    return this._work.workorderState === CODE.AS.RECEIVED;
  }

  get isWorkorderStateWatingStaff() {
    return this._work.workorderState === CODE.AS.WAITING_STAFF;
  }

  get isWorkorderStateInProgress() {
    return this._work.workorderState === CODE.AS.IN_PROGRESS;
  }

  get isWorkorderStateHoldOff() {
    return this._work.workorderState === CODE.AS.HOLD_OFF;
  }

  /**
   * 예약일정잡기
   */
  reserve = async (data) => {
    if (!data) return;

    const context = {
      doneDate: null,
      reservationDatetime: data.reservationDatetime,
      worker: this._work.worker,
      workorderId: this._work.workorderId,
      asId: this._work.asId,
      workorderState: this._work.workorderState,
      changedReason: "Reserve",
      monitoringPrinterId: this._work.monitoringPrinterId
    };

    await this.processAs(context);
  }

  /**
   * 예약일정잡기(진행상태로 변경)
   */
  reserveWithProcess = async (data) => {
    if (!data) return;

    const context = {
      doneDate: null,
      reservationDatetime: data.reservationDatetime,
      workorderId: this._work.workorderId,
      worker: this._work.worker,
      asId: this._work.asId,
      workorderState: CODE.AS.IN_PROGRESS,
      changedReason: "Reserve",
      monitoringPrinterId: this._work.monitoringPrinterId
    };

    await this.processAs(context);
  }

  /**
   * 위임
   */
  delegate = async (data) => {
    if (!data) return;

    // 위임요청으로 간주한다.
    if (!data.userId) {
      const context = {
        doneDate: null,
        worker: null,
        workorderId: this._work.workorderId,
        asId: this._work.asId,
        reservationDatetime: this._work.reservationDatetime,
        workorderState: CODE.AS.RECEIVED,
        changedReason: data.changedReason,
        monitoringPrinterId: this._work.monitoringPrinterId
      };

      await this.processAs(context);
    } else { // 위임처리
      const context = {
        doneDate: null,
        worker: data.userId,
        workorderId: this._work.workorderId,
        asId: this._work.asId,
        reservationDatetime: this._work.reservationDatetime,
        workorderState: CODE.AS.WAITING_STAFF,
        changedReason: data.changedReason,
        monitoringPrinterId: this._work.monitoringPrinterId
      };

      await this.processAs(context);
    }
  }

  /**
   * 배정(할당)
   */
  assign = async (user) => {
    if (!user || !user.userId) return;

    const context = {
      doneDate: null,
      worker: user.userId,
      reservationDatetime: this._work.reservationDatetime,
      workorderId: this._work.workorderId,
      asId: this._work.asId,
      workorderState: CODE.AS.WAITING_STAFF,
      monitoringPrinterId: this._work.monitoringPrinterId
    };

    await this.processAs(context);
  }

  /**
   * 거절
   */
  deny = async (v) => {
    const context = {
      doneDate: null,
      workorderId: this._work.workorderId,
      asId: this._work.asId,
      workorderState: CODE.AS.RECEIVED,
      worker: null,
      reservationDatetime: this._work.reservationDatetime,
      changedReason: v.changedReason,
      monitoringPrinterId: this._work.monitoringPrinterId
    }

    await this.processAs(context);
  }

  /**
   * 취소
   */
  cancel = async (v) => {
    const context = {
      doneDate: v.doneDate,
      workorderId: this._work.workorderId,
      asId: this._work.asId,
      workorderState: CODE.AS.CANCELED,
      worker: this._work.worker,
      reservationDatetime: this._work.reservationDatetime,
      asContent: v.asContent,
      monitoringPrinterId: this._work.monitoringPrinterId,
      asExcludePeriod: v.asExcludePeriod
    };

    await this.processAs(context);
  }

  /**
   * 완료
   */
  complete = async (editData) => {
    const context = {
      doneDate: editData.doneDate,
      doneReasonCode: editData.doneReasonCode,
      asContent: editData.asContent,
      workorderId: this._work.workorderId,
      asId: this._work.asId,
      worker: this._work.worker,
      workorderState: CODE.AS.COMPLETED,
      reservationDatetime: this._work.reservationDatetime,
      monitoringPrinterId: this._work.monitoringPrinterId,
      requestFileData: editData.requestFileData
    };

    const signImageName = "signpad-" + this._work.workorderId + "-" + this._work.asId + ".png";

    await this.processAs(context, editData.signImage, signImageName);
  }

  /**
   * 수락
   */
  accept = async () => {
    const context = {
      doneDate: null,
      worker: userManager.user.userId,
      reservationDatetime: this._work.reservationDatetime,
      workorderId: this._work.workorderId,
      asId: this._work.asId,
      workorderState: CODE.AS.IN_PROGRESS,
      monitoringPrinterId: this._work.monitoringPrinterId
    };

    await this.processAs(context);
  }

  /**
   * 배정
   *
   * - 추가적인 정보들을 입력받습니다.
   */
  assignWithInformation = async (data) => {
    const context = {
      doneDate: null,
      worker: data.worker,
      reservationDatetime: data.reservationDatetime,
      workorderId: this._work.workorderId,
      asId: this._work.asId,
      workorderState: data.workorderState,
      accountId: this._work.accountId,
      monitoringPrinterId: this._work.monitoringPrinterId
    };

    await this.processAs(context);
  }

  async processAs(context, signImage = null, signImageName = null) {
    try {
      const asContentDettachments = context.requestFileData?.asContentDettachments || undefined
      const asContentAttachments = context.requestFileData?.asContentAttachments || undefined

      let response

      if(asContentDettachments || asContentAttachments){

        response = await new RequesterBuilder()
          .path(PATH.WORK_ORDER.PROCESS_WITH_SIGN_NEW)
          .multipart()
          .appendObject("context", context)
          .appendDataUri("signImage", signImage, signImageName)
          .appendObject("asContentDettachments", asContentDettachments)
          .appendFiles("asContentAttachments", asContentAttachments)
          .enqueue();
      }else {
        response = await new RequesterBuilder()
          .path(PATH.WORK_ORDER.PROCESS_WITH_SIGN)
          .multipart()
          .appendObject("context", context)
          .appendDataUri("signImage", signImage, signImageName)
          .enqueue();
      }
      cloneSelective(response, this._work);
      this.vm.notifyEvent(ON_WORKORDER_STATUS_UPDATED, {workorderId: this._work.workorderId});
    } catch (e) {
      console.error(e);
    }
  }
}