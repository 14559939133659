<template>
  <div
    class="status-options-bar"
  >
    <div
      class="pa-2 chip_wrap"
    >
      <template v-if="$route.name === 'MainPrinter'">
        <vue-switch
          v-model="printerQuickSortModel"
          color="#BCD1E2"
          :true-text="'설치순' | translate"
          :false-text="'오류순' | translate"
          true-text-color="#0c98fe"
          false-text-color="#EC4DA3"
          true-background-color="#C9E8FF"
          false-background-color="#FBD8EB"
          class="switch"
        />
      </template>
      <template v-if="$route.name === 'MainAs' ">
        <vue-switch
          v-model="asQuickSortModel"
          color="#BCD1E2"
          :true-text="'예약순' | translate"
          :false-text="'접수순' | translate"
          true-text-color="#0c98fe"
          false-text-color="#EC4DA3"
          true-background-color="#C9E8FF"
          false-background-color="#FBD8EB"
          class="switch"
        />
      </template>
      <template v-if="$route.name === 'ConsultNote'">
        <vue-switch
          v-model="consultQuickSortModel"
          color="#BCD1E2"
          :true-text="'전체' | translate"
          :false-text="'본인' | translate"
          true-text-color="#0c98fe"
          false-text-color="#EC4DA3"
          true-background-color="#C9E8FF"
          false-background-color="#FBD8EB"
          class="switch"
        />
      </template>
      <v-chip
        v-for="(item, index) in items"
        :key="index"
        class="mr-1"
        :color="item.color || '#EDD8FB'"
      >
        <div
          :style="{maxWidth: item.maxWidth}"
          style="white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis;"
        >
          {{ item.text }}
        </div>
      </v-chip>
      <div style="display: inline-block; margin-right: 49px;" />
    </div>
    <div
      class="close_button_wrap"
    >
      <v-btn
        icon
        @click="onClickReset"
      >
        <img src="@/assets/images/m_close_button.svg">
      </v-btn>
    </div>
  </div>
</template>

<script>
    export default {
        name: "StatusOptionsBar",
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            quickSort: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                // false: 오류순, true: 설치순
                printerQuickSortModel: this.quickSort,
                consultQuickSortModel: this.quickSort,
                asQuickSortModel: this.quickSort,
            };
        },
        watch: {
          quickSort(newValue) {
            this.printerQuickSortModel = newValue;
            this.consultQuickSortModel = newValue
            this.asQuickSortModel = newValue;
          },

            /**
             * 프린터 빠른 정렬이 토글되었습니다.
             * 메인 프린터 컴포넌트에 알려줍니다.(MainPrinter.vue)
             */
            printerQuickSortModel(newValue) {
                this.notifyPrinterQuickSort();
                this.$emit("update:quick-sort", newValue)
            },
          consultQuickSortModel(newValue) {
            this.notifyConsultQuickSort();
            this.$emit("update:quick-sort", newValue)
          },

            asQuickSortModel(newValue) {
              this.notifyAsQuickSort();
              this.$emit("update:quick-sort", newValue)
            },
        },
        created() {
        },
        methods: {
            /**
             * 필터초기화 버튼이 클릭되었습니다.
             */
            onClickReset() {
                this.$emit('reset');
                this.printerQuickSortModel = false;
                this.asQuickSortModel = false;
            },

            /**
             * 프린터 빠른 정렬이 변경되었습니다.
             */
            notifyPrinterQuickSort() {
                this.notifyEvent(this.mpsEvents.ON_MAIN_PRINTER_QUICK_SORT_CHANGED, this.printerQuickSortModel);
            },
            notifyConsultQuickSort() {
            this.notifyEvent(this.mpsEvents.ON_CONSULT_NOTE_QUICK_SORT_CHANGED, this.consultQuickSortModel);
          },

            /**
             * as 빠른 정렬이 변경되었습니다.
             */
            notifyAsQuickSort() {
                this.notifyEvent(this.mpsEvents.ON_MAIN_AS_QUICK_SORT_CHANGED, this.asQuickSortModel);
            },
        }
    }
</script>

<style scoped>
    .status-options-bar {
        position: fixed;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        border-bottom: 1px solid #ddd;
        top: 56px;
        min-height: 49px;
        max-height: 49px;
        left: 0;
        right: 0;
        z-index: 3;
        background: white;
    }

    .switch {
      font-size: 0.8125rem;
      line-height: 25px;
    }

    .v-chip.v-size--default {
        border-radius: 14px;
        color: white;
        font-size: 12px;
        height: 28px;
    }

    .chip_wrap {
        display: block;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        text-align: left;
    }

    .close_button_wrap {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 49px;
        background-color: rgba(255, 255, 255, 0.8);
        top: 0;
        right: 0;
        z-index: 1;
    }

    .close_button_wrap > img {
        position: absolute;
    }

    .v-chip.v-size--default {
      color: #AF4DEC;
    }

    >>>.v-input--switch .v-input--switch__thumb {
      background-color: white !important;
    }

    >>> .v-input--switch--inset .v-input--switch__track {
      opacity: 1;
    }
</style>
