<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    class="overflow-hidden"
    persistent
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <img src="@/assets/images/main/svg/buttonFilter.svg">
      </v-btn>
    </template>

    <v-card class="overflow-hidden">
      <div class="d-flex justify-space-between align-center px__15">
        <v-card-title
          class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
        >
          {{ '검색 필터' | translate }}
        </v-card-title>

        <v-btn
          text
          class="button__Close"
          @click="onClickClose"
        >
          <!--{{ '닫기' | translate }}-->
          <img src="@/assets/images/main/svg/button_Close.svg">
        </v-btn>
      </div>


      <v-card-text
        style="max-height: calc(90vh - 58px - 50px)"
        class="overflow-y-auto overflow-x-hidden"
      >
        <v-row
          justify="center"
          class="align-center"
        >
          <!--          <v-col cols="4">-->
          <!--            접수구분-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-select
              v-model="cmsState"
              :items="cmsStateOptions"
              item-text="codeValue"
              item-value="codeKey"
              :placeholder="'CMS' | translate"
              hide-details
              clearable
              class="pa-0 ma-0"
            />
          </v-col>
        </v-row>
        <v-row
          justify="center"
          class="align-center"
        >
          <!--          <v-col cols="4">-->
          <!--            진행상태-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-select
              v-model="autoIssueYn"
              :items="autoIssueYnOptions"
              :placeholder="'세금계산서' | translate"
              item-text="codeValue"
              item-value="codeKey"
              hide-details
              clearable
              class="pa-0 ma-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <!--          <v-col cols="4">-->
          <!--            진행상태-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-select
              v-model="useYn"
              :items="useYnOptions"
              :placeholder="'계약상태' | translate"
              item-text="codeValue"
              item-value="codeKey"
              hide-details
              clearable
              class="pa-0 ma-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <!--          <v-col cols="4">-->
          <!--            진행상태-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-select
              v-model="billDayList"
              :items="billDayOnlyNumberOptions"
              :placeholder="'결제일' | translate"
              item-text="codeValue"
              item-value="codeKey"
              multiple
              clearable
              hide-details
              class="pa-0 ma-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <!--          <v-col cols="4">-->
          <!--            진행상태-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-select
              v-model="calculationStartdateList"
              :items="billDayOnlyNumberOptions"
              :placeholder="'계산시작일' | translate"
              item-text="codeValue"
              item-value="codeKey"
              multiple
              clearable
              hide-details
              class="pa-0 ma-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <!--          <v-col cols="4">-->
          <!--            진행상태-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-select
              v-model="unvisitedMonth"
              :items="unvisitedMonthOptions"
              :placeholder="'장기 미방문' | translate"
              item-text="codeValue"
              item-value="codeKey"
              clearable
              hide-details
              class="pa-0 ma-0"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <!--      <v-divider />-->
      <v-card-actions
        class="ma-0 mt-2 pa-0"
      >
        <!--        <v-spacer />-->

        <v-btn
          text
          class="button__100per radius__None"
          @click="onClickApply"
        >
          {{ '적용' | translate }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import CodeOptions from "@/components/mixins/CodeOptions";

    export default {
        name: "AccountSearchFilterButton",
        components: {},
        mixins: [CodeOptions],
        props: {
            state: {
                type: Object, default: () => { return {} }
            },
        },
        data() {
            return {
                dialog: false,
                // CMS 상태
                cmsState: undefined,
                // 세금계산서
                autoIssueYn: undefined,
                // 계약상태
                useYn: undefined,
                // 계산시작일
                calculationStartdateList: [],
                // 결제일
                billDayList: [],
                // 장기미방문
                unvisitedMonth: undefined,
            };
        },
        computed: {
            _state() {
                return {
                    cmsState: this.cmsState,
                    autoIssueYn: this.autoIssueYn,
                    useYn: this.useYn,
                    calculationStartdateList: this.calculationStartdateList,
                    billDayList: this.billDayList,
                    unvisitedMonth: this.unvisitedMonth,
                }
            },
        },
        watch: {
            '_state'(newState) {
                this.$emit('update:state', this._state);
            },
        },
        created() {
            this.loadCmsStateOptions();
            this.loadAutoIssueYnOptions();
            this.loadUseYnOptions();
            this.loadBillDayOnlyNumberOptions();
            this.loadUnvisitedMonthOptions();
            this.cmsState = this.state.cmsState;
            this.autoIssueYn = this.state.autoIssueYn;
            this.useYn = this.state.useYn;
            this.calculationStartdateList = this.state.calculationStartdateList;
            this.billDayList = this.state.billDayList;
            this.unvisitedMonth = this.state.unvisitedMonth;
        },
        methods: {
            onClickClose() {
                this.dialog = false;
            },

            onClickApply() {
                this.$emit("onFilter", this._state);
                this.dialog = false;
            },
        }
    }
</script>

<!--<style scoped src="./mps-sheet/mps-sheet.css"></style>-->

<style scoped>
  >>> .v-dialog {
    overflow: hidden !important;
  }

  >>> .v-select__selections > input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }

  >>> .v-select__selections > input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }

  >>> .v-dialog > .v-card > .v-card__text {
    padding: 0 24px 0 !important;
  }

  >>>.v-item-group {
    flex: 1;
  }

  >>>.v-btn-toggle > .v-btn.v-btn {
    flex: 1 !important;
  }

  >>>.v-btn:not(.v-btn--round).v-size--default {
    min-width: auto;
  }
</style>