<template>
  <div class="carousel-tabs">
    <v-tabs
      v-model="model"
      color="#333"
      :show-arrows="false"
      hide-slider
      style="border-bottom: 1px solid #ddd;"
    >
      <v-tab
        v-for="(item, index) in items"
        :key="item.value"
        v-ripple="false"
        :href="'#' + item.value"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <!--    <div-->
    <!--      v-for="(item, index) in items"-->
    <!--      :key="item.value"-->
    <!--      class="carousel-tabs__item"-->
    <!--    >-->
    <!--      <slot :name="'item.' + item.value">-->
    <!--        <component :is="item.component" />-->
    <!--      </slot>-->
    <!--    </div>-->
    <v-tabs-items
      v-model="model"
      class="fill-height"
    >
      <v-tab-item
        v-for="(item, index) in items"
        :key="item.value"
        :value="item.value"
        class="fill-height overflow-y-auto"
      >
        <component
          :is="item.component"
          v-if="item.value === model"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
    export default {
        name: "CarouselTabsItems",
        props: {
            value: {
                type: String,
                default: null,
            },
            items: {
                type: Array,
                default: () => [],
            },
          items2: {
            type: Array,
            default: () => [],
          },
        },
        data() {
            return {
                model: this.value,
            };
        },
        watch: {
            value() {
                this.model = this.value;
            },

            model() {
                this.$emit("input", this.model);
            },
        },
      methods: {
        onBottomNavChanged(nav) {
          if (!this.nav) return;
          this.$emit('input', nav);
        },
      }
    }
</script>

<style scoped>
    .carousel-tabs {
        width: 100%;
        height: calc(100% - 36px);
    }

    .carousel-tabs__item {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        display: inline-block;
    }

    /** v-tabs 스타일링 시작 */
    >>> .v-tabs-bar {
        height: 36px;
    }

    >>> .v-tab {
        min-width: 60px !important;
    }

    >>> .theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before {
        opacity: 0 !important;
    }

    >>> .v-slide-group__prev {
      display: none !important;
    }

    >>> .v-tab.v-tab--active {
      font-weight: bold !important;
    }
    /** v-tabs 스타일링 종료 */
</style>
