<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        :cols="labelCols"
        class="mps-form-label__wrapper"
        :class="{'mps-form-label__full': fullWidthLabel}"
      >
        <span
          v-if="inEditMode && required"
          class="color__red mr-1"
        >*</span>
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        :cols="contentCols"
        class="mps-form-field__wrapper"
        :class="{'mps-form-field__full': fullWidthLabel}"
      >
        <template v-if="inEditMode">
          <div class="in-edit-mode">
            <div
              class="form-textarea__fieldset"
              :class="{ disabled: disabled }"
              :style="styleObject"
              style="display: flex;"
            >
              <textarea
                ref="textarea"
                v-model="model"
                class="form-textarea"
                :disabled="disabled"
                :placeholder="placeholder"
                :style="textareaHeight"
                :rows="1"
                :type="options.type || 'text'"
                :maxlength="options.maxlength || 99999"
              />

              <!--              <v-btn-->
              <!--                style="border-radius: 50%;"-->
              <!--                dark-->
              <!--                fab-->
              <!--                color="#999"-->
              <!--                width="18"-->
              <!--                height="18"-->
              <!--                class="ml-2"-->
              <!--              >-->
              <!--                <v-icon-->
              <!--                  x-small-->
              <!--                  dark-->
              <!--                >-->
              <!--                  mdi-close-->
              <!--                </v-icon>-->
              <!--              </v-btn>-->
            </div>
          </div>
        </template>

        <template v-else>
          <div class="in-view-mode">
            <div style="display: flex; width: 100%; justify-content: start;">
              <div class="form-link">
                <a
                  v-if="options.linkName"
                  @click.stop.prevent="onClickLink"
                >{{ model }}</a>
                <span v-else>{{ model }}</span>
              </div>
            </div>
          </div>
          <!--          <div class="in-view-mode">-->
          <!--            <div-->
          <!--              class="form-textarea__fieldset"-->
          <!--              :style="styleObject"-->
          <!--            >-->
          <!--              <textarea-->
          <!--                ref="textarea"-->
          <!--                :placeholder="placeholder"-->
          <!--                class="form-textarea"-->
          <!--                :style="textareaHeight"-->
          <!--                :rows="1"-->
          <!--                :value="model"-->
          <!--                disabled-->
          <!--                type="text"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </div>-->
        </template>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import MBaseFormTextarea from "@/assets/plugins/mps-form/providers/MBaseFormTextarea";
    import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";

    export default {
        name: "MFormMpsTextarea",
        extends: MBaseFormTextarea,
        mixins: [TextAreaAutogrow],
        computed: {
            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },
        },
        watch: {
            model() {
                if (!this.model || !this.$refs.textarea) return
                this.$refs.textarea.value = this.model;
                this.autoGrow(this.$refs.textarea);
            }
        },
        mounted() {
            window.addEventListener("resize", e => {
                if (this.model) {
                    this.model += " ";
                    this.$nextTick(() => {
                        this.model = this.model.slice(0, -1);
                    });
                }
            });
            this.autoGrow(this.$refs.textarea);
        },
        methods: {
            onClickLink() {
                if (!this.options.linkName) return;
                this.routerManager.push({
                    name: this.options.linkName,
                    params: this.options.params,
                });
            },
        },
    }
</script>

<style scoped>
    >>> .border_bottom {
        border-bottom: 1px solid #ddd !important;
    }

    >>> .border_bottom > div:last-child {
        flex-basis: 50px !important;
        flex-grow: 1 !important;
    }
</style>
