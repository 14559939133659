<template>
  <v-dialog
    fullscreen
    :value="model"
  >
    <v-btn
      fixed
      style="z-index: 11; right: 0; margin-right: 8px; top: 8px;"
      icon
      large
      dark
      @click="clickClose"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-carousel
      ref="container"
      v-model="innerIndex"
      mandatory
      :continuous="false"
      :cycle="false"
      touchless
    >
      <v-carousel-item
        v-for="(item, i) in list"
        :key="i"
      >
        <v-sheet
          color="black"
          height="100%"
          tile
        >
          <pinch-zoom
            auto-height
            overflow="visible"
            style="height: 100%;"
          >
            <img
              :src="item.src"
              :alt="item.alt"
            >
          </pinch-zoom>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-dialog>
</template>

<script>
    export default {
        name: "ImagePreviewer",
        props: {
            list: {
                type: Array,
                default: () => [],
            },
            index: {
                type: Number,
                default: 0,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                model: this.value,
                innerIndex: this.index,
                mounted: false,
            };
        },
        watch: {
            model() {
                if (this.model) {
                    this.backGuard.push(() => {
                        this.model = false;
                    });
                }
                this.$emit('input', this.model);
            },

            value() {
                this.model = this.value;
            },

            innerIndex() {
                this.$emit('update:index', this.innerIndex);
            },

            index() {
                this.innerIndex = this.index;
            },

            list() {
            },
        },
        mounted() {
            this.mounted = true;
        },
        methods: {
            clickClose() {
                this.model = false;
                this.backGuard.pop();
            },
        },
    }
</script>

<style scoped>
    .v-carousel {
        height: 100% !important;
        background: black;
        z-index: 9;
    }

    >>> .v-window-item {
        display: flex;
        align-items: center;
    }

    >>> .v-carousel__item {
        height: calc(100% - 112px) !important;
        /*height: 2000px !important;*/
    }

    .v-sheet {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pinch-zoom-content {
        width: 1000px;
    }
</style>