export default class PageController {

    _page;
    _pageOfStart;
    _onPageChangedListener;
    _max;
    _marked;

    _initialValues = {
        pageOfStart: 0
    };

    _history = [];

    /**
     * 페이지 처리를 도와주는 컨트롤러 입니다.
     *
     * @param pageOfStart 페이지의 시작. 0으로 설정하면 페이지의 시작이 0 입니다.
     * @param max 페이지 최댓값. 이 값을 초과하여 페이지가 증가하지 않습니다.
     */
    constructor(pageOfStart = 1, max = 0) {
        this._initialValues.pageOfStart = pageOfStart;
        this._initialValues.max = max;
        this.init();
    }

    ////////////////////////////////////////////////////
    // public
    ////////////////////////////////////////////////////

    /**
     * 페이지가 실제로 변경된 경우에 통지받는 리스너입니다.
     * current = 현재 페이지
     * previous = 이전 페이지
     * direction = 변동 방향. 양의 정수면 증가. 음의 정수면 감소. 0이 될 수 없음.
     * @param onPageChangedListener (current, previous, direction)
     */
    set onPageChangedListener(onPageChangedListener) {
        this._onPageChangedListener = onPageChangedListener;
    }

    /**
     * 증가 가능한 페이지의 최댓값을 설정합니다. 이 값을 초과하여 페이지가 증가하지 않습니다.
     * @param max 페이지 최댓값. 양의 정수가 아니면 무제한으로 평가됩니다.
     */
    setMax(max) {
        this._max = max;
        this._sanitizePage();
        this._handleForListener();
    }

    get canGoPrev() {
        return this._page > this._pageOfStart;
    }

    get canGoNext() {
        return !this._limited() || this._page < this._max;
    }

    set page(value) {
        return this._processChangePage(() => this._page = value, false);
    }

    /**
     * 현재 페이지를 반환합니다.
     */
    get page() {
        return this._page;
    }

    /**
     * 이전 페이지를 반환합니다.
     * @returns {*}
     */
    get prevPage() {
        return this._history[this._history.length - 1];
    }

    /**
     * 초기 상태로 전환합니다.
     */
    init() {
        this._history = [];
        this._pageOfStart = this._initialValues.pageOfStart;
        this._page = this._initialValues.pageOfStart;
        this.setMax(this._initialValues.max);
        this._sanitizePage();
    }

    /**
     * 다음 페이지 번호를 반환합니다.
     * @returns {number}
     */
    next() {
        return this._processChangePage(() => this._page++);
    }

    /**
     * 이전 페이지 번호를 반환합니다.
     * @returns {number}
     */
    prev() {
        return this._processChangePage(() => this._page--);
        this._sanitizePage();
        this._handleForListener();
    }

    /**
     * 이전 페이지로 돌아가려면 호출합니다.
     * @return 이전으로 되돌린 페이지 번호
     */
    revert() {
        if (this._history.length > 0) {
            return this._page = this._history[this._history.length - 1];
        } else {
            console.warn('There is no history to go to back.');
        }
    }

    ////////////////////////////////////////////////////
    // private
    ////////////////////////////////////////////////////

    /**
     * 최대 페이지가 존재하는지 여부입니다.
     * @returns {boolean}
     * @private
     */
    _limited() {
        return this._max > 0;
    }

    /**
     *
     * @private
     */
    _sanitizePage() {
        if (this._page < this._pageOfStart) this._page = this._pageOfStart;
        if (this._limited() && this._page > this._max) this._page = this._max;
    }

    /**
     * 현재 페이지를 임시로 저장합니다.
     * @private
     */
    _mark() {
        this._marked = this._page;
    }

    /**
     * 값의 변화를 평가 후 리스너에 통지합니다.
     * @private
     */
    _handleForListener() {
        // 페이지 값의 변동 방향
        const direction = this._page - this._marked;
        // 변동없음
        // 아무것도 하지 않습니다.
        // 실제 변동이 있을 때만 리스너에 통지합니다.
        if (direction === 0) return;
        // 리스너에 통지합니다.
        if (!!this._onPageChangedListener) this._onPageChangedListener(this._page, this._marked, direction);
    }

    /**
     * page 변경을 위한 표현식을 처리합니다.
     * 단 표현식 처리 전/후에 추가 작업을 수행합니다.
     * @param expression
     * @param notify
     * @returns {number}
     * @private
     */
    _processChangePage(expression, notify = true) {
        // this._mark();
        this._history.push(this._page);
        if (expression !== undefined) expression();
        this._sanitizePage();
        if (notify) this._handleForListener();
        return this._page;
    }

}
