import ArrayList from "@/assets/plugins/mps-common/expansions/array/array-list";
import {Preferences} from "@/assets/plugins/mps-common/preferences/preferences";

const KEY_LAST_KNOWN_LOCATION = 'last_known_location';

class LocationManager {

    _pref = new Preferences('location-manager');
    _lastKnownLocation;
    _inTracking;
    _locationListeners = new ArrayList();
    _interval;
    _intervalTimeout;

    constructor() {
        if (this.isGeolocationSupported()) {

        }
    }

    isGeolocationSupported = () => window.navigator.geolocation;

    addLocationListener(locationListener) {
        this._locationListeners.add(locationListener);
    }

    removeLocationListener(locationListener) {
        return this._locationListeners.remove(locationListener);
    }

    get lastKnownLocation() {
        return this._pref.get(KEY_LAST_KNOWN_LOCATION);
    }

    set intervalTimeout(timeout) {
        if (this._intervalTimeout === timeout) return;
        this._intervalTimeout = timeout;
        if (this.isTracking()) this._restartInterval(); // 이미 인터벌중이면 재시작
    }

    findCurrentLocation(callback) {
        if (!this.isGeolocationSupported()) {
            console.warn('This client does not support geolocation.');
            callback(); // 파라미터 없이 그냥 콜.
            return;
        }
        window.navigator.geolocation.getCurrentPosition((position) => {
            this._pref.put(KEY_LAST_KNOWN_LOCATION, position);
            callback(position);
        });
    }

    /**
     * 위치 추적을 중지합니다.
     */
    stopTracking() {
        this._inTracking = false;
        this._stopInterval();
    }

    /**
     * 위치 추적을 시작합니다.
     */
    startTracking() {
        this._inTracking = true;
        if (this.isTracking()) this._notifyToListeners(this.lastKnownLocation); // 마지막으로 알려진 위치 전달.
        this._startInterval();
    }

    isTracking() {
        return this._inTracking;
    }

    _restartInterval() {
        this._stopInterval();
        this._startInterval();
    }

    _stopInterval() {
        if (this._interval) clearInterval(this._interval);
    }

    _startInterval() {
        this._interval = setInterval(this._handleInterval, this._intervalTimeout)
    }

    _handleInterval() {
        this.findCurrentLocation((position) => this._notifyToListeners(position));
    }

    _notifyToListeners(position) {
        this._locationListeners.forEach((e) => {
            try {
                e.onLocationChanged(position);
            } catch (e) {
                console.error(e);
            }
        });
    }
}

const locationManager = new LocationManager();//싱글톤
export {locationManager}
