import {DateTime} from "luxon";
import {isNOU} from "../expansions/condition";

const parseStringDate = function (objectDate) {
    const parsers = [
        DateTime.fromISO,
        DateTime.fromRFC2822,
        DateTime.fromSQL,
        DateTime.fromHTTP,
        DateTime.fromMillis,
        DateTime.fromSeconds,
        DateTime.fromJSDate
    ];
    let error;
    for (const parser of parsers) {
        try {
            return parser(objectDate); // 파싱에 성공하면 반환.
        } catch (e) {
            error = e;
        }
    }
    throw error; // 파싱하지 못한 경우에만 발생.
};


const parseObjectDate = function (objectDate) {
    if (objectDate instanceof DateTime) return objectDate;
    else throw new Error('objectDate is not a luxon DateTime.');
};

const parseDate = (date) => {
    if (isNOU(date)) return null;
    const type = typeof date;
    try {
        switch (type) {
            case 'string':
                return parseStringDate(date);
            case 'object':
                return parseObjectDate(date);
            default:
                return null;
        }
    } catch (e) {
        return null;
    }
};

export default parseDate;
