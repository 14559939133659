<template>
  <div style="width: 100%;">
    <slot v-bind="{ on }">
      <v-btn
        color="green darken-1"
        style="border: 1px solid #009900"
        text
        @click="onClick"
      >
        {{ '완료' | translate }}
      </v-btn>
    </slot>
  </div>
</template>

<script>
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "AsSuccessDialogWithModal",
        components: {},
        mixins: [ModalVuetifyUser],
        props: {
            resign: {
                type: Boolean,
                default: false,
            },
            item: {
                type: Object,
                default: undefined
            },
            options: {
                type: Object, default: () => {
                    return {}
                }
            },
            callback: {
                type: Function,
                default: undefined,
            },
        },
        data() {
            return {}
        },
        computed: {
            on() {
                return {
                    click: this.onClick,
                }
            }
        },
        methods: {
            onClick() {
                this.$emit("click:button");
                this.openModalVuetify(MODAL_VUETIFY_NAMES.AS.SUCCESS, {
                        persistent: true,
                        fullscreen: true,
                        hideOverlay: true,
                        transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                    }, {item: this.item, resign: this.resign, options: this.options},
                    (data) => {
                        this.$emit('click:complete', data);
                        if (this.callback) this.callback(data);
                    }, MODAL_VUETIFY_NAMES.AS.SUCCESS + "-" + this.item.raw.workorderId,
                );
            },
        },
    }
</script>

<style scoped>

</style>
