import {translationManager} from '@/assets/plugins/mps-common/translation/translation-manager.js';

const AS = {
    RECEIVED: '1',
    WAITING_STAFF: '2',
    IN_PROGRESS: '3',
    CANCELED: '4',
    COMPLETED: '5',
    HOLD_OFF: '6',
};

const WORKORDER_RECEIVE_TYPE = {
    // 수집 지연으로 인한 A/S 접수
    COLLECTING_DELAY_VISIT: 'D',
    // 미납금 회수를 위한 A/S 접수
    RECEIVABLE_VISIT: 'R',
    // 장기미방문 A/S 접수
    LONG_TERM_UNVISITED: 'U',
    // 만기도래 A/S 접수
    EXPIRING: 'E',
    // 신규
    NEW: 'N',
    // 해지
    CANCEL: 'C',
    // 정기방문
    REGULAR_VISIT: 'V',
    // 고장신고
    BREAK_DOWN: 'B',
};

const WORKORDER_RECEIVE_TYPE_NAME = {
    NULL: translationManager.translate("선택 안함"),
    N: translationManager.translate("신규 설치"),
    C: translationManager.translate("해지"),
    R: translationManager.translate("미납회수"),
    U: translationManager.translate("장기 미방문"),
    E: translationManager.translate("만기 도래"),
    D: translationManager.translate("수집지연"),
    V: translationManager.translate("정기방문"),
    B: translationManager.translate('고장신고'),
};

const WORKORDER_RECEIVE_TYPE_ITEMS = [
    {codeKey: "0", codeValue: WORKORDER_RECEIVE_TYPE_NAME.NULL},
    {codeKey: "N", codeValue: WORKORDER_RECEIVE_TYPE_NAME.N},
    {codeKey: "C", codeValue: WORKORDER_RECEIVE_TYPE_NAME.C},
    {codeKey: "R", codeValue: WORKORDER_RECEIVE_TYPE_NAME.R},
    {codeKey: "U", codeValue: WORKORDER_RECEIVE_TYPE_NAME.U},
    {codeKey: "E", codeValue: WORKORDER_RECEIVE_TYPE_NAME.E},
    {codeKey: "D", codeValue: WORKORDER_RECEIVE_TYPE_NAME.D},
    {codeKey: "V", codeValue: WORKORDER_RECEIVE_TYPE_NAME.V},
    {codeKey: 'B', codeValue: WORKORDER_RECEIVE_TYPE_NAME.B},
];

export default {AS, WORKORDER_RECEIVE_TYPE, WORKORDER_RECEIVE_TYPE_NAME, WORKORDER_RECEIVE_TYPE_ITEMS};
