<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>
      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <template v-if="inEditMode">
          <div class="in-edit-mode">
            <div
              class="form-textarea__fieldset"
              :class="{ disabled: disabled }"
              :style="styleObject"
            >
              <textarea
                ref="textarea"
                class="form-textarea"
                :value="display"
                :disabled="disabled"
                readonly
                :rows="1"
                :placeholder="placeholder"
                type="text"
                :style="textareaHeight"
                @click="onClick"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="in-view-mode">
            <div
              class="form-textarea__fieldset"
              :style="styleObject"
            >
              <textarea
                ref="textarea"
                class="form-textarea"
                :value="display"
                :placeholder="placeholder"
                disabled
                type="text"
                :rows="1"
                :style="textareaHeight"
              />
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
    import MFormRentalcompanyCodeDialogPicker
        from "@/assets/plugins/mps-form/providers/MFormRentalcompanyCodeDialogPicker";
    import MFormRentalcompanyCodeFullDialog
        from "@/assets/plugins/mps-form/providers/components/dialog/MFormRentalcompanyCodeFullDialog";
    import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "MFormMpsRentalcompanyCodeDialogPicker",
        extends: MFormRentalcompanyCodeDialogPicker,
        mixins: [TextAreaAutogrow, ModalVuetifyUser],
        data() {
            return {
                dialog: false,
            }
        },
      computed: {
        styleObject() {
          return {
            textAlign: this.contentAlign,
          };
        },
      },
        watch: {
            display() {
                this.$refs.textarea.value = this.display;
                this.autoGrow(this.$refs.textarea);
            }
        },
        mounted() {
            this.autoGrow(this.$refs.textarea);
        },
        methods: {
          onClick() {
            this.openModalVuetify(MODAL_VUETIFY_NAMES.FORM.PICKER_RENTALCOMPANY_CODE, {
                      fullscreen: true,
                      transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                    }, {title: this.titleComputed, codeType: this.options.codeType},
                    (data) => {
                      this.model = data;
                    }
            );
          },
        },
    }
</script>