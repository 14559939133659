<template>
  <div>
    <v-row class="mps-form-wrapper">
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>
      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <div>
          <label>
            <template v-if="inEditMode">
              <div class="in-edit-mode">
                <v-bottom-sheet
                  v-model="sheet"
                  @click:outside="onClickOutside"
                >
                  <template v-slot:activator="{ on }">
                    <input
                      :value="displayDate"
                      class="form-textfield"
                      :placeholder="placeholder"
                      type="text"
                      :disabled="disabled"
                      readonly
                      @click="onFocus"
                      v-on="on"
                    >
                  </template>
                  <v-sheet
                    v-if="dateSheet"
                    class="px-1"
                    height="370px"
                  >
                    <v-row justify="center">
                      <v-date-picker
                        v-model="innerDate"
                        full-width
                        type="date"
                        :locale="locale"
                        :clearable="clearable"
                        :min="innerMinDateComputed"
                        @click:date="onClickDate"
                      />
                    </v-row>
                  </v-sheet>
                  <v-sheet
                    v-else-if="timeSheet"
                    height="370px"
                    class="px-3"
                  >
                    <v-row justify="center">
                      <v-time-picker
                        v-model="innerTime"
                        full-width
                        :locale="locale"
                        :clearable="clearable"
                        @click:minute="onClickMinute"
                      />
                    </v-row>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
            </template>
            <template v-else>
              <div class="in-view-mode">
                <div
                  class="form-textarea__fieldset"
                  :style="styleObject"
                >
                  <textarea
                    :value="displayDate"
                    class="form-textarea"
                    disabled
                    :placeholder="placeholder"
                    type="text"
                  />
                </div>
              </div>
            </template>
          </label>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import MFormDateTimePicker from "@/assets/plugins/mps-form/providers/MFormDateTimePicker";
    import now from "@/assets/plugins/mps-common/time/now";

    export default {
        name: "MFormMpsDateTimePicker",
        extends: MFormDateTimePicker,
        mixins: [],
        data() {
            return {
                sheet: false,
                dateSheet: false,
                timeSheet: false,
                innerMinDate: null,
            }
        },
        computed: {
            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },
            innerMinDateComputed() {
                if (!this.innerMinDate) return null;
                return this.innerMinDate.toISODate();
            },
            locale() {
                if (!this.$userManager) return;
                const user = this.user;
                let code = user.languageCode ? user.languageCode : 'en';
                if (user.countryCode) {
                    code += '-' + user.countryCode; // e) XX -> XX-KR
                }
                return code;
            },
        },
        watch: {
            'sheet'() {
                this.dateSheet = true;
                this.timeSheet = false;
            },
        },
        created() {
            this.canNotSelectPastFunc();
        },
        methods: {
            canNotSelectPastFunc() {
                if (this.options.canNotSelectPast) this.innerMinDate = now().startOf('day');
                else this.innerMinDate = null;
            },

            onClickDate() {
                this.dateSheet = false;
                this.timeSheet = true;
            },

            /**
             * Minute 이 선택될 때 Backguard 를 제거합니다.
             */
            onClickMinute() {
                this.backGuard.pop();
                this.sheet = false;
            },

            /**
             * BottomSheet 의 바깥쪽 부분이 선택될 때
             * 설정된 Backguard를 제거합니다.
             */
            onClickOutside() {
                this.backGuard.pop();
            },

            /**
             * 데이트피커가 열릴 때 Backguard 를 설정합니다.
             */
            onFocus() {
                this.backGuard.push(() => {
                    this.sheet = false;
                });
            },
        },
    }
</script>

<style scoped>
    >>> .v-picker__title {
        height: 87px;
    }

    >>> .v-time-picker-clock__ampm > .v-picker__title__btn:first-child {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    >>> .v-time-picker-clock__ampm > .v-picker__title__btn:nth-child(2) {
        position: absolute;
        top: 10px;
        right: 10px;
    }
</style>
