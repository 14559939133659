<template>
  <v-container
    ref="container"
    class="overflow-y-auto pa-0"
    style="border-top: 1px solid #ddd;"
  >
    <v-row
      v-for="(item,index) in items"
      :key="item.raw.codeId"
    >
      <address-item-simple-card
        :item="item"
        @pick="pick => $emit('pick', pick)"
      />
    </v-row>
  </v-container>
</template>

<script>

    import AddressItemSimpleCard from "@/components/items/address/AddressItemSimpleCard";
    export default {
        name: "AddressListItemSimpleCard",

        components: {AddressItemSimpleCard},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        methods: {}
    }
</script>

<style scoped>

</style>