<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";
    import {PATH} from "@/constants";

    export default {
        name: "MFormCodePicker",
        extends: MBaseFormPicker,
        data() {
            return {
                items: [],
                inLoading: false,
                innerData: null,
            }
        },
        computed: {
            displayable() {
                // items 가 없으면 표시 불가능
                if (this.$isEmpty(this.items)) return false;
                return true;
            },
            filteredItems() {
                return this.items;
            },
            display() {
                if (!!this.model && typeof this.model === "object") {
                    return this.model.codeValue;
                }
                return "";
            },
        },
        watch: {
            filteredItems() {
                this.model = this.filteredItems.find(item => item.codeKey === this.innerData);
            },

            innerData() {
                this.model = this.filteredItems.find(item => item.codeKey === this.innerData);
            },

            model() {
                if (!this.model) return;
                this.innerData = this.model.codeKey;
            },
        },
        created() {
            this.loadItems();
            console.log("MFormCOdePicker created");
        },
        mounted() {
            this.loadItems();
            console.log("MFormCOdePicker mounted");
        },
        methods: {
            async loadItems() {
                this.inLoading = true;
                try {
                    const response = await this.$request(PATH.OPTIONS_LOAD_CODE_CODE_KEY)
                        .setObject({codeType: this.options.codeType})
                        .enqueue();
                    this.items = response.data;
                    if (!!this.model) this.innerData = this.model.codeKey;
                } catch (e) {
                    this.items = undefined;
                }
                this.inLoading = false;
            }
        }
    }
</script>
