<script>
    export default {
        name: "MpsFormStyle"
    }
</script>

<style>
    .mps-form-wrapper {
        margin: 0;
        border-bottom: 1px solid #ddd;
        padding: 0;
        align-items: center;
    }

    .mps-form-wrapper.mps-form-checkbox .mps-form-field__wrapper .in-edit-mode .form-textarea__fieldset {
        border: 0 !important;
        padding: .8rem .8rem;
    }

    .mps-form-explanation {
        margin-top: .5rem;
        font-size: .8rem;
        line-height: 1.2rem;
    }

    .mps-form-label__wrapper {
        margin: 0;
        padding: 5px .25rem 5px 18px;
        text-align: left;
        line-height: 1.5rem;
    }

    .mps-form-field__wrapper {
        margin: 0;
        padding: 5px 18px 5px 5px;
        line-height: 1.5rem;
    }

    .mps-form-label__full {
        height: auto;
        padding: 5px 18px 5px 18px;
    }

    .mps-form-field__full {
        padding: 0 18px 5px 18px;
        line-height: 1.2rem;
    }

    label.mps-form-label {
        font-size: 1rem;
    }

    /* TEXTFIELD */
    input.form-textfield {
        width: 100%;
        text-align: right;
        padding: 6px 4px;
        font-size: 1rem;
        margin: 0 !important;
    }

    input.form-telphone,
    input.form-link {
        width: 100%;
        text-align: right;
        padding: 6px 4px;
        font-size: 1rem;
        margin: 0 !important;
        outline: none;
        border: 1px solid #ddd;
    }

    .in-view-mode div.form-telphone,
    .in-view-mode div.form-link {
        width: 100%;
        text-align: right;
        padding: 6px 4px;
        font-size: 1rem;
        min-height: 38px;
    }

    .in-view-mode input.form-textfield {
        padding: 7px 5px;
    }

    .form-textarea__fieldset {
        background: #fff;
        padding: 6px 4px;
    }

    .in-edit-mode .form-textarea__fieldset {
        border: 1px solid #ddd;
    }

    .in-view-mode .form-textarea__fieldset {
        padding: 7px 5px 7px 5px;
    }

    .form-textarea__fieldset.disabled {
        background: #f1f1f1;
    }

    .in-edit-mode input.form-textfield:disabled {
        background: #f1f1f1;
    }

    .in-edit-mode input.form-textfield {
        border: 1px solid #ddd !important;
        margin: 5px 0;
    }

    /* TEXTAREA */
    textarea.form-textarea {
        text-align: inherit;
        width: 100%;
        overflow: hidden;
        display: block;
        margin: 0 0;
        /*padding: 8px 4px;*/
        padding: 0;
        font-size: 1rem;
        border: 0 !important;
        height: 24px;
    }

    input.form-textfield:focus,
    textarea.form-textarea:focus,
    select.form-select:focus {
        outline: 0;
    }

    .border--Bottom__1px {
        border-bottom: 1px solid #ddd
    }

    .form--padding {
        padding: 5px 0;
    }

    .form--line--Height {
        line-height: 1.5rem;
    }

    .in-edit-mode textarea.form-textarea:disabled {
        background: #f1f1f1;
        color: #999;
    }

    .in-view-mode textarea.form-textarea {
        /*color: #333;*/
        -webkit-text-fill-color: rgba(51, 51, 51, 1);
        -webkit-opacity: 1;
        color: rgba(51, 51, 51, 1);
    }

    .in-edit-mode textarea.form-textarea {
        border: 1px solid #ddd;
        /*color: #333;*/
        -webkit-text-fill-color: rgba(51, 51, 51, 1);
        -webkit-opacity: 1;
        color: rgba(51, 51, 51, 1);
    }

    /* SELECT */
    select.form-select {
        text-align-last:right;
        width: 100%;
        font-size: 1rem;
        padding: 6px 4px;
    }

    select.form-select > option {
        text-align: right;
        text-align-last: right;
        font-size: 1rem;
    }

    .in-edit-mode select.form-select {
        color: #333;
        border: 1px solid #ddd;
        margin: 0 0;
        font-size: 1rem;
    }

    .in-view-mode select.form-select {
    }
</style>
