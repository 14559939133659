import {documentActiveStateDetector} from "@/assets/plugins/mps-common/document/js/active-detector";
import {getEventListeners, isElement, removeEventListeners} from "@/assets/plugins/mps-common/document/js/common";

const install = (Vue, options) => {
    Vue.prototype.$documentActiveStateDetector = documentActiveStateDetector;
    Vue.prototype.$isElement = el => isElement(el);
    Vue.prototype.$getEventListeners = (element, eventName) => getEventListeners(element, eventName);
    Vue.prototype.$removeEventListeners = (element, eventName) => removeEventListeners(element, eventName);
};

export default {install}
