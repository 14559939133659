<template>
  <v-card class="radius__None">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title class="d-flex justify-center flex-grow-1 font__20 font-weight-bold">
        {{ '배정' | translate }} : {{ '직원 선택' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="close"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>
    <v-divider />
    <v-card-text
      style="height: 300px;"
      class="px-0"
    >
      <v-list class="pa-0">
        <v-list-item-group
          v-model="selected"
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            class="pa-0 border_btm"
          >
            <v-list-item-content class="text-left">
              <v-list-item-title
                class="pl-5 font__14"
                v-text="item.userFullname"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>

    <v-divider />
    <v-card-actions class="pa-0 height50">
      <v-row class="ma-0">
        <v-btn
          class="button__100per radius__None"
          text
          style="color: #0C98FE;"
          @click="onClickApply"
        >
          {{ '배정' | translate }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";

    export default {
        name: "StaffAssignVuetifyModal",
        components: {},
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                selected: null,
            }
        },
        computed: {
            exclude() {
                return this.propsData.exclude;
            },

            options() {
                return this.propsData.options;
            },

            items() {
                if (!this.options.workorderStaffOptions) return [];
                return this.options.workorderStaffOptions.filter(item => item.userId !== this.exclude);
            },
            selectedItem() {
                if (isNOU(this.selected)) return;
                return this.items[this.selected];
            },
        },
        methods: {
            onClickClose() {
                this.close();
            },

            onClickApply() {
                if (isNOU(this.selected)) return this.$alert(this.$translate("배정할 A/S기사를 선택해주세요"));

                this.callAndClose(this.selectedItem);
            },
        },
    }
</script>

<style scoped>
    >>> .v-dialog {
        border-radius: 0 !important;
    }

    >>> .row {
        justify-content: flex-end !important;
    }

    >>> .height50 {
        height: 50px;
    }
</style>