import {SET_PROP, SET_PROPS} from "./keys";

export default {
    [SET_PROP](state, payload) {
        const {target, key, value} = payload;

        if (typeof state.props[target] !== 'object') {
            this.vm.$set(state.props, target, {});
        }

        this.vm.$set(state.props[target], key, value);
    },
    [SET_PROPS](state, payload) {
        const {target, props} = payload;

        this.vm.$set(state.props, target, {});

        for (let i = 0; i < props.length; i++) {
            const {key, value} = props[i];
            this.vm.$set(state.props[target], key, value);
        }
    },
}