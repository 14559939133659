import utilManager from "@/assets/plugins/mps-common/util/util-manager";
import {ROUTER_ACTION, routerManager} from "@/assets/plugins/mps-common/router/router-manager";
import {isEmpty} from "@/assets/plugins/mps-common/symbols";

const HISTORY_ACTION = {
    BACK: "BACK",
    FORWARD: "FORWARD",
};

/**
 * 처음 한 번 라우터를 무시합니다. 루트 라우터에서 분기를 태워서 다시 홈으로 이동하기 때문에 초기 앱 실행시
 * save() 메소드가 같은 키값으로 두 번 수행되어지는 버그가 존재합니다. 따라서 첫 로드시 한 번 save() 를 스킵합니다.
 */
const IGNORE_COUNT = 1;

class HistoryManager {
    _ignoreCount = 0;

    /**
     * 뷰에서 히스토리를 관리하기 위한 스택입니다.
     */
    _historyStack = [];
    /**
     * 마지막 액션이 pushState 인지 popState 인지 판단합니다.
     */
    _lastAction = undefined;

    /**
     * 라우터 이동시마다 고유한 키 값을 부여합니다.
     */
    _uniqueId = undefined;

    /**
     * 마지막 라우터 액션이 실제로 어떤 액션인지 나타냅니다.(백가드는 포함하지 않습니다.)
     * (BACK, PUSH, REPLACE)
     */
    _routerAction = undefined;

    get isEmpty() {
        return this.length === 0;
    }

    get lastAction() {
        return this._lastAction;
    }

    get isBack() {
        return this._lastAction === HISTORY_ACTION.BACK;
    }

    get isForward() {
        return this._lastAction === HISTORY_ACTION.FORWARD;
    }

    get length() {
        return this._historyStack.length;
    }

    get lastStack() {
        if (this.isEmpty) return null;
        return this._historyStack[this.length - 1];
    }

    /**
     * 스택중 가장 마지막에 추가된 히스토리를 반환합니다.
     */
    get lastHistory() {
        if (this.isEmpty) return null;
        let history = null;
        let i = this._historyStack.length - 1
        for (; i >= 0; i--) {
            if (this._historyStack[i].history) {
                history = this._historyStack[i];
                break;
            }
        }
        if (i < 0) return null;
        return history;
    }

    /**
     * 스택중 가장 마지막에 추가된 히스토리의 키를 반환합니다.
     */
    get lastHistoryKey() {
        if (this.isEmpty) return null;
        if (!this.lastHistory) return null;
        return this.lastHistory.key;
    }

    get lastIsReplace() {
        if (this.isEmpty) return false;
        if (!this.lastHistory) return false;
        return this.lastHistory.action === "replace";
    }

    get lastIsPush() {
        if (this.isEmpty) return false;
        if (!this.lastHistory) return false;
        return this.lastHistory.action === "push";
    }

    get uniqueId() {
        return this._uniqueId;
    }

    get isLastHistory() {
        return !!this.lastStack.history;
    }

    get isLastBackGuard() {
        return !!this.lastStack && !!this.lastStack.backGuard;
    }

    get routerAction() {
        return this._routerAction;
    }

    /**
     * 파라미터로 id 값을 받아서 내부의 uniqueId 와 비교합니다.
     * uniqueId 와 해당 컴포넌트에서 저장해둔 id 값이 다르다면 route 가 변경되었다는 의미입니다.
     * 초기 실행시는 id 값이 없으므로 항상 true를 반환합니다.
     */
    wasRoute(id) {
        if (!id) return true;
        return this._uniqueId !== id;
    }

    includes(key) {
        if (this.isEmpty) return false;
        if (!key) return false;
        return !!this._historyStack.find(e => e.key === key);
    }

    /**
     * 라우터에서 Replace 로 이동한 경우 마지막 액션을 FORWARD 로 하고 히스토리는 등록하지 않습니다.
     */
    _replace() {
        this._lastAction = HISTORY_ACTION.FORWARD;
        this._lastToReplace(); // 마지막 히스토리를 replace 로 변환합니다.
    }

    /**
     * 라우터에서 push 로 이동한 경우 마지막 액션을 FORWARD 로 하고 히스토리를 스택에 추가합니다.
     */
    _push(key) {
        this._lastAction = HISTORY_ACTION.FORWARD;
        this._historyStack.push({key: key, history: true, forward: true, action: "push"}); // 히스토리 스택을 쌓습니다.
    }

    _lastToReplace() {
        if (!this.lastHistory) return;
        this.lastHistory.action = "replace";
    }

    /**
     * forward(pushState) 일 경우 스택에 히스토리 정보를 push,
     * back(popState) 일 경우 스택에서 히스토리 정보를 pop 합니다.
     * 단! divider 라우터일시 backGuard 만 체크하고 리턴합니다.
     * @return boolean backGuard 에 의해 guard() 되었다면 false 아니면 true 입니다.
     */
    guardOrSave(isDivider = false) {
        if (this._ignoreCount < IGNORE_COUNT) {
            this._ignoreCount++;
            return true; // 첫 실행 can next
        } // 첫 실행 무시 => 앱 실행시 라우트를 2번 이동하여 히스토리를 등록하기에 첫 번째는 무시합니다.
        const key = window.history.state.key;
        /** Back */
        if (!this.isEmpty && this.lastHistoryKey === key) { // 마지막 키와 같다면 히스토리 Back 입니다.
            if (this.length > 2 && this.isLastHistory && this.lastIsReplace && this._historyStack[this.length - 2].backGuard) {
                this._historyStack.pop();
                this._lastAction = HISTORY_ACTION.BACK;
                return true;
            }

            if (this.isLastBackGuard) { // 마지막 스택이 backGuard 라면 guard 한다.
                this.guard();
                this._lastAction = HISTORY_ACTION.BACK;
                return false;
            }

            if (isDivider) {
            } else if (this.lastIsReplace) { /** Back After Replace */
            } else if (this.lastIsPush) { /** Back After Push */
                this._historyStack.pop();
            }

            this._routerAction = "BACK";
            this._lastAction = HISTORY_ACTION.BACK;
            return true;
        }

        // 라우트가 변경되었다는 유니크한 키를 생성하여 저장합니다.
        this._uniqueId = utilManager.getUniqueId();

        /** History Replace */
        if (routerManager.lastIsReplace) {
            this._replace();
            this._routerAction = "REPLACE";
            return true;
        }

        /** History Forward */
        if (routerManager.lastIsPush) {
            this._push(key);
            this._routerAction = "PUSH";
            return true;
        }

        return true;
    }

    /**
     * 스택의 가장 마지막 데이터가 backGuard 일 경우 1 스택을 pop 합니다.
     */
    removeBackGuard() {
        if (this.isLastBackGuard) this._historyStack.pop();
    }

    /**
     * BackGuard 에서 호출합니다.
     *
     * BackGuard 의 상태를 히스토리에 같이 저장합니다.
     */
    addBackGuard(callback) {
        this._historyStack.push({callback: callback, backGuard: true});
    }

    /**
     * 스택의 마지막이 BackGuard 인 경우 true, 아니면 false
     */
    get onGuard() {
        return this.isLastBackGuard;
    }

    /**
     * 스택의 마지막에 BackGuard 가 설정되어 있는 경우 guard 합니다.
     */
    guard() {
        if (this._historyStack.length > 0) {
            if (this.isLastBackGuard) {
                if (this.lastStack.callback) {
                    this.lastStack.callback();
                }
                this.removeBackGuard();
            }
        }
    }
}

const historyManager = new HistoryManager();

export {historyManager, HISTORY_ACTION};