import PageController from "@/assets/plugins/mps-view/paging/page-controller";
import Evaluator from "@/assets/plugins/mps-view/paging/evaluator";

export default class Pager {

    _countPerPage;
    _controller;
    _evaluator;
    _endOfData = false;
    _onPageChangedListener;

    /**
     *
     * @param page 초기 페이지 번호입니다. 기본값은 1 입니다.
     * @param countPerPage 한페이지에 표시될 아이템 개수 입니다. 기본값은 15 입니다.
     * @param key 데이터를 평가할 때 제공 데이터 자체를 평가하지 않고 내부를 평가해야하는 경우에 사용합니다. dot 를 이용해서 경로 평가를 할 수 있습니다. 예) data.list.0 -> response.data.list[0] 을 평가
     */
    constructor(page = 1, countPerPage = 15, key = 'data') {
        this._controller = new PageController(page);
        this._controller.onPageChangedListener = this._pageChangedHandler;
        this._countPerPage = countPerPage;
        this._evaluator = new Evaluator(countPerPage, key);
        this._endOfData = false;
    }

    _pageChangedHandler = (current, previous, direction) => {
        this._notifyPageChanged();
    };

    _notifyPageChanged() {
        if (this._onPageChangedListener) this._onPageChangedListener(this.state);
    }

    set key(key) {
        this._evaluator.key = key;
    }

    set page(value) {
        this._controller.page = value;
    }

    get page() {
        return this._controller.page;
    }

    set countPerPage(value) {
        this._countPerPage = value;
        this._evaluator.countPerPage = value;
    }

    get countPerPage() {
        return this._countPerPage;
    }

    get onPageChangedListener() {
        return this._onPageChangedListener;
    }

    set onPageChangedListener(value) {
        this._onPageChangedListener = value;
    }

    get endOfData() {
        return this._endOfData;
    }

    get state() {
        return {
            pageNumber: this._controller.page,
            pageSize: this._countPerPage
        };
    }

    destroy() {
        delete this._countPerPage;
        delete this._controller;
        delete this._evaluator;
    }

    next(callback) {
        this._controller.next();
        if (!!callback && typeof callback === 'function') {
            callback(this._controller.page, this._countPerPage);
        }
    }

    prev() {
        this._controller.prev();
    }

    /**
     * 새 페이지 번호를 제대로 불러오지 못한 경우.
     * 이 함수를 호출해서 이전 페이지 번호로 돌아갈 수 있습니다.
     */
    revert() {
        this._controller.revert();
    }

    proceed(response, key) {
        return new Promise((resolve) => {
            const {items, endOfData} = this._evaluator.evaluate(response, key);
            this._endOfData = endOfData;
            resolve(items);
        });
    };

}
