<template>
  <div style="height: 100%; overflow-y: hidden; background: white;">
    <my-tool-bar
      :action-mode="true"
      :closable="true"
      @click:close="onClickClose"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          ref="toolbarSearchAction"
          v-model="searchKeyword"
          closable
          :placeholder="'협력업체명' | translate"
          @close="onClickClose"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="actionMode = true"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px); overflow-y: hidden;">
      <template v-if="!loading">
        <template v-if="items.length > 0">
          <!-- 인피니트 리스트 -->
          <vue-infinite-loader
            :items="items"
            item-key="raw.codeId"
            item-text="raw.codeValue"
            :search-input="searchKeyword"
          >
            <template #default="{ item }">
              <rentalcompany-code-item-simple-card
                :item="item"
                @pick="onClickApply"
              />
            </template>
          </vue-infinite-loader>
        </template>
        <template v-else>
          <empty-data :text="'협력업체가 없습니다' | translate" />
        </template>
        <!-- 새로고침 버튼 -->
        <refresh-button
          :container="mounted ? $refs.container : undefined"
          no-bottom
          @click="onRefresh"
        />
      </template>
      <template v-else>
        <progress-loading />
      </template>
    </div>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import RentalcompanyCode from "@/model/rentalcompany-code";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import EmptyData from "@/components/part/EmptyData";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import RefreshButton from "@/components/comp/RefreshButton";
    import RentalcompanyCodeItemSimpleCard from "@/components/items/remtalcompany-code/RentalcompanyCodeItemSimpleCard";

    export default {
        name: "MFormRentalcompanyCodeFullModal",
        components: {
            RentalcompanyCodeItemSimpleCard,
            RefreshButton,
            ProgressLoading,
            EmptyData, MyToolBar, ToolbarSearchAction
        },
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                items: [],
                loading: false,
                searchKeyword: "",
                identifier: 0,
                mounted: false,
            }
        },
        computed: {
            codeType() {
                return this.propsData.codeType;
            },
        },
        mounted() {
          this.searchKeyword = "";
          this.loadItems();
            this.mounted = true;
        },
        methods: {
            async loadItems() {
                this.loading = true;
                const response = await this.$request(PATH.RENTALCOMPANY_CODES.BASE)
                    .setObject({codeType: this.codeType})
                    .wait(500)
                    .enqueue();
                this.items = response.data.map(item => new RentalcompanyCode(item));
                this.loading = false;
            },

            /**
             * 닫기 행동을 정의합니다.
             */
            onClickClose() {
                this.close();
            },

            onClickApply(data) {
                this.callAndClose(data);
            },

            /**
             * 새로고침 버튼을 클릭하였습니다.
             *
             * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
             */
            onRefresh() {
                this.loadItems();
            },
        }
    }
</script>

<style scoped>
</style>