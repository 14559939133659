import Vue from "vue";
import AlertDialogBuilder from "@/assets/plugins/mps-dialog/AlertDialogBuilder";
import DialogOverlay from "@/assets/plugins/mps-dialog/DialogOverlay";

const bus = new Vue();

export default {

    install(Vue, options) {
        this.installPrototypes(Vue, options);
        const components = {DialogOverlay};
        Object.keys(components).forEach(key => Vue.component(key, components[key]));
    },
    installPrototypes: function (Vue, options) {
        Vue.prototype.$mps_dialog_event_bus = bus;
        Vue.prototype.$dialog = () => new AlertDialogBuilder(bus);
        Vue.prototype.$alert = (message) => new Promise((resolve) => {
            const vm = new Vue();
            return new AlertDialogBuilder(bus)
                .title(vm.$translate('알림'))
                .message(message)
                .buttonPositive(vm.$translate('확인'), resolve)
                .build()
                .show();
        });
        Vue.prototype.$confirm = (message, title) => new Promise((resolve, reject) => {
            const vm = new Vue();
            const builder = new AlertDialogBuilder(bus);
            if (!!title) {
                builder.title(title);
            }
            builder
                .message(message)
                .buttonNegative(vm.$translate('취소'), reject)
                .buttonPositive(vm.$translate('확인'), resolve)
                .build()
                .show();
        });
    }

}
