<template>
  <v-card
    class="ma-0 pa-0"
    flat
    width="100%"
    style="border-bottom: 1px solid #ddd;"
    @click="onClick"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      two-line
      class="pa-0 ma-0"
    >
      <v-list-item-content class="pa-0 ma-0">
        <v-list-item-title
          class="text-left"
          style="padding: 0 30px;"
        >
          {{ item.raw.codeValue }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    export default {
        name: "RentalcompanyCodeItemSimpleCard",
        components: {},
        props: {
            item: {type: Object, default: undefined},
        },
        computed: {},
        methods: {
            onClick() {
                this.$emit('pick', this.item.raw);
            },
        }
    }
</script>

<style scoped>
    .v-application .subtitle-2 {
        font-size: 0.875rem;
        font-weight: inherit !important;
        letter-spacing: 0.0071428571em !important;
        line-height: 1.375rem;
        font-family: "Roboto", sans-serif !important;
    }
</style>