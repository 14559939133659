<template>
  <div style="height: 100%; background: white;">
    <my-tool-bar
      :action-mode="true"
      :title="''"
      :closable="true"
      @click:close="onClickClose"
    >
      <template #action="{loading}">
        <ToolbarSearchAction
          ref="toolbarSearchAction"
          v-model="searchKeyword"
          closable
          :placeholder="'모델/시리얼' | translate"
          @close="onClickClose"
        />
      </template>
      <template #buttons>
        <v-btn
          icon
          @click="actionMode = true"
        >
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px); overflow-y: hidden;">
      <v-tabs
        v-model="tab"
        background-color="white"
        class="fill-height"
        center-active
        fixed-tabs
      >
        <v-tab
          v-if="whetherLoadPrinters"
        >
          {{ '프린터' | translate }}
        </v-tab>
        <v-tab
          v-if="whetherLoadUserModels"
        >
          {{ '유저' | translate }}
        </v-tab>
        <v-tab
          v-if="whetherLoadPrinterModels"
        >
          {{ '모델' | translate }}
        </v-tab>

        <v-tabs-items
          v-model="tab"
          style="height: 100%; overflow-y: hidden;"
        >
          <v-tab-item
            v-if="whetherLoadPrinters"
            style="height: calc(100% - 48px); overflow-y: hidden;"
          >
            <m-form-printer-list
              :items="printerList"
              :identifier="identifier"
              :loading="loading"
              :search-input="searchKeyword"
              @pick="onClickApply"
            />
          </v-tab-item>
          <v-tab-item
            v-if="whetherLoadUserModels"
            style="height: calc(100% - 48px); overflow-y: hidden;"
          >
            <m-form-user-model-list
              :items="codesModelList"
              :identifier="identifier"
              :loading="loading"
              :search-input="searchKeyword"
              @pick="pick => onClickApply({ printerModelName: pick.codeValue })"
            />
          </v-tab-item>
          <v-tab-item
            v-if="whetherLoadPrinterModels"
            style="height: calc(100% - 48px); overflow-y: hidden;"
          >
            <m-form-printer-model-list
              :items="printerModelList"
              :identifier="identifier"
              :loading="loading"
              :search-input="searchKeyword"
              @pick="onClickApply"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      <!-- 새로고침 버튼 -->
      <refresh-button
        :key="0"
        no-bottom
        @click="onRefresh"
      />
    </div>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
    import Printer from "@/model/printer";
    import Code from "@/model/code";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import MFormPrinterModelList from "@/components/vuetify-modal/form/printer/MFormPrinterModelList";
    import MFormPrinterList from "@/components/vuetify-modal/form/printer/MFormPrinterList";
    import MFormUserModelList from "@/components/vuetify-modal/form/printer/MFormUserModelList";
    import RefreshButton from "@/components/comp/RefreshButton";

    export default {
        name: "MFormPrinterFullModal",
        components: {
            RefreshButton,
            MFormUserModelList,
            MFormPrinterList,
            MFormPrinterModelList,
            ToolbarSearchAction,
            MyToolBar,
        },
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                tab: null,
                items: [],
                codesItems: [],
                loading: false,
                finalOptions: undefined,
                actionMode: false,
                historyId: undefined,
                identifier: 0,
                searchKeyword: "",
            }
        },
        computed: {
            searchOptions() {
                return this.propsData.searchOptions;
            },

            whetherLoadUserModels() {
                return this.searchOptions.whetherLoadUserModels;
            },

            onlyPrinterModels() {
                return this.searchOptions.isPrinterContract === false && this.searchOptions.isMonitoringPrinter === false;
            },

            whetherLoadPrinterModels() {
                return (!isNOU(this.searchOptions.whetherLoadPrinterModels) ? this.searchOptions.whetherLoadPrinterModels :
                    (this.onlyPrinterModels || !(this.searchOptions.isPrinterContract === true || this.searchOptions.isMonitoringPrinter === true)))
            },

            whetherLoadPrinters() {
                return (!isNOU(this.searchOptions.whetherLoadPrinters) ? this.searchOptions.whetherLoadPrinters :
                    (!this.onlyPrinterModels))
            },

            printerList() {
                if (!Array.isArray(this.items)) return [];
                return this.items && this.items.filter(item => {
                    return item.raw.printerContractId || item.raw.monitoringPrinterId;
                });
            },

            printerModelList() {
                if (!Array.isArray(this.items)) return [];
                return this.items && this.items.filter(item => {
                    return !item.raw.monitoringPrinterId && !item.raw.printerContractId;
                });
            },

            codesModelList() {
                return this.codesItems;
            },
        },
        mounted() {
            this.load();
            this.initSearchOptions();
        },
        methods: {
            async load() {
                this.identifier++;
                this.loading = true;
                await this.loadItems();
                if (this.whetherLoadUserModels) {
                    await this.loadCodesItems();
                }
                this.loading = false;
            },

            initSearchOptions() {
                this.searchKeyword = "";
            },

            async loadItems() {
                const response = await this.$request(PATH.PRINTER.AND_PRINTER_LSIT)
                    .setObject({
                        ...this.searchOptions,
                        whetherLoadPrinterModels: this.whetherLoadPrinterModels,
                        whetherLoadPrinters: this.whetherLoadPrinters,
                    })
                    .wait(500)
                    .enqueue();
                this.items = response.data.map(item => new Printer(item));
            },

            async loadCodesItems() {
                const response = await this.$request(PATH.RENTALCOMPANY_CODES.BASE)
                    .setObject({codeType: 'CPMDL'})
                    .wait(500)
                    .enqueue();
                this.codesItems = response.data.map(item => new Code(item));
            },

            /**
             * 닫기 행동을 정의합니다.
             */
            onClickClose() {
                this.close();
            },

            onClickApply(data) {
                this.callAndClose(data);
            },

            /**
             * 새로고침 버튼을 클릭하였습니다.
             *
             * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
             */
            onRefresh() {
                this.load();
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>