<template>
  <div>
    <template v-if="!inLoading">
      <v-select
        v-if="displayable"
        v-model="model"
        :clearable="clearable"
        :disabled="disabled"
        item-text="codeValue"
        item-value="codeKey"
        :items="filteredItems"
        :label="label"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="rules"
        :menu-props="{ bottom: true, offsetY: true }"
        return-object
      />
      <v-text-field
        v-else
        readonly
        disabled
        class="v-content"
        :label="label"
        :value="'데이터 불러오기 실패'"
      />
    </template>
    <template v-else>
      <v-text-field
        readonly
        class="v-content"
        :label="label"
        :value="model.codeValue"
      />
    </template>
  </div>
</template>

<style scoped>

</style>

<script>
    import MFormRentalcompanyCodePicker from "@/assets/plugins/mps-form/providers/MFormRentalcompanyCodePicker";

    export default {
        name: "MFormVuetifyRentalcompanyCodePicker",
        extends: MFormRentalcompanyCodePicker,
    }
</script>
