<script>/**
 * Vuetify Modal 각각의 상태 유지를 도와주는 믹스인입니다.
 */

import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";

export default {
    name: "ModalVuetifyKeppState",
    mixins: [OnFilterResetListener],
    data() {
        return {
            state: {},
        };
    },
    methods: {
        onFilterReset(data) {
            if (!data.uniqueModalId || typeof data.uniqueModalId !== 'string') return;
            this.state[data.uniqueModalId] = undefined;
        },

        setState(id, value) {
            this.state[id] = value;
        },

        getState(id) {
            let obj = null;
            // 배열이나 오브젝트이면 cloneDeep 합니다.
            if (this.state[id] != null && typeof this.state[id] === 'object') {
                obj = {};
                this.$cloneDeep(this.state[id], obj);
            } else { // 아니면 그냥 대입합니다.
                obj = this.state[id];
            }
            return obj;
        },
    },
}
</script>