<script>

    export default {
        name: "ModalVuetifyHelper",
        mixins: [],
        props: {
            id: undefined,
            propsData: {
                type: Object,
                default: () => {
                }
            },
        },
        computed: {
            modalVuetifyComponent() {
                let hierarchyCount = 0;
                while (true) {
                    hierarchyCount++;
                    let expression = 'this';
                    for (let i = 0; i < hierarchyCount; i++) {
                        expression += '.$parent';
                    }
                    const vue = eval(expression);
                    // 더이상 Vue 객체가 없음.
                    if (vue === undefined) return null;
                    if (vue.isModalVuetifyRoot) return vue;
                }
            },
        },
        methods: {
            setState(value) {
                if (!this.id) return;
                this.modalVuetifyComponent.setState(this.id, value);
            },

            getState() {
                if (!this.id) return;
                return this.modalVuetifyComponent.getState(this.id);
            },

            /**
             * 모달 콜백 함수를 호출합니다.
             */
            call(data) {
                this.modalVuetifyComponent.call(data);
            },

            /**
             * 모달 콜백 함수 호출후 모달을 닫습니다.
             */
            callAndClose(data) {
                this.call(data);
                this.close();
            },

            /**
             * 모달 콜백 함수를 호출합니다.
             */
            close() {
                this.modalVuetifyComponent.close();
            },
        },
    }
</script>