<script>
    export default {
        name: 'MBaseFormElement',
        props: {
            formKey: undefined,
            value: undefined,
            loading: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: undefined
            },
            readonly: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: undefined
            },
            rules: {
                type: [Array],
                default: () => [v => true]
            },
            checkError: {
                type: Boolean,
                default: false,
            },
            errorMessage: {
                type: String,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false
            },
            clearable: {
                type: Boolean,
                default: false
            },
            optionsData: {
                type: Object,
                default: undefined
            },
            inEditMode: {
                type: Boolean,
                default: false,
            },
            fullWidthLabel: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                model: this.value,
                updateData: null,
            }
        },
        computed: {
            contentAlign() {
                return this.fullWidthLabel === true ? "left" : "right";
            },

            labelCols() {
                return this.fullWidthLabel === true ? 12 : 4;
            },

            contentCols() {
                return this.fullWidthLabel === true ? 12 : 8;
            },

            options() {
                return !!this.optionsData && typeof this.optionsData === 'object' ? this.optionsData : {};
            },
        },
        watch: {
            value(newValue, oldValue) {
                this.model = newValue;
            },
            model(newValue, oldValue) {
                this.$emit('input', newValue);
            },
            updateData(newValue, oldValue) {
                this.$emit("update:data", newValue);
            },
        },
    }
</script>
