import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
import parseDate from "@/assets/plugins/mps-common/time/parse-date";
import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";

const getDuration = (datetime) => {
    const target = parseDate(datetime);
    const now = require('luxon').DateTime.utc();
    return now.diff(target, ['seconds', 'minutes', 'hours', 'days', 'months', 'years']);
};

const t = (v) => translationManager.translate(v);

const toFormattedDuration = (datetime) => {
    if (isNOU(datetime)) return 'unknown';
    const duration = getDuration(datetime);
    const {years, months, days, hours, minutes, seconds} = duration.values;

    const ago = translationManager.translate('전');
    let message;
    if (years > 0) {
        message = `${years} ${t('년')} ${ago}`;
    } else if (months > 0) {
        message = `${months} ${t('달')} ${ago}`;
    } else if (days > 0) {
        message = `${days} ${t('일')} ${ago}`;
    } else if (hours > 0) {
        message = `${hours} ${t('시간')} ${ago}`;
    } else if (minutes > 0) {
        message = `${minutes} ${t('분')} ${ago}`;
    } else {
        message = `${Math.round(seconds)} ${t('초')} ${ago}`;
    }
    return message;
};

export {toFormattedDuration, getDuration}

