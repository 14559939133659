<script>
    import {CODE, MAXLENGTH} from "@/constants";
    import {TERMS_OF_USE} from "@/constants/terms-of-use";
    import {NOTICE_SORT} from "@/constants/notice-value";
    import {VERSION} from "@/constants/version";
    import {ETC} from "@/constants/etc-code";
    import fileManager from "@/assets/plugins/mps-common/file/file-manager";
    import utilManager from "@/assets/plugins/mps-common/util/util-manager";
    import {historyManager} from "@/assets/plugins/mps-common/history/history-manager";
    import {vuexManager} from "@/assets/plugins/mps-common/vuex/VuexManager";
    import {formStr} from "@/assets/plugins/mps-common/formatters/string-formatter";
    import {numberHelper} from "@/assets/plugins/mps-common/formatters/number-helper";

    export default {
        name: "DataMixin",
        data() {
            return {
                MAXLENGTH: MAXLENGTH,
                TERMS_OF_USE: TERMS_OF_USE,
                NOTICE_SORT: NOTICE_SORT,
                VERSION: VERSION,
                ETC: ETC,
                fileManager: fileManager,
                utilManager: utilManager,
                historyManager: historyManager,
                vuexManager: vuexManager,
                numberHelper: numberHelper,
                CODE: CODE,
            };
        },
        methods: {
            formStr(format, ...args) {
                return formStr(format, args);
            },

            getImgUrl(src) {
                return require('@/assets/images/' + src);
            },

            getUniqueId() {
                return this.utilManager.getUniqueId();
            },

            trimAllSpaces(str) {
                return this.utilManager.trimAllSpaces(str);
            },

            trim(str) {
                return this.utilManager.trim(str);
            },

            searchByKeyword(obj, keyword, keyArr) {
                return this.utilManager.searchByKeyword(obj, keyword, keyArr);
            },

            byString(o, s) {
                return this.utilManager.byString(o, s);
            },

            assign(o, s, v) {
                return this.utilManager.assign(o, s, v);
            },
        },
    }
</script>
