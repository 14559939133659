import LoadingOverlayUser from "@/assets/plugins/loading-overlay/LoadingOverlayUser";

export default {
    install(Vue, options) {
        this.useMixins(Vue, options);
    },

    useMixins(Vue, options) {
        Vue.use(LoadingOverlayUser, options);
    },
}