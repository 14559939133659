import isNullOrUndef from './is-null-or-undef'
import isEmpty from './is-empty'
import isPrimitive from './is-primitive'
import isObject from './is-object'
import isArray from './is-array'
import isEqualTypes from './is-equal-types'
import isEqualArray from './is-equal-array'
import isEqual from './is-equal'

/**
 * 두 객체가 동일하지 않음을 비교합니다.
 * strict 비교를 수행합니다.
 *
 * @param v1
 * @param v2
 * @param ignoreUndefined 값이 undefined 인 프로퍼티들은 무시합니다.
 * @returns {boolean}
 */
const isDirty = (v1, v2) => !isEqual(v1, v2);

export {
    isNullOrUndef as isNOU,
    isNullOrUndef,
    isEmpty,
    isPrimitive,
    isObject,
    isArray,
    isDirty,
    isEqual,
    isEqualTypes,
    isEqualArray,
};
