<template>
  <v-card class="radius__None">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title class="d-flex justify-center flex-grow-1 font__20 font-weight-bold">
        {{ '회원탈퇴' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="close"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>
    <v-divider />
    <v-card-text
      style="height: 240px;"
      class="pa-4"
    >
      <div class="flex align-start justify-start text-start pa-1">
        {{ '회원탈퇴를 하실 경우 MPS 서비스 이용이 불가합니다. ' | translate }}
      </div>
      <div class="flex align-start justify-start text-start pa-1">
        {{ '본인 확인을 위해 비밀번호를 입력해 주세요.' | translate }}
      </div>
      <input
        v-model="password1"
        class="inputst"
        type="password"
        :placeholder="'비밀번호를 입력하세요' | translate"
        :maxlength="MAXLENGTH.USER.PASSWORD"
        name="rental_login_pw_1"
      >
      <input
        v-model="password2"
        class="inputst"
        type="password"
        :placeholder="'비밀번호 재입력' | translate"
        :maxlength="MAXLENGTH.USER.PASSWORD"
        name="rental_login_pw_2"
      >
      <div class="flex align-start justify-start text-start pa-1">
        {{ '문의: 070-5030-0939' | translate }}
      </div>
    </v-card-text>

    <v-divider />
    <v-card-actions class="pa-0 height50">
      <v-row class="ma-0">
        <v-btn
          class="button__100per radius__None"
          text
          style="color: #0C98FE;"
          :disabled="!password1 || !password2"
          @click="onClickApply"
        >
          {{ '탈퇴신청' | translate }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";

export default {
  name: "RetiredModal",
  components: {},
  mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
  data() {
    return {
      password1: undefined,
      password2: undefined,
    }
  },
  computed: {
    exclude() {
      return this.propsData.exclude;
    },

    options() {
      return this.propsData.options;
    },

    items() {
      if (!this.options || !this.options.workorderStaffOptions) return [];
      return this.options.workorderStaffOptions.filter(item => item.userId !== this.exclude);
    },
  },
  methods: {
    onClickClose() {
      this.close();
    },

    onClickApply() {
      if(this.password1 !== this.password2)
        return this.$alert(this.$translate('입력하신 두 비밀번호가 일치하지 않습니다.'));

      this.callAndClose({password: this.password1});
    },
  },
}
</script>

<style scoped>
>>> .v-dialog {
  border-radius: 0 !important;
}

>>> .row {
  justify-content: flex-end !important;
}

>>> .height50 {
  height: 50px;
}
</style>