import {ACTION_HIDE_DIALOG, ACTION_SHOW_DIALOG} from "@/assets/plugins/mps-dialog/constants";

export default class AlertDialog {
    constructor(eventBus, options) {
        this.eventBus = eventBus;
        this.options = options;
    }

    show() {
        this.eventBus.$emit(ACTION_SHOW_DIALOG, this.options);
    }

    hide() {
        this.eventBus.$emit(ACTION_HIDE_DIALOG);
    }

}