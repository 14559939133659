<template>
  <v-app>
    <my-tool-bar
      :action-mode="actionMode"
      :title="titleComputed"
      :closable="true"
      @click:close="$emit('update:dialog', false)"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          v-model="options.searchKeyword"
          :placeholder="'협력업체명' | translate"
          @close="actionMode = false"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="actionMode = true"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <m-layout>
      <v-layout class="fill-height">
        <v-container
          ref="container"
          class="overflow-y-auto pa-0 ma-0"
          style="height: calc(100vh - 56px);"
        >
          <template v-if="!loading">
            <template v-if="filteredList.length > 0">
              <rentalcompany-code-list-item-simple-card
                :items="filteredList"
                @pick="pick => $emit('pick', pick)"
              />
            </template>
            <template v-else>
              <empty-data :text="'협력업체가 없습니다' | translate" />
            </template>
          </template>
          <template v-else>
            <progress-loading />
          </template>
        </v-container>
      </v-layout>
    </m-layout>
  </v-app>
</template>
<script>
    import {PATH} from "@/constants";
    import MLayout from "@/components/MLayout";
    import RentalcompanyCode from "@/model/rentalcompany-code";
    import RentalcompanyCodeListItemSimpleCard
        from "@/components/items/remtalcompany-code/RentalcompanyCodeListItemSimpleCard";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import EmptyData from "@/components/part/EmptyData";
    import ProgressLoading from "@/components/part/ProgressLoading";

    export default {
        name: "MFormRentalcompanyCodeFullDialog",
        components: {
          ProgressLoading,
          EmptyData, MyToolBar, ToolbarSearchAction, RentalcompanyCodeListItemSimpleCard, MLayout},
        props: {
            title: null,
            dialog: null,
            codeType: null,
        },
        data() {
            return {
                items: [],
                loading: false,
                actionMode: false,
                options: {
                    searchKeyword: "",
                },
            }
        },
        computed: {
            titleComputed() {
                return this.title || this.$translate("선택");
            },

            filteredList() {
                if (this.items.length === 0) return [];
                if (!this.options.searchKeyword) return this.items;

                return this.items.filter(item => {
                    return this.trimAllSpaces(item.raw.codeValue.toLowerCase()).indexOf(this.trimAllSpaces(this.options.searchKeyword.toLowerCase())) > -1
                });
            },
        },
        created() {
        },
        mounted() {
            this.loadItems(); // 첫 요청
        },
        methods: {
            trimAllSpaces(str) {
                try {
                    return str.split(' ').join('');
                } catch (err) {
                    console.error('PrinterModal trimAllSpaces Error! ' + err);
                    return '';
                }
            },

            async loadItems(loadMore = false) {
                if (this.loading) return;
                // 로딩중으로 표시
                this.loading = true;

                try {
                    const response = await this.$request(PATH.RENTALCOMPANY_CODES.BASE)
                        .setObject({codeType: this.codeType})
                            .wait(300)
                        .enqueue();
                    this.items = response.data.map(item => new RentalcompanyCode(item));
                } catch (e) {

                }
                this.loading = false;
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>