<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        :cols="8"
        class="mps-form-field__wrapper mps-form-field__full"
      >
        <!-- View Mode -->
        <div
          v-if="attachments != null && attachments.length > 0"
          class="mt-5"
        >
          <div
            v-for="(attach, index) in attachments"
            :key="attach.path"
            class="mb-3"
          >
            <button
              class="attachment_file_button"
              @click="fileDownload(attach)"
            >
              <div class="attachment_file_name">
                {{ attach.originalFilename }}
              </div>
              <v-icon>mdi-download</v-icon>
            </button>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import {PATH} from "@/constants";
import MBaseFormElement from "@/assets/plugins/mps-form/providers/MBaseFormElement";

export default {
        name: "MFormMpsFileInfos",
        extends: MBaseFormElement,
        data() {
            return {
                showIndex: 0,
                preview: false,
                attachments: null
            };
        },

        created() {
            this.attachments = this.model;
        },
        methods: {
            fileDownload(attach) {
              try {
                const url = `${PATH.BASE_URL}${PATH.RESOURCE.FILE}?path=${encodeURI(attach.path)}&filename=${encodeURI(attach.originalFilename)}`;
                console.log(url);
                location.href = url;
              } catch (e) {
                console.error(e);
                this.$alert("파일 경로가 올바르지 않습니다.");
              }
            },
        }
    }
</script>

<style scoped>
    #app .dettached__file {
        background-color: #ff5252 !important;
        border-color: #ff5252 !important;
        color: white !important;
        text-decoration: line-through !important;
    }

    .button__Delete {
        background: rgba(255, 0, 0, 0.2);
    }

    .button__Delete.active {
        background: rgba(0, 255, 0, 0.2);
    }

    .image__overay {
        background: rgba(255, 0, 0, 0.2);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    >>> .border_bottom {
        border-bottom: 1px solid #ddd !important;
    }

    >>> .border_bottom > div:last-child {
        flex-basis: 50px !important;
        flex-grow: 1 !important;
    }

    .border-image {
        border: 1px solid #ddd;
    }

    .attachment_file_button {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        background-color: #f5f5f5;
        padding: 6px 8px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }

    .attachment_file_name {
        width: 88%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.875rem;
    }
</style>
