<template>
  <div>
    <span
      v-if="hasError"
      class="red--text"
    >
      {{ display }}
    </span>
  </div>
</template>

<script>
    export default {
        name: "FormError",
        props: {
            rules: { type: Array, default: () => [v => true] },
            checkError: { type: Boolean, default: () => false },
            model: null,
            errorMessage: null,
        },
        computed: {
            hasError() {
                if (!this.checkError) return false;
                if (Array.isArray(this.rules)) return false;
                let bool = false;
                for (let i = 0; i < this.rules.length; i++) {
                    const rule = this.rules[i];
                    if (typeof rule !== 'function') continue;
                    if (rule(this.model) === false) continue;
                    bool = true;
                    break;
                }
                return bool;
            },

            display() {
                return this.errorMessage || this.$translate('잘못된 값입니다.');
            },
        },
    }
</script>

<style scoped>
</style>