<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>
      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <div>
          <label>
            <template v-if="inEditMode && !disabled">
              <div class="in-edit-mode">
                <v-bottom-sheet
                  v-model="sheet"
                  @click:outside="onClickOutside"
                >
                  <template v-slot:activator="{ on }">
                    <input
                      :value="displayDate"
                      class="form-textfield"
                      :placeholder="placeholder"
                      type="text"
                      readonly
                      @click="onFocus"
                      v-on="on"
                    >
                  </template>
                  <v-sheet
                    class="px-1"
                    height="370px"
                  >
                    <v-row justify="center">
                      <v-date-picker
                        v-model="innerDate"
                        full-width
                        :locale="locale"
                        :clearable="clearable"
                        @click:date="onClick"
                      />
                    </v-row>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
            </template>
            <template v-else>
              <div class="in-view-mode">
                <div
                  class="form-textarea__fieldset"
                  :style="styleObject"
                >
                  <textarea
                    :value="displayDate"
                    class="form-textarea"
                    disabled
                    :placeholder="placeholder"
                    :rules="rules"
                    type="text"
                  />
                </div>
              </div>
            </template>
          </label>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import MFormDatePicker from "@/assets/plugins/mps-form/providers/MFormDatePicker";

    export default {
        name: "MFormMpsDatePicker",
        extends: MFormDatePicker,
        mixins: [],
        data() {
            return {
                sheet: false,
            }
        },
        computed: {
            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },
            locale() {
                if (!this.$userManager) return;
                const user = this.user;
                let code = user.languageCode ? user.languageCode : 'en';
                if (user.countryCode) {
                    code += '-' + user.countryCode; // e) XX -> XX-KR
                }
                return code;
            },
        },

        watch: {},

        methods: {
            /**
             * BottomSheet 의 바깥쪽 부분이 선택될 때
             * 설정된 Backguard를 제거합니다.
             */
            onClickOutside() {
                this.backGuard.pop();
            },

            /**
             * date 가 선택될 때 Backguard 를 제거합니다.
             */
            onClick() {
                this.backGuard.pop();
                this.sheet = false;
            },

            /**
             * 데이트피커가 열릴 때 Backguard 를 설정합니다.
             */
            onFocus() {
                this.backGuard.push(() => {
                    this.sheet = false;
                });
            },
        },
    }
</script>
