<template>
  <v-row
    no-gutters
    class="pa-0 ma-0 align-center"
  >
    <v-col>
      <j-date-picker
        v-model="internalDateStart"
        :icon="icon"
        :label="startLabel"
        :type="type"
        :disable-future="disableFutureStart"
        :disable-past="disablePastStart"
        :allowed-dates="allowedDatesStart"
      />
    </v-col>
    <slot name="mid" />
    <v-col class="pl-3">
      <j-date-picker
        v-model="internalDateEnd"
        :icon="icon"
        :label="endLabel"
        :type="type"
        :disable-future="disableFutureEnd"
        :disable-past="disablePastEnd"
        :allowed-dates="allowedDatesEnd"
      />
    </v-col>
  </v-row>
</template>

<script>
    import {DateTime} from "luxon";
    import JDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/JDatePicker";

    export default {
        name: "JPeriodPicker",
        components: {JDatePicker},
        props: {
          disableFutureStart: {
            type: Boolean,
            default: false,
          },
          disableFutureEnd: {
            type: Boolean,
            default: false,
          },
          disablePastStart: {
            type: Boolean,
            default: false,
          },
          disablePastEnd: {
            type: Boolean,
            default: false,
          },
          allowedDatesStart: {
            type: Function,
            default: null,
          },
          allowedDatesEnd: {
            type: Function,
            default: null,
          },
            startLabel: {
                type: String,
                default: undefined,
            },
            endLabel: {
                type: String,
                default: undefined,
            },
            dateStart: {
                type: [String, Date, DateTime],
                default: undefined
            },
            dateEnd: {
                type: [String, Date, DateTime],
                default: undefined
            },
            type: {
                type: String,
                default: 'date'
            },
            icon: {
                type: String,
                default: undefined
            }
        },
        data() {
            return {
                internalDateStart: this.dateStart,
                internalDateEnd: this.dateEnd,
            }
        },
        watch: {
            dateStart(newDate, oldDate) {
                this.internalDateStart = newDate;
            },
            dateEnd(newDate, oldDate) {
                this.internalDateEnd = newDate;
            },
            internalDateStart(newDate, oldDate) {
                const d = this.$parseDate(newDate);
                if (!d) {
                  this.$emit('update:dateStart', null);
                  return;
                }
                let modified;
                switch (this.type) {
                  case 'date':
                    modified = d.startOf('day').toISO();
                    break;
                  case 'month':
                    modified = d.startOf('month').toISO();
                    break;
                  case 'year':
                    modified = d.startOf('year').toISO();
                }
                this.$emit('update:dateStart', modified);
            },
            internalDateEnd(newDate, oldDate) {
                // 종료일은 type 에 따라서 해당 type 의 마지막이 되어야 합니다.
                // 예를 들어 날짜인 경우 해당일의 23:59:59 가 되어야 합니다.
                const d = this.$parseDate(newDate);
                if (!d) {
                  this.$emit('update:dateEnd', null);
                  return;
                }
                let modified;
                switch (this.type) {
                  case 'date':
                    modified = d.endOf('day').toISO();
                    break;
                  case 'month':
                    modified = d.endOf('month').toISO();
                    break;
                  case 'year':
                    modified = d.endOf('year').toISO();
                }
                this.$emit('update:dateEnd', modified);
            }
        },
        methods: {},
    }
</script>

<style scoped>

</style>
