<script>
    import MBaseFormElement from "@/assets/plugins/mps-form/providers/MBaseFormElement";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

    export default {
        name: "MBaseFormNumber",
        extends: MBaseFormElement,
        data() {
            return {
                innerModel: this.model,
                mark: null,
            }
        },
        computed: {
            min() {
                if (isNOU(this.options.min)) return NaN;
                const num = Number(this.options.min);
                if (isNaN(num)) return NaN;
                return num;
            },
            max() {
                if (isNOU(this.options.max)) return NaN;
                const num = Number(this.options.max);
                if (isNaN(num)) return NaN;
                return num;
            },
        },
        watch: {
            model() {
                this.innerModel = this.model;
            },

            innerModel() {
                this.model = Number(this.innerModel);
            },
        },
        created() {
            this.innerModel = this.model;
        },
        methods: {
        }
    }
</script>