import vuetify from "./vuetify";

const providers = {
    VUETIFY: 'vuetify',
};

const elements = {
    vuetify,
};

export {providers, elements};
