import {GET_SETTING_DATA, UPDATE_SETTING, SET_SALES,
    SET_ACCOUNT, SET_CONTRACT, SET_WORK, SET_AUTO, SET_DATA, UPDATE_COMMENT} from "./keys";

async function getData(dashboard, key, params) {
    let data = null;
    try {
        data = params ? await dashboard[key](params) : dashboard[key]();
        console.log(`!!-- data ${key} :  ${data}`);

    } catch (e) {
        console.log(`!!-- get ${key} error :  ${e}`);
        data = null;
    }

    return data;
}


export default {
    async [GET_SETTING_DATA]({ state, commit }) {
        const { dashboard } = state
        const data = await getData(dashboard, 'requestSetting');
        await commit(SET_DATA, {key: 'setting', data: data.data})
    },
    async [SET_SALES]({ state, commit}, { startDate, endDate }) {
        const { dashboard } = state
        const params = { startDate, endDate }
        const data = await getData(dashboard, 'requestSales', params);
        await commit(SET_DATA, {key: 'salesForSum', data: data})
        await commit(SET_DATA, {key: 'salesForTrend', data: data})
    },
    async [SET_ACCOUNT]({ state, commit }, { startDate, endDate }) {
        const { dashboard } = state
        const params = { startDate, endDate }
        const data = await getData(dashboard, 'requestAccount', params);
        await commit(SET_DATA, {key: 'accountForSum', data: data})
        await commit(SET_DATA, {key: 'accountForTrend', data: data})
    },
    async [SET_CONTRACT]({ state, commit }, { startDate, endDate }) {
        const { dashboard } = state
        const params = { startDate, endDate }
        const data = await getData(dashboard, 'requestContract', params);
        await commit(SET_DATA, {key:'contractList', data: data})
    },
    async [SET_WORK]({ state, commit }, { startDate, endDate }) {
        const { dashboard } = state
        const params = { startDate, endDate }
        const data = await getData(dashboard, 'requestWork', params);
        await commit(SET_DATA, {key:'workForSum', data: data})
        await commit(SET_DATA, {key:'workForTrend', data: data})
    },
    async [SET_AUTO]({ state, commit }, { startDate, endDate }) {
        const { dashboard } = state
        const params = { startDate, endDate }
        const data = await getData(dashboard, 'requestAuto', params);
        await commit(SET_DATA, {key:'autoForSum', data: data})
    },
    async [UPDATE_SETTING]({ state }, { data, cb }) {
        const { dashboard } = state
        dashboard.requestSettingUpdate(data)
            .then(() => cb())
    },
    async [UPDATE_COMMENT]({ state, dispatch }, { data, cb }) {
        const { dashboard } = state
        await dashboard.requestCommentUpdate(data)
            .then(() =>  cb(true))
            .catch(() => cb(false))
    },
}