<!--
 거래처 선택 또는 입력 가능한 Form 입니다.
 사용자가 신규업체를 등록하거나 기존 업체를 선택하기 위해 만들어졌습니다.
-->
<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <!-- 기존 거래처 선택 피커 -->
        <div
          v-show="!isNewAccount"
          class="in-edit-mode"
        >
          <div
            class="form-textarea__fieldset"
            :class="{ disabled: disabled }"
            :style="styleObject"
          >
            <textarea
              ref="textarea"
              class="form-textarea"
              :value="display"
              :disabled="disabled"
              readonly
              :placeholder="placeholder"
              type="text"
              :rows="1"
              :style="textareaHeight"
              @click="onClick"
            />
          </div>
        </div>
        <!-- 신규 거래처 입력 -->
        <div
          v-show="isNewAccount"
          class="in-edit-mode"
        >
          <div
            class="form-textarea__fieldset"
            :class="{ disabled: disabled }"
            :style="styleObject"
          >
            <textarea
              ref="textarea-new-account"
              v-model="newAccountName"
              class="form-textarea"
              :disabled="disabled || !inEditMode"
              :placeholder="'신규 거래처를 입력해 주세요' | translate"
              type="text"
              :rows="1"
              :style="textareaHeight"
              :maxlength="options.maxlength || 99999"
              @click="onClick"
            />
          </div>
          <!-- 신규 거래처명 미입력 안내 -->
          <span
            v-show="isNewAccountNameEmpty"
            class="new-account-input-info"
          >
            * {{ '미 입력시 임의로 생성됩니다.' | translate }}
          </span>
          <span

            style="width: 100%; display: flex;justify-content: space-between; align-items: center; height: 25px"
          >
            <span style="font-size: 0.825rem;">{{ "거래처 담당기사 본인 설정" | translate }}</span>
            <v-switch
              v-model="isSelfCharge"
            />
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
    import MFormAccountPicker from "@/assets/plugins/mps-form/providers/MFormAccountPicker";
    import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "MFormMpsAccountPickOrWriter",
        components: {},
        extends: MFormAccountPicker,
        mixins: [TextAreaAutogrow, ModalVuetifyUser],
      props: {
        params: {
          type: Object,
          default: undefined
        },
      },
        data() {
            return {
              // 신규로 등록한 거래처명
              newAccountName: undefined,
              isSelfCharge: false
            }
        },
        computed: {
            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },
          /**
           * 신규 거래처 여부
           * @returns {boolean}
           */
          isNewAccount(){
            return !!this.params && !!this.params.isNewAccount
          },
          isNewAccountNameEmpty(){
            return this.newAccountName ? false : true
          },
        },
        watch: {
          display() {
              this.$refs.textarea.value = this.display;
              this.autoGrow(this.$refs.textarea);
          },
          newAccountName(newValue, old){
            console.log("### 22 data", newValue)
            this.model = {
              accountName:newValue,
              isSelfCharge: this.isSelfCharge
            }
            this.autoGrow(this.$refs["textarea-new-account"]);

          },
          isSelfCharge(newValue){
            this.model = {
              accountName: this.newAccountName,
              isSelfCharge: newValue
            }

          },
          /**
           * 신규 거래처 A/S 등록 여부
           * @param newValue
           */
          isNewAccount(newValue){
            // 변경 시 newAccountName 초기화 합니다.
            this.newAccountName = null
            this.isSelfCharge = false
          }
        },
        mounted() {
          this.autoGrow(this.$refs["textarea-new-account"]);
          this.newAccountName = this.model

        },
        methods: {
          onClick() {
            // 신규 거래처일 경우 입력을 받기위해 모달을 띄우지 않습니다.
            // 수정 모드가 아닐 경우 모달을 띄우지 않습니다.
            if(!this.isNewAccount || !this.inEditMode){
              this.openModalVuetify(MODAL_VUETIFY_NAMES.FORM.PICKER_ACCOUNT, {
                    fullscreen: true,
                    transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                  }, {title: this.titleComputed,},
                  (data) => {
                console.log("### 11 data", data)
                    this.model = data;
                  }
              );
            }
          },
        },
    }
</script>

<style scoped>
.new-account-input-info{
  font-size: 0.85rem;
  color: #EC4DA3;
}
>>> .v-input--switch v-input--switch--inset{
  width: 15px;
}
</style>
