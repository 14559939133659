import {createNamespacedHelpers} from "vuex";
import {NAMESPACE} from "./keys";
import * as KEYS from './keys'
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";


// 네임스페이스 자동 붙이기
const {mapState, mapGetters, mapActions, mapMutations} = createNamespacedHelpers(NAMESPACE);
export {mapState, mapGetters, mapActions, mapMutations, KEYS};


export default {
    namespaced: true,
    state: {
        setting: null,
        dashboard: null,
        salesForSum: null,
        salesForTrend: null,
        accountForSum: null,
        accountForTrend: null,
        contractList: null,
        workForSum: null,
        workForTrend: null,
        autoForSum: null,
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
};