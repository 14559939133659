<template>
  <!-- Block Overlay -->
  <div
    v-show="show"
    class="block-overlay"
    style="opacity: 0; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 10;"
    :style="{zIndex: this.zIndex}"
    @click.self.stop.prevent="onClickOverlay"
  />
</template>

<script>
    import {
        blockOverlayEventBus,
        HIDE_BLOCK_OVERLAY,
        SHOW_BLOCK_OVERLAY
    } from "@/assets/plugins/block-overlay/constants";

    export default {
        name: "BlockOverlayLayerLegacy",
        props: {
            zIndex: {
                type: Number,
                default: 10,
            }
        },
        data() {
            return {
                show: false,
                callback: undefined,
            }
        },
        mounted() {
            blockOverlayEventBus.$on(SHOW_BLOCK_OVERLAY, this.showOverlay);
            blockOverlayEventBus.$on(HIDE_BLOCK_OVERLAY, this.hideOverlay);
        },
        beforeDestroy() {
            blockOverlayEventBus.$off(SHOW_BLOCK_OVERLAY, this.showOverlay);
            blockOverlayEventBus.$off(HIDE_BLOCK_OVERLAY, this.hideOverlay);
        },
        methods: {
            showOverlay(callback) {
                this.show = true;
                this.callback = callback;
            },

            hideOverlay() {
                this.show = false;
            },

            onClickOverlay() {
                this.show = false;
                if (this.callback) this.callback();
            },
        },
    }
</script>

<style scoped>

</style>