import {timeZoneHelper} from "@/assets/plugins/mps-common/time/time-zone-helper";
import installLuxonCompat from "./luxon-compat";
import parseDate from "./parse-date";
import now from "@/assets/plugins/mps-common/time/now";
import {DateTime} from "luxon";
import {getDuration, toFormattedDuration} from "@/assets/plugins/mps-common/time/duration-util";
import userManager from "@/assets/plugins/mps-common/user/user-manager";

export default {

    install(Vue, options) {
        installLuxonCompat(); // luxon 호환성 함수 설치
        Vue.prototype.$timeZone = () => timeZoneHelper.getTimeZone();
        Vue.prototype.$language = () => timeZoneHelper.getBrowserLanguage();
        Vue.prototype.$languagePart = () => timeZoneHelper.getBrowserLanguage().split('-')[0];
        Vue.prototype.$country = () => timeZoneHelper.getBrowserCountry();
        Vue.prototype.$countryAuth = () => (!!userManager && userManager.validate) ? userManager.user.countryCode : timeZoneHelper.getBrowserCountry();
        Vue.prototype.$languageAuth = () => (!!userManager && userManager.validate) ?
            (userManager.isRentalUser ? userManager.user.languageCode + "-" + userManager.user.countryCode : timeZoneHelper.getBrowserLanguage()) : timeZoneHelper.getBrowserLanguage();
        Vue.prototype.$languagePartAuth = () => (!!userManager && userManager.validate) ?
            (userManager.isRentalUser ? userManager.user.languageCode : timeZoneHelper.getBrowserLanguage().split('-')[0]) : timeZoneHelper.getBrowserLanguage().split('-')[0];
        Vue.prototype.$now = (datetime) => now(datetime);
        Vue.prototype.$duration = (datetime) => getDuration(datetime);
        Vue.prototype.$formattedDuration = (datetime) => toFormattedDuration(datetime);
        Vue.prototype.$parseDate = (datetime) => parseDate(datetime);
        Vue.prototype.$parseFromISO = (datetime) => DateTime.fromISO(datetime);
        Vue.prototype.$toFormat = (datetime, format) => DateTime.fromISO(datetime).toFormat(format);
    },

}
