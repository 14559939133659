<template>
  <v-btn
    icon
    @click="onClick"
  >
    <img src="@/assets/images/main/svg/buttonFilter.svg">
  </v-btn>
</template>

<script>
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import SearchFilterInterface from "@/components/app/toolbar/component/filter-button/vuetify/SearchFilterInterface";

    export default {
        name: "ConsultNoteSearchFilterButtonWithModal",
        components: {},
        mixins: [SearchFilterInterface],
        methods: {
            onClick() {
                const uniqueModalId = this.openModalVuetify(MODAL_VUETIFY_NAMES.FILTER.CONSULT_NOTE, {
                        persistent: true,
                        maxWidth: "500px",
                    }, {state: this.state, ...this.propsData},
                    (data) => {
                        this.$emit("onFilter", data);
                    }
                );

                this.$emit("uniqueModalId", uniqueModalId);
            },
        }
    }
</script>

<style scoped>
</style>