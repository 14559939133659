export default class Branch {

    _item;

    constructor(item) {
        this._item = item;
    }

    get raw() {
        return this._item;
    }

    get key() {
        return this._item.accountId + "_" + this._item.accountBranchId;
    }

    get invalidPosition() {
        return !this._item.lat || !this._item.lng;
    }

}
