const EVENT_SCROLL = 'scroll';
const EVENT_RESIZE = 'DOMSubtreeModified';

export default class VerticalScrollDetector {

    el;

    constructor(el, handler) {
        this.el = el;
        this._handler = function (e) {
            const el = e.target;
            switch (e.type) {
                case EVENT_SCROLL:
                    const visibleHeight = el.clientHeight; // 화면에 보이는 높이.
                    const contentHeight = el.scrollHeight; // 스크롤 컨텐츠 전체 높이.
                    const scrollY = el.scrollTop; // 스크롤 값.
                    const toBottom = contentHeight - (visibleHeight + scrollY);
                    if (handler) handler(scrollY, toBottom, visibleHeight);
                    break;
                case EVENT_RESIZE:
                    break;
                default:
                    break;
            }
        }
    }

    on() {
        this.el.addEventListener(EVENT_SCROLL, this._handler);
        this.el.addEventListener(EVENT_RESIZE, this._handler);
    }

    off() {
        this.el.removeEventListener(EVENT_SCROLL, this._handler);
        this.el.removeEventListener(EVENT_RESIZE, this._handler);
    }

}