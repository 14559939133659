<template>
  <div style="height: 100%; background: white;">
    <my-tool-bar
      :action-mode="true"
      :title="'거래처 선택' | translate"
      :closable="true"
      @click:close="onClickClose"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          ref="toolbarSearchAction"
          :value="searchOptions.searchKeyword"
          closable
          :placeholder="'거래처 검색' | translate"
          @close="onClickClose"
          @search="onSearchClicked"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="actionMode = true"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>

      <template #fixed>
        <account-search-filter-button-with-modal
          ref="filterButton"
          @onFilter="onFilterApplyClicked"
          @uniqueModalId="v => uniqueModalId = v"
        />
      </template>
    </my-tool-bar>

    <status-options-bar
      :items="statusOptionsItems"
      @reset="onResetFilter"
    />

    <div style="position: relative; top: 105px; height: calc(100% - 105px); overflow-y: hidden;">
      <!-- 인피니트 리스트 -->
      <vue-infinite-loader
        :fetch="fetch"
        :identifier="identifier"
        item-key="raw.accountId"
      >
        <template #default="{ item }">
          <account-item-card
            :item="item"
            @click:item="onClickApply"
          />
        </template>
      </vue-infinite-loader>

      <refresh-button
        :container="mounted ? $refs.container : undefined"
        no-bottom
        @click="onRefresh"
      />
    </div>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import Account from "@/model/account";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import AccountSearchFilterButtonWithModal
        from "@/components/app/toolbar/component/filter-button/vuetify/AccountSearchFilterButtonWithModal";
    import StatusOptionsBar from "@/components/comp/ StatusOptionsBar";
    import RefreshButton from "@/components/comp/RefreshButton";
    import AccountItemCard from "@/components/items/account/AccountItemCard";

    export default {
        name: "MFormAccountFullModal",
        components: {
            AccountSearchFilterButtonWithModal,
            AccountItemCard,
            RefreshButton, StatusOptionsBar, ToolbarSearchAction, MyToolBar
        },
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                historyId: undefined,
                uniqueModalId: undefined,
                filterNameList: [],
                searchOptions: {},
                identifier: 0,
                mounted: false,
            }
        },
        computed: {
            statusOptionsItems() {
                let items = [];
                if (this.searchOptions.searchKeyword) items.push({
                    text: this.searchOptions.searchKeyword,
                    color: 'orange',
                    maxWidth: "100px"
                });
                if (!this.$isEmpty(this.filterNameList)) items = items.concat(this.filterNameList);
                return items;
            },
        },
        mounted() {
          this.onResetFilter();
            this.mounted = true;
        },
        methods: {
            async fetch(page, pageSize) {
                this.searchOptions.pageNumber = page;
                this.searchOptions.pageSize = pageSize;

                const response = await this.$request(PATH.ACCOUNT.LIST)
                    .setObject(this.searchOptions)
                    .catch()
                    .wait(500)
                    .enqueue();
                return response.data.map(e => new Account(e));
            },

            /**
             * 검색키워드와 필터 조건을 초기화 합니다.
             */
            initSearchOptions() {
                this.searchOptions = {searchKeyword: '', useYn: 'Y'};
                this.filterNameList = [{text: this.$translate("계약중")}];
            },

            /**
             * 툴바 검색 버튼이 눌렸습니다.
             * @param keyword
             */
            onSearchClicked(keyword) {
                this.searchOptions.searchKeyword = keyword;
                this.identifier++;
            },

            onFilterApplyClicked(data) {
                this.filterNameList = data.filterNameList;
                this.searchOptions = {...this.searchOptions, ...data.state};
                this.identifier++;
            },

            /**
             * 닫기 행동을 정의합니다.
             */
            onClickClose() {
                this.close();
            },

            onClickApply(data) {
                this.callAndClose(data.raw);
            },

            /**
             * 필터 조건을 초기화하였습니다.
             */
            onResetFilter() {
                this.initSearchOptions();
                this.identifier++;
            },

            /**
             * 새로고침 버튼을 클릭하였습니다.
             *
             * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
             */
            onRefresh() {
                this.identifier++;
            },
        }
    }
</script>

<style scoped>
</style>