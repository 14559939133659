import MPullToRefresh from "@/assets/plugins/mps-mobile/pull-to-refresh/MPullToRefresh";

export default {
    install(Vue, options) {
        this.installComponents(Vue);
    },
    installComponents: function (Vue) {
        const components = {
            MPullToRefresh
        };
        for (let key in components) {
            Vue.component(key, components[key]);
        }
    }
}
