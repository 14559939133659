import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";
import MixinTranslation from "@/assets/plugins/mps-common/translation/MixinTranslation";

export default {

    install(Vue, options) {
        // 번역 관리자
        Vue.prototype.$translationManager = translationManager;
        Vue.mixin(MixinTranslation);
    }

}
