import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";

const PRINTER_MODAL_RESULT = {
    PRINTER: "PRINTER",
    PRINTER_MODEL: "PRINTER_MODEL",
    CODE_MODEL: "CODE_MODEL"
};

const SORT_USER = {
    RENTALCOMPANY: "RENTALCOMPANY",
    COMMUNITY: "COMMUNITY",
};

const TERMS = {
    TERM_SERVICE_AGREEMENT: "TERM_SERVICE_AGREEMENT",
    TERM_PRIVATE_POLICY: "TERM_PRIVATE_POLICY",
    TERM_LOCATION_AGREEMENT: "TERM_LOCATION_AGREEMENT",
}

const MULTIFILE_FORM_LIST_TYPE = {
    TEXT_TYPE: "text",
    IMAGE_TYPE: "image",
};

/**
 * 파일 업로드 폼에서 허용할 파일 종류를 구분합니다.
 * @type {{}}
 */
const ACCEPT = {
    IMAGE: "image/*,application/pdf",
}

const DIALOG = {
    TRANSITION: {
        DEFAULT: "",
        BOTTOM_TRANSITION: "dialog-bottom-transition",
    },
}

const VUETIFY_MODAL = {
    CLOSE_DELAY: 300,
}

const PERMISSION = {
    ACCOUNT_REGISTER_EDIT: translationManager.translate("거래처 정보 등록 및 수정 권한이 없습니다."),
    ACCOUNT_INFO: translationManager.translate('거래처 정보 접근 권한이 없습니다.'),
    BILLING_INFO: translationManager.translate('매출 정보 접근 권한이 없습니다.')
}

const ETC = {
    MULTIFILE_FORM_LIST_TYPE,
    PRINTER_MODAL_RESULT,
    SORT_USER,
    ACCEPT,
    TERMS,
    DIALOG,
    VUETIFY_MODAL,
    PERMISSION
}

export {PRINTER_MODAL_RESULT, SORT_USER, ETC};
