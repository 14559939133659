/**
 * PC 의 윈도우 매니저를 모사하는 매니저입니다.
 * PC 의 윈도우 매니저는 하나의 화면에서 스스로 윈도우의 추가/제거를 관리하지만, 모바일은 1화면이 1라우트에 할당됩니다.
 */
export default class MockingWindowManager {

    _vm;

    constructor(vm) {
        this._vm = vm;
    }

    /**
     * 현재 화면을 교체합니다.
     * HTML 히스토리 추가없이 현재 페이지를 변경합니다.
     *
     * @param objectThatHasName name property 를 가지고 있는 객체. 예) VueComponent, location(router)
     * @param params 전달하려는 파라미터. router 에서 해당 route 에 props:true 가 선언되어야 수신할 수 있습니다.
     */
    replace(objectThatHasName, params = {}) {
        const location = this._toLocation(objectThatHasName, params);
        // noinspection JSIgnoredPromiseFromCall
        this._vm.$router.replace(location);
    }

    /**
     * 새 화면이 추가됩니다.
     * HTML 히스토리에 추가됩니다.
     *
     * @param objectThatHasName name property 를 가지고 있는 객체. 예) VueComponent, location(router)
     * @param params 전달하려는 파라미터. router 에서 해당 route 에 props:true 가 선언되어야 수신할 수 있습니다.
     */
    push(objectThatHasName, params) {
        const location = this._toLocation(objectThatHasName, params);
        // noinspection JSIgnoredPromiseFromCall
        this._vm.$router.push(location);
    }

    /**
     * 이름을 가진 객체와 파라미터를 이용해서 라우트 될 수 있는 location 객체로 전환합니다.
     *
     * @param objectThatHasName name 프로퍼티를 가지고있는 객체
     * @param params 전달하려는 파라미터
     * @returns {{name: *, params: {}}}
     * @private
     */
    _toLocation(objectThatHasName, params = {}) {
        if (objectThatHasName === undefined) throw new Error('Check the 1st parameter is defined: Could not process a route. A parameter \'objectThatHasName\' is undefined.');
        if (objectThatHasName == null) throw new Error('Check the 1st parameter is defined: Could not process a route. A parameter \'objectThatHasName\' is null.');
        const name = objectThatHasName.name;
        if (name === undefined) throw new Error('Check the 1st parameter has a name: Could not process a route. a \'name\' of object is undefined.');
        if (name == null) throw new Error('Check the 1st parameter has a name: Could not process a route. a \'name\' of object is null.');
        if (typeof name !== "string") throw new Error('Check the 1st parameter type is the \'string\': Could not process a route. a \'name\' is not a string.');
        return {name: name, params: {...params}};
    }

}