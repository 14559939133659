<template>
  <v-row
    align="center"
    class="fill-height mx-0"
  >
    <div
      class="mx-auto font-weight-bold"
      style="font-size: 1.1rem; color: #999;"
    >
      <div class="d-flex justify-center align-center my-5">
        <img src="@/assets/images/main/svg/icon_error.svg">
        <span class="ml-2">{{ text }}</span>
      </div>
    </div>
  </v-row>
</template>

<script>
    export default {
        name: "EmptyData",
        props: {
            text: {type: String, default: "No Data"}
        },
    }
</script>

<style scoped>
</style>