import {DateTime, Info} from 'luxon';
import common from "@/assets/plugins/common/functions";

const DEFAULT_TIME_ZONE = 'GMT';

class TimeZoneHelper {

    /**
     * 브라우저의 언어를 반환합니다.
     * 확인 할 수 없는 경우 en-US 를 반환합니다.
     * @returns {string}
     */
    getBrowserLanguage() {
        let language;
        if (navigator.language === undefined) {
            language = navigator.languages[0];
        } else {
            language = navigator.language;
        }
        if (language) {
            const extracted = common.extractLanguage(language);
            if (!extracted) return "en-US";
            return extracted[0] + "-" + extracted[1];
        }
        return "en-US";
    };

    getBrowserCountry() {
        let language = this.getBrowserLanguage();
        if (language) {
            const split = language.split('-');
            return split.length === 2 ? split[1] : undefined;
        } else {
            return undefined;
        }
    }

    /**
     * 타임존을 반환합니다.
     * @param dateTime luxon DateTime object. (optional)
     * @returns {string}
     */
    getTimeZone(dateTime = DateTime.local()) {
        // 현재 환경이 타임존을 지원 함.
        if (Info.features().zones) {
            return dateTime.zoneName;
        }
        // 지원하지 않음.
        else {
            // TODO : 타임존 지원 안하면 포맷에 GMT 를 붙여서 포맷팅해야 할까?
            return DEFAULT_TIME_ZONE;
        }
    }

}

const timeZoneHelper = new TimeZoneHelper();

export {timeZoneHelper};
