<script>
    import {EVENTS} from "@/event/index";

    export default {
        name: "Events",
        data() {
            return {
                mpsEvents: EVENTS,
            }
        },
    }
</script>
