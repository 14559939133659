<script>
    import MBaseFormElement from "@/assets/plugins/mps-form/providers/MBaseFormElement";
    import {ETC} from "@/constants/etc-code";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
    import fileManager from "@/assets/plugins/mps-common/file/file-manager";

    export default {
        name: "MBaseFormFileUpload",
        extends: MBaseFormElement,
        data() {
            return {
                attachments: undefined,
                files: [],
                filesAppend: undefined,
            };
        },
        computed: {
            accept() {
                return this.options.accept || "*";
            },

            filesWithId() {
                let i = 0;
                if (this.$isEmpty(this.files) === true) return [];
                const arr = [];
                for (let index = 0; index < this.files.length; index++) {
                    const e = this.files[index];
                    const obj = {
                        id: this.formKey + "-" + "file-" + index,
                        file: e,
                    }
                    arr.push(obj);
                }
                return arr;
            },
        },
        watch: {
            filesAppend() {
                if (this.$isEmpty(this.filesAppend) === true) return;
                for (let i = 0; i < this.filesAppend.length; i++) {
                    const e = this.filesAppend[i];
                    this.files.push(e);
                }
            },

            filesWithId() {
                if (this.$isEmpty(this.filesWithId) === true) return;
                this.filesWithId.forEach(f => {
                    const reader = new FileReader();

                    reader.onload = function (e) {
                        const el = document.getElementById(f.id)
                        if (!!el) {
                            el.setAttribute("src", e.target.result);
                        }
                    }

                    reader.readAsDataURL(f.file); // convert to base64 string
                });
            },

            "options.attachments"() {
                this.clone();
            },

            inEditMode() {
                this.init();
            },
        },
        created() {
            this.clone();
        },
        methods: {
            init() {
                if (Array.isArray(this.attachments) !== true) return;
                this.attachments.map(e => e.init());
            },

            clone() {
                this.files = [];
                if (Array.isArray(this.options.attachments) !== true) return;
                this.attachments = this.options.attachments.map(e => e.clone());
                let i = 0;
                this.attachments.forEach(e => {
                     if (this.fileManager.isImageFile(e.serverFile.path)) e.imageIndex = i++;
                });
                if (this.options.listType === ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE) {
                    this.attachments.forEach(e => {
                        e.nullize();
                        e.base64();
                    });
                }
            },
        },
    }
</script>