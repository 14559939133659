<script>
    import BaseListener from '@/assets/plugins/mps-common/event/base-listener'

    export default {
        name: "OnOpenNavigationDrawerListener",
        extends: BaseListener,
        created() {
            this.registerListener(this.mpsEvents.OPEN_NAVIGATION_DRAWER, this.openNavigationDrawer);
        },
        beforeDestroy() {
            this.unregisterListener(this.mpsEvents.OPEN_NAVIGATION_DRAWER, this.openNavigationDrawer);
        },
        methods: {
            onDrawerOpen() {
                // this will be implemented from a component mixed in.
            }
        },
    }
</script>
