import MPSCommon from "@/assets/plugins/mps-common";
import MPSDialog from "@/assets/plugins/mps-dialog";
import MPSSnackbar from "@/assets/plugins/mps-snackbar";
import MPSForm from "@/assets/plugins/mps-form";
import MPSMobile from "@/assets/plugins/mps-mobile";
import MPSMobileNativeBridge from "@/assets/plugins/mps-mobile-native-bridge";
import MPSView from "@/assets/plugins/mps-view";
import MPSWindowMobile from "@/assets/plugins/mps-window-mobile";
import MPSVuetifyCompat from "@/assets/plugins/mps-vuetify-compat";
import LoadingOverlay from "@/assets/plugins/loading-overlay";
import BlockOverlay from "@/assets/plugins/block-overlay";
import Directives from "@/assets/plugins/directives";
import CarouselTabsItems from "@/assets/plugins/carousel-tabs-items";
import Transitions from "@/assets/plugins/transitions";
import VueSwitch from "@/assets/plugins/component/vue-switch";
import Meta from "vue-meta";
import Forms from "@/components/new-comp/forms";

export default {

    install(Vue, options) {
        Vue.use(MPSCommon, options); // 기본 프레임워크
        Vue.use(MPSDialog, options); // 다이알로그
        Vue.use(MPSForm, options); // 폼
        Vue.use(MPSMobile, options); // 기본 프레임워크
        Vue.use(MPSMobileNativeBridge, options); // 네이티브 어플리케이션 브릿지
        Vue.use(MPSView, options); // 화면 요소와 관련된 기능
        Vue.use(MPSWindowMobile, options); // 윈도우 매니저
        Vue.use(MPSVuetifyCompat, options); // 뷰티파이 의존하는 호환 컴포넌트
        Vue.use(MPSSnackbar, options);
        Vue.use(LoadingOverlay, options);
        Vue.use(BlockOverlay, options);
        Vue.use(Directives, options);
        Vue.use(CarouselTabsItems, options);
        Vue.use(Transitions, options);
        Vue.use(VueSwitch, options);
        Vue.use(Meta, {attribute : 'data-vue-meta'})
        Vue.use(Forms, options)
    }
}
