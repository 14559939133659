<template>
  <div>
    <v-divider v-if="divider" />
    <div class="mt-3">
      <v-scale-transition
        mode="out-in"
        origin="center"
      >
        <v-progress-circular
          v-if="loading"
          :indeterminate="true"
          color="primary"
          class="mb-3"
        />
        <v-chip
          v-if="!loading && endOfData"
          ref="chip"
          class="mx-auto"
          color="primary"
          style="transform: none !important;"
        >
          No more data
        </v-chip>
      </v-scale-transition>
    </div>
  </div>
</template>

<script>
    import VerticalScrollDetector from "../event/vertical-scroll-detector";
    import {gsap} from "gsap";

    export default {
        name: "MInfiniteDetector",
        components: {},
        props: {
            divider: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            endOfData: {
                type: Boolean,
                default: false
            },
            targetId: {
                type: String,
                default: undefined,
            },
        },
        data() {
            return {
                detector: undefined,
                animWiggle: undefined
            }
        },
        computed: {
            canNext() {
                return !this.endOfData && !this.disabled && !this.loading;
            },
        },
        watch: {
            endOfData(end) {
                // if (this.detector) this.updateDetector();
            },
            disabled(disabled) {
                // if (this.detector) this.updateDetector();
            },
        },
        mounted() {
            const parentContainer = !!this.targetId ? document.getElementById(this.targetId) : this.$el.parentElement;
            this.detector = new VerticalScrollDetector(parentContainer, this.onScroll);
            this.updateDetector();
        },
        beforeDestroy() {
            // this.detector.off();
            delete this.detector;
        },
        methods: {
            onScroll(scrollY, toBottom, visibleHeight) {
                if (toBottom > 50) return; // 스크롤 끝에 도달하지 못함. 단, 음수인 경우 컨텐츠가 컨테이너보다 작습니다.
                if (this.loading) return; // 이미 로딩중 입니다. 다음 이벤트를 발생시키지 않습니다.
                // 다음 호출 가능.
                if (this.canNext) {
                    this.$emit('onNext');
                }
                    // 다음 호출 불가능
                // 인디캐이터 타겟이 있다면 애니매이션
                else {
                    const target = this.$refs.chip.$el;
                    if (target) {
                        gsap.fromTo(target, {duration: 0, x: 25}, {duration: 0.5, ease: "elastic.out(1.0, 0.2)", x: 0});
                    }
                }
            },
            updateDetector() {
                if (this.endOfData) {
                    this.detector.off();
                } else {
                    this.detector.on();
                }
            }
        }
    }
</script>
