<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";
    import {PATH} from "@/constants";

    export default {
        name: "MFormOptionsPicker",
        extends: MBaseFormPicker,
        data() {
            return {
                innerData: null,
            }
        },
        computed: {
            displayable() {
                if (this.$isNOU(this.options.items)) return false;
                return true;
            },
            filteredItems() {
                return this.options.items || [];
            },
            display() {
                if (!!this.model && typeof this.model === "object") {
                    return this.model.codeValue;
                }
                return "";
            },
        },
        watch: {
            innerData() {
                this.model = this.filteredItems.find(item => item.codeKey === this.innerData);
            },

            model() {
                this.innerData = this.model.codeKey;
            },
        },
        created() {
            this.innerData = this.model.codeKey;
        },
        methods: {}
    }
</script>
