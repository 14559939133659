export const EVENTS= {
    HISTORY_BACK: 'history_back',
    OPEN_NAVIGATION_DRAWER: 'open_navigation_drawer',
    OPEN_SETTINGS: 'open_settings',
    ON_MAIN_SEARCH_KEYWORD_CHANGED:'on_main_search_keyword_changed',
    ON_MAIN_SEARCH_TRIGGERED:'on_main_search_triggered',
    ON_MAIN_FILTER_TRIGGERED:'on_main_filter_triggered',
    ON_DETAIL_EDIT_TRIGGERED: 'on_detail_edit_triggered',
    ON_ETC_REQUEST_SUCCESSED: "on_etc_request_successed",
    ON_MODAL_OPEN_REQUESTED: "on_modal_open_requested",
    ON_BOTTOM_SHEET_OPEN_REQUESTED: "on_bottom_sheet_open_requested",
    /** 필터를 초기화 할 때 사용합니다. */
    ON_FILTER_RESET: "ON_FILTER_RESET",
    /** 프린터 퀵 정렬이 변경되었습니다. */
    ON_MAIN_PRINTER_QUICK_SORT_CHANGED: "ON_MAIN_PRINTER_QUICK_SORT_CHANGED",
    /** A/S 퀵 정렬이 변경되었습니다. */
    ON_MAIN_AS_QUICK_SORT_CHANGED: "ON_MAIN_AS_QUICK_SORT_CHANGED",
    ON_CONSULT_NOTE_QUICK_SORT_CHANGED: "ON_CONSULT_NOTE_QUICK_SORT_CHANGED",
};
