<script>
    import {translationManager as helper} from '@/assets/plugins/mps-common/translation/translation-manager.js';

    export default {
        name: "TranslationUtil",

        filters: {
            translate: function (key) {
                return helper.translate(key, `${key}`);
            }
        },

        directives: {
            trans: {
                bind(el, binding, vnode, oldVnode) {
                    let exp = binding.value ? binding.value : binding.expression;
                    const set = exp.split('|');
                    if (set[1]) {
                        el.innerHTML = helper.translate(set[0], set[1]);
                    } else {
                        el.innerHTML = helper.translate(set[0]);
                    }
                }
            }
        },

        methods: {
            $translate(key, defaultValue) {
                return helper.translate(key, defaultValue);
            },
            t(key, defaultValue) {
                return helper.translate(key, defaultValue);
            },
            Lang(key, defaultValue) {
                return helper.translate(key, defaultValue);
            }
        }
    }
</script>

<style scoped>

</style>