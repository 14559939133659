<script>
    export default {
        name: "StyleMixin"
    }
</script>

<style>
    html,
    body {
        height: 100%;
        width: 100%;
        overflow: auto;
    }

    .filter-divider {
        margin-top: 56px;
        border-bottom: 1px solid #ddd;
    }

    .v-slide-group__content {
        border-bottom: 4px solid #333 !important;
    }

    .v-tabs-slider {
        height: 4px !important;
        border-bottom: 4px solid #0C98FE !important;
        position: absolute;
        bottom: -4px;
    }

    input::placeholder {
        font-size: .85rem;
    }

    .v-text-field--outlined fieldset {
        border: 1px solid #aaa !important;
    }

    .v-text-field--outlined.v-input--is-focused fieldset {
        border: 1px solid #555 !important;
    }

    #app .boder-bottom, .boder-bottom {
        border-bottom: 1px solid #ddd !important;
    }

    #app .border-top, .border-top {
        border-top: 1px solid #ddd !important;
    }

    #app .v-application .primary {
        background-color: #0C98FE !important;
        color: #fff !important;
    }

    #app .v-application .primary--text {
        color: #0C98FE !important;
        caret-color: #0C98FE !important;
    }

    .button--Color {
        height: 45px !important;
        background: #2196f3 !important;
        color: #fff !important;
        flex: 1 0 !important;
    }

    /* Font Size */
    .font__13 {
        font-size: 0.813rem !important;
        line-height: 1.2rem !important;
        font-weight: normal !important;
    }

    .font__14 {
        font-size: 0.875rem !important;
        line-height: 1.375rem !important;
        font-weight: normal !important;
    }

    .font__16 {
        font-size: 1rem !important;
        line-height: 1.188rem !important;
        font-weight: normal !important;
    }

    .font__17 {
        font-size: 1.063rem !important;
        line-height: 1.5rem !important;
        font-weight: normal !important;
    }

    .font__20 {
        font-size: 1.25rem !important;
    }

    .color__999 {
        color: #999 !important;
    }

    .color__666 {
        color: #666 !important;
    }

    .color__333 {
        color: #333 !important;
    }

    .color__aaa {
        color: #aaa !important;
    }

    .color__blue {
        color: #0C98FE !important;
    }

    .color__red {
        color: #EC4DA3 !important;
    }

    /* 여백 */
    .pl__36 {
        padding-left: 36px !important;
    }

    .px__10 {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .px__15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .px__18 {
        padding-left: 18px !important;
        padding-right: 18px !important;
    }

    .py__3 {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .mxt__15 {
        margin-top: 15px !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
    }


    /* 버튼 */
    .button__Close {
        min-width: 36px !important;
        width: 36px;
        min-height: 36px !important;
        border-radius: 0;
        position: absolute;
        right: 16px;
    }

    .button__Left {
        border-radius: 0;
        height: 50px !important;
        width: 50%;
        border-right: 1px solid #ddd;
        background: #f4f4f4 !important;
        font-size: 0.938rem;
        color: #000 !important;
    }

    .button__Right {
        border-left: none;
        border-radius: 0;
        height: 50px !important;
        width: 50%;
        background: #f4f4f4 !important;
        font-size: 0.938rem;
        color: #000 !important;
    }

    .button__100per {
        width: 100% !important;
        background: #f4f4f4 !important;
        font-size: 0.938rem !important;
        color: #000 !important;
        height: 50px !important;
    }

    .button__50per {
        width: 50% !important;
        background: #f4f4f4 !important;
        font-size: 0.938rem !important;
        color: #000;
        height: 50px !important;
    }

    .radius__None {
        border-radius: 0 !important;
    }


    .v-dialog {
        border-radius: 0 !important;
    }

    .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
        border-radius: 0;
    }

    /*회원가입 css start*/
    .inputst {
        border: 1px solid #ddd;
        display: block;
        height: 50px;
        line-height: 50px;
        width: 100%;
        padding: 12px 16px;
        margin-bottom: 10px;
    }

    /*회원가입 css end*/
    .welcome__content {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100% - 56px);
        position: relative;
        top: 56px;
        left: 0;
        right: 0;
        bottom: 0;
    }

    /* Form */
    .select__arrow {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 0;
        height: 0;
        pointer-events: none;
        border-style: solid;
        border-width: 8px 5px 0 5px;
        border-color: #999 transparent transparent transparent;
    }

    select.select__custom:focus + .select__arrow {
        border-width: 0 5px 8px 5px;
        border-color: transparent transparent #999 transparent;
    }

    .display-none {
        display: none;
    }

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        display: inline-block;
    }
</style>
