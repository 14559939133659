<template>
  <v-app-bar
    :color="color"
    app
    fixed
    elevation="0"
    style="padding: 0 5px 0 0"
  >
    <v-layout
      :align-center="true"
    >
      <v-btn
        icon
        @click="onClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>

      <v-spacer />
      <div style="max-width: calc(100% - 48px); overflow-x: auto; display: flex !important;">
        <!--        <div class="ql-toolbar ql-snow ql-custom-toolbar">-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            class="ql-header outline-none"-->
        <!--            :class="{'ql-active': qlOptions.h1}"-->
        <!--            value="1"-->
        <!--            @click="toggleH1"-->
        <!--          >-->
        <!--            <svg viewBox="0 0 18 18">-->
        <!--              <path-->
        <!--                class="ql-fill"-->
        <!--                d="M10,4V14a1,1,0,0,1-2,0V10H3v4a1,1,0,0,1-2,0V4A1,1,0,0,1,3,4V8H8V4a1,1,0,0,1,2,0Zm6.06787,9.209H14.98975V7.59863a.54085.54085,0,0,0-.605-.60547h-.62744a1.01119,1.01119,0,0,0-.748.29688L11.645,8.56641a.5435.5435,0,0,0-.022.8584l.28613.30762a.53861.53861,0,0,0,.84717.0332l.09912-.08789a1.2137,1.2137,0,0,0,.2417-.35254h.02246s-.01123.30859-.01123.60547V13.209H12.041a.54085.54085,0,0,0-.605.60547v.43945a.54085.54085,0,0,0,.605.60547h4.02686a.54085.54085,0,0,0,.605-.60547v-.43945A.54085.54085,0,0,0,16.06787,13.209Z"-->
        <!--              />-->
        <!--            </svg>-->
        <!--          </button>-->
        <!--        </div>-->
        <!--        <div class="ql-toolbar ql-snow ql-custom-toolbar">-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            class="ql-header outline-none"-->
        <!--            :class="{'ql-active': qlOptions.h2}"-->
        <!--            value="2"-->
        <!--            @click="toggleH2"-->
        <!--          >-->
        <!--            <svg viewBox="0 0 18 18">-->
        <!--              <path-->
        <!--                class="ql-fill"-->
        <!--                d="M16.73975,13.81445v.43945a.54085.54085,0,0,1-.605.60547H11.855a.58392.58392,0,0,1-.64893-.60547V14.0127c0-2.90527,3.39941-3.42187,3.39941-4.55469a.77675.77675,0,0,0-.84717-.78125,1.17684,1.17684,0,0,0-.83594.38477c-.2749.26367-.561.374-.85791.13184l-.4292-.34082c-.30811-.24219-.38525-.51758-.1543-.81445a2.97155,2.97155,0,0,1,2.45361-1.17676,2.45393,2.45393,0,0,1,2.68408,2.40918c0,2.45312-3.1792,2.92676-3.27832,3.93848h2.79443A.54085.54085,0,0,1,16.73975,13.81445ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"-->
        <!--              />-->
        <!--            </svg>-->
        <!--          </button>-->
        <!--        </div>-->
        <!--        <div class="ql-toolbar ql-snow ql-custom-toolbar">-->
        <!--          <span class="ql-formats">-->
        <!--            <button-->
        <!--              type="button"-->
        <!--              class="ql-bold outline-none"-->
        <!--              :class="{'ql-active': qlOptions.bold}"-->
        <!--              @click="toggleBold"-->
        <!--            >-->
        <!--              <svg viewBox="0 0 18 18"> <path-->
        <!--                class="ql-stroke"-->
        <!--                d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"-->
        <!--              /> <path-->
        <!--                class="ql-stroke"-->
        <!--                d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"-->
        <!--              /> </svg>-->
        <!--            </button>-->
        <!--          </span>-->
        <!--        </div>-->
        <div class="ql-toolbar ql-snow ql-custom-toolbar">
          <span class="ql-formats">
            <button
              type="button"
              class="ql-image outline-none"
              @click="toggleImage"
            ><svg viewBox="0 0 18 18"> <rect
              class="ql-stroke"
              height="10"
              width="12"
              x="3"
              y="4"
            /> <circle
              class="ql-fill"
              cx="6"
              cy="7"
              r="1"
            /> <polyline
              class="ql-even ql-fill"
              points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
            /> </svg></button>
          </span>
        </div>
      </div>
    </v-layout>
  </v-app-bar>
</template>

<script>
    export default {
        name: "ToolbarQuillEditor",
        props: {
            color: {type: String, default: 'white'},
        },
        data() {
            return {
                qlOptions: {
                    bold: false,
                    h1: false,
                    h2: false,
                },
            }
        },
        methods: {
            onSave() {
                this.$emit('save');
            },
            onClose() {
                this.$emit('close')
            },

            /**
             * ql-editor start
             */
            toggleBold() {
                this.qlOptions.bold = !this.qlOptions.bold;
                const qlBold = document.querySelector(".ql-modal-editor .ql-bold");
                if (!qlBold) return;
                qlBold.click();
            },

            toggleImage() {
                const qlImage = document.querySelector(".ql-modal-editor .ql-image");
                if (!qlImage) return;
                qlImage.click();
            },

            toggleH1() {
                this.qlOptions.h1 = !this.qlOptions.h1;
                if (this.qlOptions.h1) this.qlOptions.h2 = false;
                const qlH1 = document.querySelector(".ql-modal-editor .ql-header[value='1']");
                if (!qlH1) return;
                qlH1.click();
            },

            toggleH2() {
                this.qlOptions.h2 = !this.qlOptions.h2;
                if (this.qlOptions.h2) this.qlOptions.h1 = false;
                const qlH2 = document.querySelector(".ql-modal-editor .ql-header[value='2']");
                if (!qlH2) return;
                qlH2.click();
            },
            /**
             * ql-editor end
             */
        }
    }
</script>

<style scoped>
    .ql-toolbar.ql-snow .ql-formats {
        margin: 0;
    }

    .outline-none {
        outline: none;
    }

    .ql-custom-toolbar:not(:last-child) {
        margin-right: 15px;
    }

    >>>.v-toolbar__content {
      padding: 0 10px 0 0;
    }
</style>