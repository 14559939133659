import {FORM_TYPE} from '../../form-types'
import MFormVuetifyTextField from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyTextField";
import MFormVuetifyTextarea from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyTextarea";
import MFormVuetifyCombobox from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyCombobox";
import MFormVuetifyAccountPicker from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyAccountPicker";
import MFormVuetifyModelPicker from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyPrinterPicker";
import MFormVuetifyStaffPicker from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyStaffPicker";
import MFormVuetifyAddressPicker from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyAddressPicker";
import MFormVuetifyDialogDatePicker from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyDialogDatePicker";
import MFormVuetifyDialogDateTimePicker
    from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyDialogDateTimePicker";
import MFormVuetifyBranchPicker from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyBranchPicker";
import MFormVuetifyCodePicker from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyCodePicker";
import MFormVuetifyRentalcompanyCodePicker
    from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyRentalcompanyCodePicker";
import MFormVuetifyRentalcompanyCodeDialogPicker
    from "@/assets/plugins/mps-form/providers/vuetify/MFormVuetifyRentalcompanyCodeDialogPicker";

export default {
    [FORM_TYPE.TEXT_FIELD]: MFormVuetifyTextField,
    [FORM_TYPE.TEXTAREA]: MFormVuetifyTextarea,
    [FORM_TYPE.COMBOBOX]: MFormVuetifyCombobox,
    [FORM_TYPE.DATE_PICKER]: MFormVuetifyDialogDatePicker,
    [FORM_TYPE.DATETIME_PICKER]: MFormVuetifyDialogDateTimePicker,
    [FORM_TYPE.ACCOUNT_PICKER]: MFormVuetifyAccountPicker,
    [FORM_TYPE.BRANCH_PICKER]: MFormVuetifyBranchPicker,
    [FORM_TYPE.ADDRESS_PICKER]: MFormVuetifyAddressPicker,
    [FORM_TYPE.STAFF_PICKER]: MFormVuetifyStaffPicker,
    [FORM_TYPE.CODE_PICKER]: MFormVuetifyCodePicker,
    [FORM_TYPE.RENTALCOMPANY_CODE_PICKER]: MFormVuetifyRentalcompanyCodePicker,
    [FORM_TYPE.RENTALCOMPANY_CODE_DIALOG_PICKER]: MFormVuetifyRentalcompanyCodeDialogPicker,
    [FORM_TYPE.PRINTER_PICKER]: MFormVuetifyModelPicker,
}
