import {DateTime, Settings} from "luxon";
import {timeZoneHelper} from './time-zone-helper'
import parseDate from "./parse-date";


export const DEFAULT_LANGUAGE = 'en-US';
export const DATE_FORMATS = {
    'ko-KR': {
        DATE_SHORT: 'yy/MM/dd  HH:mm',
        DATE_MONTH: 'MM-dd',
        DATE: 'yyyy-MM-dd',
        TIME: 'HH:mm:ss',
        DATETIME: 'yyyy-MM-dd HH:mm',
        MONTH: 'yyyy-MM',
        YEAR: 'yyyy',
    },
    'en-US': {
        DATE_SHORT: 'yy/MM/dd  HH:mm',
        DATE_MONTH: 'dd-LLL',
        DATE: 'dd-LLL-yyyy',
        TIME: 'HH:mm:ss',
        DATETIME: 'dd-LLL-yyyy HH:mm',
        MONTH: 'LLL-yyyy',
        YEAR: 'yyyy',
    },
    'default': {
        DATE_SHORT: 'yy/MM/dd  HH:mm',
        DATE_MONTH: 'MM-dd',
        DATE: 'yyyy-MM-dd',
        TIME: 'HH:mm:ss',
        DATETIME: 'yyyy-MM-dd HH:mm',
        MONTH: 'yyyy-MM',
        YEAR: 'yyyy',
    },
};


/**
 * 시간 관련 포맷 변환을 지원합니다.
 */
class DateFormatHelper {

    _format = DATE_FORMATS.default;

    constructor() {
        Settings.defaultZoneName = timeZoneHelper.getTimeZone();
        this.setLanguage(timeZoneHelper.getBrowserLanguage());
    }

    /**
     * MPS 로그인 된 유저 정보를 설정합니다.
     * @param user
     */
    setUser(user = null) {
        let language;
        if (user) {
            language = user.languageCode || null;
            if (user.countryCode) language += '-' + user.countryCode;
        }
        this.setLanguage(language);
    }

    setLanguage(language = null) {
        this.language = language ? language : DEFAULT_LANGUAGE;
        this._formats = DATE_FORMATS[this.language] || DATE_FORMATS.default;
    }

    format(v, format) {
        if (!v || v.length === 0) return '';
        return DateTime.fromISO(v).toFormat(format);
    }

    formatDateTimeShort = (v) => this.format(v, this._formats.DATE_SHORT);
    formatDateMonth = (v) => this.format(v, this._formats.DATE_MONTH);
    formatDate = (v) => this.format(v, this._formats.DATE);
    formatTime = (v) => this.format(v, this._formats.TIME);
    formatYear = (v) => this.format(v, this._formats.YEAR);
    formatDateTime = (v) => this.format(v, this._formats.DATETIME);
    formatMonth = (v) => this.format(v, this._formats.MONTH);
    parse = (v) => parseDate(v);
}

const dateHelper = new DateFormatHelper(); // 싱글턴

export {dateHelper}
