<template>
  <div style="width: 100%;">
    <div style="display: flex; flex-direction: row;">
      <div style="flex-grow: 1;">
        <vue-autocomplete

          :value="printerModelName"
          :items="listComputed"
          :placeholder="'프린터 검색' | translate"
          :read="(item) => item.printerName"
          item-value="key"
          item-text="printerName"
          clearable
          :loading="loading"
          :search-input.sync="searchInput"
          :no-data-text="'일치하는 항목이 없습니다.' | translate"
          hide-details
          :item-disabled="(item) => item.group === 'code' || item.group === 'printer' || item.group === 'model' || item.group === 'selected'
            || item.selected || (model && item.innerPrinterName === model.innerPrinterName)"
          :disabled="loading"
          :icon-yn="iconYn"
          @change="onChange"
          @click:clear="onClear"
        >
          <template #selection="{ item }">
            <div v-if="displayName">
              {{ displayName }}
            </div>
            <div v-else>
              {{ model && model.printerName }}
            </div>
          </template>
          <template #item="{ item }">
            <!-- 그루핑 -->
            <v-list-item-title
              v-if="item.group"
              class="py-2 px-1 font-weight-bold"
              style="background-color: #F5F1FF; color: #5200ff"
              v-text="item.groupText"
            />

            <!-- 계약 프린터 -->
            <div
              v-else-if="item.isPrinter"
              class="py-3"
            >
              <div>
                {{ item.raw.printerModelName }} [{{ item.raw.printerSerialNo || "" }}]
              </div>
              <div v-if="item.raw.installLocation">
                {{ '설치위치' | translate }}: {{ item.raw.installLocation }}
              </div>
              <div v-if="item.raw.contractState">
                {{ '계약여부' | translate }}: {{ item.contractStateName }}
              </div>
              <div v-if="item.raw.monitoringPrinterId">
                {{ '모니터링 여부' | translate }}: {{ item.raw.monitoringPrinterId ? 'Y' : 'N' }}
              </div>
            </div>

            <!-- 프린터 모델 -->
            <div v-else-if="item.isModel">
              <v-icon>mdi-printer</v-icon>
              {{ item.raw.printerModelName }}
            </div>

            <!-- 코드 모델 -->
            <div v-else-if="item.isCodeModel">
              <v-icon>mdi-bookmark-plus</v-icon>
              {{ item.raw.codeValue }}
            </div>
          </template>
        </vue-autocomplete>
      </div>
      <div
        v-if="addIcon"
        style="flex-grow: 0; flex-basis: 38px;"
        class="ml-2"
      >
        <div v-show="!addModel">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn
                icon
                color="primary"
                style="min-width: 38px; min-height: 38px;"
                v-on="on"
                @click="() => addModel = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            {{ "코드 모델 추가" | translate }}
          </v-tooltip>
        </div>
        <div v-show="addModel">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn
                icon
                color="error"
                style="min-width: 38px; min-height: 38px;"
                v-on="on"
                @click="() => addModel = false"
              >
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </template>
            {{ "닫기" | translate }}
          </v-tooltip>
        </div>
      </div>
    </div>

    <div
      v-if="addModel"
      class="mt-2"
      style="display: flex; flex-direction: row;"
    >
      <v-text-field
        v-model="addModelName"
        class="code-add-form text-left"
        style="font-size: 14px; flex-grow: 1;"
        outlined
        dense
        name="as_register_code_model_add"
        :placeholder="'코드 모델 추가' | translate"
        hide-details
        @keyup.enter.stop="onRegisterPrinterModelClicked"
      />
      <v-btn
        color="primary"
        class="ml-2"
        style="flex-grow: 0; flex-basis: 80px; min-height: 38px;"
        @click="onRegisterPrinterModelClicked"
      >
        {{ '추가' | translate }}
      </v-btn>
    </div>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import {PrinterAutocomplete} from "@/model/printer-autocomplete";

    export default {
        name: "PrinterFormAutocomplete",
        props: {
            displayName: undefined,
            printerModelName: undefined,
            accountId: undefined,
            accountBranchId: undefined,
            isPrinterContract: {
                type: Boolean,
                default: null,
            },
            isMonitoringPrinter: {
                type: Boolean,
                default: null,
            },
            whetherLoadPrinterModels: {
                type: Boolean,
                default: false,
            },
            whetherLoadCodeModels: {
                type: Boolean,
                default: false,
            },
            whetherLoadPrinters: {
                type: Boolean,
                default: false,
            },
            addIcon: {
                type: Boolean,
                default: false,
            },
            uniqueId: undefined,
            autoSelect: {
                type: Boolean,
                default: false,
            },
            idx: {
              type: Number,
              default: null
            },
            iconYn: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                model: null,
                addModelName: "",
                addModel: false,
                list: [],
                isClear: false,
                searchInput: "",
                loading: false,
            }
        },
        computed: {
            listComputed() {
                // 해당하는 프린터 모델이 목록에 없으면 임의로 추가시킵니다.
                if (!!this.model) {
                    return this.list.concat([new PrinterAutocomplete(null, "selected"), new PrinterAutocomplete({printerModelName: this.model.innerPrinterName}, null, true)]);
                        // .concat(this.list.filter(e => e.innerPrinterName !== this.model.innerPrinterName));
                }

                return this.list;
            },
        },
        watch: {
            printerModelName() {
                this.updateModel();
            },

            model() {
              if (this.idx != null){
                this.$emit("change", {model: this.model, idx: this.idx});
              } else {
                this.$emit("change", this.model);
              }
            },

            /**
             * 거래처와 영업점이 동시에 변경될 수 있는 경우 loadPrinter 가 두 번 호출되는 것을 방지하기 위해 uniqueId 를 이용합니다.
             */
            uniqueId() {
                this.loadPrinter();
            },
        },
        created() {
            this.loadPrinter();
            this.updateModel();
        },
        methods: {
            updateModel() {
                if (!!this.printerModelName) {
                    this.model = new PrinterAutocomplete({printerModelName: this.printerModelName}, null, true);
                } else {
                    this.model = null;
                }
            },

            /**
             * x표시 클릭시 사용합니다.
             */
            onClear() {
                this.model = null;
            },

            onChange(v) {
                this.model = v;
            },

            /**
             * 전체 프린터 목록을 조회합니다.
             */
            async loadPrinter() {
                if (this.loading) return;
                this.loading = true;
                let itemsCode = null;
                if (this.whetherLoadCodeModels) {
                    const res2 = await this.$request(PATH.RENTALCOMPANY_CODES.BASE)
                        .setObject({codeType: 'CPMDL'})
                        .enqueue();
                    itemsCode = res2.data.filter(e => !!e.codeValue && !!e.codeValue.trim()).map(e => new PrinterAutocomplete(e));
                }
                const res = await this.$request(PATH.PRINTER.AND_PRINTER_LSIT)
                    .setObject({
                        accountId: this.accountId,
                        accountBranchId: this.accountBranchId,
                        whetherLoadPrinterModels: this.whetherLoadPrinterModels,
                        whetherLoadPrinters: this.whetherLoadPrinters,
                        isPrinterContract: this.isPrinterContract,
                        isMonitoringPrinter: this.isMonitoringPrinter,
                    })
                    .enqueue();
                const itemsPrinter = res.data.filter(e => !!e.printerModelName && !!e.printerModelName.trim()).map(e => new PrinterAutocomplete(e));
                const itemsContractPrinter = itemsPrinter.filter(e => e.isPrinter);
                const itemsPrinterModel = itemsPrinter.filter(e => e.isModel);

                let returnValue = [];
                if (this.whetherLoadPrinters) {
                    returnValue = returnValue.concat([new PrinterAutocomplete(null, "printer")])
                        .concat(itemsContractPrinter);

                    // 만약 계약 프린터가 하나만 존재하고 auto-close prop 이 true 라면 해당 아이템을 자동으로 선택합니다.
                    if (this.autoSelect && itemsContractPrinter.length === 1) {
                        this.model = itemsContractPrinter[0];
                    }
                }
                if (this.whetherLoadCodeModels) {
                    returnValue = returnValue.concat([new PrinterAutocomplete(null, "code")])
                        .concat(itemsCode);
                }
                if (this.whetherLoadPrinterModels) {
                    returnValue = returnValue.concat([new PrinterAutocomplete(null, "model")])
                        .concat(itemsPrinterModel);
                }

                this.loading = false;
                this.list = returnValue;
            },

            /**
             * 코드를 추가합니다.
             */
            onRegisterPrinterModelClicked() {
                if (!this.addModelName || !this.addModelName.trim()) {
                    return;
                }
                const requestBody = {
                    codeValue: this.addModelName.trim(),
                    codeType: 'CPMDL',
                    showYn: 'Y',
                }
                this.onSavedModel(requestBody);
            },

            /**
             * 코드 추가 API 요청
             */
            onSavedModel(requestBody) {
                this.$request(PATH.MANAGE.ADD_RENTAL_COMPANY_CODE)
                    .setObject(requestBody)
                    .enqueue()
                    .then(res => {
                        this.addModelName = "";
                        this.toastSuccess("사용자 유저 모델이 추가되었습니다.");
                        this.loadPrinter();
                    }).catch(error => {
                    console.error(
                        error);
                });
            },

        },
    }
</script>

<style scoped>
    >>> .theme--light.v-input input {
        background-color: rgb(255, 255, 255) !important;
    }

    >>> .code-add-form.theme--light.v-input input {
        background-color: rgb(255, 255, 255) !important;
    }

    >>> .v-input__slot {
      text-align: right !important;
      padding: 4px !important;
      font-size: 0.8rem !important;
      background-color: rgb(255, 255, 255) !important;
      border: 1px solid #dddddd !important;
    }
</style>
