<template>
  <v-card style="height: 100%; overflow-y: hidden;">
    <v-toolbar
      color="white"
      elevation="0"
      class="d-flex align-center"
    >
      <dialog-title :text="'위임하기' | translate" />

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </v-toolbar>

    <v-list
      class="px-0"
      subheader
      two-line
      style="height: 100%; overflow-y: auto;"
    >
      <v-list-item>
        <v-list-item-content class="pb-0">
          <v-select-backguard
            v-model="selected"
            :items="items"
            item-text="userFullname"
            item-value="userId"
            hide-details
            :label="'기사 선택' | translate"
            outlined
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="delegate-reason"
      >
        <v-list-item-content style="padding: 12px 0 12px 0;">
          <v-textarea
            v-model="editData.changedReason"
            :label="'위임사유' | translate"
            outlined
            :rows="10"
          />
        </v-list-item-content>
      </v-list-item>

      <dialog-apply-button
        :text="'등록' | translate"
        @click:apply="onClickApply"
      />
    </v-list>
  </v-card>
</template>

<script>
    import DialogApplyButton from "@/components/part/sub/DialogApplyButton";
    import DialogTitle from "@/components/part/sub/DialogTitle";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import VSelectBackguard from "@/components/part/VSelectBackguard";

    export default {
        name: "StaffDelegateVuetifyModal",
        components: {VSelectBackguard, DialogTitle, DialogApplyButton},
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                editData: {
                    changedReason: null,
                },
                selected: null,
            }
        },
        computed: {
            exclude() {
                return this.propsData.exclude;
            },

            options() {
                return this.propsData.options;
            },

            items() {
                let arr = [{userId: "RequestDelegate", userFullname: this.$translate('위임요청')}];
                if (!!this.options.workorderStaffOptions) {
                    arr = arr.concat(this.options.workorderStaffOptions.filter(item => item.userId !== this.exclude));
                }

                return arr;
            },
            selectedItem() {
                if (isNOU(this.selected)) return;
                return this.items[this.selected];
            },
        },
        mounted() {
            const state = this.getState();
            if (!!state) {
                this.editData = state.editData;
                this.selected = state.selected;
            } else {
                this.setDefaultState();
            }
        },
        created() {
            this.setDefaultState();
        },
        methods: {
            setDefaultState() {
                this.editData = { changedReason: null, };
                this.selected = null;
            },

            onClickClose() {
                this.setState(undefined); // 닫기전 상태값을 저장한다.
                this.close();
            },

            onClickApply() {
                this.setState(undefined); // 적용을 누르면 상태값을 삭제한다.

                if (isNOU(this.selected)) return this.$alert(this.$translate("위임할 A/S 기사를 선택하여 주십시오"));

                if (this.selected == "RequestDelegate") {
                    this.callAndClose({userId: null, changedReason: this.editData.changedReason});
                } else {
                    this.callAndClose({
                        userId: this.selected,
                        changedReason: this.editData.changedReason
                    });
                }
            },
        },
    }
</script>

<style scoped>
    >>> .v-toolbar__content, .v-toolbar__extension {
        width: 100%;
        justify-content: space-between;
    }

    >>> .v-toolbar__items {
        flex: 1;
        justify-content: center;
    }

    >>> .v-text-field--outlined fieldset {
        border: 1px solid #ddd !important;
    }

    >>> .v-text-field--outlined {
        border-radius: 0px;
    }

    >>> .delegate-reason .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: calc(100vh - 205px) !important;
        max-height: calc(100vh - 205px) !important;
    }

    /*>>> .v-text-field--outlined fieldset {
      border: none !important;
    }*/
</style>