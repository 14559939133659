<script>
    import backGuard from "@/assets/plugins/mps-common/router/back-guard";
    import {routerManager} from "@/assets/plugins/mps-common/router/router-manager";

    export default {
        name: "RouterMixin",
        data() {
            return {
                backGuard: backGuard,
                routerManager: routerManager,
            };
        },
    }
</script>