<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <template v-if="inEditMode">
          <div class="in-edit-mode">
            <input
              v-model="innerModel"
              class="form-textfield"
              type="number"
              :disabled="disabled"
              :placeholder="placeholder"
            >
          </div>
        </template>

        <template v-else>
          <div class="in-view-mode">
            <input
              type="text"
              class="form-textfield"
              :value="innerModel | number"
              disabled
              :placeholder="placeholder"
            >
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import MBaseFormNumber from "@/assets/plugins/mps-form/providers/MBaseFormNumber";

    export default {
        name: "MFormMpsNumber",
        extends: MBaseFormNumber,
        mixins: [],
        watch: {
        },
        mounted() {
        },
    }
</script>
