import * as props from '@/store/modules/props';

import {
    GET_PROP,
    GET_PROPS,
    SAVE_PROP,
    SAVE_PROPS,
} from "@/store/modules/props/keys";

export default {
    computed: {
        ...props.mapGetters([GET_PROP, GET_PROPS]),
    },
    methods: {
        ...props.mapActions([SAVE_PROP, SAVE_PROPS]),
    },
}