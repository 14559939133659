import { PATH } from "@/constants";
import {DateTime} from "luxon";
import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";
import RequesterBuilder from "@/assets/plugins/mps-common/network/requester-builder";
const request = new RequesterBuilder();

export default class Dashboard {

    _rentalCompanyId = null
    _countryCode = null
    _items = {}
    _setting = {}
    _params = {}

    constructor(rentalCompanyId, countryCode) {
        this._rentalCompanyId = rentalCompanyId
        this._countryCode = countryCode;
    }

    set rentalCompanyId(id){
        this._rentalCompanyId = id;
    }

    get items() {
        return this._items
    }

    get setting() {
        return this._items?.setting
    }

    get params() {
        return this._params;
    }

    setData(key, data){
        this._items[key] = this.clearData(key, data);
        return this._items[key];
    }

    requestSales(params){
        this._params['sales'] = params;
        return request.path(PATH.REPORT.WIDGET.SALES)
            .setObject(params)
            .enqueue();
    }

    requestAccount(params){
        this._params['account'] = params;
        return request.path(PATH.REPORT.WIDGET.ACCOUNT)
            .setObject(params)
            .enqueue();
    }

    requestContract(params){
        this._params['contract'] = params;
        return request.path(PATH.REPORT.WIDGET.CONTRACT)
            .setObject(params)
            .enqueue();
    }

    requestWork(params){
        this._params['work'] = params;
        return request.path(PATH.REPORT.WIDGET.WORK)
            .setObject(params)
            .enqueue();
    }

    requestAuto(params){
        this._params['auto'] = params;
        return request.path(PATH.REPORT.WIDGET.AUTO)
            .setObject(params)
            .enqueue();
    }

    requestSetting(){
        return request.path(PATH.REPORT.WIDGET.SETTING_SELECTED)
            .setObject({})
            .enqueue();
    }

    requestSettingUpdate(params){
        return request.path(PATH.REPORT.WIDGET.SETTING_UPDATE)
            .setObject(params)
            .enqueue();
    }

    requestCommentUpdate(params){
        return request.path(PATH.REPORT.WIDGET.COMMENT_UPDATE)
            .setObject(params)
            .enqueue();
    }

    clearData(key, data){
        switch (key) {
            case 'setting':
                return this.clearSettingData(data);
            case 'salesForSum':
                return this.clearSalesSumData(data);
            case 'salesForTrend':
                return this.clearSalesTrendData(data);
            case 'accountForSum':
                return this.clearAccountSumData(data);
            case 'accountForTrend':
                return this.clearAccountTrendData(data);
            case 'contractList':
                return this.clearContractListData(data);
            case 'workForSum':
                return this.clearWorkSumData(data);
            case 'workForTrend':
                return this.clearWorkTrendData(data);
            case 'autoForSum':
                return this.clearAutoSumData(data);
        }
    }

    YNToTrueFalse(value){
        return value === 'Y';
    }

    clearSettingData(data){
        const s = {
            totalWidgetCount : data.length,
            showWidgetCount: 0,
        };

        const names = {
            '매출': 'sales',
            '거래처': 'account',
            '계약현황': 'contract',
            'A/S': 'as',
            '자동화': 'auto'
        }

        data?.map(d => {
            const key = names[d.title];
            const value = this.YNToTrueFalse(d.value || d.defaultValue);
            s[key] = {};
            s[key]['id'] = [d.reportId, d.reportSettingId]
            s[key]['name'] = key;
            s[key]['nameKo'] = d.title;
            s[key]['isShow'] = value
            s[key]['showChildCount'] = 0;
            s[key]['childIds'] = []
            s[key]['child'] = []
            s[key]['childNames'] = []

            if(value){
                s['showWidgetCount'] += 1;
            }

            d.child.map(c => {
                const value = this.YNToTrueFalse(c.value || c.defaultValue);
                s[key]['childIds'].push([c.reportId, c.reportSettingId])
                s[key]['child'].push(value)
                s[key]['childNames'].push(c.title)
                if(value){
                    s[key]['showChildCount'] += 1;
                }

                if(c.child?.length > 0){
                    s[key]['duration'] = {}
                    const cc = c.child[0];
                    s[key]['duration']['value'] = parseInt(cc.value || cc.defaultValue)
                    s[key]['duration']['list'] = cc.valuesList.map(v => parseInt(v))
                    s[key]['durationIds'] = [cc.reportId, cc.reportSettingId]
                }
            });
        })

        this._setting = data;

        return s;
    }

    clearSalesSumData(data){
        const labels = [translationManager.translate('수금완료'), translationManager.translate('미수금')];
        return {
            balanceCount: data?.balanceCount || 0,
            estimatedFee: data?.estimatedFee || 0,
            sumBalance: data?.sumBalance || 0,
            sumPrice: data?.sumPrice || 0,
            sumReqPrice: data?.sumReqPrice || 0,
            graph: {
                labels: labels,
                datasets: [{
                    data: [data?.sumPrice || 0, data?.sumBalance || 0],
                }],
                isInner: true,
                innerData :{
                    innerText: translationManager.translate('예상 매출액'),
                    innerNum: data?.estimatedFee?.toLocaleString() || '0',
                }
            },
            depositSummary: data?.depositSummary || null,
            comment: {
                reportCommentId: data?.reportCommentId || null,
                regMonth: null,
                reportId: 1,
                comment: data?.comment || '',
            }
        }
    }

    clearSalesTrendData(data){
        const labels = [];
        const sumPrices = []
        const sumBalances = []
        const sumReqPrices = []
        let allReqPrice = 0;

        data.list?.reverse().map(d => {
            d.salesMonth ? labels.push(DateTime.fromISO(d.salesMonth).setLocale(this._countryCode).toFormat('MMM')): labels.push(null)
            d.sumPrice ? sumPrices.push(d.sumPrice) : sumPrices.push(0)
            d.sumBalance ? sumBalances.push(d.sumBalance) : sumBalances.push(0)
            d.sumReqPrice ? sumReqPrices.push(d.sumReqPrice) : sumReqPrices.push(0)
            allReqPrice += d.sumReqPrice ? d.sumReqPrice : 0;
        })

        return {
            data: data.list || [],
            graph :{
                labels: labels,
                stepSize: Math.floor(allReqPrice/100000/60)*1000000,
                datasets: [
                    {
                        label: translationManager.translate('발생매출'),
                        data: sumReqPrices,
                    },
                    {
                        label: translationManager.translate('수금'),
                        data: sumPrices,
                    },
                ],
            }
        }
    }

    clearAccountSumData(data){
        return {
            cancelAccountCount: data?.cancelAccountCount || 0,
            newAccountCount: data?.newAccountCount || 0,
            totalAccountCount: data?.totalAccountCount || 0,
            comment: {
                reportCommentId: data?.reportCommentId || null,
                regMonth: null,
                reportId: 2,
                comment: data?.comment || '',
            }
        }
    }

    clearAccountTrendData(data){
        const labels = [];
        const newAccountCounts = []
        const cancelAccountCounts = []
        const totalAccountCounts = []
        let allAccount = 0;

        data.list?.reverse().map(d => {
            d.salesMonth ? labels.push(DateTime.fromISO(d.salesMonth).setLocale(this._countryCode).toFormat('MMM')): labels.push(null)
            d.newAccountCount ? newAccountCounts.push(d.newAccountCount) : newAccountCounts.push(0)
            d.cancelAccountCount ? cancelAccountCounts.push(d.cancelAccountCount) : cancelAccountCounts.push(0)
            d.totalAccountCount ? totalAccountCounts.push(d.totalAccountCount) : totalAccountCounts.push(0)
            allAccount += d.totalAccountCount ? d.totalAccountCount : 0;
        })

        return {
            data: data.list || [],
            graph: {
                labels: labels,
                stepSize: Math.floor((allAccount/10)/6)*10,
                datasets: [
                    {
                        yAxisID: 'A',
                        label: translationManager.translate('총 거래처'),
                        data: totalAccountCounts,
                    },
                    {
                        yAxisID: 'A',
                        label: translationManager.translate('신규'),
                        data: newAccountCounts,
                    },
                    {
                        yAxisID: 'A',
                        label: translationManager.translate('해지 및 삭제'),
                        data: cancelAccountCounts,
                    },
                ],
                grouped: true
            }
        }
    }

    clearContractListData(data){
        return {
            data: data.list || [],
            comment: {
                reportCommentId: data?.reportCommentId || null,
                regMonth: null,
                reportId: 3,
                comment: data?.comment || '',
            }
        }
    }

    clearWorkSumData(data){
        return {
            completionAsCount: data?.completionAsCount || 0,
            progressAsCount: data?.progressAsCount || 0,
            totalAsCount: data?.totalAsCount || 0,
            comment: {
                reportCommentId: data?.reportCommentId || null,
                regMonth: null,
                reportId: 4,
                comment: data?.comment || '',
            }
        }
    }

    clearWorkTrendData(data){
        return {
            data: data.list || []
        }
    }

    clearAutoSumData(data){
        return {
            sales: data?.sales || null,
            cms: data?.cms || null,
            tax: data?.tax || null,
            comment: {
                reportCommentId: data?.reportCommentId || null,
                regMonth: null,
                reportId: 5,
                comment: data?.comment || '',
            }
        }
    }


}