<script>

    export default {
        name: "ModalVuetifyUser",
        mixins: [],

        data() {
            return {
            }
        },

        methods: {
            generateUniqueModalId(modalName) {
                return this.$route.name + "-" + modalName;
            },

            _openModalVuetify(modalName, modalOptions = {}, propsData, callback, modalId) {
                let uniqueModalId;
                if (!modalId) {
                    uniqueModalId = this.generateUniqueModalId(modalName);
                }

                this.notifyEvent(this.mpsEvents.ON_MODAL_OPEN_REQUESTED, {
                    modalName: modalName,
                    modalOptions: modalOptions,
                    propsData: propsData,
                    callback: callback,
                    id: modalId || uniqueModalId,
                });

                return uniqueModalId;
            },

            /**
             * 모달을 오픈합니다.
             *
             * @param modalOptions
             * @param propsData
             * @param callback
             * @param modalId
             * @returns String 모달의 고유 아이디를 반환합니다.
             */
            openModalVuetify(modalName, modalOptions, propsData, callback, modalId) {
                return this._openModalVuetify(modalName, modalOptions, propsData, callback, modalId);
            },
        },
    }
</script>