<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

    export default {
        name: "MFormAccountPicker",
        extends: MBaseFormPicker,
        computed: {
            display() {
                if (!this.options) return null;
                let _t = "";
                if (!!this.options.branchName) _t += this.options.branchName;
                return _t;
            },

            titleComputed() {
                if (!this.options) return null;
                let _t = this.options.accountName + "[" + this.options.accountId + "]";
                return _t;
            },
        },
        watch: {
            options: {
                deep: true,
                handler: function () {
                    if (isNOU(this.options.branchName)) { // 오직 초기화 목적!
                        this.model = this.options.branchName;
                    }
                }
            }
        },
    }
</script>