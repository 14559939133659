<script>
    import FilterButtonProvider from "@/components/app/toolbar/component/filter-button/filter-button-provider";
    import {FILTER_BUTTON_TYPE} from "@/components/app/toolbar/component/filter-button/filter-button-types";

    export default {
        name: "FilterButtonMixin",
        data() {
            return {
                filterButtonPrivider: new FilterButtonProvider(this.formProvider || "vuetify")
            }
        },
        methods: {
            getFilterButtonComponent(type) {
                let found = false;
                Object.keys(FILTER_BUTTON_TYPE).forEach(key => {
                    if (type === FILTER_BUTTON_TYPE[key]) found = true;
                });
                if (!found) {
                    console.warn(`You should select a type for the form element. VTextField will be selected by default.`);
                    console.groupEnd();
                    return null;
                } else {
                    return this.filterButtonPrivider.getElement(type);
                }
            },
        }
    }
</script>