const NATIVE_EVENT = {
    /**
     * 네이티브에 연결되었습니다.
     */
    ON_CONNECTED: 'native_on_connected',
    /**
     * 네이티브로부터 알림을 수신했습니다.
     */
    ON_NOTIFICATION_RECEIVED: 'native_on_notification_received'
};

class Command {
    name;
    method;
    data;

    constructor(name, method, data = {}) {
        this.name = name;
        this.method = method;
        this.data = data;
    }
}

class Setting {

    GPS_URL;
    REG_PUSH_URL;
    DEL_PUSH_URL;

    constructor(GPS_URL, REG_PUSH_URL, DEL_PUSH_URL) {
        this.GPS_URL = GPS_URL;
        this.REG_PUSH_URL = REG_PUSH_URL;
        this.DEL_PUSH_URL = DEL_PUSH_URL;
    }

}

class NativeBridge {

    /**
     * Vue 에서만 사용하는 네이티브 관련된 이벤트 버스입니다.
     * @type {undefined}
     * @private
     */
    _eventBus = undefined;

    /**
     * 네이티브가 연결되었는지 여부입니다.
     * @type {boolean}
     * @private
     */
    _connected = false;

    /**
     * 네이티브가 연결되었지만, 아직 이 웹 어플리케이션에서 통신을 시도하지 않았습니다.
     * @type {boolean}
     * @private
     */
    _handShake = false;

    /**
     * 생성자입니다. Vue 와 통신을 위한 이벤트 버스가 필수입니다.
     * window 에서 함수가 호출될 떄 Vue 인스턴스로 전달할 수 있습니다.
     * @param eventBus
     */
    constructor(eventBus) {
        this._eventBus = eventBus;
    }

    /**
     * 네이티브 어플리케이션이 준비되면 호출됩니다.
     * 페이지가 두번 호출되는것도 아닌데 두번 콜 됩니다.
     */
    onNativeReady = () => {
        if (this._connected) return;
        this._connected = !!window.invokeTnboxNativeAction;
        if (this._connected) this.eventBus.$emit(NATIVE_EVENT.ON_CONNECTED);
    };

    onPushNotification = (data) => {
        if (this._connected) this.eventBus.$emit(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, data);
    };

    putUser(userId = null, accessToken = null) {
        const data = {
            userId: userId,
            accessToken: accessToken,
            pushToken: null,
            pushMsgYn: 'Y',
            telNo: null
        };
        this.invokeNative(new Command('USER', 'PUT', data));
    }

    putSetting(setting) {
        this.invokeNative(new Command('SETTINGS', 'PUT', setting));
    }

    /**
     * 현재 URL 이 변경 될 때 호출됩니다.
     * @param setting
     */
    putUrl(url) {
        this.invokeNative(new Command('URL', 'PUT', url));
    }

    exit() {
        try{
            this.invokeNative(new Command("EXIT", 'PUT', 'none'));
        }catch (e) {
            console.log(e);
        }
    }

    invokeNative(command) {
        if (this._connected) {
            try {
                window.invokeTnboxNativeAction(JSON.stringify(command));
            } catch (e) {
                // console.error(e)
            }
        } else {
            console.error('Cannot call the invokeNative function!');
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // getter and setter
    ///////////////////////////////////////////////////////////////////////////


    /**
     * 네이티브가 연결되었는지 여부입니다.
     * @returns {boolean}
     */
    get isNativeConnected() {
        return this._connected;
    }

    get eventBus() {
        return this._eventBus;
    }

    set eventBus(value) {
        this._eventBus = value;
    }

}

const NATIVE_COMMAND = {
    EXIT: "EXIT",
}

const nativeBridge = new NativeBridge();
export {
    NATIVE_COMMAND,
    nativeBridge,
    NATIVE_EVENT,
    NativeBridge,
    Setting,
}
