<template>
  <v-card style="height: 100%; overflow-y: hidden;">
    <v-toolbar
      color="white"
      elevation="0"
      class="d-flex align-center"
    >
      <dialog-title :text="'취소하기' | translate" />

      <v-btn
        text
        class="button__Close mr-0"
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </v-toolbar>

    <v-list
      subheader
      three-line
      style="height: calc( 100% - 110px ); overflow-y: auto;"
    >
      <v-list-item>
        <v-list-item-content>
          <v-textarea
            :key="activatedId"
            v-model="editData.asContent"
            v-focus-delay:child-textarea="300"
            :label="'취소사유' | translate"
            outlined
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="item._work.register === 'SYSTEM' && !!item._work.monitoringPrinterId">
        <v-list-item-content>
          <span>{{ 'A/S 자동 접수 예외 기간(일간)' | translate }}</span>
          <v-select
            v-model="asExcludePeriod"
            :items="period"
            item-value="value"
            item-text="key"
          />
        </v-list-item-content>
      </v-list-item>

      <dialog-apply-button
        :text="'등록' | translate"
        @click:apply="onClickApply"
      />
    </v-list>
  </v-card>
</template>

<script>
    import DialogApplyButton from "@/components/part/sub/DialogApplyButton";
    import DialogTitle from "@/components/part/sub/DialogTitle";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";

    export default {
        name: "AsCancelVuetifyModal",
        components: {DialogTitle, DialogApplyButton},
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                editData: {
                    asContent: null,
                    doneDate: this.$now(),
                },
                activatedId: 0,
                asExcludePeriod: null,
                period: [
                  {value: null,
                    key: '선택안함'
                  } ,
                  {value: 1,
                    key: '1'
                  } ,
                  {value: 2,
                    key: '2'
                  } ,
                  {value: 3,
                    key: '3'
                  } ,
                  {value: 4,
                    key: '4'
                  } ,
                  {value: 5,
                    key: '5'
                  } ,
                  {value: 6,
                    key: '6'
                  } ,
                  {value: 7,
                    key: '7'
                  } ,
                  {value: 8,
                    key: '8'
                  } ,
                  {value: 9,
                    key: '9'
                  } ,
                  {value: 10,
                    key: '10'
                  } ,
                  {value: 11,
                    key: '11'
                  } ,
                  {value: 12,
                    key: '12'
                  } ,
                  {value: 13,
                    key: '13'
                  } ,
                  {value: 14,
                    key: '14'
                  } ,
                  {value: 15,
                    key: '15'
                  } ,
                  {value: 16,
                    key: '16'
                  } ,
                  {value: 17,
                    key: '17'
                  } ,
                  {value: 18,
                    key: '18'
                  } ,
                  {value: 19,
                    key: '19'
                  } ,
                  {value: 20,
                    key: '20'
                  } ,
                  {value: 21,
                    key: '21'
                  } ,
                  {value: 22,
                    key: '22'
                  } ,
                  {value: 23,
                    key: '23'
                  } ,
                  {value: 24,
                    key: '24'
                  } ,
                  {value: 25,
                    key: '25'
                  } ,
                  {value: 26,
                    key: '26'
                  } ,
                  {value: 27,
                    key: '27'
                  } ,
                  {value: 28,
                    key: '28'
                  } ,
                  {value: 29,
                    key: '29'
                  } ,
                  {value: 30,
                    key: '30'
                  } ]
            }
        },
        computed: {
            item() {
                return this.propsData.item;
            },
        },
        mounted() {
            this.asExcludePeriod = null;
            this.editData = this.getState() || this.getDefaultState();
            if (this.item.raw.asContent) this.editData.asContent = this.item.raw.asContent;
            this.activatedId++;
        },
        created() {
            this.editData = this.getDefaultState();
        },
        methods: {
            getDefaultState() {
                return {
                    doneDate: this.$now(),
                    asContent: null,
                }
            },

            onClickClose() {
                this.setState(this.editData); // 닫기전 상태값을 저장한다.
                this.close();
            },

            onClickApply() {
                this.setState(undefined);

                const d = this.$parseDate(this.editData.doneDate);
                const serverData = {
                    doneDate: !!d ? d.toISO() : null,
                    asContent: this.editData.asContent,
                    asExcludePeriod: this.asExcludePeriod
                };

                this.callAndClose(serverData);
            },
        },
    }
</script>

<style scoped>
    >>> .v-toolbar__content, .v-toolbar__extension {
        width: 100%;
        justify-content: space-between;
    }

    >>> .v-toolbar__items {
        flex: 1;
        justify-content: center;
    }

    >>> .v-text-field--outlined fieldset {
        border: 1px solid #ddd !important;
    }

    >>> .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: calc(100vh - 135px) !important;
        max-height: calc(100vh - 135px) !important;
    }

    /* >>> .v-text-field--outlined fieldset {
       border: none !important;
     }*/


    >>> .button__Close {
        margin-right: -22px;
    }
</style>
