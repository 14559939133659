<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <template v-if="inEditMode">
          <div class="in-edit-mode">
            <input
              v-model="model"
              class="form-telphone"
              type="tel"
              :disabled="disabled"
              :placeholder="placeholder"
              :maxlength="options.maxlength"
            >
          </div>
        </template>

        <template v-else>
          <div class="in-view-mode">
            <div style="display: flex; width: 100%; justify-content: start;">
              <a
                v-if="options.canSms"
                :href="hasPhone ? 'sms:' + model : 'javascript: void(0)'"
                @click.stop
              >
                <img
                  v-if="hasPhone"
                  class="mt-2"
                  src="@/assets/images/main/svg/icon_sms.svg"
                >
                <img
                  v-else
                  class="mt-2"
                  src="@/assets/images/main/svg/icon_sms_gray.svg"
                >
              </a>
              <div class="form-telphone">
                <a
                  v-if="hasPhone && !options.noLink"
                  :href="hasPhone && !options.noLink ? 'tel:' + model : 'javascript:void(0)'"
                >{{ model }}</a>
                <span v-else>{{ model }}</span>
              </div>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
    import MFormPhoneNumber from "@/assets/plugins/mps-form/providers/MFormPhoneNumber";

    export default {
        name: "MFormMpsPhoneNumber",
        extends: MFormPhoneNumber,
        computed: {
            hasPhone() {
                return this.$isNotEmpty(this.model);
            }
        },
    }
</script>

<style scoped>

</style>