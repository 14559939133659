import masker from "vue-the-mask/src/masker";
import now from "@/assets/plugins/mps-common/time/now";
import parseDate from "@/assets/plugins/mps-common/time/parse-date";

export default {
    install(Vue, options) {
        Vue.filter('tel', function (value) {
            if (typeof value === "undefined" || value == null) return '';
            const nums = ['010', '011', '016', '017', '018', '018'];
            const isMobile = nums.filter(n => value.startsWith(n)) > 0;
            const onlySeoul = value.startsWith('02');
            let mask;
            if (isMobile || !onlySeoul) {
                mask = ['###-###-####', '###-####-####'];
            } else {
                mask = ['##-###-####', '##-####-####'];
            }
            const tokens = {'#': {pattern: /\d/}};
            return masker(value, mask, true, tokens);
        });

        Vue.filter('price', function (value) {
            if (isNaN(Number(value))) return '';
            let mask = ['###,###,###,###'];
            const numberStr = '' + value;
            const tokens = {'#': {pattern: /[\d]/}};
            return masker(numberStr, mask, true, tokens);
        });

        Vue.filter('serial', function (value) {
            if (_.isNil(value)) return '';
            let mask = ['XXXX-XXXX-XXXX-XXXX'];
            const tokens = {'X': {pattern: /[0-9a-zA-Z]/}};
            return masker(value, mask, true, tokens);
        });

        Vue.filter('companyNumber', function (value) {
            if (typeof value === "undefined" || value == null) return '';
            let mask = '###-##-#####';
            const tokens = {'#': {pattern: /\d/}};
            return masker(value, mask, true, tokens);
        });

        Vue.filter('birthday', function (value) {
            if (typeof value === "undefined" || value == null) return '';
            let mask = '####-##-##';
            const tokens = {'#': {pattern: /\d/}};
            return masker(value, mask, true, tokens);
        });

        Vue.filter('timeOffset', function (value) {
            if (typeof value === "undefined" || value == null || value === '') return 'Undefined';
            const offsetMinutes = now().setZone(value).offset;
            if (!offsetMinutes) return "";
            const hours = offsetMinutes / 60;
            const negative = hours < 0;
            const sign = negative ? '-' : '+';
            const adjustDigit = -10 < hours && hours < 10 ? '0' : '';
            return `${sign}${adjustDigit}${Math.abs(hours)}:00`;
        });

    }

}
