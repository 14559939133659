import MForm from "@/assets/plugins/mps-form/MForm";
import {FORM_TYPE} from "@/assets/plugins/mps-form/form-types";
import MpsFormStyle from "@/assets/plugins/mps-form/providers/mixins/MpsFormStyle";

const setTheme = (theme) => {

};

const installPrototypes = (Vue, options) => {
    Vue.prototype.$mps.form = {
        types: FORM_TYPE,
    }
};

const install = (Vue, options) => {
    if (!Vue.prototype['$mps']) Vue.prototype['$mps'] = {};
    installPrototypes(Vue, options);
    const components = {MForm};
    Object.keys(components).forEach(key => Vue.component(key, components[key]));
    // form style mixin
    Vue.mixin(MpsFormStyle);
};

export default {install}
