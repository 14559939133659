<template>
  <div>
    <my-tool-bar
      :main="false"
      :action-mode="keepProps.actionMode"
      :title="titlePassed || title"
      @click:back="onClickback"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          ref="toolbarSearchAction"
          :value="keepProps.searchKeyword"
          :loading="loading"
          :placeholder="searchBarPlaceholder"
          always-active
          @close="keepProps.actionMode=false"
          @search="onSearchClicked"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="onSearchModeClicked"
        >
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>

      <template #fixed>
        <component
          :is="filterButtonComponent"
          ref="filterButton"
          :props-data="filterPropsData"
          :state.sync="keepProps.state"
          @onFilter="onFilterApplyClicked"
          @uniqueModalId="v => keepProps.uniqueModalId = v"
        />
      </template>
    </my-tool-bar>

    <status-options-bar
      :items="statusOptionsItems"
      @reset="onResetFilter"
    />

    <div
      class="overflow-y-hidden"
      :style="contentStyleObject"
    >
      <transition>
        <router-view class="fill-height overflow-y-hidden" />
      </transition>
    </div>
  </div>
</template>


<script>
    import MyToolBar from "../components/app/toolbar/MyToolBar";
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MLayout from "@/components/MLayout";
    import VuexProps from "@/framework/vuex/VuexProps";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import FilterButtonMixin from "@/components/app/toolbar/component/filter-button/FilterButtonMixin";
    import KeepProps from "@/views/mixins/KeepProps";
    import {FILTER_BUTTON_TYPE} from "@/components/app/toolbar/component/filter-button/filter-button-types";
    import StatusOptionsBar from "@/components/comp/ StatusOptionsBar";

    const target = 'SubListMain';

    export default {
        name: "SubListMain",
        components: {
            StatusOptionsBar,
            ToolbarSearchAction,
            MLayout, MyToolBar
        },
        extends: WindowComponent,
        mixins: [VuexProps, FilterButtonMixin, KeepProps],
        data() {
            return {
                historyId: undefined,
            };
        },
        computed: {
            contentHeight() {
                return "calc(100% - 56px - 49px)";
            },

            contentTop() {
                return 56 + 49 + 'px';
            },

            contentStyleObject() {
                return {
                    position: "relative",
                    height: this.contentHeight,
                    top: this.contentTop,
                }
            },

            filterPropsData() {
                switch (this.$route.name) {
                    case 'AsMainReceivedSubList':
                        return {exclude: ["WORKER", "WORKORDER_STATE"]};
                    case 'AsMainWatingStaffSubList':
                        return {exclude: ["WORKORDER_STATE"]};
                    case 'AsMainInProgressSubList':
                        return {exclude: ["WORKORDER_STATE"]};
                    case 'AsMainDayFinishSubList':
                        return {exclude: ["WORKORDER_STATE"]};
                    case 'AsMainDayCancelSubList':
                        return {exclude: ["WORKORDER_STATE"]};
                    case 'AsMainReservedSubList':
                        return {exclude: ["WORKORDER_STATE"]};
                    case 'OutstandingAccountSubList':
                    case 'ExpirationAccountSubList':
                    case 'UnvisitedAccountSubList':
                        return null;
                    case 'AsMainWorkorderSubList':
                        return {exclude: ["WORKER"], excludeWorkorderStateList: ["1", "6"]};
                    default:
                        return null;
                }
            },

            /**
             * 현재 라우트에 해당되는 필터 버튼을 반환합니다.
             */
            filterButtonComponent() {
                switch (this.$route.name) {
                    case 'AsMainReceivedSubList':
                    case 'AsMainWatingStaffSubList':
                    case 'AsMainInProgressSubList':
                    case 'AsMainDayFinishSubList':
                    case 'AsMainDayCancelSubList':
                    case 'AsMainReservedSubList':
                    case 'AsMainWorkorderSubList':
                        return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.MAIN_AS);
                    case 'OutstandingAccountSubList':
                    case 'ExpirationAccountSubList':
                    case 'UnvisitedAccountSubList':
                        return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.MAIN_ACCOUNT);
                    default:
                        return null;
                }
            },

            searchBarPlaceholder() {
                switch (this.$route.name) {
                    case 'AsMainReceivedSubList':
                    case 'AsMainWatingStaffSubList':
                    case 'AsMainInProgressSubList':
                    case 'AsMainDayFinishSubList':
                    case 'AsMainDayCancelSubList':
                    case 'AsMainReservedSubList':
                    case 'AsMainWorkorderSubList':
                        // return "거래처/모델/시리얼번호"
                        return this.$translate("검색어를 입력하세요.");
                    case 'OutstandingAccountSubList':
                    case 'ExpirationAccountSubList':
                    case 'UnvisitedAccountSubList':
                        // return "거래처/사업자번호/담당자"
                        return this.$translate("검색어를 입력하세요.");
                    default:
                        return this.$translate("검색어를 입력하세요.");
                }
            },

            props() {
                return this.GET_PROPS(target);
            },

            titlePassed() {
                return this.props.titlePassed;
            },

            titleComputed() {
                return this.titlePassed || this.$translate(this.title);
            },

            /**
             * StatusOptionsBar(상태 필터바) 에서 사용할 아이템을 반환합니다.
             */
            statusOptionsItems() {
                let items = [];
                if (this.keepProps.searchKeyword) items.push({
                    text: this.keepProps.searchKeyword,
                    color: 'orange',
                    maxWidth: "100px"
                });
                if (!this.$isEmpty(this.keepProps.filterNameList)) items = items.concat(this.keepProps.filterNameList);
                return items;
            },
        },
        watch: {
            /**
             * 라우터가 변경될 때마다 필터 조건과 검색 키워드를 초기화 시켜줍니다.
             */
            '$route.name'() {
                if (this.doKeepProps() && this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
                    this.resetKeepProps(); // 필터 초기화
                }

                this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장
            },
        },
        methods: {
            /**
             * 메인 라우터인 경우만 상태값을 저장합니다.
             */
            doKeepProps() {
                switch (this.$route.name) {
                    case 'AsMainReceivedSubList':
                    case 'AsMainWatingStaffSubList':
                    case 'AsMainInProgressSubList':
                    case 'AsMainDayFinishSubList':
                    case 'AsMainDayCancelSubList':
                    case 'AsMainReservedSubList':
                    case 'AsMainWorkorderSubList':
                    case 'OutstandingAccountSubList':
                    case 'ExpirationAccountSubList':
                    case 'UnvisitedAccountSubList':
                        return true;
                    default:
                        return false;
                }
            },

            getDefaultKeepProps() {
                let state = {};
                let filterNameList = [];
                switch (this.$route.name) {
                    case "OutstandingAccountSubList":
                    case "ExpirationAccountSubList":
                    case "UnvisitedAccountSubList":
                        state = {useYn: "Y"};
                        filterNameList = [{text: this.$translate("계약중")}];
                        break;
                    case "AsMainWorkorderSubList":
                        state = {workorderStateList: ['2', '3']};
                        filterNameList = [{text: this.$translate("기사대기")}, {text: this.$translate("진행")},];
                        break;
                    default:
                        break;
                }
                return {
                    actionMode: false,
                    searchKeyword: '',
                    state: state,
                    filterNameList: filterNameList,
                    uniqueModalId: undefined,
                }
            },

            onClickback() {
                this.SAVE_PROP({
                    target: target,
                    key: 'titlePassed',
                    value: null
                });
            },

            /**
             * 검색 모드 버튼이 눌리면 액션 모드로 전환합니다.(검색 버튼과 다름. 단순히 모드 전환)
             */
            onSearchModeClicked() {
                this.keepProps.actionMode = true;
                setTimeout(() => {
                    if (this.$refs.toolbarSearchAction) this.$refs.toolbarSearchAction.focus();
                }, 100);
            },

            /**
             * 검색 버튼이 눌렸습니다. 검색 발동 이벤트를 전달합니다.
             * @param keyword
             */
            onSearchClicked(keyword) {
                this.keepProps.searchKeyword = keyword;
                this.notifyEvent(this.mpsEvents.ON_MAIN_SEARCH_TRIGGERED, keyword);
            },

            /**
             * 필터 적용 버튼이 눌려졌습니다. 검색 발동 이벤트를 전달합니다.
             */
            onFilterApplyClicked(data) {
                this.keepProps.state = data.state;
                this.keepProps.filterNameList = data.filterNameList;
                this.notifyEvent(this.mpsEvents.ON_MAIN_FILTER_TRIGGERED, data.state);
            },

            /**
             * 모든 필터 검색 조건을 초기화 합니다.
             */
            onResetFilter() {
                this.notifyEvent(this.mpsEvents.ON_FILTER_RESET, {uniqueModalId: this.keepProps.uniqueModalId});
                const actionMode = this.keepProps.actionMode;
                this.resetKeepProps();
                this.keepProps.actionMode = actionMode;
            },
        },
    }
</script>
