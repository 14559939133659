import isPrimitive from "@/assets/plugins/mps-common/expansions/condition/is-primitive";
import isEqualArray from "@/assets/plugins/mps-common/expansions/condition/is-equal-array";

/**
 * 두 파라미터들의 값(객체라면 내부 데이터까지)의 동일 여부를 반환합니다.
 *
 * @param v1
 * @param v2
 * @param acceptor
 * @returns {boolean}
 */
const isEqual = (v1, v2) => {
    if (v1 === undefined && v2 === undefined) return true; // 같은 undefined.
    if (v1 === null && v2 === null) return true; // 같은 null.
    if (v1 === undefined && v2 === null) return false; // 하나는 undef 하나는 null.
    if (v1 === null && v2 === undefined) return false; // 하나는 null 하나는 undef.
    if (typeof v1 !== typeof v2) return false; // 타입이 다릅니다.
    // 원시형이명 단순히 값만 비교합니다.
    if (isPrimitive(v1) && isPrimitive(v2)) return v1 === v2;
    // 객체형입니다. (객체 또는 배열)
    const keys1 = Object.keys(v1).sort();
    const keys2 = Object.keys(v2).sort();
    // 키 배열 비교
    if (!isEqualArray(keys1, keys2)) return false; // 같은 키 배열이 아님.
    const length = keys1.length;
    for (let i = 0; i < length; i++) {
        const e1 = v1[keys1[i]];
        const e2 = v2[keys2[i]];
        if (e1 === e2) continue;
        if (isEqual(e1, e2)) continue;
        return false;
    }
    return true;
};
export default isEqual;
