<template>
  <div style="height: 100%; background: white;">
    <my-tool-bar
      :action-mode="true"
      :title="titleComputed"
      :closable="true"
      @click:close="onClickClose"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          ref="toolbarSearchAction"
          v-model="options.searchKeyword"
          closable
          :placeholder="'영업점/주소' | translate"
          @close="onClickClose"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="actionMode = true"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px); overflow-y: auto;">
      <template v-if="!loading">
        <template v-if="filteredItems.length > 0">
          <branch-list-item-simple-card
            :items="filteredItems"
            @pick="onClickApply"
          />

          <!-- 새로고침 버튼 -->
          <refresh-button
            :container="mounted ? $refs.container : undefined"
            no-bottom
            @click="onRefresh"
          />
        </template>
        <template v-else>
          <empty-data :text="'영업점이 없습니다' | translate" />
          <!-- 새로고침 버튼 -->
          <refresh-button
            :container="mounted ? $refs.container : undefined"
            no-bottom
            @click="onRefresh"
          />
        </template>
      </template>
      <template v-else>
        <progress-loading />
      </template>
    </div>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import Branch from "@/model/branch";
    import BranchListItemSimpleCard from "@/components/items/branch/BranchListItemSimpleCard";
    import EmptyData from "@/components/part/EmptyData";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import RefreshButton from "@/components/comp/RefreshButton";

    export default {
        name: "MFormBranchFullModal",
        components: {
            RefreshButton,
            ToolbarSearchAction,
            MyToolBar, ProgressLoading, EmptyData, BranchListItemSimpleCard
        },
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                items: [],
                loading: false,
                options: {
                    searchKeyword: null,
                },
                actionMode: false,
                historyId: undefined,
                mounted: false,
            }
        },
        computed: {
            title() {
                return this.propsData.title;
            },

            accountId() {
                return this.propsData.accountId;
            },

            accountBranchId() {
                return this.propsData.accountBranchId;
            },

            titleComputed() {
                return this.title || this.$translate("영업점 선택");
            },

            filteredItems() {
                return this.items.filter(e => this.searchByKeyword(e, this.options.searchKeyword, ["raw.branchName", "raw.address", "raw.addressDetail"]));
            },
        },
        watch: {},
        created() {
        },
        mounted() {
          if (this.historyManager.wasRoute(this.historyId)) this.initSearchOptions();
          setTimeout(() => {
            this.$refs.toolbarSearchAction.select();
          }, 100);

          this.historyId = this.historyManager.uniqueId;
          this.loadItems();
            this.mounted = true;
        },
        methods: {
            /**
             * 목록을 로드합니다.
             */
            async loadItems(loading = true) {
                if (!this.accountId) return;
                if (this.loading) return;
                // 로딩중으로 표시
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.BRANCH.LIST)
                        .setObject({
                            accountId: this.accountId,
                            accountBranchId: this.accountBranchId,
                        })
                            .wait(500)
                        .enqueue();
                    this.items = response.data.map(item => new Branch(item));
                } catch (e) {

                }
                this.loading = false;
            },

            /**
             * 툴바 검색 버튼이 눌렸습니다.
             * @param keyword
             */
            onSearchClicked(keyword) {
                this.options.searchKeyword = keyword;
                this.loadItems();
            },

            /**
             * 검색키워드와 필터 조건을 초기화 합니다.
             */
            initSearchOptions() {
                this.options = {};
                this.$refs.toolbarSearchAction.clear();
            },

            /**
             * 닫기 행동을 정의합니다.
             */
            onClickClose() {
                this.close();
            },

            onClickApply(data) {
                this.callAndClose(data);
            },

            /**
             * 새로고침 버튼을 클릭하였습니다.
             *
             * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
             */
            onRefresh() {
                this.loadItems();
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>