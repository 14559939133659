import clone from "./clone";
import {isNOU} from "../condition";

/**
 * 원본 데이터를 대상에 복사하지만, 대상에 해당 프로퍼티가 존재할 때만 복사합니다.
 * 깊은 복사를 수행합니다. (객체 참조 복사가 아닌 원시형 값을 조사해서 복사)
 *
 * @param src 원본.
 * @param dst 대상.
 */
export default (src, dst) => {
    // 반응성 복사는 대상 객체가 반드시 존재해야 합니다.
    if (isNOU(dst)) throw new Error(`To clone selectively, dst(Destination object) must be required and defined.`);
    const processor = (src, dst, key, value) => dst[key] = value; // 그냥 일반적 복사입니다.
    const acceptor = (src, dst, key, value) => dst.hasOwnProperty(key); // 선택적으로 복사합니다. 대상 객체에 해당 키가 존재하는 경우에만 복사를 수행합니다.
    clone(src, dst, processor, acceptor);
};
