<template>
  <v-navigation-drawer
    v-model="active"
    absolute
    temporary
    width="256px"
    @input="onDrawerActiveStateChanged"
  >
    <slot>
      <!--유저 프로필 부분-->
      <v-row
        class="blue"
        no-gutters
      >
        <v-list width="100%">
          <user-avatar />
          <user-profile />
        </v-list>
      </v-row>
      <!--네비게이션-->
      <v-row
        class="white"
        no-gutters
      >
        <v-container
          class="pb-12"
          fluid
        >
          <!--네비게이션 버튼 리스트-->
          <!-- <v-row
            align="start"
            justify="start"
          >
            <v-col>
              <div
                class="navigation-drawer-notice-item"
                @click="onClickNotice(NOTICE_SORT.PRODUCT_INFORMATION)"
              >
                {{ "제품정보" | translate }}
              </div>
              <div
                class="navigation-drawer-notice-item"
                @click="onClickNotice(NOTICE_SORT.INDUSTRY_TRENDS)"
              >
                {{ "업계동향" | translate }}
              </div>
            </v-col>
          </v-row>-->

          <!--네비게이션 버튼 리스트-->
          <div class="mt-5" />
          <!-- <v-row
            align="start"
            justify="start"
          >
            <v-col class="text-left">
              <div
                v-for="(nav) in navItems"
                :key="nav.id"
                class="navigation-drawer-menu-item"
                @click="onNavItemClicked(nav)"
              >
                {{ nav.title }}
              </div>
            </v-col>
          </v-row>-->
          <!--로그아웃-->
          <v-row
            align="start"
            justify="start"
          >
            <v-col class="text-left">
              <div
                class="navigation-drawer-menu-item"
                @click="logout"
              >
                {{ '로그아웃' | translate }}
              </div>
            </v-col>
            <!--            <v-btn-->
            <!--              class="mx-auto"-->
            <!--              outlined-->
            <!--              tile-->
            <!--              @click="$userManager.logout()"-->
            <!--            >-->
            <!--              {{ '로그아웃' | translate }}-->
            <!--            </v-btn>-->
          </v-row>
        </v-container>
      </v-row>

      <!--푸터-->
      <v-footer
        absolute
        class="flex-column"
      >
        <div class="policy">
          <a
            href="javascript:void(0)"
            @click="onClickTermsOfUse(TERMS_OF_USE.SERVICE_AGREEMENT)"
          >{{ '이용약관' | translate }}</a>
          <a
            href="javascript:void(0)"
            @click="onClickTermsOfUse(TERMS_OF_USE.PRIVATE_POLICY)"
          >{{ '개인정보처리방침' | translate }}</a>
          <a
            href="javascript:void(0)"
            @click="onClickTermsOfUse(TERMS_OF_USE.LOCATION_AGREEMENT)"
          >{{ '위치정보이용약관' | translate }}</a><!--임대회원만 노출 됨-->
        </div>
        <div
          class="member-retired"
          @click="memberRetired"
        >
          {{ '회원탈퇴' | translate }}
        </div>
        <div class="mx-auto">
          {{ 'Version: ' }}{{ VERSION }}
        </div>
      </v-footer>
    </slot>
  </v-navigation-drawer>
</template>

<script>
import UserProfile from "@/components/app/drawer/UserProfile";
import UserAvatar from "@/components/app/drawer/UserAvater";
import NavItems from "@/components/app/drawer/NavItems";
import VuexProps from "@/framework/vuex/VuexProps";
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
import {PATH} from "@/constants/index";

export default {
  name: "MyNavigationDrawer",
  components: {UserAvatar, UserProfile},
  mixins: [NavItems, VuexProps, ModalVuetifyUser],
  props: {
    value: {type: Boolean, default: false},
    nav: {type: String, default: undefined}
  },
  data() {
    return {
      active: this.value,
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.active = newValue;
    },

    active() {
      // 메뉴가 열리면 BackGuard를 설정합니다.
      if (this.active) {
        this.backGuard.push(() => {
          this.active = false;
        });
      } else { // 메뉴가 닫히면 backGuard를 비워줍니다..
        this.backGuard.pop();
      }
    },
  },
  methods: {
    onDrawerActiveStateChanged(active) {
      this.$emit('input', active);
    },

    onNavItemClicked(nav) {
      this.backGuard.pop();
      if (nav.push) {
        this.routerManager.push({name: nav.routeName});
      } else {
        this.$emit('update:nav', nav.routeName);
      }
      this.active = false;
    },

    onClickNotice(sortCd) {
      this.backGuard.pop();
      this.routerManager.push({name: 'WelcomeNoticeList', params: {sortCd: sortCd}});
    },

    logout() {
      this.backGuard.pop(); // 메뉴로 라우터 이동 후 다시 메인으로 돌아올 때 Nav 가 닫혀있기 때문에 설정해준 backGuard 를 제거해줍니다.
      this.$userManager.logout();
    },

    memberRetired() {
      this.active = false
      //ToDo 회월 탈퇴
        this.openModalVuetify(MODAL_VUETIFY_NAMES.MEMBER.RETIRED, {
            // persistent: true,
            // scrollable: true,
            maxWidth: "400px",
          }, {},
          (data) => {
            this.sendRequestRetired(data)
          }, MODAL_VUETIFY_NAMES.MEMBER.RETIRED,
        );

    },

    sendRequestRetired(data) {
      const param = {
        ...data,
        userId: this.user?.userId
      }

      this.$request(PATH.USER_RETIRED)
        .setObject(param)
        .enqueue()
        .then(res => {
          this.$snackbarSuccess(this.$translate('탈퇴처리 완료했습니다.'));
          this.$userManager.logout();
        })
    },


    /**
     * 이용약관/개인정보처리방침/위치정보이용약관 클릭시 호출
     */
    onClickTermsOfUse(which) {
      this.routerManager.push({name: 'TermsOfUse', params: {which: which}})
    },
  },
}
</script>

<style scoped>
.navigation-drawer-notice-item {
  border-bottom: 1px solid rgb(221, 221, 221);
  font-size: 1rem;
  padding: 10px 0 6px 0;
  font-weight: 600;
  color: #333;
  text-align: left;
}

.navigation-drawer-menu-item {
  display: inline-block;
  font-size: 1rem;
  padding: 10px 0 6px 0;
  font-weight: 600;
  color: #333;
  text-align: left;
}

.member-retired {
  background: #e3e3e3;
  width: 100%;
  cursor: pointer;
  user-select: none;
  padding: 0.25rem;
  font-weight: bold;
}
.policy {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
.policy a{
  width: 100%;
  color: #0c98fe;
  margin: 8px 0;
  text-decoration-line: none;
}
</style>