<template>
  <v-card class="overflow-hidden">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
      >
        {{ '검색 필터' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <!--{{ '닫기' | translate }}-->
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>


    <v-card-text
      style="max-height: calc(90vh - 58px - 50px)"
      class="overflow-y-auto overflow-x-hidden"
    >
      <v-row
        justify="center"
        class="align-center"
      >
        <v-col cols="12">
          <j-period-picker
            :date-end.sync="state.endDate"
            :date-start.sync="state.startDate"
            class="mx-3 mb-4"
            :start-label="'사용기간 시작일' | translate"
            :end-label="'사용기간 종료일' | translate"
            disable-future-start
            disable-future-end
          >
            <template #mid>
              <div class="mb-1 mr-3 ml-4">
                ~
              </div>
            </template>
          </j-period-picker>
        </v-col>
      </v-row>
   
      <v-row />
    </v-card-text>
    <!--      <v-divider />-->
    <v-card-actions
      class="ma-0 mt-2 pa-0"
    >
      <!--        <v-spacer />-->
      <v-btn
        text
        class="button__100per radius__None"
        @click="onClickApply"
      >
        {{ '적용' | translate }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import CodeOptions from "@/components/mixins/CodeOptions";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import JPeriodPicker from "@/assets/plugins/mps-vuetify-compat/pickers/JPeriodPicker";
    import {dateHelper} from "@/assets/plugins/mps-common/time/date-helper";


    export default {
        name: "PrinterDailyUsageFilterModal",
        components: {JPeriodPicker},
        mixins: [CodeOptions, ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                state: {},
                historyId: undefined,
            };
        },
        computed: {
            _state() {
                return {
                  startDate: this.state.startDate,
                    endDate: this.state.endDate,

                }
            },

            /**
             * 필터된 아이템의 이름 배열을 반환합니다.
             */
            filterNameList() {
                const arr = [];
                let dateStartText = null;
                let dateEndText = null;

                // 시작일
                if (!this.$isEmpty(this.state.startDate)) {
                  dateStartText = this.$date(this.state.startDate);
                }

                // 종료일
                if (!this.$isNOU(this.state.endDate)) {
                  dateEndText = this.$date(this.state.endDate);
                }

                if (!this.$isNOU(dateStartText) || !this.$isNOU(dateEndText)) {
                  arr.push({text: (dateStartText || "") + " ~ " + (dateEndText || "")});
                }


                return arr;
            },
        },
        mounted() {
            if (this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
                this.state = this.getDefaultState();
            } else {
                this.state = this.getState() || this.getDefaultState();
            }

            this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장


        },
        methods: {
            getDefaultState() {
                return {
                  startDate:  this.$now().startOf('day').minus({ month: 3 }),
                    endDate: this.$now().endOf("day"),

                }
            },

            onClickClose() {
                this.close();
            },

            onClickApply() {
                this.setState(this._state);
                this.callAndClose({state: this._state, filterNameList: this.filterNameList});
            },
        }
    }
</script>

<!--<style scoped src="./mps-sheet/mps-sheet.css"></style>-->

<style scoped>
    >>> .v-dialog {
        overflow: hidden !important;
    }

    >>> .v-select__selections > input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-select__selections > input:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-dialog > .v-card > .v-card__text {
        padding: 0 24px 0 !important;
    }

    >>> .v-item-group {
        flex: 1;
    }

    >>> .v-btn-toggle > .v-btn.v-btn {
        flex: 1 !important;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
    }
</style>
