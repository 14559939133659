<template>
  <v-app
    id="app"
    style="height: 100vh; overflow-y: hidden;"
  >
    <transition
      :name="transitionName"
    >
      <!--이 어플리케이션의 첫번째 라우트 입니다. 화면마다 자식 라우트를 가질수도 있습니다. 대표적으로 Main 화면의 경우 자식 라우트를 가지고 있습니다.-->
      <!--방문한 라우트는 인스턴스를 유지합니다.-->
      <router-view style="height: 100%;" />
    </transition>
    <modal-vuetify-modal-layer-legacy />
    <dialog-overlay />
    <snackbar-overlay />
    <loading-overlay-layer-legacy />
    <!-- Block Overlay 등록 -->
    <block-overlay-layer-legacy />
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #333;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/*SLIDE ENTER*/
.slide-in-enter-active,
.slide-in-leave-active {
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease;
}

.slide-in-enter {
  transform: translateX(100%);
}

.slide-in-leave-to {
  transform: translateX(-100%);
  /*transform: scale(0.95);*/
}

/*SLIDE EXIT*/
.slide-out-enter-active,
.slide-out-leave-active {
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease;
}

.slide-out-enter {
  transform: translateX(-100%);
}

.slide-out-leave-to {
  transform: translateX(100%);
}

/*!*EXCHANGE*!*/
/*.exchange-enter-active,*/
/*.exchange-leave-active {*/
/*    transition-duration: 0.15s;*/
/*    transition-property: all;*/
/*    transition-timing-function: ease;*/
/*}*/

/*.exchange-enter,*/
/*.exchange-leave {*/
/*    opacity: 0;*/
/*}*/
</style>
<script>
import DialogOverlay from "@/assets/plugins/mps-dialog/DialogOverlay";
import NativeMixin from "@/assets/plugins/mps-mobile-native-bridge/NativeMixin";
import ModalVuetifyModalLayerLegacy from "@/assets/plugins/vuetify-modal/ModalVuetifyModalLayerLegacy";
import LoadingOverlayLayerLegacy from "@/assets/plugins/loading-overlay/LoadingOverlayLayerLegacy";
import BlockOverlayLayerLegacy from "@/assets/plugins/block-overlay/BlockOverlayLayerLegacy";
import {PATH} from "@/constants";

export default {
  metaInfo: {
    meta: [
      {
        name: 'viewport',
        vmid: 'viewport',
        content: 'width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0, viewport-fit=cover'
      }
    ],
  },
  components: {BlockOverlayLayerLegacy, LoadingOverlayLayerLegacy, ModalVuetifyModalLayerLegacy, DialogOverlay},
  mixins: [NativeMixin],
  data() {
    return {
      transitionName: 'slide-in',
      verified: false,
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.name === 'Welcome') {
        this.transitionName = null;
      } else {
        if (this.historyManager.routerAction === 'PUSH') {
          this.transitionName = 'slide-in';
        } else if (this.historyManager.routerAction === 'BACK') {
          this.transitionName = 'slide-out';
        } else {
          this.transitionName = 'exchange';
        }
      }

      this.requestUseLog()
    }
  },
  mounted() {
    // 앱 초기 실행시 지연시간 없이 알림 메시지를 보여주기 위한 로직
    // this.showDialogImmediatly();
    // this.$appSetting.mounted = true;
  },

  updated() {
    this.loadServerVersion()
  },
  methods: {
    /**
     * 로그 API 호출 // 공지사항 조회 기능도 겸 (모바일 공지기능은 아직 없음)
     */
    async requestUseLog() {
      let logModel = null
      if (this.user?.userId) {
        logModel = {
          register: this.user.userId,
          content: 'path: ' + this.$route.path,
          logType: 'M_USER_USE_LOG',
          crud: 'R',
          rentalcompanyId: this.user.rentalcompanyId
        }
        const res = await this.$request(PATH.BOARD.NOTICE.DISPLAY).setObject({
          logModel: logModel,
          countryCode: this.$userManager.countryCode
        }).enqueue()
      }

    },
    async loadServerVersion() {
      const res = await this.$request(PATH.PUBLIC.SERVER_VERSION)
        .setObject({frontendType: 'M'})
        .enqueue()
      const data = res.data[0]
      const newVersion = [data.major | 0, data.minor | 0, data.patch | 0]
      if (this.checkValidVersion(newVersion)) this.reloadDueToNewVersion()
    },

    checkValidVersion(newVersion) {
      if (
        !process ||
        !process.env ||
        !process.env.PACKAGE_VERSION ||
        !newVersion ||
        newVersion.length <= 0
      ) {
        console.error('Check version Failed!')
        console.error('process.env ::', process.env)
        console.error('newVersion ::', newVersion)
        return false
      }
      const currentVersion = process.env.PACKAGE_VERSION.split('.')
      const length = currentVersion.length
      if (length <= 0) return false
      for (let i = 0; i < length; i++) {
        const curV = i < currentVersion.length ? Number(currentVersion[i]) : 0
        const serV = i < newVersion.length ? Number(newVersion[i]) : 0
        if (serV > curV) {
          return true
        } else if (curV > serV) {
          return false
        }
      }
      return false
    },

    reloadDueToNewVersion() {
      console.info('Server version is higher then current version.')
      alert(this.$translate('신규버전이 감지되어 화면을 새로고침합니다.'))
      console.info('Refresh browser...')
      window.location.reload(true)
    },
  },
}
</script>
