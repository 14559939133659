<template>
  <v-app>
    <my-tool-bar
      :action-mode="actionMode"
      :title="titleComputed"
      :closable="true"
      @click:close="$emit('update:dialog', false)"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          v-model="options.searchKeyword"
          :placeholder="'영업점/주소' | translate"
          @close="actionMode = false"
          @search="onSearchClicked"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="actionMode = true"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <m-layout>
      <v-layout class="fill-height">
        <v-container
          ref="container"
          class="overflow-y-auto pa-0 ma-0 mx-auto"
          style="height: calc(100vh - 56px);"
        >
          <template v-if="!loading">
            <template v-if="items.length > 0">
              <branch-list-item-simple-card
                :items="items"
                @pick="pick => $emit('pick', pick)"
              />
            </template>
            <template v-else>
              <empty-data :text="'영업점이 없습니다' | translate" />
            </template>
          </template>
          <template v-else>
            <progress-loading />
          </template>
        </v-container>
      </v-layout>
    </m-layout>
  </v-app>
</template>
<script>
    import {PATH} from "@/constants";
    import MLayout from "@/components/MLayout";
    import Branch from "@/model/branch";
    import BranchListItemSimpleCard from "@/components/items/branch/BranchListItemSimpleCard";
    import EmptyData from "@/components/part/EmptyData";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";

    export default {
        name: "MFormBranchFullDialog",
        components: {
            ToolbarSearchAction,
            MyToolBar, ProgressLoading, EmptyData, BranchListItemSimpleCard, MLayout
        },
        props: {
            title: null,
            dialog: null,
            accountId: null,
            accountBranchId: null,
        },
        data() {
            return {
                items: [],
                loading: false,
                options: {
                    searchKeyword: null,
                },
                actionMode: false,
            }
        },
        computed: {
            titleComputed() {
                return this.title || this.$translate("영업점 선택");
            },
        },
        watch: {
            accountId() {
                this.loadItems();
            },
            accountBranchId() {
                this.loadItems();
            },
        },
        created() {
        },
        mounted() {
            this.loadItems();
        },
        methods: {
            /**
             *
             */
            async loadItems(loading = true) {
                if (!this.accountId) return;
                if (this.loading) return;
                // 로딩중으로 표시
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.BRANCH.LIST)
                        .setObject({
                            accountId: this.accountId,
                            accountBranchId: this.accountBranchId,
                            searchKeyword: this.options.searchKeyword
                        })
                            .wait(300)
                        .enqueue();
                    this.items = response.data.map(item => new Branch(item));
                } catch (e) {

                }
                this.loading = false;
            },

            /**
             * 툴바 검색 버튼이 눌렸습니다.
             * @param keyword
             */
            onSearchClicked(keyword) {
                this.options.searchKeyword = keyword;
                this.loadItems();
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>