<script>
    import MockingWindowManager from "@/assets/plugins/mps-window-mobile/mocking-window-manager";
    import ScrollStateManager from "@/assets/plugins/mps-window-mobile/ScrollStateManager";

    export default {
        name: "WindowComponent",
        mixins: [ScrollStateManager],
        data() {
            return {
                isWindowComponent: true,
                // params: undefined,
                // title: undefined
            }
        },

        computed: {
            title() {
                return this.$route.meta.title;
            },
            params() {
                return this.$props;
            },
            windowManager() {
                return new MockingWindowManager(this, () => this.saveScrollState(this.$el));
            },
        },

        created() {
            console.log(this.$options.name + ':created()');
            // if (!this.$nativeBridge.isNativeConnected);
        },


        mounted() {
            console.log(this.$options.name + ':mounted()');
        },

        updated() {
            console.log(this.$options.name + ':updated()');
        },

        beforeDestroy() {
            console.log(this.$options.name + ':beforeDestroy()');
        },

        destroyed() {
            console.log(this.$options.name + ':destroyed()');
        },

        // activated() {
        //     console.log(this.$options.name + ':activated()');
        // },

        unmounted() {
            console.log(this.$options.name + ':unmounted()');
        }
    }
</script>

<style scoped>

</style>
