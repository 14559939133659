<script>
/**
 *
 */
    export default {
        name: "KeepProps",
        data() {
            return {
                innerKeepProps: {},
            }
        },
        computed: {
            keepProps() {
                return this.innerKeepProps[this.$route.name] || {};
            }
        },
        watch: {
            "$route.name"() {
                console.log("keepProps watch $route.name", this.$route.name);

                this.saveKeepProps();
            }
        },
        created() {
            this.saveKeepProps();
        },
        methods: {
            /**
             * 라우터 변경시 상태를 저장합니다.
             */
            saveKeepProps() {
                if (this.doKeepProps()) { // keepProps 를 사용하는 라우터이면
                    if (typeof this.innerKeepProps[this.$route.name] !== 'object') { // keepProps 가 없을 경우 초기화
                        this.$set(this.innerKeepProps, this.$route.name, {});
                        this.$cloneReactive(this.getDefaultKeepProps(), this.innerKeepProps[this.$route.name]);
                    } else { // 기존 keepProps 가 있을경우
                        this.innerKeepProps[this.$route.name] = {...this.innerKeepProps[this.$route.name], ...this.getInitDataWhenActivatedKeepProps()}; // 덮어쓰기
                    }
                }
            },

            /**
             * @override 이 라우터가 keepProps 를 사용할건지 결정합니다.
             */
            doKeepProps() {
                return true;
            },

            /**
             * @override 초기화 데이터 입니다.
             */
            getDefaultKeepProps() {
                return {};
            },

            /**
             * @override keepProps 가 활성화될때 초기화할 데이터를 Object 로 반환합니다.
             */
            getInitDataWhenActivatedKeepProps() {
                return {};
            },

            /**
             * 현재 설정된 라우터의 keepProps 를 초기화 합니다.
             */
            resetKeepProps() {
                this.innerKeepProps[this.$route.name] && this.$cloneReactiveNew(this.getDefaultKeepProps(), this.innerKeepProps[this.$route.name]);
            },
        },
    }
</script>
