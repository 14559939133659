<script>
    import MBaseFormPicker from "./MBaseFormPicker";

    export default {
        name: "MFormDatePicker",
        extends: MBaseFormPicker,
        props: {
        },
        data() {
            return {
                date: undefined,
                innerDate: undefined,
            }
        },
        computed: {
            displayDate() {
                return this.$date(this.date);
            },
        },
        watch: {
            'innerDate'(newDate) {
                this.updateModel();
            },
            'model'(newModel) {
                this.sanitize();
            }
        },
        created() {
            this.sanitize();
        },
        methods: {
            updateModel() {
                this.model = this.$parseDate(this.innerDate);
            },
            sanitize() {
                const parsed = this.$parseDate(this.model);
                this.date = !!parsed ? parsed.toISODate() : undefined;
            }
        }
    }
</script>
