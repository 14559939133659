<script>
    export default {
        name: 'PageableComponent',
        props: {
            paging: {type: Boolean, default: false},
            page: {type: Number, default: 1},
            pageOfStart: {type: Number, default: 1},
            countPerPage: {type: Number, default: 15},
            endOfData: {type: Boolean, default: false}
        },
        computed: {
            canNext() {
                return this.paging && !this.endOfData;
            }
        }
    }
</script>