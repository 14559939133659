export default class Account {

    _account;

    constructor(account) {
        this._account = account;
    }

    get raw() {
        return this._account;
    }

}