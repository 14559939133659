<template>
  <div>
    <v-row class="mps-form-wrapper">
      <v-col
        cols="4"
        class="mps-form-label__wrapper"
      >
        <label class="mps-form-label">{{ label }}</label>
      </v-col>
      <v-col
        cols="8"
        class="mps-form-field__wrapper"
      >
        <template v-if="displayable && !inLoading && inEditMode">
          <div class="in-edit-mode">
            <select
              v-model="innerData"
              class="form-select"
              :disabled="disabled"
              :style="styleObject"
            >
              <option
                v-for="item in filteredItems"
                :key="item.codeId"
                :value="item.codeKey"
              >
                {{ item.codeValue | translate }}
              </option>
            </select>
          </div>
        </template>
        <template v-else>
          <div class="in-view-mode">
            <div
              class="form-textarea__fieldset"
              :style="styleObject"
            >
              <textarea
                class="form-textarea"
                :value="display | translate"
                disabled
                :rows="1"
                :placeholder="placeholder"
                :rules="rules"
                type="text"
              />
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import MFormCodePicker from "@/assets/plugins/mps-form/providers/MFormCodePicker";

    export default {
        name: "MFormMpsCodePicker",
        extends: MFormCodePicker,
        mixins: [],
        computed: {
            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },
        }
    }
</script>