import userManager from "@/assets/plugins/mps-common/user/user-manager";
import iso4217 from "@/assets/plugins/mps-common/formatters/iso4217";
import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

/**
 * 숫자 관련 포맷 변환을 지원합니다.
 */
class NumberHelper {

    constructor() {

    }

    _getValidCurrency() {
        return iso4217.find(item => item.countryCode === userManager.user.countryCode);
    }

    formatCurrency(value) {
        const valid = this._getValidCurrency();
        if (!valid) return this.formatNumber(value);

        if (isNOU(value)) value = 0;
        if (isNaN(Number(value))) value = 0;
        const currency = valid.currency;
        if (!!currency) {
            return new Intl.NumberFormat(userManager.user.languageCode, { style: 'currency', currency: currency, currencyDisplay: "symbol" }).format(value);
        } else {
            return new Intl.NumberFormat().format(value);
        }
    }

    formatCurrencyName(value) {
        const valid = this._getValidCurrency();
        if (!valid) return this.formatNumber(value);

        if (isNOU(value)) value = 0;
        if (isNaN(Number(value))) value = 0;
        const currency = valid.currency;
        if (!!currency) {
            return new Intl.NumberFormat(userManager.user.languageCode, { style: 'currency', currency: currency, currencyDisplay: "name" }).format(value);
        } else {
            return new Intl.NumberFormat().format(value);
        }
    }

    formatNumber(value) {
        if (isNOU(value)) value = 0;
        if (isNaN(Number(value))) value = 0;
        return new Intl.NumberFormat().format(value);
    }

}

export const numberHelper = new NumberHelper();
