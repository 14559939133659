<script>
    import {NATIVE_EVENT, Setting} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
    import {PATH} from "@/constants";
    import VuexProps from "@/framework/vuex/VuexProps";
    import AlertDialogBuilder from "@/assets/plugins/mps-dialog/AlertDialogBuilder";
    import {vm} from "@/main";
    import Work from "@/model/work";
    import {ACTION_HIDE_DIALOG} from "@/assets/plugins/mps-dialog/constants";

    export default {
        name: "NativeMixin",
        mixins: [VuexProps],
        data() {
            return {
                connected: false
            }
        },
        watch: {
            /**
             * 라우트 변경시 변경된 URL 을 네이티브에 알립니다.
             * @param to
             * @param from
             */
            '$route'(to, from) {
                this.$nativeBridge.putUrl(to.path);
            }
        },
        created() {
            // Native 연결 이벤트를 수신합니다. 1회성 이벤트.
            this.$nativeEventBus.$once(NATIVE_EVENT.ON_CONNECTED, () => {
                this.connected = true;
                this.notifyToNative();
            });
        },
        mounted() {
            // DialogOverlay 가 mounted 된 후에 리스너 콜백을 등록합니다.
            // 알림이 발생하면 다이알로그를 보여줍니다.
            this.$nativeEventBus.$on(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.showDialogNotification);
        },
        beforeDestroy() {
            this.$nativeEventBus.$off(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.showDialogNotification);
        },
        methods: {
            notifyToNative() {
                const urlUpdateLocation = PATH.BASE_URL + PATH.NATIVE_COMPAT.UPDATE_LOCATION;
                const urlUpdateToken = PATH.BASE_URL + PATH.NATIVE_COMPAT.REGISTER_FIREBASE_TOKEN;
                const urlDeleteToken = PATH.BASE_URL + PATH.NATIVE_COMPAT.DELETE_FIREBASE_TOKEN;
                this.$nativeBridge.putSetting(new Setting(urlUpdateLocation, urlUpdateToken, urlDeleteToken));
                this.$nativeBridge.putUser(this.$userManager.user);
            },
            showDialogNotification(data) {
                this.$mps_dialog_event_bus.$emit(ACTION_HIDE_DIALOG);

                setTimeout(() => {
                    //Push 메시지에 escape 문자가 있는 경우 이부분을 json.parse에서 오류가 나지 않게 처리
                    var fixedEscapedData = data.replace(/\r/g, '').replace(/\n/g, '\\n');
                    const payload = JSON.parse(fixedEscapedData);
                    switch (payload.domain) {
                        case 'WORK':
                            // 작업 상태 변경 다이얼로그
                            this.showDialogWork(payload);
                            break;
                        case 'WORK_SEQUENCE':
                            // 작업 순서 변경 다이얼로그
                            this.showDialogWorkSequence(payload);
                            break;
                    }
                }, 100);
            },
            showDialogWork(payload) {
                let message = payload.content;
                let title = payload.title;
                const builder = new AlertDialogBuilder(this.$mps_dialog_event_bus);
                builder
                    .title(title)
                    .message(message)
                    .buttonNegative(this.$translate('상세보기'), () => {
                        this.routerManager.push({name: "AsDetailForPush", params: {workorderId: payload.workorderId,}});
                    });
                // 작업 상태에 따른 다이나믹 액션 버튼을 만듭니다.
                switch (payload.workorderState) {
                    case "1":
                    case "2":
                        builder.buttonPositive(this.$translate('수락'), () => {
                            this.$request(PATH.WORK_ORDER.DETAIL)
                                .setObject({
                                    workorderId: payload.workorderId,
                                    asId: payload.asId,
                                })
                                .enqueue()
                                .then(res => {
                                    new Work(res).accept(); // 수락 기능 수행
                                });
                        });
                        break;
                    case "3":
                    case "4":
                    case "5":
                    case "6":
                        break;
                }
                builder
                    .build()
                    .show();
            },
            showDialogWorkSequence(payload) {
                let title = '작업 순서 변경';
                let message = '{0}님 작업 순서가 변경되었습니다.';
                this.$alert(this.$formatTranslation(message, payload.userFullname));
            },
        },
    }
</script>
