import ScrollStateManager from "./ScrollStateManager";

export default {
    install(Vue, options) {
        this.installComponents(Vue);
    },
    installComponents: function (Vue) {
        const components = {
            ScrollStateManager
        };
        for (let key in components) {
            Vue.component(key, components[key]);
        }
    }
}