<template>
  <div style="position:fixed; bottom: 0; left: 0; right: 0; width: 100%">
    <v-btn
      text
      class="button__100per"
      @click="$emit('click:apply')"
    >
      {{ text }}
    </v-btn>
  </div>
</template>

<script>
    export default {
        name: "DialogApplyButton",
        props: {
            text: {
                type: String,
                default: "Apply"
            }
        }
    }
</script>

<style scoped>

</style>