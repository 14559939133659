/**
 * javascript array 를 java 의 list 인터페이스처럼 사용할 수 있는 클래스입니다.
 */
import {isNOU} from "../../expansions/condition";

export default class ArrayList {

    _array = [];


    constructor(...items) {
        this.addAll(items);
    }

    get size() {
        return this._array.length;
    }

    has(item) {
        return this._array.indexOf(item) !== -1;
    }

    add(item) {
        if (isNOU(item)) throw new Error(`'item' must be not null or undefined.`);
        if (this.has(item)) return;
        this._array.push(item);
    }

    addAll(...items) {
        if (isNOU(items)) throw new Error(`'items' must be not null or undefined.`);
        if (!Array.isArray(items)) throw new Error(`'items' must be an Array(built-in)`);
        items.forEach((e) => this.add(e));
    }

    remove(item) {
        if (isNOU(item)) throw new Error(`'item' must be not null or undefined.`);
        if (!this.has(item)) return;
        const index = this._array.indexOf(item);
        return this._array.splice(index, 1);
    }

}
