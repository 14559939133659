<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required, 'required-blue': !required && requiredBlue}"
    >
      <div class="mobile-input-wrap-title">{{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        > ⓘ </button></div>
      <div
        class="mobile-input-wrap-input"
      >
        <printer-form-autocomplete
          :display-name="contractContext.printerModelName"
          :title="contractContext.printerModelName"
          :printer-model-name="contractContext.printerModelName"
          :account-id="contractContext.accountId"
          :unique-id="contractContext.accountId"
          is-monitoring-printer
          whether-load-printers
          whether-load-printer-models
          whether-load-code-models
          :auto-select="false"
          :icon-yn="false"
          @change="updatePrinter"
        />
      </div>
    </label>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";
import PrinterFormAutocomplete from "@/components/preset/autocomplete/PrinterFormAutocomplete";


export default {
  name: "MobilePrinterAutoComplete",
  components: {PrinterFormAutocomplete},
  extends: MobileBaseInput,
  props: {
    contractContext: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    updatePrinter(item) {
      this.$emit('selected',item)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-input{
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;
  &-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title{
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;
      flex: 2;
      &-btn{
        color: #0c98fe;
      }
    }
    &-input{
      flex: 3;
      &-sublabel{
        overflow: auto;
        color: #6e6e6e;
        margin-right: 6px;
        &:after{
          content: ':';
        }
      }
      select{
        padding: 0.25rem;
        border: 1px solid #dddddd;
        text-align: right;
        width: 100%;
        flex: 1;
        &:focus{
          outline: none;
        }
      }
      &-unit{
        overflow: auto;
        color: #6e6e6e;
        margin-left: 6px;
      }
    }
    &.required:before{
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }
    &.required-blue:before{
      content: '*';
      color: #0000ff;
      position: absolute;
      left: -3px;
    }
  }

  &-description{
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0 0.25rem;
    &:before{
      content: 'ⓘ';
    }
  }
}
</style>