/**
 * 백가드를 설정하면 디바이스나 웹에서 뒤로가기 클릭시 라우터 이동보다 백가드에 설정된 콜백함수를 호출하게 됩니다.
 * 백가드에 저장된 콜백함수가 더 이상 존재하지 않으면 라우터 이동이 이뤄집니다.
 */
import {historyManager} from "@/assets/plugins/mps-common/history/history-manager";

class BackGuard {
    /**
     * 히스토리의 마지막 스택이 BackGuard 일 경우 히스토리에서 backGuard 를 하나 제거합니다.
     */
    pop() {
        historyManager.removeBackGuard();
    }

    /**
     * callback 을 historyManager Stack 에 추가합니다.
     */
    push(callback) {
        historyManager.addBackGuard(callback);
    }
}


const backGuard = new BackGuard();

export default backGuard;