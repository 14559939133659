<template>
  <v-btn-toggle
    v-model="selected"
    background-color="white"
    class="defined-period-picker d-flex justify-space-between text--black"
    color="primary"
    dense
    tile
  >
    <v-btn
      v-for="(item,index) in items"
      :key="index"
      active-class="white--text accent"
      class="px-0"
      tile
      :disabled="selected === index"
    >
      <span>{{ item.name }}</span>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
    import {DateTime} from "luxon"
    import MBaseDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/MBaseDatePicker";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

    /**
     * 정의된 기간 선택기 입니다.
     */
    export default {
        name: "DefinedPeriodPicker",
        mixins: [MBaseDatePicker],
        props: {
            dateStart: {
                type: [String, Date, DateTime],
                default: undefined
            },
            dateEnd: {
                type: [String, Date, DateTime],
                default: undefined
            },
            buttonIndex: {
                type: [Number],
                default: -1,
            },
        },
        data() {
            return {
                selected: -1,
                date: {
                    start: undefined,
                    end: undefined,
                },
                items: [
                    {name: this.$translate('당일')},
                    {name: this.$translate('1주일')},
                    {name: this.$translate('1개월')},
                    {name: this.$translate('6개월')},
                    {name: this.$translate('전체')},
                ]
            }
        },
        /**
         * [dateStart, dateEnd]
         * 외부에서 전달한 prop 은 날짜 계산이 불가능한 객체 또는 문자일 수 있습니다.
         * luxonize 함수로 날짜 계산이 가능한 luxon 객체로 변환합니다.
         * 변환 후 start 와 end 를 특정 기간에 해당되는지 평가하여 버튼 상태를 변경합니다.
         */
        watch: {
            dateStart(newDate, oldDate) {
                this.date.start = this.luxonize(newDate);
                this.evaluatePeriod();
            },
            dateEnd(newDate, oldDate) {
                this.date.end = this.luxonize(newDate);
                this.evaluatePeriod();
            },
            /**
             * * 버튼 상태가 변경되면, 해당 버튼 인덱스에 맞는 start 와 end 로 계산됩니다.
             * 그리고 곧바로 외부에 이벤트로 알립니다.
             * @param newIndex
             * @param oldIndex
             */
            selected(newIndex, oldIndex) {
                this.computePeriod(newIndex);
                this.notifyDate();
                this.$emit('update:buttonIndex', this.selected);
            }
        },
        created() {
            this.selected = this.buttonIndex;
        },
        methods: {
            evaluatePeriod() {
                const start = this.$parseDate(this.dateStart);
                const end = this.$parseDate(this.dateEnd);

                // 전체
                if (isNOU(start) && isNOU(end)) {
                    this.selected = 4;
                }
                // 당일
                else if (start.equals(end) || start.endOf('day').equals(end)) {
                    this.selected = 0;
                }
                // 1주일
                else if (start.equals(end.minus({week: 1})) || start.endOf('day').equals(end.minus({week: 1}))) {
                    this.selected = 1;
                }
                // 1개월
                else if (start.equals(end.minus({month: 1})) || start.endOf('day').equals(end.minus({month: 1}))) {
                    this.selected = 2;
                }
                // 6개월
                else if (start.equals(end.minus({month: 6})) || start.endOf('day').equals(end.minus({month: 6}))) {
                    this.selected = 3;
                }
            },
            computePeriod(index) {
                let start = this.$now().startOf('day');
                const end = this.$now().endOf('day');
                switch (index) {
                    //당일
                    case 0:
                        this.date.start = start;
                        this.date.end = end;
                        break;
                    //1주일
                    case 1:
                        this.date.start = start.minus({week: 1});
                        this.date.end = end;
                        break;
                    //1개월
                    case 2:
                        this.date.start = start.minus({month: 1});
                        this.date.end = end;
                        break;
                    //6개월
                    case 3:
                        this.date.start = start.minus({month: 6});
                        this.date.end = end;
                        break;
                    //전체
                    case 4:
                        this.date.start = undefined;
                        this.date.end = undefined;
                        break;
                }
            },
            // onButtonClicked(index) {
            //     this.computePeriod(index);
            // },
            notifyDate() {
                if (this.date.start) {
                    this.$emit('update:dateStart', this.date.start.toISO());
                } else {
                    this.$emit('update:dateStart', undefined);
                }
                if (this.date.end) {
                    this.$emit('update:dateEnd', this.date.end.toISO());
                } else {
                    this.$emit('update:dateEnd', undefined);
                }
            }
        },
    }
</script>

<style scoped>
  .v-application .accent {
    background-color: #0C98FE !important;
    border-color: #0C98FE !important;
  }

  .defined-period-picker > button.v-btn.v-btn--disabled.white--text.accent {
    color: #FFFFFF !important;
    caret-color: #FFFFFF !important;
    background-color: #0C98FE !important;
    border-color: #0C98FE !important;
  }
</style>
