import {toFormattedDuration} from "@/assets/plugins/mps-common/time/duration-util";
import {isThresholdExceededOf} from "@/assets/plugins/mps-common/time/duration-common";

export default class Printer {

    _item;

    constructor(item) {
        this._item = item;
    }

    get raw() {
        return this._item;
    }

    get uniqueKey() {
        return this._item.printerContractId + "," + this._item.monitoringPrinterId;
    }

    get printerFullName() {
        return this._item.printerModelName + "[" + this._item.printerSerialNo + "]";
    }

    get printerName() {
        return this.raw.printerModelName;
    }

    get formattedCollectedDate() {
        return toFormattedDuration(this._item.uptDatetime);
    }

    get isInHeadOffice() {
        return this._item.accountId === 0;
    }

    get isThresholdExceeded() {
        return isThresholdExceededOf(this._item.uptDatetime);
    }

    get contractStateNameComputed() {
        return this.raw.contractState === "1" || this.raw.contractState === "Y" ? "계약중" : ""
    }

}
