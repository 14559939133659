<template>
  <v-card
    class="ma-0 boder-bottom"
    flat
    width="100%"
    style="padding: 10px 15px;"
    @click="onClick"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item>
      <v-list-item-content class="pa-0">
        <v-list-item-title class="text-left">
          {{ item.raw.branchName }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-left">
          {{ item.raw.address }} {{ item.raw.addressDetail }} ({{ item.raw.zipCode }})
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    export default {
        name: "BranchItemSimpleCard",
        components: {},
        props: {
            item: {type: Object, default: undefined},
        },
        computed: {
        },
        methods: {
          onClick() {
              this.$emit('pick', this.item.raw);
          },
        }
    }
</script>

<style scoped>
    .v-application .subtitle-2 {
        font-size: 0.875rem;
        font-weight: inherit !important;
        letter-spacing: 0.0071428571em !important;
        line-height: 1.375rem;
        font-family: "Roboto", sans-serif !important;
    }
</style>