import {get, put} from '../app/local-storage-helper';

const KEY_LANGUAGES = 'key_languages';

class TranslationManager {

    constructor() {
        this.load();
    }

    load() {
        const languages = get(KEY_LANGUAGES, []);
        this.pack = this.repack(languages);
    }

    save(languages) {
        this.pack = this.repack(languages);
        put(KEY_LANGUAGES, languages);
    }

    getPack() {
        return this.pack;
    }

    /**
     * 객체 배열을 키값 형태로 변경합니다.
     * @param languages
     * @returns {[]}
     */
    repack(languages) {
        let langPack = [];
        if (languages) {
            for (const e of languages) {
                langPack[e.key] = e.value.trim();
            }
        }
        return langPack;
    }

    translate(key, defaultValue) {
        // 키 유효성 검증
        if (key) {
            // 값 조회
            let value = this.pack[key];
            // 값 유효성 검증
            if (value !== undefined) {
                //반환
                return value;
            } else {
                return defaultValue ? defaultValue : key.split('_').join(' ');
            }
        } else {
            return defaultValue ? defaultValue : key;
        }
    }


}

const translationManager = new TranslationManager();

export {translationManager};