/**
 * 툴바 필터 버튼 컴포넌트입니다.
 */
export const FILTER_BUTTON_TYPE = {
    MAIN_AS: 'main_as',
    MAIN_ACCOUNT: 'main_account',
    MAIN_PRINTER: 'main_printer',
    MAIN_DEVICE: 'main_device',
    MAIN_PRINTER_DAILY: 'main_printer_daily',
    CONSULT_NOTE: 'consult_note',
};
