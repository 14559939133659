<template>
  <v-card class="overflow-hidden">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
      >
        {{ (editContext.printerInstallLogId == null ? '설치 내역 추가' : '설치 내역 수정') | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <!--{{ '닫기' | translate }}-->
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>
    <v-card-text
      style="max-height: calc(90vh - 58px - 50px)"
      class="overflow-y-auto overflow-x-hidden"
    >
      <v-row class="row-title">
        <v-col style="text-align: start;">
          {{ '설치일자' | translate }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <j-date-picker
            v-model="editContext.installDate"
            :placeholder="'설치일자' | translate"
            type="date"
            disable-future
          />
        </v-col>
      </v-row>
      <v-row class="row-title">
        <v-col style="text-align: start;">
          {{ '모델' | translate }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <printer-form-autocomplete
            :display-name="editContext.printerModelName"
            :title="editContext.printerModelName"
            :printer-model-name="editContext.printerModelName"
            :account-id="editContext.accountId"
            :unique-id="editContext.accountId"
            is-monitoring-printer
            whether-load-printers
            whether-load-printer-models
            whether-load-code-models
            :auto-select="false"
            :icon-yn="false"
            @change="updatePrinter"
          />
        </v-col>
      </v-row>
      <v-row class="row-title">
        <v-col style="text-align: start;">
          {{ '시리얼' | translate }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <input
            v-model="editContext.printerSerialNo"
            type="text"
            :title="editContext.printerSerialNo"
          >
        </v-col>
      </v-row>
      <v-row class="row-title mt-6">
        <v-col>
          {{ '초기 페이지' | translate }}
        </v-col>
      </v-row>
      <v-row class="row-title">
        <v-col style="text-align: start;">
          {{ '흑백' | translate }} A4
        </v-col>
        <v-col style="text-align: start;">
          {{ '컬러' | translate }} A4
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vue-numeric
            v-model="editContext.totalMonoPagecount"
            :max="99999999"
            :min="0"
          />
        </v-col>
        <v-col>
          <vue-numeric
            v-model="editContext.totalColorPagecount"
            :max="99999999"
            :min="0"
          />
        </v-col>
      </v-row>
      <v-row class="row-title">
        <v-col style="text-align: start;">
          {{ '흑백' | translate }} A3
        </v-col>
        <v-col style="text-align: start;">
          {{ '컬러' | translate }} A3
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vue-numeric
            v-model="editContext.totalMonoPagecountA3"
            :max="99999999"
            :min="0"
          />
        </v-col>
        <v-col>
          <vue-numeric
            v-model="editContext.totalColorPagecountA3"
            :max="99999999"
            :min="0"
          />
        </v-col>
      </v-row>
      <v-row class="row-title">
        <v-col style="text-align: start;">
          {{ '메모' | translate }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <textarea
            v-model="editContext.memo"
            cols="50"
            maxlength="3000"
            rows="3"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      class="ma-0 mt-2 pa-0"
    >
      <v-btn
        text
        class="button__100per radius__None"
        @click="onClickApply"
      >
        {{ '저장' | translate }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
import VSelectBackguard from "@/components/part/VSelectBackguard";
import JDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/JDatePicker";
import PrinterFormAutocomplete from "@/components/preset/autocomplete/PrinterFormAutocomplete";

export default {
  name: "PrinterInstallLogAddModal",
  components: {PrinterFormAutocomplete, JDatePicker},
  mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
  data() {
    return {
      editContext: {}
    };
  },
  computed: {},
  created() {
  },
  mounted() {
    this.editContext = this.propsData
  },
  methods: {
    onClickClose() {
      this.close();
    },

    onClickApply() {
      this.callAndClose(this.editContext);
    },

    updatePrinter(item) {
      if (!item) {
        this.editContext.printerModelName = null;
        this.editContext.printerModelId = null;
        this.editContext.monitoringPrinterId = null;
      }
      if (item.isPrinter) {
        this.editContext.printerModelName = item.raw.printerModelName;
        this.editContext.printerModelId = item.raw.printerModelId;
        this.editContext.monitoringPrinterId = item.raw.monitoringPrinterId;
        this.editContext.printerSerialNo = item.raw.printerSerialNo;
      } else if (item.isModel) {
        this.editContext.printerModelName = item.raw.printerModelName;
        this.editContext.printerModelId = item.raw.printerModelId;
        this.editContext.monitoringPrinterId = null;
        this.editContext.printerSerialNo = null;
      } else if (item.isCodeModel) {
        this.editContext.printerModelName = item.raw.codeValue;
        this.editContext.printerModelId = 0;
        this.editContext.monitoringPrinterId = null;
        this.editContext.printerSerialNo = null;
        this.editContext.companyModelId = item.raw.codeKey;
      }
    },
  }
}
</script>

<!--<style scoped src="./mps-sheet/mps-sheet.css"></style>-->

<style scoped>
>>> .v-dialog {
  overflow: hidden !important;
}

>>> .v-select__selections > input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

>>> .v-select__selections > input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

>>> .v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0 !important;
}

>>> .v-item-group {
  flex: 1;
}

>>> .v-btn-toggle > .v-btn.v-btn {
  flex: 1 !important;
}

>>> .v-btn:not(.v-btn--round).v-size--default {
  min-width: auto;
}

>>> .col {
  padding: 5px;
}
>>> .row-title {
  margin-top: 3px;
}
input {
  width: 100%;
  border-bottom: 1px solid #dddddd;
  padding: 4px 6px;
}

textarea{
  width: 100%;
  border: 1px solid #dddddd;
  padding: 4px 6px;
}
</style>