<template v-if="user">
  <v-list-item
    color="primary"
  >
    <v-list-item-content>
      <v-list-item-title class="d-inline-block white--text mb-1">
        {{ user.name }}
      </v-list-item-title>
      <v-list-item-subtitle class="d-inline-block white--text">
        ({{ user.rentalcompanyName }})
      </v-list-item-subtitle>
      <!--      <v-list-item-subtitle class="d-inline-block white&#45;&#45;text">-->
      <!--        ({{ user.roleTitle }})-->
      <!--      </v-list-item-subtitle>-->
    </v-list-item-content>
  </v-list-item>
</template>

<script>
    export default {
        name: "UserProfile",
        computed: {
        },
    }
</script>

<style scoped>

</style>
