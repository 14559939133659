<template>
  <v-row
    align="center"
    class="fill-height mx-0"
  >
    <div class="mx-auto">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
  </v-row>
</template>

<script>
    export default {
        name: "ProgressLoading"
    }
</script>

<style scoped>
</style>