import MultipartBuilder from "@/assets/plugins/mps-common/network/multipart-builder";
import HttpClient from "@/assets/plugins/mps-common/network/http-client";
import {PATH} from "@/constants";
import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
import {format} from "@/assets/plugins/mps-common/formatters/string-formatter";
import {isEmpty} from "@/assets/plugins/mps-common/symbols";

export const METHOD = {
    GET: 'get',
    POST: 'post',
};

const preconditions = require('preconditions').errr();


/**
 * HTTP 요청의 구성과 호출을 쉽게 하기위한 클래스입니다.
 */
export default class RequesterBuilder {

    _multipart = false;
    _method;
    _fragment;
    _params;
    _headers;
    _body;
    _files;
    _onComplete;
    _catchManually;
    _loading;
    _ms;

    constructor(baseUrl = PATH.BASE_URL) {
        if (baseUrl.endsWith('/')) {
            this.baseUrl = baseUrl;
        } else {
            throw new Error(`baseUrl must be end with slash '/'`);
        }
    }

    path(path, ...args) {
        if (args) {
            this._fragment = format(path, ...args);
        } else {
            this._fragment = path;
        }
        return this;
    }

    post() {
        this._method = 'post';
        return this;
    }

    get() {
        this._method = 'get';
        return this;
    }

    wait(ms) {
        this._ms = ms;
        return this;
    }

    loading() {
        this._loading = true;
        return this;
    }

    multipart() {
        this._multipart = true;
        return this;
    }

    appendParam(name, value) {
        if (!this._params) this._params = {};
        this._params[name] = value;
        return this;
    }

    setParams(params) {
        this._params = params;
        return this;
    }

    appendHeader(name, value) {
        if (!this._headers) this._headers = {};
        this._headers[name] = value;
        return this;
    }

    setHeaders(headers) {
        this._headers = headers;
        return this;
    }

    appendObject(name, object) {
        if (!this._body) this._body = [];
        this._body.push({name: name, value: object});
        return this;
    }

    setObject(object) {
        if (this._body) this._body.splice(0, this._body.length);
        return this.appendObject('body', object);
    }

    setFiles(name, files) {
        preconditions.shouldBeDefined(name);
        preconditions.shouldBeString(name);
        preconditions.shouldBeDefined(files);
        preconditions.shouldBeArray(files);
        if (!!this._files) this._files.splice(0, this._files.length);
        for (const file of files) this.appendFile(name, file);
        return this;
    }

    appendFile(name, file) {
        if (!this._files) this._files = [];
        this._files.push({name: name, value: file});
        return this;
    }

    appendFiles(name, files) {
        preconditions.shouldBeDefined(name);
        preconditions.shouldBeString(name);
        preconditions.shouldBeDefined(files);
        preconditions.shouldBeArray(files);
        if (files) for (const file of files) this.appendFile(name, file);
        return this;
    }


    appendDataUri(name, dataURI, filename) {
        if (isNOU(this._files)) this._files = [];
        if (!dataURI) return this;

        const file = this.dataURItoBlob(dataURI);
        this._files.push({name: name, value: file, filename: filename});
        return this;
    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded loader component to raw binary loader held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    onComplete(callback) {
        this._onComplete = callback;
        return this;
    }

    /**
     * 이 요청에 대한 에러를 직접 캐치할 수 있도록 합니다.
     * @returns {RequesterBuilder}
     */
    catch() {
        this._catchManually = true;
        return this;
    }

    /**
     * 작성된 요청 스펙을 빌드하고 요청합니다.
     * @returns {*}
     */
    enqueue() {
        // 명시적으로 POST, GET 이 선택되지 않았다면, body 존재 여부에 따라서 자동으로 구성합니다.
        if (!this._method) this._method = this._body ? METHOD.POST : METHOD.GET;
        if (!this._headers) {
            this._headers = {};
        }
        if (!this._params) {
            this._params = {};
        }
        // 원격 요청을 위한 설정.
        const config = {
            method: this._method,
            url: this._createUrl(), // fragment 를 붙인 전체 url
            headers: this._headers,
            params: this._params,
            data: this._createData(), // json / multipart 자동으로 구성
        };
        return new HttpClient().createPromise(config, this._onComplete, this._catchManually, this._loading, this._ms || 0);
    }

    _createUrl() {
        return this.baseUrl + this._fragment;
    }

    _createData() {
        // 멀티파트로 데이터를 구성해야 함.
        if (this._isMultipartNeeded()) {
            this._trimPairs(this._body); // 빈 바디 트림.
            this._trimPairs(this._files); // 빈 파일 데이터 트림.
            const builder = new MultipartBuilder();
            if (this._body) {
                for (const pair of this._body) {
                    builder.appendObject(pair.name, pair.value);
                }
            }
            if (this._files) {
                for (const pair of this._files) {
                    builder.appendFile(pair.name, pair.value, pair.filename);
                }
            }
            return builder.build();
        }
        // 멀티파트 필요없음.
        else if (this._method === METHOD.POST) {
            if (isNOU(this._body)) return {};
            const keys = Object.keys(this._body);
            return this._body[keys[0]].value;
        }
    }

    _trimPairs(array) {
        if (!array) return;
        let i = array.length;
        while (i--) {
            const pair = array[i];
            if (!pair || !pair.name || !pair.value) {
                array.splice(i, 1);
            }
        }
    }

    _isMultipartNeeded() {
        return this._multipart || (this._body && this._body.length > 1 || this._files && this._files.length > 0);
    }
}
