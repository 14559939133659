<template>
  <div
    ref="container"
    style="width: 100%; height: 100%; overflow-y: auto !important;"
  >
    <div
      v-for="(item, index) in innerListComputed"
      v-show="item.visible"
      :key="'as-list-' + itemKey ? byString(item.raw, itemKey + index) : index"
      style="width: 100%;"
      class="as-list-item"
    >
      <slot v-bind="{ item: item.raw, index }">
        Must nedded default slot
      </slot>
    </div>
    <infinite-loading
      v-if="!!fetch"
      :identifier="identifier"
      @infinite="infiniteHandler"
    />
  </div>
</template>

<script>
    import InfiniteItem from "@/assets/plugins/infinite-loader/infinite-item";

    export default {
        name: "VueInfiniteLoader",
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            fetch: {
                type: Function,
                default: undefined,
            },
            page: {
                type: Number,
                default: 1,
            },
            itemsPerPage: {
                type: Number,
                default: 10,
            },
            identifier: {
                type: Number,
                default: 0,
            },
            itemKey: {
                type: String,
                default: undefined,
            },
            itemText: {
                type: String,
                default: undefined,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            searchInput: {
                type: String,
                default: "",
            },
            valid: {
                type: Function,
                default: undefined,
            },
        },
        data() {
            return {
                ipage: this.page,
                innerItems: this.items,
                list: [],
                innerList: [],
                inLoading: false,
            };
        },
        computed: {
            innerListComputed() {
                const list = this.innerList.map(e => new InfiniteItem(e));
                if (this.searchInput && this.searchInput.trim() && this.itemText) {
                    list.forEach(e => {
                        const text = this.byString(e.raw, this.itemText);
                        if (text) {
                            e.visible = text.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1;
                        } else {
                            e.visible = false;
                        }
                    });
                }
                return list;
            },
        },
        watch: {
            page() {
                this.ipage = this.page;
            },

            items() {
                this.innerItems = this.items;
                this.overwrite();
            },

            /**
             * identifier 가 변경되면 목록을 초기화합니다.
             */
            identifier() {
                this.changeType();
            },

            innerList() {
                this.$emit('update:inner-list', this.innerList);
            },
        },
        mounted() {
            this.overwrite();
        },
        methods: {
            /**
             * 목록을 덮어씁니다.
             */
            overwrite() {
                if (this.inLoading) return;
                if (this.isNotEmptyArray(this.innerItems)) {
                    this.list = [];
                    this.innerList = [];
                    if (this.valid && typeof this.valid === 'function') {
                        this.innerItems.filter(e => this.valid(e)).forEach(e => this.innerList.push(e));
                    } else {
                        this.innerItems.forEach(e => this.innerList.push(e));
                    }
                    this.innerItems = [];
                } else {
                    this.innerList = [];
                }
            },

            isNotEmptyArray(arr) {
                return !!arr && Array.isArray(arr) && arr.length > 0;
            },

            /**
             * vue-infinite-loading 핸들러입니다.
             *
             * @infinite 이벤트가 발생할 때 이 메서드를 호출하세요.
             */
            infiniteHandler($state) {
                if (this.inLoading) return;

                if (!this.fetch) {
                    console.warn("Need fetch() method to infinite scroll!");
                    return;
                }

                this.inLoading = true;
                const interval = setInterval(() => {
                    if (this.loading) {
                        return;
                    } // 외부에서 로딩 중이면 펜딩상태
                    clearInterval(interval);
                    Promise.resolve(this.fetch(this.ipage, this.itemsPerPage))
                        .then((items) => {
                            this.inLoading = false;
                            if (items.length) {
                                this.ipage += 1;
                                this.list = items;
                                if (this.valid && typeof this.valid === 'function') {
                                    this.list.filter(e => this.valid(e)).forEach(e => this.innerList.push(e));
                                } else {
                                    this.list.forEach(e => this.innerList.push(e));
                                }
                                this.list = [];
                                $state.loaded();
                            } else {
                                $state.complete();
                            }
                        })
                        .catch(e => { // 데이터 로드중 에러가 발생하면 error 처리합니다.
                            this.inLoading = false;
                            console.error(e);
                            $state.error();
                        });
                }, 0);
            },

            /**
             * vue-infinite-loading 을 초기화합니다.
             */
            changeType() {
                this.ipage = this.page;
                this.list = [];
                this.innerList = [];
                this.innerItems = [];
            },
        },
    }
</script>

<style scoped>

</style>