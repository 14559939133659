<script>
    import MBaseFormElement from "@/assets/plugins/mps-form/providers/MBaseFormElement";

    export default {
        name: "MFormCombobox",
        extends: MBaseFormElement,
        computed: {
            items() {
                return this.options.items;
            },
            itemText() {
                return this.options.itemText;
            },
            itemValue() {
                return this.options.itemValue;
            }
        }
    }
</script>
