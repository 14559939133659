<template>
  <div>
    <div
      v-if="menu"
      style="opacity: 0; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 7;"
      @click.self.stop.prevent="onClickOutside"
    />
    <v-menu
      v-model="menu"
      close-on-click
      close-on-content-click
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
          height="22px"
          icon
          width="22px"
          @click="onClickButton"
          v-on="on"
        >
          <!--<v-icon>mdi-dots-vertical</v-icon>-->
          <img
            src="@/assets/images/main/svg/button_More.svg"
          >
        </v-btn>
      </template>
      <v-list class="text-left">
        <a
          v-if="hasPhone"
          :href="hasPhone ? 'tel:' + telNo: 'javascript:void(0)'"
          @click="onClickItem"
        >
          <v-list-item :class="{'grey-text':!hasPhone}">
            <v-list-item-title>{{ '전화걸기' | translate }}</v-list-item-title>
          </v-list-item>
        </a>
        <a
          v-if="hasPhone"
          :href="hasPhone ? 'sms:' + telNo : 'javascript:void(0)'"
          @click="onClickItem"
        >
          <v-list-item :class="{'grey-text':!hasPhone}">
            <v-list-item-title>{{ 'SMS 보내기' | translate }}</v-list-item-title>
          </v-list-item>
        </a>
        <a
          v-if="showMapBt"
          :href="canShowMap ? 'geo:' + lat+','+lng : 'javascript:void(0)'"
          @click="onClickItem"
        >
          <v-list-item
            :class="{'grey-text':!canShowMap}"
          >
            <v-list-item-title>
              {{ '지도보기' | translate }}
            </v-list-item-title>
          </v-list-item>
        </a>
        <a
          v-if="isAccountComp"
          @click="onClickeAs"
        >
          <v-list-item>
            <v-list-item-title>{{ 'A/S 등록' | translate }}</v-list-item-title>
          </v-list-item>
        </a>
        <a
          v-if="showPrinterBt"
          @click="onClickePrinterDaily"
        >
          <v-list-item>
            <v-list-item-title>{{ '일별사용량 보기' | translate }}</v-list-item-title>
          </v-list-item>
        </a>
        <a
          v-if="showPrinterBt && printer.printerModelType === '1'"
          @click="onTonerClicked"
        >
          <v-list-item>
            <v-list-item-title>{{ '토너 재고관리' | translate }}</v-list-item-title>
          </v-list-item>
        </a>
        <a
          v-if="isAccountComp"
          @click="onClickAddrCopy"
        >
          <v-list-item>
            <v-list-item-title>{{ '주소 복사' | translate }}</v-list-item-title>
          </v-list-item>
        </a>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
    import {PATH} from "@/constants";

    export default {
        name: "ContextMenu",
        props: {
            telNo: {
                type: String,
                default: undefined
            },
            location: {
                type: Object,
                default: undefined
            },
            isAccountComp:{
              type: Boolean,
              default: false
            },
          showMapBt:{
              type: Boolean,
              default: true
            },
          showPrinterBt:{
              type: Boolean,
              default: false
            },
          account: undefined,
          printer: undefined,
        },
        data() {
            return {
                menu: false,
            };
        },
        computed: {
            lat() {
                return !!this.location ? this.location.lat : 0;
            },
            lng() {
                return !!this.location ? this.location.lng : 0;
            },
            hasPhone() {
                return this.$isNotEmpty(this.telNo);
            },
            canShowMap() {
                if (this.$isNOU(this.lat) || this.$isNOU(this.lng)) return false;
                if (isNaN(this.lat) || isNaN(this.lng)) return false;
                if (this.lat === 0 && this.lng === 0) return false;
                return true;
            }
        },
        methods: {
            onClickOutside() {
                this.menu = false;
                this.backGuard.pop();
            },

            onClickButton() {
                this.backGuard.push(() => {
                    this.menu = false;
                });
            },

            onClickItem() {
                this.backGuard.pop()
            },
            onClickeAs() {
              this.routerManager.push({name: "AsRegister", params: {account: this.account}});
            },

            /**
             * 일별사용량
             */
            onClickePrinterDaily() {
              this.routerManager.push({name: "PrinterDailyUsage", params: {printer: this.printer}});
            },

            /**
             * 주소 클립보드 복사.
             */
            onClickAddrCopy(){
              if(!this.account) return

              const address = `${this.account.address || ''} ${this.account.addressDetail || ''}`
              this.$copyText(address).then(() => {
                this.$snackbarSuccess(this.$translate("복사되었습니다."));
              })
            },



            /**
             * 토너 재고 관리 데이터 조회
             */
            async loadTonerRefill() {
              if (
                  this.printer.printerModelType !== '1' ||
                  !this.printer.refillHistory ||
                  this.printer.refillHistory === 0
              ){
                return null
              }
              const result = await this.$request(PATH.TONER_REFILL.RETRIEVE)
                  .setObject({
                    printerTonerRefillId: this.printer.refillHistory,
                  })
                  .enqueue()
              return result
            },


            async onTonerClicked() {
              const res = await this.loadTonerRefill()

              if (!res) {
                this.$nextTick(() => {
                  this.$dialog()
                      .message(this.$translate('재고 관리중이 아닙니다. 재고관리를 시작하시겠습니까?'))
                      .buttonNegative(this.$translate('취소'))
                      .buttonPositive(this.$translate('이동'), () => {
                        this.routerManager.push({
                          name: "PrinterTonerManagementCreate",
                          params: {
                            printer: this.printer,
                          }
                        });
                      })
                      .build()
                      .show();
                })
              } else {
                this.routerManager.push({
                  name: "PrinterTonerManagementDetail",
                  params: {
                    monitoringPrinterId: this.printer.monitoringPrinterId,
                    refillHistory: this.printer.refillHistory,
                    printerContractId: this.printer.printerContractId,
                    isCreated: false
                  }
                });
              }
            },
        },
    }
</script>

<style scoped>
    .v-application a {
        text-decoration: none;
    }
</style>
