import {nativeBridge} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
import {appSetting} from "@/main";

// App.vue 가 mouted 되기 전에 도착한 알림 data 들을 담아둘 큐
const queue = [];
// 모바일 앱의 상태를 나타냅니다.
let isMobileActiveState = false;

const install = (Vue, options) => {
    // 이벤트버 스
    const eventBus = new Vue();
    nativeBridge.eventBus = eventBus;

    // 네이티브 브릿지
    Vue.prototype.$nativeBridge = nativeBridge;
    Vue.prototype.$nativeEventBus = eventBus;

    // 네이티브 어플리케이션이 초기화 되면 호출하는 함수.
    if (typeof window['jsTnBoxInit'] === 'undefined') {
        window['jsTnBoxInit'] = () => nativeBridge.onNativeReady();
    }

    if (typeof window['jsPushNotification'] === 'undefined') {
        window['jsPushNotification'] = (data) => {
            nativeBridge.onPushNotification(data);
        }
    }

    Vue.prototype.$getNotification = () => getNotification();
};

/**
 * 큐에 저장된 첫 번째 알림을 반환합니다. 없을 경우 null 을 반환합니다.
 */
const getNotification = () => {
    if (queue.length === 0) return null;
    return queue.splice(0, 1)[0];
};

/**
 * 일정 주기로 queue 에 담아진 메시지들을 전송합니다.
 */
function _notifyInterval() {
    setInterval(() => {
        _notifyNext();
    }, 1000 /* 1초 간격으로 알림을 전송합니다. */);
}

/**
 * 앱에 알림을 전송하는 로직을 실행합니다.
 */
function _notifyNext() {
    if (queue.length === 0) return;
    // 어플리케이션이 꺼져 있는 상태에서 알림이 클릭된 경우 아직 App.vue 가 mouted 되지 않았기 때문에(=> DialogOverlay 를 사용하여 알림 메시지를 보여주는데 이 컴포넌트는 App.vue 에 설치되어 있다)
    // App.vue 가 mouted 될 때까지 기다린 후에 전송합니다.
    if (!appSetting.mounted) return;

    const data = getNotification(); // 첫 번째 알림을 가져온 뒤 큐에서 제거합니다.
    nativeBridge.onPushNotification(data); // App.vue 가 mouted 되었습니다. 큐에 저장된 알림을 전송합니다.
}

export default {install}
