<template>
  <v-card style="height: 100%; overflow-y: auto;">
    <v-toolbar
      color="white"
      elevation="0"
      class="d-flex align-center"
    >
      <dialog-title :text="title" />

      <v-btn
        text
        class="button__Close mr-0"
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </v-toolbar>

    <v-list
      v-if="mount"
      subheader
      three-line
      style="height: calc(100% - 56px); overflow-y: auto; padding-bottom: 100px;"
    >
      <v-list-item>
        <v-list-item-content>
          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; font-weight: bold;"
          >
            <span>{{ '메일 주소 입력' | translate }}</span>
          </div>
          <div class="flex flex-row justify-space-between align-center">
            <div
              class="flex-1"
              style="position: relative; border: 1px solid #eeeeee; width: 100%"
            >
              <input
                v-model="mail"
                style="padding: 0.5rem; width: 100%"
                @keyup.enter="addMail"
              >
            </div>
            <div
              class="ml-3 mr-3"
              @click="addMail"
            >
              <v-icon>mdi-plus</v-icon>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; font-weight: bold;"
          >
            <span>{{ '메일 리스트' | translate }}</span>
          </div>
          <div
            class="flex-wrap justify-start"
            style="width: 100%"
          >
            <div
              v-for="(item, i) in emailList"
              :key="`mail${i}`"
              class="ma-1"
              style="border: 1px solid #82B1FF; background: #0c98fe; border-radius: 50px;width: fit-content; color: #FFFFFF"
            >
              <span class="ml-3">
                {{ item }}
              </span>
              <button
                class="ml-2"
                style="font-size: 0.725rem; background: #2A5270; width: 2rem; height: 2rem; border-radius: 50px;"
                @click="deleteMail(item)"
              >
                <v-icon
                  color="#ffffff"
                  small
                >
                  mdi-delete
                </v-icon>
              </button>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
      <dialog-apply-button
        :text="'이메일전송' | translate"
        @click:apply="onClickApply"
      />
    </v-list>
  </v-card>
</template>

<script>
import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
import DialogApplyButton from "@/components/part/sub/DialogApplyButton";
import DialogTitle from "@/components/part/sub/DialogTitle";
import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";

export default {
  name: "ElectricalContractSendMailModal",
  components: {DialogApplyButton, DialogTitle,},
  mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
  data() {
    return {
      padOptions: {
        minWidth: 0.5,
        maxWidth: 2.5,
        minDistance: 1,
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgba(255,0,255, 1)"
      },
      mounting: false,
      mount: false,
      activatedId: 0,
      mail: undefined,
      emailList: [],
    }
  },
  computed: {
    title() {
      return this.$translate("메일 전송")
    },
  },
  mounted() {
    this.activatedId++;
    this.mail = this.propsData.supplyEmail
    this.addMail()
    this.mounting = true;
    this.mount = true;
  },
  methods: {
    onClickClose() {
      this.emailList = []
      this.close();
    },

    onClickApply() {
      this.callAndClose({emailList: this.emailList});
      this.emailList = []
    },

    addMail() {
      if(!this.mail) return
      else if (!this.checkEmailValid(this.mail)) return this.$snackbarError(this.$translate("메일 주소가 올바르지 않습니다."));
      this.emailList.push(this.mail)
      this.mail = undefined
    },

    deleteMail(mail) {
      this.emailList = this.emailList.filter( e => e !== mail)
    },

    checkEmailValid(mail) {
      const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      return re.test(mail)
    }
  }
}
</script>

<style scoped>
>>> .v-toolbar__content, .v-toolbar__extension {
  width: 100%;
  justify-content: space-between;
}

>>> .v-toolbar__items {
  flex: 1;
  justify-content: center;
}

>>> .v-text-field--outlined fieldset {
  border: 1px solid #ddd !important;
}

>>> .v-text-field--outlined {
  border-radius: 0px;
}

</style>
