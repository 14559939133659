<template>
  <div v-if="showing">
    <div
      class="loading-overlay d-flex justify-center align-center"
      click.stop.prevent
    >
      <v-progress-circular
        size="46"
        color="primary"
        style="z-index: 1000;"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
    import OnLoadingOverlayOpenRequestListener from "@/framework/listeners/OnLoadingOverlayOpenRequestListener";
    import OnLoadingOverlayCloseRequestListener from "@/framework/listeners/OnLoadingOverlayCloseRequestListener";

    export default {
        name: "LoadingOverlayLayerLegacy",
        components: {},
        mixins: [OnLoadingOverlayOpenRequestListener, OnLoadingOverlayCloseRequestListener],
        data() {
            return {
                counter: 0,
            }
        },
        computed: {
            showing() {
                return this.counter > 0;
            },
        },
        methods: {
            /**
             * 오버레이를 화면에 띄웁니다.
             */
            onLoadingOverlayOpenRequested() {
                this.counter++;
            },

            /**
             * 오버레이를 닫습니다.
             */
            onLoadingOverlayCloseRequested() {
                this.counter--;
            },
        }
    }
</script>

<style scoped>
    .loading-overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(33, 33, 33, 0.6);
        z-index: 999;
    }
</style>