<script>
    export default {
        name: "FormController",
        props: {
            edit: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                isMounted: false,
                inEditMode: false,
                sourceModel: {},
                backupModel: {}
            }
        },

        computed: {
            inReadMode() {
                return !this.inEditMode;
            },
            isDirty() {
                return this.$isDirty(this.sourceModel, this.backupModel)
            }
        },

        watch: {
            edit(newValue, oldValue) {
                this.setEditMode(newValue);
            },
            isMounted(newValue, oldValue) {
            },
            inEditMode(newMode, oldMode) {
                this.$emit('@update:edit', newMode);
            }
        },

        created() {
            this.initialize();
        },

        mounted() {
            this.isMounted = true;
        },

        methods: {
            initialize() {
                this.setEditMode(this.edit);
            },
            setEditMode(enabled) {
                this.inEditMode = enabled;
                // 편집 상태로 전환 될 때 원본 백업
                if (this.inEditMode) {
                    this.$cloneReactive(this.sourceModel, this.backupModel);
                }
                // 일반 상태로 전환 될 때 백업 제거
                else this.backupModel = {};
            },
            restore() {
                this.$emit('update:formData', this.backupModel);
            },
        },

    }
</script>
