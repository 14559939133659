import App from './app/index'
import Document from './document'
import Event from './event'
import Expansions from './expansions'
import Filters from './filters'
import Formatters from './formatters'
import Location from './location'
import Network from './network'
import Permissions from './permissions'
import Preferences from './preferences'
import Style from './style'
import Time from './time'
import Translation from './translation'
import User from './user'
import PermissionCanonical from './permission-canonical'
import Data from './data'
import Router from './router';

export default {

    install(Vue, options = {}) {
        // set debug flag.
        if (!options.hasOwnProperty('debuggable')) {
            options['debuggable'] = process.env.NODE_ENV === 'development';
        }
        // install all plugins.
        [
            App,
            Document,
            Event,
            Expansions,
            Filters,
            Formatters,
            Location,
            Network,
            Permissions,
            Preferences,
            Style,
            Time,
            Translation,
            User,
            PermissionCanonical,
            Data,
            Router,

        ].forEach(plugin => Vue.use(plugin, options));
    },

}
