export default {
    name: "BaseListener",
    methods: {
        _checkFunctionExists(eventName, func) {
            const componentName = this.$options.name;
            if (eventName === undefined || eventName === null || eventName === '') {
                throw new Error(`eventName of ${componentName} is undefined or null or empty.`);
            }
            if (func === undefined || func === null) {
                console.warn(`function of ${componentName} is undefined or null.`);
            }
            if (typeof func !== 'function') {
                console.warn(`function of ${componentName} is not a function.`);
            }
        },
        registerListener(eventName, func) {
            this._checkFunctionExists(eventName, func);
            this.$listenerEventBus.$on(eventName, func);
        },
        unregisterListener(eventName, func) {
            this._checkFunctionExists(eventName, func);
            this.$listenerEventBus.$off(eventName, func);
        },
    },
}