import { render, staticRenderFns } from "./MDatePicker.vue?vue&type=template&id=563bbb54&scoped=true"
import script from "./MDatePicker.vue?vue&type=script&lang=js"
export * from "./MDatePicker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563bbb54",
  null
  
)

export default component.exports