<template>
  <v-card class="overflow-hidden">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
      >
        {{ '검색 필터' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <!--{{ '닫기' | translate }}-->
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>


    <v-card-text
      style="max-height: calc(90vh - 58px - 50px)"
      class="overflow-y-auto overflow-x-hidden"
    >
      <v-row>
        <v-col cols="12">
          <v-select-backguard
            v-model="state.installStatus"
            :items="installStatusOptions"
            :placeholder="'출고 여부' | translate"
            item-text="codeValue"
            item-value="codeKey"
            clearable
            hide-details
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select-backguard
            v-model="state.status"
            :items="connectionStatusOptions"
            :placeholder="'접속상태' | translate"
            item-text="codeValue"
            item-value="codeKey"
            hide-details
            clearable
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select-backguard
            v-model="state.deviceType"
            :items="deviceTypeOptions"
            :placeholder="'장치구분' | translate"
            item-text="codeValue"
            item-value="codeKey"
            hide-details
            clearable
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      class="ma-0 mt-2 pa-0"
    >
      <v-btn
        text
        class="button__100per radius__None"
        @click="onClickApply"
      >
        {{ '적용' | translate }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import CodeOptions from "@/components/mixins/CodeOptions";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import VSelectBackguard from "@/components/part/VSelectBackguard";

    export default {
        name: "DeviceSearchFilterModal",
        components: {VSelectBackguard},
        mixins: [CodeOptions, ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                state: {},
                historyId: undefined,
            };
        },
        computed: {
            _state() {
                return {
                    installStatus: this.state.installStatus || null, // 출고 여부
                    status: this.state.status || null, // 접속상태
                    deviceType: this.state.deviceType || null, // 장치구분
                }
            },

            /**
             * 필터된 아이템의 이름 배열을 반환합니다.
             */
            filterNameList() {
                const arr = [];

                // 출고 여부
                if (!this.$isEmpty(this.state.installStatus)) {
                  const found = this.installStatusOptions.find(e => this.state.installStatus === e.codeKey);
                  if (!!found) arr.push({text: found.codeValue});
                }

                // 접속상태
                if (!this.$isNOU(this.state.status)) {
                    const found = this.connectionStatusOptions.find(e => this.state.status === e.codeKey);
                    if (!!found) arr.push({text: found.codeValue});
                }

                // 장치구분
                if (!this.$isNOU(this.state.deviceType)) {
                    const found = this.deviceTypeOptions.find(e => this.state.deviceType === e.codeKey);
                    if (!!found) arr.push({text: found.codeValue});
                }

                return arr;
            },
        },
        mounted() {
            if (this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
                this.state = this.getDefaultState();
            } else {
                this.state = this.getState() || this.getDefaultState();
            }

            this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장
            this.loadOptions();
        },
        methods: {
            getDefaultState() {
                return {
                    installStatus: null,
                    status: null,
                    deviceType: null,
                }
            },

            loadOptions() {
                this.loadInstallStatusOptions();
                this.loadConnectionStatusOptions();
                this.loadDeviceTypeOptions();
            },

            onClickClose() {
                this.close();
            },

            onClickApply() {
                this.setState(this._state);
                this.callAndClose({state: this._state, filterNameList: this.filterNameList,});
            },
        }
    }
</script>

<!--<style scoped src="./mps-sheet/mps-sheet.css"></style>-->

<style scoped>
    >>> .v-dialog {
        overflow: hidden !important;
    }

    >>> .v-select__selections > input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-select__selections > input:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-dialog > .v-card > .v-card__text {
        padding: 0 24px 0 !important;
    }

    >>> .v-item-group {
        flex: 1;
    }

    >>> .v-btn-toggle > .v-btn.v-btn {
        flex: 1 !important;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
    }
</style>