<template>
  <div>
    <my-navigation-drawer
      v-model="drawer"
      :nav.sync="nav"
    />
    <my-tool-bar
      :main="true"
      :action-mode="keepProps.actionMode"
      :title="title"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <toolbar-search-action
          ref="toolbarSearchAction"
          :value="keepProps.searchKeyword"
          :loading="loading"
          :placeholder="searchBarPlaceholder"
          @close="keepProps.actionMode=false"
          @search="onSearchClicked"
        />
        <!--        always-active-->
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          v-if="isSearchableComponent"
          icon
          @click="onSearchModeClicked"
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>

      <template #fixed>
        <component
          :is="filterButtonComponent"
          ref="filterButton"
          :state.sync="keepProps.state"
          @onFilter="onFilterApplyClicked"
          @uniqueModalId="v => keepProps.uniqueModalId = v"
        />
        <!-- 알림 아이콘 -->
        <!-- 추후에 다시 사용할 예정입니다. -->
        <!--        <v-btn-->
        <!--          v-if="isNoticeComponent"-->
        <!--          icon-->
        <!--          style="position: relative"-->
        <!--        >-->
        <!--          <img src="@/assets/images/main/svg/notificationIcon.svg">-->
        <!--          <div class="notification__Number">-->
        <!--            2-->
        <!--          </div>-->
        <!--        </v-btn>-->
      </template>
    </my-tool-bar>

    <template v-if="isSearchableComponent">
      <status-options-bar
        :quick-sort.sync="keepProps.quickSort"
        :items="statusOptionsItems"
        @reset="onResetFilter"
      />
    </template>

    <div
      class="overflow-y-hidden"
      :style="contentStyleObject"
    >
      <transition>
        <router-view
          class="fill-height overflow-y-auto"
          @on-load-save-filter="onLoadSaveFilter"
        />
      </transition>
    </div>

    <!--하단 네비게이션-->
    <my-bottom-navigation
      v-model="nav"
    />
  </div>
</template>


<script>
import MyToolBar from "../components/app/toolbar/MyToolBar";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyNavigationDrawer from "@/components/app/drawer/MyNavigationDrawer";
import MyBottomNavigation from "@/components/app/bottom/MyBottomNavigation";
import OnOpenNavigationDrawerListener from "@/event/OnOpenNavigationDrawerListener";
import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
import {FILTER_BUTTON_TYPE} from "@/components/app/toolbar/component/filter-button/filter-button-types";
import FilterButtonMixin from "@/components/app/toolbar/component/filter-button/FilterButtonMixin";
import KeepProps from "@/views/mixins/KeepProps";
import StatusOptionsBar from "@/components/comp/ StatusOptionsBar";
import {get, put, remove} from '@/assets/plugins/mps-common/app/local-storage-helper';

/**
 * 로그인 사용자의 첫 화면은 메인입니다.
 * 메인은 탭 컨텐츠를 가지고 있으며, 이 메인 안에서 자식 라우터를 가지고 있습니다.
 * 탭이 변경될 때 자식 라우터에서 라우트합니다.
 */
export default {
  name: "AdminMain",
  components: {
    StatusOptionsBar,
    ToolbarSearchAction, MyBottomNavigation, MyNavigationDrawer, MyToolBar
  },
  extends: WindowComponent,
  mixins: [OnOpenNavigationDrawerListener, FilterButtonMixin, KeepProps],
  data() {
    return {
      /**
       * 현재 툴바 모드가 액션모드인지 여부입니다.
       */
      drawer: false,
      nav: undefined,
      historyId: undefined,
      active: true,
      mainList: [
        'AdminHome',
        'WorkerHome',
        'MainPrinter',
        'MainAs',
        'MainAccounts',
        'MainDevices',
      ]
    }
  },
  computed: {
    contentHeight() {
      if (this.$route.name === 'AdminHome') return "calc(100% - 56px - 56px)";
      if (this.$route.name === 'WorkerHome') return "calc(100% - 56px - 56px)";
      return "calc(100% - 56px - 49px - 56px)";
    },

    contentTop() {
      if (this.$route.name === 'AdminHome') return "56px";
      if (this.$route.name === 'WorkerHome') return "56px";
      return 56 + 49 + 'px';
    },

    contentStyleObject() {
      return {
        position: "relative",
        height: this.contentHeight,
        top: this.contentTop,
      }
    },

    /**
     * 현재 라우트에 해당되는 필터 버튼을 반환합니다.
     */
    filterButtonComponent() {
      switch (this.$route.name) {
        case 'MainAs':
          return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.MAIN_AS);
        case 'MainAccounts':
          return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.MAIN_ACCOUNT);
        case 'MainPrinter':
          return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.MAIN_PRINTER);
        case 'MainDevices':
          return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.MAIN_DEVICE);
        case 'ConsultNote':
          return this.getFilterButtonComponent(FILTER_BUTTON_TYPE.CONSULT_NOTE);
        default:
          return null;
      }
    },

    /**
     * 현재 라우트가 메인 관련 컴포넌트인지 여부를 계산합니다.
     */
    isMainComponent() {
      switch (this.$route.name) {
        case 'AdminHome':
        case 'WorkerHome':
        case 'MainPrinter':
        case 'MainAs':
        case 'MainAccounts':
        case 'MainDevices':
          return true;
        default:
          return false;
      }
    },

    isNoticeComponent() {
      switch (this.$route.name) {
        case 'AdminHome':
        case 'WorkerHome':
          return true;
        default:
          return false;
      }
    },

    /**
     * 현재 라우트가 검색가능한 컴포넌트인지 여부를 계산합니다.
     */
    isSearchableComponent() {
      switch (this.$route.name) {
        case 'MainPrinter':
        case 'MainAs':
        case 'MainAccounts':
        case 'MainDevices':
        case 'ConsultNote':
          return true;
        default:
          return false;
      }
    },

    mLayoutOffsetY() {
      return this.isSearchableComponent ? -56 : 0;
    },

    searchBarPlaceholder() {
      switch (this.$route.name) {
        case 'MainPrinter':
        // return this.$translate("거래처/모델/시리얼번호");
        case 'MainAs':
        // return this.$translate("거래처/모델/시리얼번호");
        case 'MainAccounts':
        // return this.$translate("거래처/사업자번호/담당자");
        case 'MainDevices':
          // return this.$translate("거래처/시리얼번호");
          return this.$translate("검색어를 입력하세요.");
        default:
          return this.$translate("검색어를 입력하세요.");
      }
    },

    /**
     * StatusOptionsBar(상태 필터바) 에서 사용할 아이템을 반환합니다.
     */
    statusOptionsItems() {
      let items = [];
      if (this.keepProps.searchKeyword) items.push({
        text: this.keepProps.searchKeyword,
        color: '#EDD8FB',
        maxWidth: "100px"
      });
      if (!this.$isEmpty(this.keepProps.filterNameList)) items = items.concat(this.keepProps.filterNameList);
      return items;
    },
  },
  watch: {
    /**
     * 라우트가 이동될 때 호출됩니다.
     * @param to 현재 라우트
     * @param from 이전 라우트
     */
    nav(to, from) {
      // 이동하려는 라우트가 다른 경우에만 이동. 현재 활성중인 라우트를 다시 불러오지 않기 위함.
      if (this.$route.name !== to) {
        // 저장된 검색필터 지우기
        // this.mainList.forEach(e => {
        //     if (e !== to){
        //       remove(`${e}-searchOption`)
        //     }
        // })
        this.routerManager.replace({name: to});
      }
    },
    /**
     * 라우트가 이동될 때 호출됩니다.
     * @param to 현재 라우트
     * @param from 이전 라우트
     */
    '$route'(to, from) {
      this.nav = to.name;
      // 메인 라우트로 이동시 히스토리 Back 이 아닌 페이지 이동이라면 필터 초기화
    },

    '$route.name'() {
      if (this.doKeepProps() && this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
        this.resetKeepProps(); // 필터 초기화
      }

      this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장
    },

    /**
     *
     * 검색 키워드가 변경될 때 마다 감지합니다.
     * (@change 감지라 한글자씩 감지하지는 않습니다)
     * @param newKeyword 새 검색 키워드
     * @param oldKeyword 이전 검색 키워드
     */
    'keepProps.searchKeyword'(newKeyword, oldKeyword) {
      this.notifyEvent(this.mpsEvents.ON_MAIN_SEARCH_KEYWORD_CHANGED, newKeyword);
    },
  },
  unmounted() {
  },
  mounted() {
  },
  created() {
  },
  methods: {
    getInitDataWhenActivatedKeepProps() {
      switch (this.$route.name) {
        case 'MainPrinter':
          return {quickSort: false};
          break;
      }

      return {};
    },

    /**
     * 메인 라우터인 경우만 상태값을 저장합니다.
     */
    doKeepProps() {
      switch (this.$route.name) {
        case 'WorkerHome':
        case 'MainPrinter':
        case 'ConsultNote':
        case 'MainAs':
        case 'MainAccounts':
        case 'MainDevices':
          console.log(this.$route.name, this.historyManager.isForward);
          return true;
        default:
          return false;
      }
    },

    getDefaultKeepProps() {
      let state = {};
      let filterNameList = [];
      let quickSort = false;
      switch (this.$route.name) {
        case "ConsultNote":
          state = {accLevelType: null};
          break;
        case "MainPrinter":
          quickSort = false;
          break;
        case "MainAccounts":
          state = {useYn: "Y"};
          filterNameList = [{text: this.$translate("계약중")}];
          break;
        case "MainAs":
          state = {workorderStateList: ['1', '2', '3'], endDate: this.$now().endOf("day").toISO()};
          filterNameList = [
            {text: " ~ " + this.$date(this.$now().endOf("day"))},
            {text: this.$translate("전체")},
            {text: this.$translate("접수")},
            {text: this.$translate("기사대기")},
            {text: this.$translate("진행")},
          ];
          break;
        default:
          break;
      }
      return {
        actionMode: false,
        searchKeyword: '',
        state: state,
        filterNameList: filterNameList,
        uniqueModalId: undefined,
        quickSort: quickSort,
      }
    },

    /**
     * 네비게이션 서랍 열기
     */
    openNavigationDrawer() {
      this.drawer = true;
    },

    /**
     * 검색 모드 버튼이 눌리면 액션 모드로 전환합니다.(검색 버튼과 다름. 단순히 모드 전환)
     */
    onSearchModeClicked() {
      this.keepProps.actionMode = true;
      setTimeout(() => {
        if (this.$refs.toolbarSearchAction) this.$refs.toolbarSearchAction.focus();
      }, 100);
    },

    /**
     * 검색 버튼이 눌렸습니다. 검색 발동 이벤트를 전달합니다.
     */
    onSearchClicked(keyword) {
      this.keepProps.searchKeyword = keyword;
      this.notifyEvent(this.mpsEvents.ON_MAIN_SEARCH_TRIGGERED, keyword);
      // 검색필터 저장
      this.saveFilter()
    },

    /**
     * 필터 적용 버튼이 눌려졌습니다. 검색 발동 이벤트를 전달합니다.
     */
    onFilterApplyClicked(data) {
      this.keepProps.state = data.state;
      this.keepProps.filterNameList = data.filterNameList;
      this.notifyEvent(this.mpsEvents.ON_MAIN_FILTER_TRIGGERED, data.state);
      // 검색필터 저장
      this.saveFilter()
    },

    /**
     * 현재 필터 내용을 저장합니다.
     */
    saveFilter() {
      put(`${this.$route.name}-searchOption`, JSON.stringify({saveDate: this.$now().toISO(), ...this.keepProps}))
    },

    /**
     * 마지막 검색 필터 내용을 불러옵니다.
     */
    onLoadSaveFilter() {
      const stringJson = get(`${this.$route.name}-searchOption`)
      if (!stringJson || stringJson === '')
        return

      const parseJsonData = JSON.parse(stringJson)

      if (!parseJsonData.saveDate ){
        return
      } else {
        const saveDate = this.$parseDate(parseJsonData.saveDate)
        if (saveDate.diffNow().toObject().milliseconds < -36000000){
          return
        }
      }

      Object.keys(parseJsonData).forEach(e => {
        if (e !== 'saveDate') {
          this.keepProps[e] = parseJsonData[e]
        }
      })
      const {state, searchKeyword, ...others} = parseJsonData
      const {pageSize, pageNumber, ...searchOption} = state
      this.notifyEvent(this.mpsEvents.ON_MAIN_FILTER_TRIGGERED, {...searchOption, searchKeyword});
    },

    /**
     * 모든 필터 검색 조건을 초기화 합니다.
     */
    onResetFilter() {
      this.notifyEvent(this.mpsEvents.ON_FILTER_RESET, {uniqueModalId: this.keepProps.uniqueModalId});
      const actionMode = this.keepProps.actionMode;
      this.resetKeepProps();
      this.keepProps.actionMode = actionMode;
      remove(`${this.$route.name}-searchOption`)
    },
  },
}
</script>

<style scoped>
>>> .v-application--wrap {
  display: flex;
  flex-direction: column;
}

.notification__Number {
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #0C98FE;
  color: #fff;
  left: 24px;
  bottom: 9px;
}
</style>
