<script>
    import {MODAL_VUETIFY_COMPONENTS} from "@/constants/vuetify-modal";
    import OnModalOpenRequestedListener from "@/event/OnModalOpenRequestedListener";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
    import OnBottomSheetOpenRequestedListener from "@/event/OnBottomSheetOpenRequestedListener";
    import ModalVuetifyKeppState from "@/assets/plugins/vuetify-modal/ModalVuetifyKeppState";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "ModalVuetifyMultiModal",
        mixins: [OnModalOpenRequestedListener, OnBottomSheetOpenRequestedListener, ModalVuetifyKeppState],
        data() {
            return {
                components: MODAL_VUETIFY_COMPONENTS,
                stack: [],
                // keep-alive 를 유지하기 위한 empty 스택입니다.
                // stack 에서 pop 이 될 경우 해당 자리에 빈 오브젝트를 삽입합니다.
                stackFake: [],
                closing: false,
                MAX_COUNT_OF_MODALS: 2, // 최대 모달 갯수
            };
        },

        computed: {
            lastStack() {
                if (this.stack.length === 0) return undefined;
                return this.stack[this.stack.length - 1];
            },
        },

        watch: {
        },

        created() {
        },

        methods: {
            getComponent(modalName) {
                if (modalName) {
                    const foundModal = this.components.find((e) => e.name === modalName);
                    if (foundModal) {
                        return foundModal.component;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },

            close() {
                if (this.stack.length === 0) return;
                if (this.closing) return;

                // 트랜지션이 완료될 때까지 기다린다.
                // 그렇지 않으면 닫는 트랜지션 애니메이션이 깨진다.
                this.lastStack.modal.dialog = false;
                this.closing = true;
                setTimeout(() => {
                    if (this.stack.length === 0) return;
                    this.stack.pop();
                    this.closing = false;
                    this.backGuard.pop();
                }, ETC.VUETIFY_MODAL.CLOSE_DELAY);
            },

            /**
             * 콜백이 존재할 경우 콜백함수를 호출합니다.
             */
            call(data) {
                if (this.closing) return;
                if (!!this.lastStack.modal.callback) this.lastStack.modal.callback(data);
            },

            /**
             * 모달을 엽니다.
             */
            openModal(data) {
                if (isNOU(data.modalName)) {
                    return;
                }

                const modal = {
                    id: data.id,
                    name: data.modalName,
                    callback: data.callback,
                    component: this.getComponent(data.modalName),
                    dialog: true,
                }

                if (!modal.component) return this.$alert("No Component!");

                const options = this.getModalOptions(data.modalOptions);

                // 이 모달의 상태값이 있다면 가져온다.
                // propsDate로 넘어온 데이터가 있다면 덮어쓴다.
                let propsData = {
                    ...(this.getState(modal.id) || {}),
                    ...(data.propsData || {})
                }

                const obj = {
                    modal: modal,
                    options: options,
                    propsData: propsData,
                };

                this.backGuard.push(() => {
                    obj.modal.dialog = false;
                });

                this.stack.push(obj);
            },

            /**
             * 모달에 사용할 prop 을 생성합니다.
             */
            getModalOptions(modalOptions) {
                const mo = modalOptions || {};
                return {
                    persistent: mo.persistent === true,
                    maxWidth: mo.maxWidth || "100%",
                    transition: mo.transition,
                    width: mo.width,
                    fullscreen: mo.fullscreen === true,
                    hideOverlay: mo.hideOverlay === true,
                    scrollable: mo.scrollable === true,
                }
            },

            /**
             * 모달이 요청될 때 호출됩니다.
             */
            onModalOpenRequested(data) {
                if (this.closing) return;
                this.openModal(data); // 새 모달을 엽니다.
            },

            openBottomSheet(data) {
                if (isNOU(data.bottomSheetName)) {
                    return;
                }

                const modal = {
                    id: data.id,
                    name: data.bottomSheetName,
                    callback: data.callback,
                    component: this.getComponent(data.bottomSheetName),
                    dialog: true,
                }

                if (!modal.component) return this.$alert("No Component!");

                const options = this.getBottomSheetOptions(data.modalOptions);

                // 이 모달의 상태값이 있다면 가져온다.
                // propsDate로 넘어온 데이터가 있다면 덮어쓴다.
                let propsData = {
                    ...(this.getState(modal.id) || {}),
                    ...(data.propsData || {})
                }

                const obj = {
                    modal: modal,
                    options: options,
                    propsData: propsData,
                };

                this.backGuard.push(() => {
                    obj.modal.dialog = false;
                });

                this.stack.push(obj);
            },

            getBottomSheetOptions(modalOptions) {
                const mo = modalOptions || {};
                return {

                }
            },

            /**
             * 바텀시트형 모달이 요청될 때 호출됩니다.
             */
            onBottomSheetOpenRequested(data) {
                if (this.closing) return;
                this.openBottomSheet(data);
            },
        },
    }
</script>