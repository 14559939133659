<template v-if="user">
  <v-list-item color="primary">
    <v-list-item-avatar
      class="d-block mx-auto"
      height="90px"
      width="90px"
    >
      <v-img
        :src="avatar"
      />
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
    export default {
        name: "UserAvatar",
        computed: {
            avatar() {
                return this.user.avatar || require('@/assets/images/main/ic_main_profile.png');
            }
        }
    }
</script>

<style scoped>

</style>