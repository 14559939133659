<template>
  <div style="height: 100%;">
    <my-tool-bar
      :title="'거래처 선택' | translate"
      :closable="true"
      @click:close="$emit('update:dialog', false)"
    >
      <!--액션 모드에 노출되는 UI 입니다.-->
      <!--닫기 버튼이 눌리면 액션 모드를 비활성합니다.-->
      <!--슬롯에서 제공되는 loading 변수로 로딩 상태를 표시할 수 있습니다.-->
      <!--v-model 은 액션 뷰의 텍스트와 동기화됩니다.-->
      <template #action="{loading}">
        <ToolbarSearchAction
          v-model="searchOptions.searchKeyword"
          :placeholder="'거래처/사업자번호/담당자' | translate"
          @search="onSearchClicked"
        />
      </template>
      <!--툴바 우측의 버튼들입니다.-->
      <!--검색 기능을 제공하는 컴포넌트에서만 노출되도록 합니다.-->
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
        >
          <!--          <v-icon>mdi-magnify</v-icon>-->
          <img src="@/assets/images/main/svg/buttonSearch.svg">
        </v-btn>
      </template>

      <template #fixed>
        <account-search-filter-button
          ref="filterButton"
          @onFilter="onFilterApplyClicked"
        />
      </template>
    </my-tool-bar>

    <div style="height: calc(100% - 56px);">
      <!-- 인피니트 리스트 -->
      <vue-infinite-loader
        :items="items"
        :fetch="fetch"
        :identifier="identifier"
        item-key="raw.accountId"
      >
        <template #default="{ item }">
          <account-item-card
            :item="item"
            @click:item="pick => $emit('pick', pick.raw)"
          />
        </template>
      </vue-infinite-loader>
    </div>
  </div>
</template>
<script>
    import {PATH} from "@/constants";
    import Account from "@/model/account";
    import AccountSearchFilterButton
        from "@/components/app/toolbar/component/filter-button/vuetify/AccountSearchFilterButton";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";
    import AccountItemCard from "@/components/items/account/AccountItemCard";

    export default {
        name: "MFormAccountFullDialog",
        components: {
            AccountItemCard,
            AccountSearchFilterButton, ToolbarSearchAction, MyToolBar
        },
        props: {
            title: null,
            dialog: null,
        },
        data() {
            return {
                items: [],
                searchOptions: {},
                identifier: 0,
                mounted: false,
            }
        },
        mounted() {
            this.mounted = true;
            this.identifier++;
        },
        methods: {
            async fetch(page, pageSize) {
                this.searchOptions.pageNumber = page;
                this.searchOptions.pageSize = pageSize;

                const response = await this.$request(PATH.ACCOUNT.LIST)
                    .setObject(this.searchOptions)
                    .catch()
                    .wait(500)
                    .enqueue();
                return response.data.map(e => new Account(e));
            },

            /**
             * 툴바 검색 버튼이 눌렸습니다.
             * @param keyword
             */
            onSearchClicked(keyword) {
                this.searchOptions.searchKeyword = keyword;
                this.identifier++;
            },

            onFilterApplyClicked(state) {
                this.searchOptions = {...this.searchOptions, ...state};
                this.identifier++;
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>