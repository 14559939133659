<template>
  <v-app-bar
    :color="color"
    :extended="extended"
    :extension-height="extensionHeight"
    app
    style="padding: 0 0 0 0 !important; max-height: 56px !important;"
    elevation="0"
  >
    <v-layout
      :align-center="true"
      style="padding-right: 5px !important;"
    >
      <div
        v-if="!actionMode"
        style="display: flex; width: 100%; align-items: center;"
      >
        <slot>
          <!--일반모드:general-->
          <template>
            <!--네비게이션 버튼. 메인 화면에서만 보입니다. 눌리면 이벤트를 발생시킵니다.-->
            <v-app-bar-nav-icon
              v-if="main"
              @click="notifyEvent(mpsEvents.OPEN_NAVIGATION_DRAWER)"
            >
              <!-- 햄버거 메뉴 아이콘 -->
              <img src="@/assets/images/main/svg/button_Home.svg">
            </v-app-bar-nav-icon>
            <v-btn
              v-else-if="closable"
              icon
              @click="$emit('click:close')"
            >
              <!--            닫기 버튼 -->
              <!--<v-icon>mdi-close</v-icon>-->
              <img src="@/assets/images/main/svg/button_Close.svg">
            </v-btn>
            <v-btn
              v-else
              icon
              @click="onClickBack"
            >
              <!--            뒤로가기 버튼 -->
              <!--<v-icon>mdi-arrow-left</v-icon>-->
              <img src="@/assets/images/main/svg/button__Previous.svg">
            </v-btn>

            <v-toolbar-title>
              <div class="d-flex justify-space-between align-center font-weight-bold">
                {{ title }}
              </div>
            </v-toolbar-title>

            <v-spacer />
            <!-- 기본 버튼 -->
            <template v-if="!loading">
              <slot name="buttons" />
            </template>
          </template>
        </slot>
      </div>

      <div
        v-if="actionMode"
        style="display: flex; width: 100%; align-items: center;"
      >
        <slot
          name="action"
        />
      </div>

      <!-- 필터 버튼 -->
      <slot name="fixed" />
    </v-layout>
  </v-app-bar>
</template>

<script>
    import WindowElement from "@/assets/plugins/mps-window-mobile/WindowElement";
/**
 *
 *
 */
    export default {
        name: "MyToolBar",
        mixins: [WindowElement],
        props: {
            value: {type: Array, default: () => []},
            main: {type: Boolean, default: false},
            title: {type: String, default: 'No Title'},
            color: {type: String, default: 'white'},
            loading: {type: Boolean, default: false},
            closable: {type: Boolean, default: false},
            actionMode: {type: Boolean, default: false},
            extended: {type: Boolean, default: false},
            extensionHeight: {type: String, default: undefined},
        },
        computed: {
            selectionTitle() {
                if (!value) return;
                if (value.length === 0) return 'Select items';
                return value.length + ' Selected';
            }
        },
        methods: {
            onClickBack() {
                this.$emit('click:back');
                this.routerManager.back();
            },
        },
    }
</script>

<style scoped>
    header.v-toolbar {
        box-shadow: none !important;
    }

    >>> .v-toolbar__content {
        border-bottom: 1px solid #ddd;
        max-height: 56px !important;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 3s;
        transition-property: all;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave {
        opacity: 0;
    }

    >>> .v-toolbar__content, .v-toolbar__extension {
        padding: 0 !important;
    }

    .notification__Number {
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: #0C98FE;
        color: #fff;
        left: 24px;
        bottom: 9px;
    }

    >>> .v-text-field--outlined fieldset {
        border: 1px solid #aaa !important;
    }

    >>> .v-text-field--outlined.v-input--is-focused fieldset {
        border: 1px solid #555 !important;
    }
</style>
