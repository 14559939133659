<template>
  <v-row
    class="mps-form-wrapper"
  >
    <v-col
      cols="4"
      class="mps-form-label__wrapper"
    >
      <label class="mps-form-label">{{ label }}</label>
    </v-col>

    <v-col
      cols="8"
      class="mps-form-field__wrapper"
    >
      <template v-if="inEditMode">
        <div class="in-edit-mode">
          <the-mask
            v-model="model"
            class="form-textfield"
            :mask="['XXXX-XXXX-XXXX']"
            :placeholder="placeholder"
            :diabled="disabled"
            type="url"
          />
        </div>
      </template>

      <template v-else>
        <div class="in-view-mode">
          <the-mask
            :mask="['XXXX-XXXX-XXXX']"
            class="form-textfield"
            disabled
            type="text"
            :value="model"
          />
        </div>
      </template>
    </v-col>
  </v-row>
</template>


<script>
    import MBaseFormVueTheMask from "@/assets/plugins/mps-form/providers/MBaseFormVueTheMask";

    export default {
        name: "MFormMpsVueTheMask",
        extends: MBaseFormVueTheMask,
    }
</script>
