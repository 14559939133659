/**
 * 스크롤 상태입니다.
 * @type {{SETTLING: string, IDLE: string, SCROLLING: string, PULLING: string}}
 */
const SCROLL_STATE = {
    /**
     * 스크롤 되고 있지 않습니다.
     */
    IDLE: 'scroll_state_idle',
    /**
     * 상호작용에 의해 스크롤되고 있는 상태입니다.
     * 사용자의 터치 시작 ~ 터치 종료 사이에 발생한 스크롤 입니다.
     */
    SCROLLING: 'scroll_state_scrolling',
    /**
     * 상호작용되고 있지 않지만, 스크롤 상태가 변하는 경우입니다.
     * 사용자가 스크롤 플링 한 경우에 발생합니다.
     */
    SETTLING: 'scroll_state_settling',
    /**
     * 상호작용으로 스크롤 영역보다 더 스크롤 한 경우에 발생합니다.
     * overScrolled 파라미터가 음수이면 상단 오버 스크롤, 양수이면 하단 오버 스크롤 입니다.
     */
    PULLING: 'scroll_state_pulling', // is OVER_SCROLL
};

/**
 * 스크롤이 끝 단에 닿았는지 여부입니다.
 * @type {{TOP: string, BOTTOM: string}}
 */
const EDGE = {
    NONE: 'edge_none',
    TOP: 'edge_top',
    BOTTOM: 'edge_bottom'
};

class ScrollDetectorMobile {

    /**
     * 스크롤 컨텐츠를 가지고 있는 컨테이너 입니다.
     */
    _container;

    /**
     * 오버 스크롤 저항 수치입니다. 0~1
     * 수치 만큼 오버 스크롤이 적게 움직입니다.
     */
    _resist;

    /**
     * 컨테이너의 현재 스크롤 수치입니다.
     */
    _scrollTop;

    /**
     * 스크롤 상태.
     */
    _state = SCROLL_STATE.IDLE;

    /**
     * 현재 터치중인지 여부.
     */
    _onTouch;

    /**
     * 터치 시작점
     */
    _touchStarted = {x: 0, y: 0};

    /**
     * 당기기 모드로 진입 가능한 상태. 단, 당김 허가는 _canOverScroll 을 바라봅니다.
     */
    _edge;

    /**
     * 상단 또는 하단 끝에 도달하여 오버 스크롤 할 수 있는지 여부
     */
    _canOverScroll;

    /**
     * 상호작용 종료시 IDLE 검사를 위한 타임아웃
     */
    _timeout;

    /**
     * 스크롤 상태 변경 리스너
     */
    _onScrollStateChangedListener;

    /**
     * 스크롤 리스너
     */
    _onScrollChangedListener;

    /**
     * 최상단 또는 최하단 도착 리스너
     */
    _onEdgeChangedListener;

    /**
     * 오버 스크롤 리스너
     */
    _onOverScrollChangedListener;

    /**
     * 생성자
     *
     * @param container 스크롤 감시할 대상
     * @param resist 저항
     */
    constructor(container, resist = 0.5) {
        this._container = container;
        this._evaluateCanOverScroll(); // 엘리먼트 스크롤 상태를 평가합니다.
    }

    /**
     *  스크롤 상태가 변경되면 호출됩니다.
     *
     * @param onScrollStateChangedListener (newState, oldState)
     */
    set onScrollStateChangedListener(onScrollStateChangedListener) {
        this._onScrollStateChangedListener = onScrollStateChangedListener;
    }

    /**
     * 컨텐츠가 스크롤 될 때 호출됩니다.
     *
     * @param onScrollListener (scrollState, scrollTop, visibleHeight, contentHeight)
     */
    set onScrollListener(onScrollListener) {
        this._onScrollChangedListener = onScrollListener;
    }

    /**
     * 스크롤 컨텐츠가 최상단 또는 최하단에 도착 또는 떠날 때 호출됩니다.
     *
     * @param onEdgeChangedListener (newEdge, oldEdge)
     */
    set onEdgeChangedListener(onEdgeChangedListener) {
        this._onEdgeChangedListener = onEdgeChangedListener;
    }

    /**
     * 오버 스크롤이 발생할 때 호출됩니다.
     * edge 가 EDGE.TOP 이면 최상단에서 당기는 중입니다. (PullToRefresh)
     * 당기는 거리는 양수인 경우 TOP 에서 당기고, 음수인 경우 BOTTOM 에서 당깁니다.
     *
     * @param onOverScrollChangedListener (edge, distance)
     */
    set onOverScrollChangedListener(onOverScrollChangedListener) {
        this._onOverScrollChangedListener = onOverScrollChangedListener;
    }

    /**
     * 스크롤 상태를 변경합니다.
     * @param newState
     * @private
     */
    _setState(newState) {
        if (this._state === newState) return;
        const prevState = this._state;
        this._state = newState;
        if (this._onScrollStateChangedListener) this._onScrollStateChangedListener(this._state, prevState);
    }

    /**
     * 오버 스크롤 가능 여부를 평가합니다.
     * @private
     */
    _evaluateCanOverScroll() {
        const el = this._container; // 스크롤 컨테이너
        const scrollTop = el.scrollTop; // 현재 스크롤 위치. 컨텐츠의 상단을 기준으로 합니다.
        const visibleHeight = el.clientHeight; // 화면에 보이는 컨테이너 크기
        const contentHeight = el.scrollHeight; // 스크롤되는 컨텐츠 영역.
        const distanceToBottom = contentHeight - scrollTop - visibleHeight; // 컨텐츠 끝까지 남은 스크롤 거리
        const oldEdge = this._edge; // 오버 스크롤 상태를 재평가 후 비교하기 위해, 현재 상태를 임시로 저장합니다.

        // 최상단입니다.
        if (scrollTop === 0) {
            this._edge = EDGE.TOP;
        }
        // 최하단 입니다.
        else if (distanceToBottom === 0) {
            this._edge = EDGE.BOTTOM
        }
        // 둘다 아님.
        else {
            this._edge = EDGE.NONE;
        }

        // 최상단 또는 최하단으로 스크롤 되었습니다. (상태가 변경됨)
        // 현재 터치중이 아니면, 오버스크롤 가능 상태로 전환합니다.
        const changed = oldEdge !== this._edge;
        if (changed) {
            this._canOverScroll = this._edge !== EDGE.NONE && !this._onTouch;
            if (this._onEdgeChangedListener) this._onEdgeChangedListener(this._edge, oldEdge);
        }

    }

    /**
     * 터치 시작부터 현재 터치까지 이동한 거리를 반환합니다.
     * @param touch
     * @returns {{dx: number, dy: number}}
     * @private
     */
    _computeDistanceDragged(touch) {
        const dx = touch.clientX - this._touchStarted.x;
        const dy = touch.clientY - this._touchStarted.y;
        return {dx, dy};
    }

    /**
     * 오버 스크롤 거리를 계산합니다.
     * @param e
     * @private
     */
    _computeOverScroll(e) {
        // 오버 스크롤 가능 상태가 아니면 무시합니다.
        if (!this._canOverScroll) return;
        // 현재 터치 포인터와 터치 시작점으로 드래그 된 거리를 구합니다.
        const touch = e.touches[0];
        let {dx, dy} = this._computeDistanceDragged(touch);
        const pullingTop = this._edge === EDGE.TOP && dy > 0;
        const pullingBottom = this._edge === EDGE.BOTTOM && dy < 0;
        if (pullingTop || pullingBottom) {
            this._setState(SCROLL_STATE.PULLING);
            if (this._onOverScrollChangedListener) this._onOverScrollChangedListener(this._edge, dy);
        }
    }

    /**
     * 상호작용 종료 후 스크롤 유휴 상태를 검사합니다.
     * (사용자가 플링한 경우, 상호작용은 종료되었지만 스크롤링이 유지될 수 있습니다.)
     * @private
     */
    _checkIdle() {
        clearTimeout(this._timeout);
        if (this._onTouch) return;
        this._timeout = setTimeout(() => {
            // 스크롤은 중지되었지만, 당기는 중일 수 있습니다. 당기는 중이면 IDLE 로 전환하지 않습니다.
            if (this._state === SCROLL_STATE.PULLING) return;
            this._setState(SCROLL_STATE.IDLE);
        }, 50);
    }

    ///////////////////////////////////////////////////////////////////////////
    //
    ///////////////////////////////////////////////////////////////////////////

    _onScroll = (e) => {
        this._scrollTop = e.target.scrollTop;
        this._evaluateCanOverScroll();
        // 스크롤 되고 있을 때 손가락이 닿은 상태면 스크롤 상태, 아니라면 항해 상태입니다.
        this._setState(this._onTouch ? SCROLL_STATE.SCROLLING : SCROLL_STATE.SETTLING);
        this._checkIdle();
        // 스크롤 상태 알림
        if (this._onScrollChangedListener) {
            const el = this._container; // 스크롤 컨테이너
            const scrollTop = el.scrollTop; // 현재 스크롤 위치. 컨텐츠의 상단을 기준으로 합니다.
            const visibleHeight = el.clientHeight; // 화면에 보이는 컨테이너 크기
            const contentHeight = el.scrollHeight; // 스크롤되는 컨텐츠 영역.
            this._onScrollChangedListener(this._state, scrollTop, visibleHeight, contentHeight);
        }
    };

    _onTouchStart = (e) => {
        this._onTouch = true;
        const {clientX, clientY} = e.touches[0];
        this._touchStarted.x = clientX;
        this._touchStarted.y = clientY;
    };

    _onTouchMove = (e) => {
        switch (this._state) {
            case SCROLL_STATE.IDLE: // 사실 터치 무브 상태에서는 IDLE 일 수가 없습니다.
            case SCROLL_STATE.PULLING:
                this._computeOverScroll(e);
                break;
            default:
                // 다른 상태에서는 계산할 필요가 없습니다.
                break;
        }
    };

    _onTouchEnd = (e) => {
        this._onTouch = false;
        this._touchStarted.x = 0;
        this._touchStarted.y = 0;
        this._evaluateCanOverScroll();

        // 오버 스크롤 중이면, 오버 스크롤 종료를 알립니다.
        // if (this._state === SCROLL_STATE.PULLING) {
        //     if (this._onOverScrollChangedListener) this._onOverScrollChangedListener(this._edge, 0);
        // }

        // 터치가 끝났으니, IDLE 로 전환해야 합니다.
        // 단, this._canOverScroll 상태가 아니면 스크롤중일 수 있습니다.
        if (this._canOverScroll) this._setState(SCROLL_STATE.IDLE);
        else this._checkIdle();
    };

    on() {
        this._container.addEventListener('touchstart', this._onTouchStart);
        this._container.addEventListener('touchend', this._onTouchEnd);
        this._container.addEventListener('touchmove', this._onTouchMove);
        this._container.addEventListener('scroll', this._onScroll);
    }

    off() {
        this._container.removeEventListener('touchstart', this._onTouchStart);
        this._container.removeEventListener('touchend', this._onTouchEnd);
        this._container.removeEventListener('touchmove', this._onTouchMove);
        this._container.removeEventListener('scroll', this._onScroll);
    }
}

export {ScrollDetectorMobile, SCROLL_STATE, EDGE};