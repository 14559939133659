import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";
import parseDate from "@/assets/plugins/mps-common/time/parse-date";
import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
import {getDuration} from "@/assets/plugins/mps-common/time/duration-util";

const common = {};

common.extractLanguage = (language) => {
    if (typeof language !== 'string') return null;
    const trim = language.trim();
    if (trim.length !== 5) return null;
    const languageCode = trim.slice(0, 2).toLowerCase();
    const countryCode = trim.slice(3, 5).toUpperCase();
    return [languageCode, countryCode];
};

/**
 * 배열의 순서를 뒤집습니다.
 *
 * @param value
 * @returns {*[]|*}
 */
common.reverse = (value) => {
    try {
        return value.slice().reverse();
    } catch (e) {
        return [];
    }
};

/**
 * month 에 해당하는 월의 번역된 문자열을 반환합니다.
 *
 * @param month 1-12 의 숫자
 * @returns {string} 해당 월의 번역된 문자열 반환, 잘못된 값일 경우 Unkown 반환
 */
common.translateMonth = (month) => {
    let text;
    switch (month) {
        case 1:
            text = translationManager.translate("1월");
            break;
        case 2:
            text = translationManager.translate("2월");
            break;
        case 3:
            text = translationManager.translate("3월");
            break;
        case 4:
            text = translationManager.translate("4월");
            break;
        case 5:
            text = translationManager.translate("5월");
            break;
        case 6:
            text = translationManager.translate("6월");
            break;
        case 7:
            text = translationManager.translate("7월");
            break;
        case 8:
            text = translationManager.translate("8월");
            break;
        case 9:
            text = translationManager.translate("9월");
            break;
        case 10:
            text = translationManager.translate("10월");
            break;
        case 11:
            text = translationManager.translate("11월");
            break;
        case 12:
            text = translationManager.translate("12월");
            break;
        default:
            text = "Unkown";
            break;
    }
    return text;
};

common.pad = (value, posNum, char = '0') => {
    const i = parseInt(value);
    if (Number.isNaN(i)) return NaN;

    // 자릿수를 구한다.
    let realPosNum = 1;
    if (i >= 10) {
        let m = i;
        do {
            realPosNum++;
            m = Math.floor(m / 10);
            console.log(m);
            if (Number.isNaN(m)) return NaN;
        } while (m > 0);
    }

    let text = '' + i;

    // 원하는 자릿수가 더 큰 경우 왼쪽 남은 공간을 char 문자로 채운다.
    if (posNum > realPosNum) {
        for (let j = posNum - realPosNum; j > 0; j--) {
            text = char + text;
        }
    }

    return text;
};

common.sortObject = (unordered, asc = true) => {
    const ordered = Object.keys(unordered).sort().reduce(
        (obj, key) => {
            obj[key] = unordered[key];
            return obj;
        },
        {},
    );
    return asc ? ordered : ordered.reverse();
};

export default common;
