<template>
  <v-card class="radius__None">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
      >
        {{ '검색 필터' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <!--{{ '닫기' | translate }}-->
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>


    <v-card-text
      style="max-height: calc(90vh - 300px)"
      class="overflow-y-auto overflow-x-hidden px-5"
    >
      <v-row class="mb-6 ma-0">
        <defined-period-picker
          :date-end.sync="state.endDate"
          :date-start.sync="state.startDate"
          :button-index.sync="state.buttonIndex"
          class="pa-0"
        />
      </v-row>
      <v-row
        justify="center"
        class="align-center"
      >
        <v-col
          cols="12"
          class="px-0"
          style="padding-bottom: 22px"
        >
          <v-select-backguard
            v-model="state.isReservation"
            :items="asOptions.dateOptions"
            clearable
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'예약일시 기준 검색' | translate"
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="px-0"
        >
          <j-period-picker
            :start-label="'시작일' | translate"
            :end-label="'종료일' | translate"
            :date-end.sync="state.endDate"
            :date-start.sync="state.startDate"
            icon="mdi-calendar"
            class="mx-3"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="align-center"
      >
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.isTodayOrAfter"
            :items="asOptions.asDateOptions"
            clearable
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'전체, 당일건, 예약건' | translate"
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="align-center"
      >
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.workorderReceiveTypeList"
            :items="CODE.WORKORDER_RECEIVE_TYPE_ITEMS.slice(1, CODE.WORKORDER_RECEIVE_TYPE_ITEMS.length)"
            clearable
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'접수 유형' | translate"
            hide-details
            multiple
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="align-center"
      >
        <!--          <v-col cols="4">-->
        <!--            접수구분-->
        <!--          </v-col>-->
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.asReceiptTypeList"
            :items="asReceiptTypeOptions"
            clearable
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'접수구분' | translate"
            hide-details
            multiple
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!excludeWorkorderState"
        justify="center"
        class="align-center"
      >
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.workorderStateList"
            :items="workorderStateOptionsExcluded"
            :placeholder="'AS진행상태' | translate"
            item-text="codeValue"
            item-value="codeKey"
            multiple
            clearable
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.reasonCodeList"
            :items="reasonCodeOptions"
            :placeholder="'접수 증상' | translate"
            item-text="codeValue"
            item-value="codeKey"
            multiple
            clearable
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
      <v-row v-if="!excludeWorker">
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.workorderStaffList"
            :items="workorderStaffOptions"
            :placeholder="'AS 기사' | translate"
            item-text="userFullname"
            item-value="userId"
            multiple
            clearable
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.isRegister"
            :items="asOptions.ShowOptions"
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'시스템 등록' | translate"
            hide-details
            clearable
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!--      <v-divider />-->
    <v-card-actions
      class="ma-0 mt-2 pa-0"
    >
      <!--        <v-spacer />-->
      <v-btn
        text
        class="button__100per radius__None"
        @click="onClickApply"
      >
        {{ '적용' | translate }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import CodeOptions from "@/components/mixins/CodeOptions";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import DefinedPeriodPicker from "@/components/options/DefinedPeriodPicker";
    import JPeriodPicker from "@/assets/plugins/mps-vuetify-compat/pickers/JPeriodPicker";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import VSelectBackguard from "@/components/part/VSelectBackguard";
    import {CODE} from "@/constants";

    export default {
        name: "AsSearchFilterModal",
        components: {VSelectBackguard, JPeriodPicker, DefinedPeriodPicker},
        mixins: [CodeOptions, ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                state: {
                  isRegister: true,
                  isReservation: true,
                  isTodayOrAfter: null,
                },
                historyId: undefined,
            };
        },
        computed: {
            excludeWorker() {
                return this.propsData.exclude && this.propsData.exclude.includes("WORKER");
            },

            excludeWorkorderState() {
                return this.propsData.exclude && this.propsData.exclude.includes("WORKORDER_STATE");
            },

            workorderStateOptionsExcluded() {
                if (this.$isEmpty(this.propsData.excludeWorkorderStateList)) return this.workorderStateOptions;
                return this.workorderStateOptions.filter(item => !this.propsData.excludeWorkorderStateList.includes(item.codeKey));
            },

            /**
             * 필터된 아이템의 이름 배열을 반환합니다.
             */
            filterNameList() {
                const arr = [];
                let dateStartText = null;
                let dateEndText = null;
                if (!this.$isNOU(this.state.startDate)) {
                    dateStartText = this.$date(this.state.startDate);
                }

                if (!this.$isNOU(this.state.endDate)) {
                    dateEndText = this.$date(this.state.endDate);
                }

                if (!this.$isNOU(dateStartText) || !this.$isNOU(dateEndText)) {
                    arr.push({text: (dateStartText || "") + " ~ " + (dateEndText || "")});
                }

                if (this.state.isTodayOrAfter !== undefined) {
                  const temp = this.asOptions.asDateOptions.find(e => this.state.isTodayOrAfter === e.codeKey)
                  arr.push({text:  temp.codeValue})
                }
                // 접수 유형
                if (!this.$isEmpty(this.state.workorderReceiveTypeList)) {
                  CODE.WORKORDER_RECEIVE_TYPE_ITEMS.filter(e => !!this.state.workorderReceiveTypeList.find(c => e.codeKey === c))
                          .map(e => e.codeValue).forEach(e => arr.push({text: e}));
                }

                // 접수구분
                if (!this.$isEmpty(this.state.asReceiptTypeList)) {
                    this.asReceiptTypeOptions.filter(e => !!this.state.asReceiptTypeList.find(c => e.codeKey === c))
                        .map(e => e.codeValue).forEach(e => arr.push({text: e}));
                }

                // 진행상태
                if (!this.$isEmpty(this.state.workorderStateList)) {
                    this.workorderStateOptionsExcluded.filter(e => !!this.state.workorderStateList.find(c => e.codeKey === c))
                        .map(e => e.codeValue).forEach(e => arr.push({text: e}));
                }

                // 접수 증상
                if (!this.$isEmpty(this.state.reasonCodeList)) {
                    this.reasonCodeOptions.filter(e => !!this.state.reasonCodeList.find(c => e.codeKey === c))
                        .map(e => e.codeValue).forEach(e => arr.push({text: e}));
                }

                // A/S 기사
                if (!this.$isEmpty(this.state.workorderStaffList)) {
                    this.workorderStaffOptions.filter(e => !!this.state.workorderStaffList.find(c => e.userId === c))
                        .map(e => e.userFullname).forEach(e => arr.push({text: e}));
                }



                return arr;
            },
        },

        watch: {
          /**
           * 전체건이 아닐 때는 종료일자를 비워준다.
           * **/
          'state.isTodayOrAfter'(newValue) {
            if(newValue) this.state.endDate = null
          },
        },

        mounted() {
            if (this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
                this.state = this.getDefaultState();
            } else {
                this.state = this.getState() || this.getDefaultState();
            }

            this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장
            this.loadOptions();
        },
        methods: {
            getDefaultState() {
                let obj = {
                    buttonIndex: 4,
                    startDate: null,
                    endDate: this.$route.name === 'MainAs' ? this.$now().endOf("day").toISO() : null,
                    workorderReceiveTypeList: [],
                    reasonCodeList: [],
                    asReceiptTypeList: [],
                    workorderStateList: [],
                    workorderStaffList: [],
                    isReservation: false,
                    isTodayOrAfter: null,

                };

                if (this.propsData.state) {
                    obj = {...obj, ...this.propsData.state};
                }

                return obj;
            },

            generateState() {
                let newVar = {
                    buttonIndex: this.state.buttonIndex,
                    startDate: this.state.startDate || null,
                    endDate: this.state.endDate || null,
                    workorderReceiveTypeList: this.state.workorderReceiveTypeList || [],
                    reasonCodeList: this.state.reasonCodeList || [],
                    asReceiptTypeList: this.state.asReceiptTypeList || [],
                    workorderStateList: this.state.workorderStateList || [],
                    workorderStaffList: this.state.workorderStaffList || [],
                    isRegister: this.state.isRegister,
                    isReservation: this.state.isReservation,
                    isTodayOrAfter: this.state.isTodayOrAfter,
                };

                if (this.excludeWorker) {
                    delete newVar.workorderStaffList;
                }

                if (this.excludeWorkorderState) {
                    delete newVar.workorderStateList;
                }

                return newVar
            },

            loadOptions() {
                this.loadOptionsForReasonCode();
                this.loadOptionsForAsReceiptType();
                this.loadOptionsForWorkorderState();
                this.loadOptionsForWorkorderStaff();
            },

            onClickClose() {
                this.close();
            },

            onClickApply() {
                const state = this.generateState();
                this.setState(state);
                const s = {
                    ...state,
                    buttonIndex: undefined,
                }
                this.callAndClose({state: s, filterNameList: this.filterNameList});
            },
        }
    }
</script>

<style scoped>
    >>> .v-dialog {
        overflow: hidden !important;
    }

    >>> .v-select__selections > input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-select__selections > input:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-dialog > .v-card > .v-card__text {
        padding: 0 24px 0 !important;
    }

    >>> .v-item-group {
        flex: 1;
    }

    >>> .v-btn-toggle > .v-btn.v-btn {
        flex: 1 !important;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
    }

    >>> .v-text-field__details {
        display: none;
    }
</style>
