/**
 * 서버 API 요청시 전체화면 Loading Overlay 시 사용합니다.
 */
export const ON_LOADING_OVERLAY_OPEN_REQUESTED = "ON_LOADING_OVERLAY_OPEN_REQUESTED";
export const ON_LOADING_OVERLAY_CLOSE_REQUESTED = "ON_LOADING_OVERLAY_CLOSE_REQUESTED";

//
// 프레임워크에 관련된 전역 이벤트입니다.
//
export const ON_ROOT_WINDOW_MANAGER_CHANGED = 'onRootWindowManagerChanged';
export const ON_WINDOW_TREE_CHANGED = 'onWindowTreeChanged';
export const ON_COMPONENT_REQUESTED = 'onComponentRequested';
export const ON_SCREEN_REQUEST = 'onScreenRequest';
export const ON_NAV_MENU_MOUSE_OVER = 'onNavMenuMouseOver';
export const ON_NAV_MENU_MOUSE_OUT = 'onNavMenuMouseOut';
export const ON_WORKORDER_STATUS_UPDATED = "ON_WORKORDER_STATUS_UPDATED";
//
// 모달에 관련된 이벤트입니다.
//
export const ON_MODAL_DETACHED = 'onModalDetached';
export const ON_MODAL_ATTACHED = 'onModalAttached';
export const ON_MODAL_RESULT = 'onModalAction';
export const ON_MODAL_OPEN_REQUESTED = 'onModalOpenRequested';
export const ON_MODAL_CLOSE_REQUESTED = 'onModalCloseRequested';
export const ON_CLOSE_MODAL_CLICKED = 'onCloseModalClicked';
//
// 다이알로그에 관련된 이벤트입니다.
//
export const ON_DIALOG_SHOW = 'onDialogShow';
export const ON_DIALOG_HIDE = 'onDialogHide'; // dismiss
//
//
//
export const ON_BRANCH_DELETED = 'onBranchDeleted';
export const ON_BRANCH_REGISTERED = 'onBranchRegistered';
export const ON_BRANCH_CHANGED = 'onBranchChanged';
export const ON_ACCOUNT_DELETED = 'onAccountDeleted';
export const ON_ACCOUNT_REGISTERED = 'onAccountRegistered';
export const ON_ACCOUNT_CHANGED = 'onAccountChanged';
export const ON_CONTRACT_SCREEN_REQUEST = 'onContractScreenRequested';
export const ON_CONTRACT_REGISTERED = 'onContractRegistered';
export const ON_DEVICE_RECEIVED = 'onDeviceReceived';
export const ON_PRINTER_CONTRACT_REGISTERED = 'onPrinterContractRegistered';
export const ON_CONTRACT_PRINTER_REGISTERED = 'onContractPrinterRegistered';
export const ON_AS_UPDATED = 'onAsUpdated';
export const ON_CMS_UPDATED = 'onCmsUpdated';
export const ON_MANAGE_UPDATED = "onManageUpdated";
export const ON_NOTICE_UPDATED = "onNoticeUpdated";
export const ON_PRINTER_MESSAGE_UPDATED = "onPrinterMessageUpdated";
export const ON_PRINTER_RULE_REGISTERED = "onPrinterRuleRegistered";
export const ON_PRINTER_SERIES_REGISTERED = "onPrinterSeriesRegistered";
export const ON_AGENT_VERSION_REGISTERED = "onAgentVersionRegisterd";
export const ON_AGENT_INTERVAL_RECEIVED = "onAgentIntervalReceived";
export const ON_PRINTER_REFILL_RECEIVED = "onPrinterRefillReceived";

//매출 관련 화면에서 사용
export const ON_SALES_REFRESH = 'onSalesRefresh';
//
//
//
/**
 * 지도에 pan 이 요청될 때 호출됩니다.
 */
export const ON_MAP_PAN_REQUESTED = 'onMapPanRequested';
//
//
//

///////////////////////////////////////////////////////////////////////////
// 작업관련
///////////////////////////////////////////////////////////////////////////
export const ON_STAFF_ASSIGNED = 'onStaffAssigned';

export const ON_VALUE_CHANGED0 = 'onValueChanged0';
export const ON_VALUE_CHANGED1 = 'onValueChanged1';
export const ON_VALUE_CHANGED2 = 'onValueChanged2';
export const ON_VALUE_CHANGED3 = 'onValueChanged3';
export const ON_VALUE_CHANGED4 = 'onValueChanged4';

// export {
//     ON_COMPONENT_REQUESTED,
//     ON_MODAL_DISMISS,
//     ON_MODAL_CLOSED,
//     ON_ADDRESS_SELECTED
// };
