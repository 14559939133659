<template>
  <div
    :style="styleObject"
    class="m-layout caption"
  >
    <slot />
  </div>
</template>

<script>
    /**
     * v-app-bar 와 v-bottom-navigation 에 반응해서 자동으로 크기를 조정하는 레이아웃 입니다.
     * v-app-bar 와 v-bottom-navigation 의 형제로 존재할 때 동작합니다.
     */
    export default {
        name: "MLayout",
        props: {
            autoResize: {type: Boolean, default: false},
            offsetY: {type: Number, default: 0},
            topRelative: {type: Number, default: 0},
        },
        data() {
            return {
                appBarHeight: 0,
                bottomNavHeight: 0,
                resizeObserver: undefined,
            }
        },
        computed: {
            outsideSpace() {
                return this.appBarHeight + this.topRelative + this.bottomNavHeight;
            },
            styleObject() {
                const style = {
                    top: (this.appBarHeight + this.offsetY) + 'px',
                    bottom: this.bottomNavHeight + 'px'
                };
                if (this.outsideSpace > 0) {
                    style.height = `calc(100% - ${this.outsideSpace}px)`;
                } else {
                    style.height = '100%';
                }
                return style;
            },
        },
        created() {
            this.resizeObserverAppBar = new ResizeObserver(this.resizeHandler);
        },
        mounted() {
            this.observe('v-app-bar');
            this.observe('v-bottom-navigation');
        },
        beforeUpdate() {
            this.resizeObserverAppBar.disconnect();
        },
        updated() {
            this.observe('v-app-bar');
            this.observe('v-bottom-navigation');
        },
        beforeDestroy() {
            this.resizeObserverAppBar.disconnect();
        },
        methods: {
            getUniqueElement(className) {
                const candidates = this.$el.parentElement.getElementsByClassName(className);
                if (candidates && candidates.length === 1) return candidates[0];
                return undefined;
            },
            observe(className) {
                const target = this.getUniqueElement(className);
                if (target) this.resizeObserverAppBar.observe(target);
            },
            resizeHandler(entries, observers) {
                console.log('resizeHandler : ' + entries.length);
                const targets = entries.map(e => e.target);
                const appBar = targets.find(e => e.className.includes('v-app-bar'));
                const bottomNav = targets.find(e => e.className.includes('v-bottom-navigation'));
                this.appBarHeight = appBar ? appBar.clientHeight : 0;
                this.bottomNavHeight = bottomNav ? bottomNav.clientHeight : 0;
            }
        }
    }
</script>

<style scoped>
    .m-layout {
        position: relative;
        left: 0;
        right: 0;
    }
</style>