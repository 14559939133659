import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";
import masker from "vue-the-mask/src/masker";

/**
 * 포맷 변환을 지원합니다.
 */
class FormatHelper {

    constructor() {

    }

    formatSerial(value) {
        if (isNOU(value)) return "";
        let mask = ['XXXX-XXXX-XXXX-XXXX-XXXX'];
        const tokens = {'X': {pattern: /[0-9a-zA-Z]/}};
        return masker(value, mask, true, tokens);
    }
}

export const formatHelper = new FormatHelper();