import store from "@/store";

class VuexManager {
    /**
     * props 데이터를 vuex에 target 키로 저장합니다.
     */
    saveProps(target, props) {
        if (props) {
            const arr = [];
            const keys = Object.keys(props);
            keys.forEach(key => arr.push({ key: key, value: props[key]}));
            store._actions['props/SAVE_PROPS'][0]({target: target, props: arr});
        }
    }
}

const vuexManager = new VuexManager();

export {vuexManager}