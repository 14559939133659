import {GET_SALES_SUM_DATA, GET_SALES_LIST_DATA,
    GET_ACCOUNT_SUM_DATA, GET_ACCOUNT_LIST_DATA,
    GET_CONTRACT_LIST_DATA,GET_WORK_SUM_DATA, GET_WORK_LIST_DATA,
    GET_AUTO_SUM_DATA

} from "./keys";

export default {
    [GET_SALES_SUM_DATA]: (state) => {
        return state.salesForSum;
    },
    [GET_SALES_LIST_DATA]: (state) => {
        return state.salesForTrend;
    },
    [GET_ACCOUNT_SUM_DATA]: (state) => {
        return state.accountForSum;
    },
    [GET_ACCOUNT_LIST_DATA]: (state) => {
        return state.accountForTrend;
    },
    [GET_CONTRACT_LIST_DATA]: (state) => {
        return state.contractList;
    },
    [GET_WORK_SUM_DATA]: (state) => {
        return state.workForSum;
    },
    [GET_WORK_LIST_DATA]: (state) => {
        return state.workForTrend;
    },
    [GET_AUTO_SUM_DATA]: (state) => {
        return state.autoForSum;
    },
}