<script>
    import MBaseFormPicker from "@/assets/plugins/mps-form/providers/MBaseFormPicker";
    import {isNOU} from "@/assets/plugins/mps-common/expansions/condition";

    export default {
        name: "MFormPrinterPicker",
        extends: MBaseFormPicker,
        computed: {
            display() {
                if (!this.options) return null;
                let _t = this.options.printerModelName;
                if (this.options.printerSerialNo) _t += "[" + this.options.printerSerialNo + "]";
                return _t;
            },

            titleComputed() {
                if (!this.options) return null;
                let _t = this.options.printerModelName;
                if (this.options.printerSerialNo) _t = this.options.printerSerialNo;
                return _t;
            },

            searchOptions() {
                if (!this.options) return null;
                return {
                    accountId: this.options.accountId,
                    accountBranchId: this.options.accountBranchId,
                    whetherLoadPrinterModels: this.options.whetherLoadPrinterModels,
                    whetherLoadPrinters: this.options.whetherLoadPrinters,
                    whetherLoadUserModels: this.options.whetherLoadUserModels,
                };
            },
        },
        watch: {
            options: {
                deep: true,
                handler: function () {
                    if (isNOU(this.options.printerModelName)) { // 오직 초기화 목적!
                        this.model = this.options.printerModelName;
                    }
                }
            }
        },
    }
</script>