export default class Address {

    _item;

    constructor(item) {
        this._item = item;
    }

    get raw() {
        return this._item;
    }

    get key() {
        return this._item.roadAddr || this._item.jibunAddr;
    }
}