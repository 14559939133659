class Path {

    _key;

    constructor(key) {
        this._key = key;
    }

    get key() {
        return isNaN(this._key) ? this._key : Number.parseInt(this._key);
    }

}

class PathFinder {

    _path = [];

    constructor(path) {
        const split = path.split('.');

    }

    get length() {
        return this._path.length;
    }

    get paths() {
        return this._path;
    }

    inspectGetter(object) {
        let target = object;
        for (let e of this._path) target = target[e.key];
        return target;
    }

}

/**
 * 리스트 형 데이터의 end-of-data 를 평가하는 평가기 입니다.
 * 제공된 countPerPage 의 개수로 더 이상 표시할 데이터가 없는지 여부를 평가 후 반환합니다.
 */
export default class Evaluator {

    _countPerPage;
    _key;

    constructor(countPerPage, key = undefined) {
        this._countPerPage = countPerPage;
        this._key = key;
    }

    evaluate = (response, key) => {
        const definedKey = key || this._key;
        let items;
        // 키가 없습니다. 대상을 평가합니다.
        if (definedKey === undefined || definedKey === null) {
            items = response;
        }
        // 접근 키가 있습니다. 대상 내부 경로에 접근해서 평가합니다.
        else {
            items = response;
            definedKey.split('.')
                .map(key => isNaN(key) ? key : Number.parseInt(key))
                .forEach(key => items = items[key]);
        }
        // 응답된 데이터가 없거나 원하는 수량에 미치지 못하면 데이터가 더 이상 없는것으로 취급합니다.
        if (items === undefined || items == null || typeof items !== "object" || !Array.isArray(items)) {
            items = [];
        }
        const endOfData = items.length < this._countPerPage;
        return {items, endOfData};
    };

    set countPerPage(value) {
        this._countPerPage = value;
    }

    set key(value) {
        this._key = value;
    }

}
