<template>
  <div>
    <template v-if="inEditMode">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on }">
          <v-textarea
            ref="textarea"
            :value="display"
            :clearable="clearable"
            :label="label"
            :loading="loading"
            readonly
            :disabled="disabled"
            :placeholder="placeholder"
            :rules="rules"
            :auto-grow="true"
            :rows="1"
            v-on="on"
          />
        </template>

        <m-form-address-full-dialog
          :title="titleComputed"
          :dialog.sync="dialog"
          @pick="onPick"
        />
      </v-dialog>
    </template>
    <template v-else>
      <v-textarea
        ref="textarea"
        :value="display"
        :clearable="clearable"
        :label="label"
        :loading="loading"
        readonly
        :placeholder="placeholder"
        :rules="rules"
        :auto-grow="true"
        :rows="1"
      />
    </template>
  </div>
</template>

<style scoped>

</style>

<script>
    import MFormAddressPicker from "@/assets/plugins/mps-form/providers/MFormAddressPicker";
    import MFormAddressFullDialog from "@/assets/plugins/mps-form/providers/components/dialog/MFormAddressFullDialog";

    /**
     * 사용하는 옵션
     * {items, itemName(optional), itemValue(optional)}
     */
    export default {
        name: "MFormVuetifyAddressPicker",
        components: {MFormAddressFullDialog},
        mixins: [MFormAddressPicker],
        data() {
            return {
                dialog: false,
            }
        },
        computed: {},
        methods: {
            onPick(pick) {
                this.dialog = false;
                this.model = pick;
            },
        },
    }
</script>
