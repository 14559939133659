<script>
import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";

export default {
  name: "SearchFilterInterface",
  mixins: [ModalVuetifyUser],
  props: {
    state: {
      type: Object,
      default: undefined
    },
    propsData: undefined,
  },
  data() {
    return {
      // 모달창을 이미 한 번 열었습니다.
      alreadyOpened: false,
    }
  },
}
</script>