<template>
  <div style="display: inline-block; position: relative;">
    <v-switch
      ref="switch"
      v-model="model"
      v-ripple="false"
      :color="color"
      :disabled="disabled"
      class="mt-0 pt-0"
      inset
      hide-details
    />
    <span
      ref="trueText"
      class="true_text"
      :style="{visibility: !model ? 'hidden' : 'visible', color: trueTextColor}"
    >{{ trueText }}</span>
    <span
      ref="falseText"
      class="false_text cumulative_usage"
      :style="{visibility: model ? 'hidden' : 'visible', color: falseTextColor}"
    >{{ falseText }}</span>
  </div>
</template>

<script>
    export default {
        name: "VueSwitch",
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: undefined,
            },
            backgroundColor: {
                type: String,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            trueText: {
                type: String,
              default: "true",
            },
            falseText: {
              type: String,
              default: "false",
            },
            trueTextColor: {
                type: String,
                default: '#333333',
            },
            falseTextColor: {
                type: String,
                default: '#FFFFFF',
            },
            trueDialColor: {
                type: String,
                default: '#2A5270',
            },
            falseDialColor: {
                type: String,
                default: '#A4A4A4',
            },
            trueBackgroundColor: {
                type: String,
                default: "#BCD1E2",
            },
            falseBackgroundColor: {
                type: String,
                default: "#3F4040",
            },
        },
        data() {
            return {
                model: this.value,
                trueTextWidth: 0,
                falseTextWidth: 0,
            };
        },
        computed: {
            switchWidth() {
                if (this.trueTextWidth > this.falseTextWidth) return this.trueTextWidth;
                if (this.trueTextWidth < this.falseTextWidth) return this.falseTextWidth;
                return this.falseTextWidth;
            },
        },
        watch: {
            value() {
                this.model = this.value;
            },

            model(newValue) {
                this.$emit("input", newValue);
                this.toggleStyle();
            },
        },
        mounted() {
            // trueText width 를 구합니다.
            this.trueTextWidth = this.$refs.trueText.clientWidth;
            // falseText width 를 구합니다.
            this.falseTextWidth = this.$refs.falseText.clientWidth;

            this.$nextTick(() => {
              // switch 의 width 값을 텍스트에 맞춰 조정합니다.
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input").style.width = (this.switchWidth + 40) + 'px';
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--switch__track").style.width = (this.switchWidth + 40) + 'px';
              // ripple 효과를 제거합니다.
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--selection-controls__ripple").style.display = "none";
              // model 값에 따라 스타일을 맞춥니다.
              this.toggleStyle();
            });
        },
        methods: {
          /**
           * model 값에 따라(true or false) 엘리먼트의 스타일을 변경합니다.
           */
          toggleStyle() {
            if (this.model) {
              this.setTrueBackgroundColor();
              this.setTrueDialPosition();
              this.setTrueDialogPosition();
            } else {
              this.setFalseBackgroundColor();
              this.setFalseDialPosition();
              this.setFalseDialogPosition();
            }
          },

          /**
           * model 값이 true 일 때 스위치의 배경 색상을 변경합니다.
           */
          setTrueBackgroundColor() {
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--switch__track").style.backgroundColor = this.trueBackgroundColor;
          },

          /**
           * model 값이 false 일 때 스위치의 배경 색상을 변경합니다.
           */
          setFalseBackgroundColor() {
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--switch__track").style.backgroundColor = this.falseBackgroundColor;
          },

          /**
           * model 값이 true 일 때 다이얼의 색상을 변경합니다.
           */
          setTrueDialPosition() {
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--switch__thumb").style.backgroundColor = this.trueDialColor;
          },

          /**
           * model 값이 false 일 때 다이얼의 색상을 변경합니다.
           */
          setFalseDialPosition() {
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--switch__thumb").style.backgroundColor = this.falseDialColor;
          },

          /**
           * model 값이 true 일 때 다이얼의 위치를 조정합니다.
           */
          setTrueDialogPosition() {
              this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--switch__thumb").style.left = (this.switchWidth - 9) + 'px';
          },

          /**
           * model 값이 false 일 때 다이얼의 position 원래대로 돌립니다.
           */
          setFalseDialogPosition() {
            this.$refs.switch.$el.querySelector(".v-input--selection-controls__input > .v-input--switch__thumb").style.left = 0;
          },
        },
    }
</script>

<style scoped>
  >>> .v-input__control {
      position: relative;
      top: 3px;
  }

  .false_text {
    position: absolute;
    right: 20px;
    top: 4px;
    pointer-events: none;
  }

  .true_text{
    position: absolute;
    left: 5px;
    top: 4px;
    pointer-events: none;
  }
</style>
