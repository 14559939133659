<template>
  <v-card>
    <v-toolbar
      color="white"
      elevation="0"
      class="d-flex align-center"
    >
      <dialog-title :text="'거절하기' | translate" />

      <v-btn
        text
        class="button__Close mr-0"
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </v-toolbar>


    <v-list
      class="px-0"
      subheader
      three-line
    >
      <v-list-item>
        <v-list-item-content>
          <v-textarea
            v-model="editData.changedReason"
            v-focus-delay:child-textarea="400"
            :label="'거절사유' | translate"
            outlined
          />
        </v-list-item-content>
      </v-list-item>

      <dialog-apply-button
        :text="'등록' | translate"
        @click:apply="onClickApply"
      />
    </v-list>
  </v-card>
</template>

<script>
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import DialogApplyButton from "@/components/part/sub/DialogApplyButton";
    import DialogTitle from "@/components/part/sub/DialogTitle";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";

    export default {
        name: "AsDenyVuetifyModal",
        components: {DialogTitle, DialogApplyButton},
        mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                editData: {},
            }
        },
        mounted() {
            this.editData = this.getState() || this.getDefaultState();
        },
        created() {
            this.editData = this.getDefaultState();
        },
        methods: {
            getDefaultState() {
                return {
                    changedReason: null,
                }
            },

            onClickClose() {
                this.setState(this.editData); // 닫기전 상태값을 저장한다.
                this.close();
            },

            onClickApply() {
                this.setState(undefined); // 적용을 누르면 상태값을 삭제한다.

                const serverData = {
                    changedReason: this.editData.changedReason,
                }

                this.callAndClose(serverData);
            },
        }
    }
</script>

<style scoped>
    >>> .v-toolbar__content, .v-toolbar__extension {
        width: 100%;
        justify-content: space-between;
    }

    >>> .v-toolbar__items {
        flex: 1;
        justify-content: center;
    }

    >>> .v-text-field--outlined fieldset {
        border: 1px solid #ddd !important;
    }

    >>> .v-text-field--outlined {
        border-radius: 0px;
    }

    >>> .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: calc(100vh - 135px) !important;
        max-height: calc(100vh - 135px) !important;
    }
</style>