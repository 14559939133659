import clone from "./clone";
import {isNOU} from "../condition";

/**
 * 원본 데이터를 대상에 복사해서 반환합니다.
 * 깊은 복사를 수행합니다. (객체 참조 복사가 아닌 원시형 값을 조사해서 복사)
 *
 * @param src 원본
 * @param dst 대상. option.
 * @returns {{복사된 객체}}
 */
export default (src, dst) => {
    // 복사 대상이 존재하지 않으면, 객체로 시작합니다.
    if (isNOU(dst)) dst = {};
    // 단순히 값을 복사 대상에 추가합니다.
    const processor = (src, dst, key, value) => dst[key] = value;
    clone(src, dst, processor);
    return dst;
};
