<template>
  <div style="overflow: hidden; pointer-events: none">
    <!--다이알로그가 보일때는 블락킹-->
    <!--    <block-layer :block="showing" />-->
    <div
      class="text-xs-center"
    >
      <v-snackbar
        v-model="showing"
        top
        class="text-center"
        :class="color"
        multi-line
        vertical
        :timeout="1500"
      >
        {{ message }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
    // import BlockLayer from "@/framework/BlockLayer";
    import {ACTION_SHOW_SNACKBAR} from "@/assets/plugins/mps-snackbar/constants";

    export default {
        name: "SnackbarOverlay",
        // components: {BlockLayer},

        data() {
            return {
                showing: false,
                message: null,
                color: 'primary',
            };
        },

        computed: {},

        created() {
            this.$mps_snackbar_event_bus.$on(ACTION_SHOW_SNACKBAR, this.showDialog);
        },

        beforeDestroy() {
            this.$mps_snackbar_event_bus.$off(ACTION_SHOW_SNACKBAR, this.showDialog);
        },

        methods: {
            spreadOptions: function (options) {
                this.message = options.message;
                this.color = options.color;
            },
            showDialog(options) {
                if (this.showing) return;
                if (this.$isNOU(options)) return;
                this.spreadOptions(options);
                this.showing = true;
            },
        }
    }
</script>

<style scoped>
    >>> .primary > .v-snack__wrapper {
        background-color: #82B1FF;
    }

    >>> .secondary > .v-snack__wrapper {
        background-color: #424242;
    }

    >>> .accent > .v-snack__wrapper {
        background-color: #ee44aa;
    }

    >>> .error > .v-snack__wrapper {
        /*background-color: #FF5252;*/
        background-color: #EC4DA3 !important;
    }

    >>> .info > .v-snack__wrapper {
        background-color: #2196F3;
    }

    >>> .success > .v-snack__wrapper {
        background-color: #4CAF50;
    }

    >>> .warning > .v-snack__wrapper {
        background-color: #FFC107;
    }

    .v-application .red {
        background-color: #EC4DA3 !important;
        border-color: #EC4DA3 !important;
    }

    >>> .red > .v-snack__wrapper {
        background-color: #EC4DA3 !important;
    }

    >>> .v-snack--bottom {
        bottom: 70px;
    }

    >>> .v-snack--top {
        top: 50px;
    }

    .v-snack {
        height: 0;
        padding: 0 !important;
    }

    .v-snack__content {
        margin-right: 0 !important;
        margin: auto !important;
    }

    >>> .v-snack__content {
        margin-right: 0 !important;
        margin: auto !important;
    }

    .v-snack--vertical .v-snack__wrapper .v-snack__action {
        margin: 0 !important;
        margin-bottom: 0 !important;
    }

    >>> .v-snack--vertical .v-snack__wrapper .v-snack__action {
        margin: 0 !important;
        margin-bottom: 0 !important;
    }

    >>> .v-snack__action {
        margin: 0 !important;
        margin-bottom: 0 !important;
    }
</style>
