import Vue from 'vue'
import Vuex from 'vuex'

import props from "./modules/props"
import dashboard from "./modules/dashboard"

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        props, // 라우터 props 정보
        dashboard
    }
})

export default store;
