<template>
  <div>
    <div
      class="mobile-input"
      :class="`depth-${depth}`"
    >
      <div class="mobile-input-wrap-title color__333 text--color">
        {{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click.stop="showHideDescription"
        >
          <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <slot name="default" />
    </div>
    <slot name="description">
      <div
        v-if="descriptionOn"
        class="mobile-input-description"
      >
        {{ t(description) | '' }}
      </div>
    </slot>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";

export default {
  name: "MobileGroupHeader",
  extends: MobileBaseInput,
  props: {
    depth: {
      type: Number,
      default: 1,
    }
  },
}
</script>

<style lang="scss" scoped>

.depth-1{
  padding: 0.5rem 0.5rem;
  height: 32px;
  /*background: rgb(230, 230, 230);*/
  background-color: #efefef;
}

.depth-2{
  padding: 0.5rem 1.5rem;
  height: 28px;
  font-size: 0.875rem;
  background: white;
}

.mobile-input{
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &-wrap-title{
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    font-weight: bold;
    font-size: 0.8125rem;
    line-height: 1.2rem;
    &-btn{
      color: #0c98fe;
      transform: translateY(4px);
    }
  }
}
.mobile-input-description{
  font-size: 0.810rem;
  line-height: 1.2rem;
  color: #0c98fe;
  text-align: left;
  padding: 0.25rem 0.25rem;
  /*&:before{
    content: 'ⓘ';
  }*/
}
</style>