/**
 * 두 배열이 같은지 여부를 반환합니다.
 * @param array1
 * @param array2
 * @returns {boolean}
 */
export default (array1, array2) => {
    if (array1 === undefined && array2 === undefined) return true; // 같은 undefined.
    if (array1 === null && array2 === null) return true; // 같은 null.
    if (!Array.isArray(array1)) throw new Error('Array1 as parameter1 is not an array.');
    if (!Array.isArray(array2)) throw new Error('Array2 as parameter1 is not an array.');
    if (array1.length !== array2.length) return false;
    const length = array1.length;
    for (let i = 0; i < length; i++) {
        if (array1[i] === array2[i]) continue;
        return false;
    }
    return true;
};
