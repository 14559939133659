import {blockOverlayEventBus} from "@/assets/plugins/block-overlay/constants";
import BlockOverlayLayerLegacy from "@/assets/plugins/block-overlay/BlockOverlayLayerLegacy";
import {hideBlockOverlay, showBlockOverlay} from "@/assets/plugins/block-overlay/preset";

export default {
    install(Vue, options) {
        Vue.component(BlockOverlayLayerLegacy);
        Vue.prototype.$blockOverlayEventBus = blockOverlayEventBus;
        Vue.prototype.$showBlockOverlay = (callback) => showBlockOverlay(callback);
        Vue.prototype.$hideBlockOverlay = () => hideBlockOverlay();
    }
}