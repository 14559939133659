<template>
  <v-row
    class="mps-form-wrapper"
  >
    <v-col
      cols="4"
      class="mps-form-label__wrapper"
    >
      <label class="mps-form-label">{{ label }}</label>
    </v-col>

    <v-col
      cols="8"
      class="mps-form-field__wrapper"
    >
      <input
        v-model="model"
        class="form-textfield"
        :clearable="clearable"
        :disabled="disabled"
        :label="label"
        :loading="loading"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="rules"
        type="text"
      >
    </v-col>
  </v-row>
</template>

<style scoped>

</style>

<script>
    import MBaseFormElement from "../MBaseFormElement";

    export default {
        name: "MFormMpsTextField",
        mixins: [MBaseFormElement]
    }
</script>
