import EventSender from './event-sender';

export default {

    install(Vue, options) {
        Vue.prototype.$listenerEventBus = new Vue(); // 이벤트 리스너 구조에서 사용됩니다.
        Vue.mixin(EventSender); // 어디에서나 이벤트를 발송할 수 있도록 글로벌 믹스인합니다.
    }

}
