/**
 * 파라미터가 원시형인지 여부를 검사합니다.
 * 자바스크립트에서 원시형은 boolean, number, string 만 해당됩니다.
 * @param v
 * const * @returns {boolean} true =해당 변수가 원시형 참조.
 */
export default (v) => {
    switch (typeof v) {
        case "boolean":
        case "number":
        case "string":
            return true;
        case "undefined":
        case "object":
        case "function":
        case "symbol":
        case "bigint":
        default:
            return false;

    }
};
