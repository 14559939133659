<template>
  <div>
    <v-row
      class="mps-form-wrapper"
    >
      <v-col
        :cols="12"
        class="mps-form-label__wrapper mps-form-label__full"
      >
        <span
          v-if="inEditMode && required"
          class="color__red mr-1"
        >*</span>
        <label class="mps-form-label">{{ label }}</label>
      </v-col>

      <v-col
        :cols="12"
        class="mps-form-field__wrapper"
        :class="{'mps-form-field__full': fullWidthLabel}"
        style="padding-left: 18px;"
      >
        <template v-if="inEditMode && !disabled">
          <div class="in-edit-mode">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="quill-wrapper"
                  v-on="on"
                >
                  <div
                    class="ql-container ql-snow"
                    style="border: 0; width: 100%; text-align: left;"
                  >
                    <div class="ql-editor">
                      <span v-html="model" />
                    </div>
                  </div>
                </div>
              </template>

              <m-form-quill-editor-full-dialog
                :html="model"
                @close="onClose"
                @save="onSave"
              />
            </v-dialog>
          </div>
        </template>

        <template v-else>
          <div class="in-view-mode">
            <div class="quill-wrapper">
              <div
                class="ql-container ql-snow"
                style="border: 0; width: 100%; text-align: left;"
              >
                <div class="ql-editor">
                  <span v-html="model" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import MBaseFormTextarea from "@/assets/plugins/mps-form/providers/MBaseFormTextarea";
    import MFormQuillEditorFullDialog
        from "@/assets/plugins/mps-form/providers/components/dialog/MFormQuillEditorFullDialog";

    export default {
        name: "MFormMpsQuillEditor",
        components: {MFormQuillEditorFullDialog},
        extends: MBaseFormTextarea,
        mixins: [],
        data() {
            return {
                dialog: false,
            }
        },
        computed: {},
        watch: {},
        mounted() {
        },
        methods: {
            onClose(html) {
                this.model = html;
                this.dialog = false
            },
            onSave(html) {
                this.onClose(html);
            },
        }
    }
</script>

<style scoped>
    >>> .border_bottom {
        border-bottom: 1px solid #ddd !important;
    }

    >>> .border_bottom > div:last-child {
        flex-basis: 50px !important;
        flex-grow: 1 !important;
    }

    .quill-wrapper {

    }

    .in-view-mode .quill-wrapper {
        padding: 1px;
    }

    .in-edit-mode .quill-wrapper {
        border: 1px solid #ddd;
    }
</style>
