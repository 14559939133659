<template>
  <v-text-field
    v-model="model"
    :clearable="clearable"
    :disabled="disabled"
    :label="label"
    :loading="loading"
    :placeholder="placeholder"
    :readonly="readonly"
    :rules="rules"
  />
</template>

<style scoped>

</style>

<script>
    import MBaseFormElement from "../MBaseFormElement";

    export default {
        name: "MFormVuetifyTextField",
        mixins: [MBaseFormElement]
    }
</script>
