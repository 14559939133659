export default class Code {

    _item;

    constructor(item) {
        this._item = item;
    }

    get raw() {
        return this._item;
    }

}