<template>
  <div>
    <div
      v-if="menu"
      style="opacity: 0; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 7;"
      @click.self.stop.prevent="onClickOverlay"
    />
    <v-select
      ref="vSelect"
      v-model="model"
      :items="items"
      :clearable="clearable"
      :item-text="itemText"
      :item-value="itemValue"
      :placeholder="placeholder"
      :multiple="multiple"
      :class="clazz"
      :hide-details="hideDetails"
      :outlined="outlined"
      :label="label"
      @focus="onFocus"
      @input="onChange"
      @click:clear="onClear"
    />
  </div>
</template>

<script>
    /**
     * v-select 를 그대로 이용하지만 backguard 와 overlay 를 설정하여 이용 편의성을 제공합니다.
     *
     * backguard: 뒤로가기시 셀렉트 박스 메뉴만 닫음
     * overlay: 메뉴 말고 다른 곳 터치 방지
     */
    export default {
        name: "VSelectBackguard",
        props: {
            value: undefined,
            multiple: {
                type: Boolean,
                default: false,
            },
            itemText: {
                type: String,
                default: "codeValue"
            },
            itemValue: {
                type: String,
                default: "codeKey"
            },
            placeholder: {
                type: String,
                default: undefined,
            },
            clazz: {
                type: String,
                default: undefined
            },
            items: {
                type: Array,
                default: () => [],
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: undefined,
            },
            outlined: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                menu: false,
                model: this.value,
                clickClear: false, // clear 버튼이 클릭되었는지 여부
            };
        },
        watch: {
            value() {
                this.model = this.value;
            },
            model() {
                this.$emit("input", this.model);
            },
        },
        methods: {
            /**
             * 셀렉트 박스가 활성화 되면 Backguard 를 설정합니다.
             */
            onFocus() {
                // clear 이벤트 발생후 focus 가 발생되므로
                // clear 시 focus 이벤트를 무시한다.
                if (this.clickClear) {
                    this.clickClear = false;
                    this.$refs.vSelect.blur();
                    return;
                }

                this.menu = true;
                this.backGuard.push(() => {
                    this.menu = false;
                    this.$refs.vSelect.blur();
                });
            },

            /**
             * 셀렉트 박스가 활성화 되면 메뉴를 제외한 전체 화면에 Overlay 가 설정됩니다.
             * Overlay 클릭시 Backguard 를 제거하며 셀렉트박스를 닫습니다.
             */
            onClickOverlay() {
                this.menu = false;
                this.backGuard.pop();
            },

            /**
             * 싱글 셀렉트인 경우 값이 선택되었을 때 Overlay와 Backguard 를 제거합니다.
             */
            onChange() {
                if (!this.menu) return; // 메뉴가 활성화 되지 않은 경우 clear 버튼 클릭시 backguard 가 제거되는 현상을 방지하기 위해 리턴합니다.
                if (this.multiple) return;
                this.menu = false;
                this.backGuard.pop();
                this.$refs.vSelect.blur();
            },

            /**
             * clear 버튼 클릭시 플래그를 설정한다.
             * clear 이벤트 발생후 focus 가 일어난다.(onFocus 참조)
             */
            onClear() {
                this.clickClear = true;
            },
        },
    }
</script>

<style scoped>

</style>